import { IChallengeLogDayData, IChallengeLoggedDay, IChallengePledgesData,
    IChallengeResponse,
    IChallenges, ICommitPledgeResponse, ILastDayToTrackChallenge, IMyPledgesData,
    IRemindMeNotificationPost,
    IRemindMeNotificationResponse,
    IRemindMeResponse } from '../../types/data'
import { CHALLENGE_PLEDGE_DETAIL_URL, CHALLENGE_PLEDGES_LIST_URL, CHALLENGES_DELETE_REMIND_ON_START_URL,
    CHALLENGES_JOIN_URL, CHALLENGES_LAST_DAY_TO_TRACK_URL,
    CHALLENGES_LOG_DAY_URL,
    CHALLENGES_REMIND_ME_NOTIFICATION_URL,
    CHALLENGES_REMIND_ON_START_URL,
    CHALLENGES_URL, COMMIT_PLEDGES_URL, MY_PLEDGES_LIST_URL } from '../../urls'
import apiClient from '../instance'

/**
 * Get challenge item
 */

export const getChallengeItem = async (): Promise<IChallenges | null> => {
    try {
        const { data: response } = await apiClient.get(CHALLENGES_URL)
        return response
    } catch (e) {
        throw e
    }
}

export const getPledgeDetail = async (pledgeId: string): Promise<IChallenges | null> => {
    try {
        const { data: response } = await apiClient.get(CHALLENGE_PLEDGE_DETAIL_URL.replace('{pledgeId}', pledgeId))
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post challenge join item
 */
export const postChallengeJoinItem = async (challenge: number | undefined): Promise<IChallengeResponse | null> => {
    try {
        const { data: response } = await apiClient.post<IChallengeResponse | null>(CHALLENGES_JOIN_URL, {
            challenge,
        })
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post challenge remind me notification item
 */
export const postRemindMeNotificationItem = async (data: IRemindMeNotificationPost): Promise<IRemindMeNotificationResponse | null> => {
    try {
        const { data: response } = await apiClient.post<IRemindMeNotificationResponse | null>(
            CHALLENGES_REMIND_ME_NOTIFICATION_URL,
            data,
        )
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post challenge remind on start item
 */
export const postChallengeRemindOnStartItem = async (challenge: number | undefined): Promise<IRemindMeResponse | null> => {
    try {
        const { data: response } = await apiClient.post<IRemindMeResponse | null>(CHALLENGES_REMIND_ON_START_URL, {
            challenge,
        })
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Delete challenge remind on start
 */
export const deleteChallengeRemindOnStart = async (challengeId: number | string | undefined): Promise<IRemindMeResponse | null> => {
    try {
        const { data: response } = await apiClient.delete<IRemindMeResponse>(
            CHALLENGES_DELETE_REMIND_ON_START_URL.replace('{challengeId}', String(challengeId)),
        )

        return response
    } catch (e) {
        throw e
    }
}

export const getChallengeLoggedDays = async (
    challenge: number | string,
): Promise<IChallengeLoggedDay | null> => {
    try {
        const { data: response } = await apiClient.get(CHALLENGES_LOG_DAY_URL, { params: { challenge } })
        return response
    } catch (e) {
        throw e
    }
}

export const postChallengeLogDayItem = async (
    data: IChallengeLogDayData,
): Promise<IChallengeLoggedDay | null> => {
    try {
        const { data: response } = await apiClient.post<IChallengeLoggedDay | null>(
            CHALLENGES_LOG_DAY_URL,
            data,
        )
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get challenge last day to track item
 */

export const getLastDayToTrackItem = async (): Promise<ILastDayToTrackChallenge | null> => {
    try {
        const { data: response } = await apiClient.get(CHALLENGES_LAST_DAY_TO_TRACK_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get my pledges item
 */

export const getMyPledgesItem = async (): Promise<IMyPledgesData | null> => {
    try {
        const { data: response } = await apiClient.get(MY_PLEDGES_LIST_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get pledges item
 */

export const getPledgesItem = async (): Promise<IChallengePledgesData | null> => {
    try {
        const { data: response } = await apiClient.get(CHALLENGE_PLEDGES_LIST_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post commit pledge item
 */
export const postCommitPledgeItem = async (pledge: number | undefined): Promise<ICommitPledgeResponse | null> => {
    try {
        const { data: response } = await apiClient.post<ICommitPledgeResponse | null>(COMMIT_PLEDGES_URL, {
            pledge,
        })
        return response
    } catch (e) {
        throw e
    }
}
