import { Dispatch } from 'redux'

import { FETCH_ARTICLE_START,
    FETCH_ARTICLE_ERROR,
    ARTICLE_MY_BOOKMARK_LIST,
    ARTICLE_CATEGORY_LIST,
    ARTICLE_BY_CATEGORY_LIST,
    ARTICLE_HOME_PAGE_LIST,
    POST_BOOKMARK_SUCCESS,
    FETCH_ARTICLE_ERROR_BOOKMARK,
    FETCH_ARTICLE_START_BOOKMARK,
    GET_ARTICLE_SEARCH_LIST,
    GET_ARTICLE_BY_ID,
    ARTICLE_ANSWER_SUCCESS,
    ARTICLE_SEARCH_COLLECTION_SUCCESS,
    CLEAR_ARTICLE_DETAIL,
    FETCH_COMPANY_ARTICLE_SUCCESS } from './consts'

import { getArticleById,
    getArticleCategoryList,
    getArticleHomePageList, getArticleListByCategory,
    getArticleMyBookmarkList,
    getArticleSearchCollectionList,
    getArticleSearchList,
    getCompanyArticleList,
    postAnswerItem,
    postBookmarkItem } from './api'
import { IAnswersResponse,
    IArticleCategory,
    IArticleHomePage,
    IArticles, ICompanyArticle,
    IQuizData,
    ISearchCollection } from '../../types/data'
import { updateUserPoint } from '../user/actions'

const fetchArticlesStart = () => ({
    type: FETCH_ARTICLE_START,
})

export const fetchArticleMyBookmarkSuccess = (response: IArticles) => ({
    type: ARTICLE_MY_BOOKMARK_LIST,
    payload: response,
})

export const fetchArticleCategorySuccess = (response: IArticleCategory) => ({
    type: ARTICLE_CATEGORY_LIST,
    payload: response,
})

export const clearArticleDetail = () => ({
    type: CLEAR_ARTICLE_DETAIL,
    payload: {},
})

export const fetchArticleByCategorySuccess = (response: IArticles) => ({
    type: ARTICLE_BY_CATEGORY_LIST,
    payload: response,
})

export const fetchArticleHomePageSuccess = (response: IArticleHomePage) => ({
    type: ARTICLE_HOME_PAGE_LIST,
    payload: response,
})

export const fetchArticleSearchSuccess = (response: IArticles) => ({
    type: GET_ARTICLE_SEARCH_LIST,
    payload: response,
})

export const fetchDiscoverDeeplinkSuccess = (response: IArticles) => ({
    type: GET_ARTICLE_BY_ID,
    payload: response,
})

const postAnswerSuccess = (response: IAnswersResponse, article: number) => ({
    type: ARTICLE_ANSWER_SUCCESS,
    payload: { response, article },
})

const fetchEnd = () => ({
    type: FETCH_ARTICLE_ERROR,
})

const fetchArticlesStartBookmark = () => ({
    type: FETCH_ARTICLE_START_BOOKMARK,
})

const postArticleBookmarkSuccess = (isBookmark: boolean, bookmarkedId: number | string | undefined) => ({
    type: POST_BOOKMARK_SUCCESS,
    payload: { bookmarkedId, isBookmark },
})

export const fetchArticleSearchCollectionSuccess = (response: ISearchCollection) => ({
    type: ARTICLE_SEARCH_COLLECTION_SUCCESS,
    payload: response,
})

export const fetchCompanyArticleSuccess = (response: ICompanyArticle) => ({
    type: FETCH_COMPANY_ARTICLE_SUCCESS,
    payload: response,
})

const fetchEndBookmark = () => ({
    type: FETCH_ARTICLE_ERROR_BOOKMARK,
})
/**
 * get my bookmark articles
 * @returns
 */

export const fetchArticleMyBookmark = (params: { page: number }) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchArticlesStart())
        const response = await getArticleMyBookmarkList(params)

        if (response) {
            dispatch(fetchArticleMyBookmarkSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchArticleMyBookmark: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch article category list
 * @returns
 */
export const fetchArticleCategoryList = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchArticlesStart())
        const response = await getArticleCategoryList()

        if (response) {
            dispatch(fetchArticleCategorySuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchArticleCategoryList: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch article list by category
 * @returns
 */

export const fetchArticleListByCategory = (category: number |string | undefined) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchArticlesStart())
        const response = await getArticleListByCategory(category)

        if (response) {
            dispatch(fetchArticleByCategorySuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchArticleListByCategory: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * get article home page
 * @returns
 */

export const fetchArticleHomePage = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchArticlesStart())
        const response = await getArticleHomePageList()

        if (response) {
            dispatch(fetchArticleHomePageSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchArticleHomePage: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post home article bookmark
 * @returns
 */

export const articleBookMark = (id: number | undefined) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchArticlesStartBookmark())
        const response = await postBookmarkItem(id)
        if (response) {
            dispatch(postArticleBookmarkSuccess(response.isBookmark, id))
        }
    } catch (e) {
        console.log('ERROR, articleBookMark: ', e)
        dispatch(fetchEndBookmark())
    }
}

/**
 * Fetch article search list
 * @returns
 */

export const fetchArticleSearch = (text: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchArticlesStart())
        const response = await getArticleSearchList(text)
        if (response) {
            dispatch(fetchArticleSearchSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchArticleSearch: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch article deeplink list
 * @returns
 */

export const fetchArticleById = (articleId: number | string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchArticlesStart())
        const response = await getArticleById(articleId)

        if (response) {
            dispatch(fetchDiscoverDeeplinkSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchArticleById: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post quizz answer
 * @returns
 */

export const postQuizzAnswer = (data: IQuizData, onSuccess: (articles: IAnswersResponse) => void) => async (
    dispatch: Dispatch,
) => {
    try {
        dispatch(fetchArticlesStart())
        const response = await postAnswerItem(data)

        if (response) {
            dispatch(postAnswerSuccess(response, data.article))
            dispatch(updateUserPoint(response.thisMonthPoints))
            onSuccess(response)
        }
    } catch (e) {
        console.log('ERROR, postQuizzAnswer: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * get article search collection list
 * @returns
 */

export const fetchArticleSearchCollection = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchArticlesStart())
        const response = await getArticleSearchCollectionList()

        if (response) {
            dispatch(fetchArticleSearchCollectionSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchArticleSearchCollection: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * get company article list
 * @returns
 */

export const fetchCompanyArticle = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchArticlesStart())
        const response = await getCompanyArticleList()

        if (response) {
            dispatch(fetchCompanyArticleSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchCompanyArticle: ', e)
        dispatch(fetchEnd())
    }
}
