import React, { useCallback, useContext, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import ChallengeCard from '../../components/challenge/ChallengeCard'
import { getChallengeList, getPledgesList } from '../../state/challenges/selectors'
import { fetchChallengeList, fetchPledges } from '../../state/challenges/actions'
import ChallengeCardMini from '../../components/challenge/ChallengeCardMini'
import PledgesCard from '../../components/pledges/PledgesCard'
import { Context } from '../../components/common/BaseContext'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'

export default function Challenge() {
    const dispatch = useDispatch()
    const { challenge } = useSelector(getChallengeList)
    const isActiveTrue = challenge?.filter((item) => item?.isActive)
    const isActiveFalse = challenge?.filter((item) => !item?.isActive)
    const { pledges } = useSelector(getPledgesList)
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Challenge' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        dispatch(fetchChallengeList)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const firstButton = document.querySelector('#challenge button') as HTMLButtonElement

        if (firstButton) {
            firstButton.focus()
            firstButton.style.outline = 'none'
        }

        const handleKeyDown = (event: any) => {
            if (event.key === 'Tab') {
                firstButton.style.removeProperty('outline')
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [challenge])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchPledges)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const pledgeCollections = [
        { slot: pledges?.slotOne, key: 'slotOne' },
        { slot: pledges?.slotTwo, key: 'slotTwo' },
        { slot: pledges?.slotThree, key: 'slotThree' },
        { slot: pledges?.slotFour, key: 'slotFour' },
        { slot: pledges?.slotFive, key: 'slotFive' },
    ].filter((i) => !!i.slot)

    const { sponsorshipBlocks } = useSelector(getHomePageLists)

    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Pledges')

    return (
        <Layout contentContainerClassName="flex-col">
            {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

            <div id="challenge"
                className={`overflow-x-hidden ${screenWidth < 1500 && 'laptop:mx-[223px] tablet:mx-[103px]'} mobile:mx-10 mx-4 mobile:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px]`}>
                {challenge.length !== 0 && (
                    <div>
                        <h1 className="text-black laptop:text-36 medium:text-36 mobile:text-32 text-26 font tracking-tight font-medium">
                            {t('lower.join-a-challenge')}
                        </h1>

                        <h1 className="font-normal text-grey01 text-16 mt-2">
                            {t('lower.challenge-description')}
                        </h1>
                    </div>
                )}

                {isActiveTrue?.map((item) => (
                    <div key={item?.id} className="mt-[30px]">
                        <span className="font-medium tracking-tight font text-black text-18">
                            {item?.challengeName}
                        </span>

                        <ChallengeCard item={item} />
                    </div>
                ))}

                <div className="flex grid mobile:grid-cols-2 mobile:gap-6 gap-3 grid-cols-1">
                    {isActiveFalse?.map((item) => (
                        <div key={item?.id} className="mt-[30px]">
                            <span className="font-medium tracking-tight font text-black text-18">
                                {item?.challengeName}
                            </span>

                            <ChallengeCardMini item={item} />
                        </div>
                    ))}
                </div>

                <h1 className="heading-uppercase medium:block hidden text-black laptop:text-36 medium:text-36 mobile:text-32 text-26 font tracking-tight font-medium">
                    {t('lower.commit-to-a-pledge')}
                </h1>

                <h1 className="font-normal text-grey01 text-16 mt-2">
                    {t('lower.pledge-description')}
                </h1>

                {pledgeCollections?.map((item) => (
                    <div key={item?.key} style={{ maxWidth: '100%' }}>
                        <div className="flex-col justify-center items-center mt-[30px]">
                            <h2 className="text-black font text-18 heading-font font-medium mb-[12px]">
                                {item?.slot?.title}
                            </h2>

                            <div
                                className={`grid laptop:gap-8 ${item?.slot?.pledges?.length === 3 && 'md:grid-cols-3 grid-cols-2'} gap-4 mt-3`}>
                                <div className={`flex ${item?.slot?.pledges?.length !== 3 ? 'overflow-x-scroll' : ''}`}>
                                    {item?.slot?.pledges?.map((i) => (
                                        <div key={i?.id}
                                            className="tablet:pr-8 medium:pr-6 pr-4 laptop:min-w-[300px] medium:min-w-[248px] min-w-[163px] mb-1">
                                            <PledgesCard item={i} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Layout>
    )
}
