import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LegDetails from './LegDetails'
import MoreDetails from './MoreDetails'
import DeleteLeg from './DeleteLeg'
import { ILegs } from '../../types/data'
import { travelSelector } from '../../state/travel/selectors'
import { editTravelCommute } from '../../state/travel/actions'
import { checkIsCar, legInstancesToFormData } from '../../utils/travel'
import { getUser } from '../../state/user/selectors'

const data: NavigationItem[] = [
    {
        id: 1,
        name: 'Leg Details',
    },
    {
        id: 2,
        name: 'Mode Details',
    },
    {
        id: 3,
        name: 'Delete Leg',
    },
]

interface NavigationItem {
    id: number;
    name: string;
}

interface NavigationProps {
    data: NavigationItem[];
    activeScreen: number | null;
    onClick: (item: NavigationItem) => void;
}

interface IProps {
    onClose: () => void;
    editItem?: ILegs
    reloadStoredData?: () => void
    unknownLegData?: {
        createLegPosition: number
        nextLeg?: ILegs | any
        previousLeg?: ILegs | any
        legStartEnd: {
            startPlaceName?: string
            startPlaceId?: string
            startPlaceLon?: string
            startPlaceLat?: string
            endPlaceName?: string
            endPlaceId?: string
            endPlaceLon?: string
            endPlaceLat?: string
        }
    }
    goingBackCommute: boolean
}

// eslint-disable-next-line react/function-component-definition,no-shadow
const Navigation: React.FC<NavigationProps> = ({ data, activeScreen, onClick }) => (
    <div className="flex w-full h-[55px] items-center overflow-y-auto no-scrollbar">
        <div className="flex w-full">
            {data?.map((item) => (
                <div key={item.id}
                    onClick={() => onClick(item)}
                    style={{ width: `${100 / data.length}%`, minWidth: 114 }}>
                    <div className={`text-14 justify-center text-marrow cursor-pointer flex h-[48px] items-center border-b-4 ${activeScreen === item.id ? 'border-spinach' : 'border-white'}`}>
                        {item.name}
                    </div>
                </div>
            ))}
        </div>
    </div>
)

export default function EditLegModal({ onClose, unknownLegData, editItem, reloadStoredData, goingBackCommute }: IProps) {
    const [activeScreen, setActiveScreen] = useState<number | null>(1)

    const { transportList, commuteStartEnd } = useSelector(travelSelector)
    const { user } = useSelector(getUser)
    const dispatch = useDispatch()

    const [placesData, setPlacesData] = useState<any>({})
    const [deleteLegIds, setDeleteLegIds] = useState<number[]>([])

    const car = transportList?.find((item) => checkIsCar(item?.name))

    const [sizeTypeData, setSizeTypeData] = useState(car?.sizes)
    const [fuelTypeData, setFuelTypeData] = useState(car?.fuelTypes)
    const [countOfPeople, setCountOfPeople] = useState<number>(Number(editItem?.countOfPeople || 0))

    const fuelType = fuelTypeData?.find((item) => item?.selected)
    const sizeCar = sizeTypeData?.find((item) => item?.selected)

    const travelDataString = localStorage.getItem('travelData')
    // @ts-ignore
    const travelData = JSON.parse(travelDataString)

    const storedData = JSON.parse(localStorage.getItem(goingBackCommute ? 'returnTravelData' : 'travelData') || '{}')

    const [selectedTransports, setSelectedTransports] = useState({})

    useEffect(() => {
        if (editItem) {
            const newData = sizeTypeData?.map((newItem) => (newItem?.name === editItem?.transportSize?.name
                ? { ...newItem, selected: !newItem.selected }
                : { ...newItem, selected: false }))
            // @ts-ignore
            setSizeTypeData(newData)
        }
        // eslint-disable-next-line
    }, [editItem])

    useEffect(() => {
        if (editItem) {
            const newData = fuelTypeData?.map((newItem) => (newItem?.name === editItem?.transportFuelType?.name
                ? { ...newItem, selected: !newItem.selected }
                : { ...newItem, selected: false }))
            // @ts-ignore
            setFuelTypeData(newData)
        }
        // eslint-disable-next-line
    }, [editItem])

    useEffect(() => {
        if (editItem) {
            setCountOfPeople(Number(editItem?.countOfPeople || 0))
        }
        // eslint-disable-next-line
    }, [editItem])

    const handleClickInsideModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation()
    }

    useEffect(() => {
        const handleGlobalClick = () => {
            onClose?.()
        }

        document.addEventListener('mousedown', handleGlobalClick)

        return () => {
            document.removeEventListener('mousedown', handleGlobalClick)
        }
    }, [onClose])

    const handleNavigationClick = (item: NavigationItem) => {
        setActiveScreen(item.id)
    }

    useEffect(() => {
        if (unknownLegData) {
            setPlacesData({
                0: unknownLegData.legStartEnd,
            })
        }
    }, [unknownLegData])

    const onSave = () => {
        // @ts-ignore
        if (checkIsCar(Object.values(selectedTransports)[0]?.name) && (!fuelType || !sizeCar)) {
            setActiveScreen(2)
            return
        }

        let commuteData: any = {}

        const legs = (editItem ? editItem?.combinedLegs : [undefined])?.map((leg) => {
            const id = leg?.backendLegId || 0

            if (leg?.position === 1) {
                if (!goingBackCommute) {
                    commuteData = {
                        ...commuteData,
                        startPlaceId: placesData[id]?.startPlaceId || leg?.startPlaceId,
                        startPlaceName: placesData[id]?.startPlaceName || leg?.startPlaceName,
                        startPlaceLat: placesData[id]?.startPlaceLat || leg?.startPlaceLat,
                        startPlaceLon: placesData[id]?.startPlaceLon || leg?.startPlaceLon,
                    }
                } else if (!user.company.isTravelTypeEvent) {
                    commuteData = {
                        ...commuteData,
                        returnJourneyStartPlaceName: placesData[id]?.startPlaceName || leg?.startPlaceName,
                        returnJourneyStartPlaceId: placesData[id]?.startPlaceId || leg?.startPlaceId,
                        returnJourneyStartPlaceLon: placesData[id]?.startPlaceLon || leg?.startPlaceLon,
                        returnJourneyStartPlaceLat: placesData[id]?.startPlaceLat || leg?.startPlaceLat,
                    }
                }
            }

            if (leg?.position === storedData.legs.length && leg) {
                if (!goingBackCommute) {
                    if (!user.company.isTravelTypeEvent) {
                        commuteData = {
                            ...commuteData,
                            endPlaceId: placesData[id]?.endPlaceId || leg?.endPlaceId,
                            endPlaceName: placesData[id]?.endPlaceName || leg?.endPlaceName,
                            endPlaceLat: placesData[id]?.endPlaceLat || leg?.endPlaceLat,
                            endPlaceLon: placesData[id]?.endPlaceLon || leg?.endPlaceLon,
                        }
                    }
                } else {
                    commuteData = {
                        ...commuteData,
                        returnJourneyEndPlaceName: placesData[id]?.endPlaceName || leg?.endPlaceName,
                        returnJourneyEndPlaceId: placesData[id]?.endPlaceId || leg?.endPlaceId,
                        returnJourneyEndPlaceLon: placesData[id]?.endPlaceLon || leg?.endPlaceLon,
                        returnJourneyEndPlaceLat: placesData[id]?.endPlaceLat || leg?.endPlaceLat,
                    }
                }
            }

            // @ts-ignore
            const isCar = checkIsCar(selectedTransports[id]?.name || leg?.transportName)

            return {
                // @ts-ignore
                transport: selectedTransports[id]?.id || leg?.transportId,
                leg: Number(id) || undefined,

                startPlaceId: placesData[id]?.startPlaceId || leg?.startPlaceId,
                startPlaceName: placesData[id]?.startPlaceName || leg?.startPlaceName,
                startPlaceLat: placesData[id]?.startPlaceLat || leg?.startPlaceLat,
                startPlaceLon: placesData[id]?.startPlaceLon || leg?.startPlaceLon,

                endPlaceId: placesData[id]?.endPlaceId || leg?.endPlaceId,
                endPlaceName: placesData[id]?.endPlaceName || leg?.endPlaceName,
                endPlaceLat: placesData[id]?.endPlaceLat || leg?.endPlaceLat,
                endPlaceLon: placesData[id]?.endPlaceLon || leg?.endPlaceLon,

                transportFuelType: isCar ? fuelType?.id : null,
                transportSize: isCar ? sizeCar?.id : null,
                countOfPeople: isCar ? countOfPeople || 0 : 0,

                commute: travelData?.backendCommuteId || commuteStartEnd.id,
            }
        })

        dispatch(
            editTravelCommute({
                ...commuteData,
                legs,
                commute: travelData?.backendCommuteId || commuteStartEnd.id,
                deleteLegIds,
            }, (response) => {
                const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')
                const returnData = JSON.parse(localStorage.getItem('returnTravelData') || 'null')

                localStorage.setItem('travelData', JSON.stringify({
                    ...outboundData,
                    startPlaceId: commuteData?.startPlaceId || outboundData.startPlaceId,
                    startPlaceName: commuteData?.startPlaceName || outboundData.startPlaceName,
                    startPlaceLat: commuteData?.startPlaceLat || outboundData.startPlaceLat,
                    startPlaceLon: commuteData?.startPlaceLon || outboundData.startPlaceLon,
                    endPlaceId: commuteData?.endPlaceId || outboundData.endPlaceId,
                    endPlaceName: commuteData?.endPlaceName || outboundData.endPlaceName,
                    endPlaceLat: commuteData?.endPlaceLat || outboundData.endPlaceLat,
                    endPlaceLon: commuteData?.endPlaceLon || outboundData.endPlaceLon,
                    legs: legInstancesToFormData(response.legs.filter((i) => !i.isGoingBack)),
                }))

                if (returnData) {
                    localStorage.setItem('returnTravelData', JSON.stringify({
                        ...returnData,
                        startPlaceId: commuteData?.returnJourneyStartPlaceId || returnData.startPlaceId,
                        startPlaceName: commuteData?.returnJourneyStartPlaceName || returnData.startPlaceName,
                        startPlaceLat: commuteData?.returnJourneyStartPlaceLat || returnData.startPlaceLat,
                        startPlaceLon: commuteData?.returnJourneyStartPlaceLon || returnData.startPlaceLon,
                        endPlaceId: commuteData?.returnJourneyEndPlaceId || returnData.endPlaceId,
                        endPlaceName: commuteData?.returnJourneyEndPlaceName || returnData.endPlaceName,
                        endPlaceLat: commuteData?.returnJourneyEndPlaceLat || returnData.endPlaceLat,
                        endPlaceLon: commuteData?.returnJourneyEndPlaceLon || returnData.endPlaceLon,
                        legs: legInstancesToFormData(response.legs.filter((i) => i.isGoingBack)),
                    }))
                }

                onClose()

                reloadStoredData?.()
            }),
        )
    }

    return (
        <div>
            <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                    <div className="bg-white relative px-4 py-6 xs-mobile:w-[500px] w-11/12 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }} onMouseDown={handleClickInsideModal}>
                        <h1 className="heading-uppercase text-18 text-cavolo font-semibold tracking-[-0.02rem]">
                            Edit leg
                        </h1>

                        <div>
                            <Navigation data={data} activeScreen={activeScreen} onClick={handleNavigationClick} />

                            {data.map((item) => (
                                <div key={item.id} style={{ display: activeScreen === item.id ? 'block' : 'none' }} className="mt-4">
                                    {/* Render content for each screen here */}
                                    {item.name === 'Leg Details' && <LegDetails setDeleteLegIds={setDeleteLegIds} deleteLegIds={deleteLegIds} setSelectedTransports={setSelectedTransports} selectedTransports={selectedTransports} editItem={editItem} onSave={onSave} setPlacesData={setPlacesData} placesData={placesData} onClose={onClose} />}
                                    {item.name === 'Mode Details' && <MoreDetails onSave={onSave} selectedTransports={selectedTransports} transportName={editItem?.transportName} setCountOfPeople={setCountOfPeople} countOfPeople={countOfPeople} setSizeTypeData={setSizeTypeData} setFuelTypeData={setFuelTypeData} sizeTypeData={sizeTypeData} fuelTypeData={fuelTypeData} onClose={onClose} />}
                                    {item.name === 'Delete Leg' && <DeleteLeg leg={editItem} reloadStoredData={reloadStoredData} unknownLegData={unknownLegData} onClose={onClose} />}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
