import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { ArrowBottomIcon, PledgeCheckIcon } from '../common/Svg'
import Skeleton from '../common/Skeleton'
import { IPledge } from '../../types/data'
import { readableC02 } from '../../utils/helpers'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item: IPledge
}

export default function PledgesCard({ item }: IProps) {
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)
    const ceoValue = readableC02(item?.ceo2Kg)

    const navigateChallengeDetail = (pledgeId: number) => {
        navigate(`/lower/pledges/pledges-detail/${pledgeId}`, { state: { lastPage: location?.pathname, ceoValue, item } })
    }

    return (
        <div onClick={() => navigateChallengeDetail(item?.id)} className="cursor-pointer h-full relative" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div className="flex justify-end mr-3">
                {item?.isJoined && (
                    <div className="absolute rounded-full flex justify-center items-center mt-3 bg-darken w-[28px] h-[28px]">
                        <PledgeCheckIcon width={17} height={12} />
                    </div>
                )}

            </div>

            <div className="h-full">
                {loading && <Skeleton className="rounded-t-lg w-full aspect-video object-cover" />}
                <img onLoad={handleImageLoad} src={item?.image} className={`${loading ? 'hidden' : 'block'} rounded-t-lg aspect-video w-[500px] object-cover`} alt="" />

                <div className="flex flex-col justify-between rounded-b-lg bg-white min-h-[150px] pb-3 hover:bg-[#F2F2F2] hover:drop-shadow-[0.3]">
                    {item.sponsorshipBlock && <SponsoredByBlock noBackground fontColor="black" noBorder containerStyles={{ marginBottom: 4 }} item={item.sponsorshipBlock} />}

                    <button className={cn('line-clamp-three text-left mb-0.5 mx-3 font-medium font tracking-tight text-black text-15 leading-[132%]', { 'mt-3': !item?.sponsorshipBlock, 'mobile:mt-3': item.sponsorshipBlock })}>
                        {loading ? (
                            <>
                                <Skeleton className="w-full mr-[45%] h-[13px] rounded-sm mt-2" />
                                <Skeleton className="w-8/12 mr-[45%] h-[13px] rounded-sm mt-2" />
                            </>
                        ) : (
                            <ContentBlockTitle text={item?.title} />
                        )}
                    </button>

                    <div className="flex items-center pl-3">
                        {loading ? (
                            <Skeleton className="w-full mr-[75%] h-[10px] rounded-sm" />
                        ) : (
                            <>
                                <ArrowBottomIcon width={15} height={15} />

                                <p className="font-semibold not-heading-font text-grey01 tracking-tight text-12 ml-[6px]">
                                    {ceoValue}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
