import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useTranslation } from 'react-i18next'
import { DismissIcon, Mark } from '../common/Svg'
import { getChallengeList } from '../../state/challenges/selectors'
import { IChallenges, IRemindMeNotificationPost } from '../../types/data'
import { postRemindMeNotification } from '../../state/challenges/actions'
import SecondButton from '../common/SecondButton'
import { Context } from '../common/BaseContext'

interface IProps {
    items: IChallenges | undefined
    setShowModal: (value: boolean) => void
    showModal: boolean
}

export default function ChallengeNotification({ items, setShowModal, showModal }: IProps) {
    const dispatch = useDispatch()
    const { challenge } = useSelector(getChallengeList)
    const [loading, setLoading] = useState(false)
    const [t] = useTranslation('global')
    const notificationFilter = challenge?.find((i) => i.id === items?.id)
    const [switchSelectorNotification, setSwitchSelectorNotification] = useState(
        notificationFilter?.notification !== null
            ? notificationFilter?.notification?.notificationEveryNDays
            : 14,
    )
    const [switchSelectorEmail, setSwitchSelectorEmail] = useState(
        notificationFilter?.notification !== null ? notificationFilter?.notification?.emailEveryNDays : 14,
    )
    const { screenWidth } = useContext(Context)

    // Send me a notification
    const switchSelectorNotificationFunc = (index: number) => {
        setSwitchSelectorNotification(index)
    }

    // Send me an email reminder
    const switchSelectorEmailFunc = (index: number) => {
        setSwitchSelectorEmail(index)
    }

    const remindMeNotificationPost = async (data: IRemindMeNotificationPost) => {
        setLoading(true)
        await dispatch(postRemindMeNotification(data))
        setLoading(false)
        setShowModal(false)
    }

    // Send me a notification data
    const notificationData = [
        { id: 1, name: t('lower.daily'), code: 1 },
        { id: 2, name: t('lower.1x-week'), code: 7 },
        { id: 3, name: t('lower.2x-week'), code: 14 },
        { id: 4, name: t('lower.3x-week'), code: 21 },
        { id: 5, name: t('lower.never'), code: 0 },
    ]

    // Send me an email reminder
    const emailData = [
        { id: 1, name: t('lower.daily'), code: 1 },
        { id: 2, name: t('lower.1x-week'), code: 7 },
        { id: 3, name: t('lower.2x-week'), code: 14 },
        { id: 4, name: t('lower.3x-week'), code: 21 },
        { id: 5, name: t('lower.never'), code: 0 },
    ]

    return (
        <div>
            {showModal ? (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div onClick={() => setShowModal(false)} className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="flex items-center justify-center min-h-screen">
                            <div className="m-[16px] relative xs-mobile:w-[435px] mobile:h-[542px] w-auto bg-white mobile:p-[24px] p-[16px] rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={() => setShowModal(false)} className="mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="mobile:mx-[24px]">
                                    <h1 className="mt-6 font text-22 font-medium tracking-tight text-darken">
                                        {t('lower.remind-me-to-track-my-progress')}
                                    </h1>

                                    <h1 className="text-16 font-light text-grey mt-0.5">
                                        {t('lower.we’ll-send-you-a-notification-to-remind-you-to-log-your-challenge-progress')}
                                    </h1>

                                    {/** ********************* Send me a notification ********************** */}
                                    <h1 className="mt-8 font text-18 font-medium tracking-tight text-darken">
                                        {t('lower.send-me-a-notification')}
                                    </h1>

                                    <div className="flex justify-between items-center w-full px-1 h-[40px] bg-mint rounded-lg mt-[10px]">
                                        {notificationData?.map((item) => (
                                            <div key={item?.id} onClick={() => switchSelectorNotificationFunc(item?.code)} className={cn(css(switchSelectorNotification === item?.code && styles.shadow), `cursor-pointer h-[32px] ${switchSelectorNotification === item?.code && 'bg-white rounded-md'} px-2 flex justify-center items-center`)}>
                                                <h1 className="text-12 font-normal text-marrow text-center">
                                                    {item.name}
                                                </h1>

                                                {switchSelectorNotification === item?.code && (
                                                    <div className="ml-1">
                                                        <Mark width={13} height={9} />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {/** ********************* Send me a notification ********************** */}

                                    {/** ******************* Send me an email reminder ********************* */}
                                    <h1 className="mt-[30px] font text-18 font-medium tracking-tight text-darken">
                                        {t('lower.send-me-an-email-reminder')}
                                    </h1>

                                    <div className="flex justify-between items-center px-1 w-full h-[40px] bg-mint rounded-lg mt-[10px]">
                                        {emailData?.map((item) => (
                                            <div key={item?.id} onClick={() => switchSelectorEmailFunc(item?.code)} className={cn(css(switchSelectorEmail === item?.code && styles.shadow), `cursor-pointer h-[32px] ${switchSelectorEmail === item?.code && 'bg-white'} rounded-md px-2 flex justify-center items-center`)}>
                                                <h1 className="text-center text-12 font-normal text-marrow">
                                                    {item.name}
                                                </h1>

                                                {switchSelectorEmail === item?.code && (
                                                    <div className="ml-1">
                                                        <Mark width={13} height={9} />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {/** ******************* Send me an email reminder ********************* */}

                                    <div className="mt-[47px]">
                                        <SecondButton
                                            loading={loading}
                                            onClick={() => remindMeNotificationPost({
                                                challenge: items?.id,
                                                emailEveryNDays: switchSelectorEmail,
                                                notificationEveryNDays: switchSelectorNotification,
                                            })}
                                            text={t('global.continue')}
                                            className="mt-2.5 w-full h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

const styles = StyleSheet.create({
    shadow: {
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
    },
})
