import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Button from '../../components/common/Button'
import { DismissIcon } from '../../components/common/Svg'
import 'react-datepicker/dist/react-datepicker.css'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import { Colors } from '../../themes'
import Loader from '../../components/common/Loader'
import { getUser } from '../../state/user/selectors'
import Dropdown from '../../components/travel/DropDown'
import { getDates } from '../../utils/date'
import { fetchGame, fetchTransport } from '../../state/travel/actions'
import { logSavedCommute } from '../../utils/travel'
import { Context } from '../../components/common/BaseContext'
import Layout from '../../components/layout/Layout'

export default function TrackTravelSavedTripDate() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { commute, favourite } = location.state || {}
    const { isEmbedded } = useContext(Context)
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [game, setGame] = useState<number | string | undefined>(undefined)
    const [gameDate, setGameDate] = useState<number | string | undefined>(undefined)
    const [isOpen, setIsOpen] = useState(false)
    const [buttonShow, setButtonShow] = useState<boolean>(false)
    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Track travel saved trip date' })
    }, [])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchGame())
        dispatch(fetchTransport())
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const handleClick = (e: any) => {
        e.preventDefault()
        setIsOpen(!isOpen)
    }

    const handleChange = (e: any) => {
        setIsOpen(!isOpen)
        setStartDate(e)
        setButtonShow(true)
    }

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    const onSubmit = () => {
        const today = new Date()
        logSavedCommute(setLoading, dispatch, startDate || today, commute, game, gameDate, navigate)
    }

    const handleSelectChange = (selectedOption: any) => {
        setGame(selectedOption?.id)
        setGameDate(selectedOption?.value)
    }

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded} maxWidth={false}>
            <div className="w-full h-screen">
                {loading ? (
                    <div className="flex flex-col items-center justify-center h-screen">
                        <h1 className="not-heading-font text-22 tracking-tight font-bold text-black mb-5">
                            {t('log.calculating-your-impact')}
                        </h1>

                        <Loader width={75} height={75} />
                    </div>
                ) : (
                    <div>
                        <style>{'html, body {height: 100%; overflow: hidden;}'}</style>
                        <div className="justify-end pt-[43px] pl-5 mobile:pl-[45px] pr-4 mobile:mx-[42px] mx-[16px]">
                            <div onClick={() => setShowQuizQuitOverlayModal(true)} className="flex justify-end cursor-pointer">
                                <DismissIcon width={18} height={18} />
                            </div>
                        </div>

                        <div className="flex flex-col items-center mt-[54px] mobile:px-[42px] px-[16px] h-screen overflow-y-scroll">
                            <div className="max-w-[600px] w-full">
                                {user?.company?.isTravelTypeEvent ? (
                                    <h1 className="text-22 tracking-tight font-semibold">
                                        {t('log.track-my-travel')}
                                    </h1>
                                ) : (
                                    <h1 className="heading-uppercase text-22 text-cavolo tracking-tight font-semibold">
                                        {favourite ? t('log.log-a-favourite-journey') : t('log.log-a-recent-journey')}
                                    </h1>
                                )}

                                {user?.company?.isTravelTypeEvent ? (
                                    <h1 className="line not-heading-font text-16 tracking-tight font-medium text-cavolo mt-[24px]">
                                        {t('log.please-select-which-game-you-attended')}
                                    </h1>
                                ) : (
                                    <h1 className="not-heading-font text-16 tracking-tight font-medium text-cavolo mt-[27px]">
                                        {t('log.what-date-did-you-travel?')}
                                    </h1>
                                )}

                                {startDate && (
                                    <h1 className="not-heading-font mt-[20px] text-16 text-cavolo font-bold">
                                        {getDates(startDate)}
                                    </h1>
                                )}

                                {user?.company?.isTravelTypeEvent ? (
                                    <div className="mt-[15px]">
                                        <Dropdown handleSelectChange={handleSelectChange} />
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            <Button
                                                type="button"
                                                className="bottom-8 duration-100"
                                                text={t('log.today')}
                                                onClick={onSubmit} />
                                        </div>

                                        <div className="flex justify-center mt-4">
                                            <button onClick={handleClick} className="not-heading-font font-semibold tracking-tight underline underline-offset-2 text-rocket text-14">
                                                {t('log.select-another-date')}
                                            </button>
                                        </div>
                                    </>
                                )}

                                {isOpen && (
                                    <div className="flex justify-center mt-4">
                                        <DatePicker selected={startDate} onChange={handleChange} inline dateFormat="dd/MM/yyyy" />
                                    </div>
                                )}
                            </div>
                        </div>

                        {(buttonShow || user?.company?.isTravelTypeEvent) && (
                            <div className={`${isEmbedded ? 'bottom-[40px] h-[120px]' : 'bottom-0 h-[112px]'} absolute bg-pistachio w-screen duration-100`}
                                style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                                <div className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                                    <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                                    <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                                        {user?.company?.isTravelTypeEvent ? (
                                            <Button
                                                loading={loading}
                                                onClick={onSubmit}
                                                disabled={!game}
                                                style={!game ? disabledStyles : undefined}
                                                className="absolute bottom-8 duration-100"
                                                text={t('global.continue')} />
                                        ) : (
                                            <Button
                                                loading={loading}
                                                onClick={onSubmit}
                                                disabled={!user?.company?.isTravelTypeEvent && startDate === null}
                                                style={!user?.company?.isTravelTypeEvent && startDate === null ? disabledStyles : undefined}
                                                className="absolute bottom-8 duration-100"
                                                text={t('global.continue')} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <QuizQuitOverlayModal
                    description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                    isShown={showQuizQuitOverlayModal}
                    onClose={() => navigate('/me/impact')}
                    onContinue={() => setShowQuizQuitOverlayModal(false)} />
            </div>
        </Layout>
    )
}
