import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import badge from '../../static/emissionLogo.png'
import { Context } from '../common/BaseContext'
import { DismissIcon } from '../common/Svg'
import SecondButton from '../common/SecondButton'
import { commuteSaveList } from '../../state/travel/selectors'
import { removeOrOffset } from '../../state/travel/actions'

interface IProps {
    isShown?: boolean
    onClose: () => void
    width?: boolean
}

interface SubmitParams {
    offsetStatus: 'contact' | "don't contact"
}

export default function OffsetYourEmissionsModal({ width, isShown = true, onClose }: IProps) {
    const dispatch = useDispatch()
    const { screenWidth } = useContext(Context)
    const { commuteSave } = useSelector(commuteSaveList)
    const [t] = useTranslation('global')

    const onSubmit = ({ offsetStatus }: SubmitParams): void => {
        dispatch(removeOrOffset({
            commute: commuteSave?.id,
            offsetStatus,
        }, () => {
            onClose()
        }))
    }

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 bg-pistachio mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={onClose} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex items-end justify-center mobile:mt-[64px] mt-[55px]" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.25))' }}>
                                    <div className="flex flex-col items-center justify-center absolute mobile:w-[135px] mobile:h-[135px] w-[100px] h-[100px]">
                                        <img src={badge} alt="" />
                                    </div>
                                </div>

                                <div className="flex flex-col justify-center items-center pb-[32px]">
                                    <div className={`${width ? 'xs-mobile:w-[386px] w-[242px]' : 'xs-mobile:w-[296px] w-[242px]'} flex flex-col items-center justify-center mt-[24px]`}>
                                        <h1 className="text-center mobile:text-32 text-28 text-black font-semibold tracking-[-0.02rem] font">
                                            {t('yourImpact.offset-your-emissions')}
                                        </h1>

                                        <h1 className="text-center mobile:text-16 text-12 mt-[2.5px] text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                            {t('yourImpact.thanks-for-registering-your-interest-in-removing-your-emissions')}
                                        </h1>

                                        <h1 className="text-center mobile:text-16 text-12 mt-[2.5px] text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                            {t('yourImpact.we-are-planning-on-offering-this-soon-and-can-let-you-know-when-it-is-available')}
                                        </h1>
                                    </div>

                                    <SecondButton
                                        onClick={() => onSubmit({ offsetStatus: 'contact' })}
                                        text={t('yourImpact.let-me-know-when-available')}
                                        className="mt-[24px] w-full h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />

                                    <SecondButton
                                        onClick={() => onSubmit({ offsetStatus: "don't contact" })}
                                        text={t('yourImpact.don’t-contact-me')}
                                        className="mt-[10px] w-full h-[48px] font-semibold bg-grey01 rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
