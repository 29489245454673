import React from 'react'

interface IProps {
    label: string
    dataPoint: string | number
}

export default function ProgressInfoButton({ dataPoint, label }: IProps) {
    return (
        <div className="py-2 px-4 rounded-lg mb-4 bg-pistachio">
            <p className="not-heading-font font font-medium w-full tracking-[-0.02rem] leading-[126%] text-black text-18">
                {dataPoint}
            </p>

            <p className="tracking-[-0.01rem] w-full leading-[150%] text-grey01 text-14">
                {label}
            </p>
        </div>
    )
}
