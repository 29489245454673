import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { IArticles } from '../../types/data'
import { getDatesLocalization } from '../../utils/date'
import { SavedIconActive, SavedIconDefault } from '../common/Svg'
import { articleBookMark } from '../../state/articles/actions'
import Skeleton from '../common/Skeleton'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item: IArticles
    fan?: boolean
}

export default function DiscCardMedium({ item, fan }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [t] = useTranslation('global')
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    const onPressBookmark = async (id: number) => {
        await dispatch(articleBookMark(id))
    }

    const navigateArticleDetail = (articleId: number | string) => {
        navigate(`/learn/articles/${articleId}`)
    }

    const [formattedDate, setFormattedDate] = useState<string | undefined>(undefined)

    useEffect(() => {
        const fetchData = async () => {
            if (item?.createdAt) {
                const formatted = await getDatesLocalization(item?.createdAt, languageData)
                setFormattedDate(formatted)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [item?.createdAt])

    let extractedNumber: number | null = null

    if (typeof item?.getReadTime === 'string') {
        const matchResult = item?.getReadTime.match(/\d+/)
        if (matchResult) {
            extractedNumber = parseInt(matchResult[0], 10)
        }
    }

    return (
        <div className="cursor-pointer relative bg-white rounded-lg" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div className="flex justify-end mr-3">
                <button onClick={() => onPressBookmark(item?.id)} className="absolute rounded-full flex justify-center items-center mt-3 bg-black w-[28px] h-[28px]">
                    {item?.isBookmark ? (
                        <SavedIconActive width={12} height={15} />
                    ) : (
                        <SavedIconDefault width={12} height={15} />
                    )}
                </button>
            </div>

            <div onClick={() => navigateArticleDetail(item.id)}>
                {loading && <Skeleton className="rounded-lg mobile:h-[169px] medium:h-[210px] laptop:w-[490px] laptop:h-[271px] w-full tablet:h-[223px] object-cover" />}
                <img onLoad={handleImageLoad} src={item?.image} className={cn(`${loading ? 'hidden' : 'block'} rounded-t-lg ${fan ? 'aspect-w-16 aspect-h-9 aspect-video' : 'mobile:h-[169px] medium:h-[210px] laptop:w-[490px] laptop:h-[271px] w-full'} tablet:h-[223px] object-cover`, { 'rounded-t-lg': !item.sponsorshipBlock })} alt="" />
                {item?.sponsorshipBlock && <SponsoredByBlock noBorder noBackground item={item.sponsorshipBlock} />}

                <div className="px-3 pb-5 mt-3 flex flex-col justify-between">
                    <h5 className="text-12 font-light text-grey01">
                        {loading ? (
                            <Skeleton className="w-4/12 h-[8px] rounded-sm mt-2" />
                        ) : (
                            <span>
                                {item?.isDateStampShown && formattedDate} {item?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                            </span>
                        )}
                    </h5>

                    <h1 className="mt-0.5 font-medium font tracking-tight text-black text-18">
                        {loading ? (
                            <Skeleton className="w-8/12 h-[12px] rounded-sm" />
                        ) : (
                            <ContentBlockTitle text={item?.title} />
                        )}
                    </h1>

                    <h2 className="mt-1 tracking-[-.01em] leading-[150%] text-grey01 text-14">
                        {loading ? (
                            <>
                                <Skeleton className="w-11/12 h-[12px] rounded-sm mt-1" />
                                <Skeleton className="w-6/12 h-[12px] rounded-sm mt-2" />
                            </>
                        ) : (
                            item?.subTitle
                        )}
                    </h2>
                </div>
            </div>
        </div>
    )
}
