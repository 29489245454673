import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DismissIcon } from './Svg'
import SecondButton from './SecondButton'
import badge from '../../static/badge.png'
import { Context } from './BaseContext'
import { useQueryParams } from '../../hooks/queryString'

interface IProps {
    isShown?: boolean
    onClose: () => void
    badgeName: string
    description: string
    width?: boolean
    image?: string
}

export default function BadgeModal({ width, isShown = true, onClose, description, badgeName, image }: IProps) {
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    const params = useQueryParams()

    useEffect(() => {
        if (params?.milestoneModal) {
            onClose()
        }
        // eslint-disable-next-line
    }, [params])

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 bg-pistachio mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={onClose} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex items-end justify-center mobile:mt-[64px] mt-[55px]" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.25))' }}>
                                    <div className="flex flex-col items-center justify-center absolute mobile:w-[123px] mobile:h-[123px] w-[100px] h-[100px]">
                                        <img src={badge || image} alt="" />
                                    </div>
                                </div>

                                <div className="flex flex-col justify-center items-center pb-[32px]">
                                    <div className={`${width ? 'xs-mobile:w-[386px] w-[242px]' : 'xs-mobile:w-[296px] w-[242px]'} flex flex-col items-center justify-center mt-[24px]`}>
                                        <h1 className="mobile:text-22 text-18 text-black font-semibold tracking-[-0.02rem] font">
                                            {t('global.congrats')}
                                        </h1>

                                        <h1 className="text-center mobile:text-22 text-18 mt-[2.5px] text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                            {description}
                                        </h1>

                                        <h1 className="text-center mobile:text-22 text-18 text-black tracking-tight font-semibold font mobile:leading-[28.6px] leading-[23.6px]">
                                            {badgeName}&nbsp;
                                            <span className="text-center mobile:text-22 text-18 text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                                {t('global.achievement')}
                                            </span>
                                        </h1>
                                    </div>

                                    <SecondButton onClick={onClose} text={t('global.continue')} className="mt-[24px] w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
