const BASE = 'user/'

export const SET_USER = `${BASE}SET_USER`

export const SIGN_OUT = `${BASE}SIGN_OUT`

export const FETCH_USER_START = `${BASE}FETCH_USER_START`
export const FETCH_LOGIN_CONFIG_START = `${BASE}FETCH_LOGIN_CONFIG_START`
export const LOGIN_SUCCESS = `${BASE}LOGIN_SUCCESS`
export const FETCH_USER_SUCCESS = `${BASE}FETCH_USER_SUCCESS`
export const FETCH_USER_ERROR = `${BASE}FETCH_USER_ERROR`
export const FETCH_USER_UPDATE_SUCCESS = `${BASE}FETCH_USER_UPDATE_SUCCESS`
export const FETCH_USER_REGISTER_SUCCESS = `${BASE}FETCH_USER_REGISTER_SUCCESS`
export const FETCH_USER_RESET_PASSWORD_SUCCESS = `${BASE}FETCH_USER_RESET_PASSWORD_SUCCESS`
export const FETCH_USER_ADD_VISIT_POINTS = `${BASE}FETCH_USER_ADD_VISIT_POINTS`
export const FETCH_BADGE_TASKS_SUCCESS = `${BASE}FETCH_BADGE_TASKS_SUCCESS`
export const FETCH_USER_UPDATE_SETTING_SUCCESS = `${BASE}FETCH_USER_UPDATE_SETTING_SUCCESS`
export const FETCH_USER_UPDATE_PASSWORD_SUCCESS = `${BASE}FETCH_USER_UPDATE_PASSWORD_SUCCESS`
export const CHANGE_USER_POINTS_SUCCESS = `${BASE}CHANGE_USER_POINTS_SUCCESS`
export const FETCH_PRIVACY_POLICY_SUCCESS = `${BASE}FETCH_PRIVACY_POLICY_SUCCESS`
export const FETCH_HOW_TO_EARN_POINTS_SUCCESS = `${BASE}FETCH_HOW_TO_EARN_POINTS_SUCCESS`
export const FETCH_USER_AVATAR_UPDATE_SUCCESS = `${BASE}FETCH_USER_AVATAR_UPDATE_SUCCESS`
export const FETCH_FINAL_POINT_REWARDS_SUCCESS = `${BASE}FETCH_FINAL_POINT_REWARDS_SUCCESS`
export const POST_FINAL_POINTS_REWARDS_SUCCESS = `${BASE}POST_FINAL_POINTS_REWARDS_SUCCESS`
export const FETCH_SETTINGS_SUCCESS = `${BASE}FETCH_SETTINGS_SUCCESS`
export const FETCH_WHITE_LABELING_SUCCESS = `${BASE}FETCH_WHITE_LABELING_SUCCESS`
export const FETCH_MENU_ITEMS_SUCCESS = `${BASE}FETCH_MENU_ITEMS_SUCCESS`
export const FETCH_LANGUAGE_ITEMS_SUCCESS = `${BASE}FETCH_LANGUAGE_ITEMS_SUCCESS`
export const FETCH_REFERRAL_ITEMS_SUCCESS = `${BASE}FETCH_REFERRAL_ITEMS_SUCCESS`
export const FETCH_SAVED_PLACES_SUCCESS = `${BASE}FETCH_SAVED_PLACES_SUCCESS`
export const FETCH_ACTIVITIES_SUCCESS = `${BASE}FETCH_ACTIVITIES_SUCCESS`
export const POST_SAVED_PLACES_SUCCESS = `${BASE}POST_SAVED_PLACES_SUCCESS`
export const POST_ACTIVITIES_SUCCESS = `${BASE}POST_ACTIVITIES_SUCCESS`
export const DELETE_SAVED_PLACES_SUCCESS = `${BASE}DELETE_SAVED_PLACES_SUCCESS`
export const EDIT_SAVED_PLACES_SUCCESS = `${BASE}EDIT_SAVED_PLACES_SUCCESS`
export const GET_ORGANISATIONAL_UNITS_SEARCH_LIST = `${BASE}GET_ORGANISATIONAL_UNITS_SEARCH_LIST`
export const FETCH_LOGIN_CONFIG_SUCCESS = `${BASE}FETCH_LOGIN_CONFIG_SUCCESS`
export const FETCH_LANDING_PAGE_SUCCESS = `${BASE}FETCH_LANDING_PAGE_SUCCESS`
