import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AuthContainer from '../components/auth/LoginContainer'
import Button from '../components/common/Button'
import { useQueryParams } from '../hooks/queryString'
import { getLoginConfig } from '../state/user/selectors'
import { Colors } from '../themes'

export default function AuthNavigator() {
    const navigate = useNavigate()
    const params = useQueryParams()
    const { loginConfig } = useSelector(getLoginConfig)
    const [t] = useTranslation('global')

    return (
        <AuthContainer title={t('register.sign-up-or-log-in')}>
            <Button onClick={() => navigate(`/register?to=${params.to}`)} text={t('register.sign-up')} />

            <p style={{ color: loginConfig?.whiteLabeling?.grey01 }} className="text-center text-14 mt-[60px]">
                {t('register.already-have-a-lowr-account')}
            </p>

            <button
                style={{ borderColor: loginConfig?.whiteLabeling?.rocket || Colors.rocket, backgroundColor: loginConfig?.whiteLabeling?.mint || Colors.mint }}
                onClick={() => navigate(`/login?to=${params.to}`)}
                className="border-solid hover:text-spinach hover:ease-in hover:duration-200 hover:bg-[#b3ffc0] h-[48px] w-full font-semibold border rounded-md mt-1.5 mobile:mt-3 laptop:mt-4 text-14 text-rocket mb-8">
                {t('register.log-in')}
            </button>
        </AuthContainer>

    )
}
