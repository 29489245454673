import { Action, applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { persistStore } from 'redux-persist'
import thunk, { ThunkAction } from 'redux-thunk'

import homeReducer from './home/reducer'
import userReducer from './user/reducer'
import perksReducer from './perks/reducer'
import recipeReducer from './recipe/reducer'
import prizeReducer from './prize/reducer'
import articlesReducer from './articles/reducer'
import challengeReducer from './challenges/reducer'
import leaderboardReducer from './leaderboard/reducer'
import quizReducer from './quiz/reducer'
import travelReducer from './travel/reducer'

const rootReducer = combineReducers({
    home: homeReducer,
    user: userReducer,
    perks: perksReducer,
    recipe: recipeReducer,
    prize: prizeReducer,
    articles: articlesReducer,
    challenges: challengeReducer,
    leaderboard: leaderboardReducer,
    quiz: quizReducer,
    travel: travelReducer,
})

const middlewares = [thunk]

declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(...middlewares),
    ),
)

const persistor = persistStore(store)

export { persistor, store }

export type RootState = ReturnType<typeof rootReducer>

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
