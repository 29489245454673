import React, { FC, useContext, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Context } from './BaseContext'

interface IToolTipProps {
    text: string
    children?:React.ReactNode
}

// eslint-disable-next-line react/function-component-definition
const Tooltip:FC<IToolTipProps> = ({ text, children }) => {
    const [showTooltip, setShowTooltip] = useState<Boolean>(false)
    const { screenWidth } = useContext(Context)

    return (
        <div style={{ zIndex: 1000 }} className="mx-[5px] mt-[5px]" onClick={() => setShowTooltip(!showTooltip)}>
            {children}

            {showTooltip && (
                <div className={cn('mt-2 bg-white text-grey01 tracking-[-.01em] leading-[150%] px-[19px] py-[16px] text-14 font-normal rounded-lg absolute mobile:w-[350px]', css(screenWidth <= 768 ? styles.textActive : styles.textDefault))}>
                    {text}
                </div>
            )}
        </div>
    )
}

export default Tooltip

const styles = StyleSheet.create({
    textActive: {
        right: 40,
        left: 40,
        filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))',
    },
    textDefault: {
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
    },
})
