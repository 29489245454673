import { ICategoryResponse,
    IRecipe,
    IRecipeComment, IRecipeCommentResponse,
    IRecipeHomePage, IRecipePledgeLogResponse,
    IRecipeRateResponse,
    IRecipeRatingData, ISearchCollection,
    ITags, IWriteRecipeReviewData } from '../../types/data'
import apiClient from '../instance'
import { RECIPE_BOOKMARK_URL,
    RECIPE_CATEGORY_URL,
    RECIPE_HOME_PAGE_URL,
    RECIPE_ID_DEEPLINK_URL,
    RECIPE_RATING_URL,
    RECIPE_SEARCH_URL,
    RECIPE_TAGS_URL,
    RECIPES_MY_BOOKMARK_URL,
    RECIPE_COMMENT_URL,
    COMMENTS_BY_RECIPE_PAGINATION_URL,
    RECIPE_BY_CATEGORY_URL,
    RECIPE_SEARCH_COLLECTION_URL, RECIPE_LOG } from '../../urls'

/**
 * Get list of user bookmarked perks
 */
export const getRecipeBookmarkedList = async (params: { page: number }): Promise<IRecipe | null> => {
    try {
        const { data: response } = await apiClient.get<IRecipe>(RECIPES_MY_BOOKMARK_URL, {
            params,
        })
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post recipe bookmark
 */
export const postRecipeBookmarkItem = async (id: number): Promise<{ bookmarked: boolean } | null> => {
    try {
        const { data: response } = await apiClient.post<{ bookmarked: boolean } | null>(RECIPE_BOOKMARK_URL, {
            recipeId: id,
        })
        return response
    } catch (e) {
        throw e
    }
}

export const getRecipeHomePage = async (): Promise<IRecipeHomePage | null> => {
    try {
        const { data: response } = await apiClient.get<IRecipeHomePage>(RECIPE_HOME_PAGE_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getRecipeCategoryList = async (): Promise<ICategoryResponse | null> => {
    try {
        const { data: response } = await apiClient.get<ICategoryResponse>(RECIPE_CATEGORY_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getRecipeTagsList = async (): Promise<ITags | null> => {
    try {
        const { data: response } = await apiClient.get<ITags>(RECIPE_TAGS_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getRecipeSearchList = async (text: string): Promise<IRecipe | null> => {
    try {
        const { data: response } = await apiClient.get<IRecipe | null>(RECIPE_SEARCH_URL, {
            params: { text },
        })
        return response
    } catch (e) {
        throw e
    }
}

export const getRecipeDetail = async (id: number): Promise<IRecipe | null> => {
    try {
        const { data: response } = await apiClient.get<IRecipe | null>(RECIPE_ID_DEEPLINK_URL, { params: { id } })
        return response
    } catch (e) {
        throw e
    }
}

export const getRecipeCommentsList = async (recipe: object): Promise<IRecipeComment | null> => {
    try {
        const { data: response } = await apiClient.get<IRecipeComment>(COMMENTS_BY_RECIPE_PAGINATION_URL, {
            params: recipe,
        })
        return response
    } catch (e) {
        throw e
    }
}

export const postRateRecipe = async (data: IRecipeRatingData): Promise<IRecipeRateResponse | null> => {
    try {
        const { data: response } = await apiClient.post<IRecipeRateResponse | null>(RECIPE_RATING_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get by category list
 */
export const getByCategoryList = async (tags: string | undefined): Promise<IRecipe | null> => {
    try {
        const { data: response } = await apiClient.get<IRecipe>(RECIPE_BY_CATEGORY_URL, { params: { tags } })

        return response
    } catch (e) {
        throw e
    }
}

export const recipePostComment = async (
    data: IWriteRecipeReviewData,
): Promise<IRecipeCommentResponse | null> => {
    try {
        const { data: response } = await apiClient.post<IRecipeCommentResponse | null>(
            RECIPE_COMMENT_URL,
            data,
        )

        return response
    } catch (e) {
        throw e
    }
}

export const getRecipeByCategory = async (category: number | string): Promise<IRecipe | null> => {
    try {
        const { data: response } = await apiClient.get<IRecipe>(RECIPE_BY_CATEGORY_URL, {
            params: { category },
        })
        return response
    } catch (e) {
        throw e
    }
}

export const getRecipesByTags = async (tags: string): Promise<IRecipe | null> => {
    try {
        const { data: response } = await apiClient.get<IRecipe>(RECIPE_BY_CATEGORY_URL, {
            params: { tags },
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get recipe search collection list
 */
export const getRecipeSearchCollection = async (): Promise<ISearchCollection | null> => {
    try {
        const { data: response } = await apiClient.get<ISearchCollection>(RECIPE_SEARCH_COLLECTION_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post recipe pledge log
 */
export const postRecipePledgeLogItem = async (id: number | undefined): Promise<IRecipePledgeLogResponse | null> => {
    try {
        const { data: response } = await apiClient.post<IRecipePledgeLogResponse | null>(RECIPE_LOG, {
            recipe: id,
        })
        return response
    } catch (e) {
        throw e
    }
}
