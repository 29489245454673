import * as humps from 'humps'
import filter from 'lodash/filter'
import { ContentBlock, IDashboardColumn } from '../types/data'

export function rateFixed(rate: number) {
    return Number(rate.toFixed(1))
}

export const displayDistanceWithUnit = (km: number, unit: string, t: (key: string) => string | number) => {
    let result = km

    if (unit === 'miles') {
        result = km * 0.621371
    }

    if (result < 10) {
        return `${result.toFixed(1)} ${t(`global.${unit}-unit`)}`
    }

    return `${result.toFixed(0)} ${t(`global.${unit}-unit`)}`
}

export const getFormData = (data: object) => {
    const formData = new FormData()

    Object.entries(data)?.forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value?.forEach((item) => formData.append(humps.decamelize(key), item))
            return
        }
        formData.append(humps.decamelize(key), value)
    })
    return formData
}

export const readableC02 = (ceo2Kilograms: number, showCO2E = true) => {
    if (ceo2Kilograms < 1) {
        return `${(ceo2Kilograms * 1000)?.toFixed(0)} ${ceo2Kilograms === 0 ? 'kg' : 'g'} ${
            showCO2E ? ' CO2e' : ''
        }`
    }

    if (ceo2Kilograms >= 10) {
        return `${Math.round(ceo2Kilograms)} kg${showCO2E ? ' CO2e' : ''}`
    }

    return `${ceo2Kilograms?.toFixed(1)} kg${showCO2E ? ' CO2e' : ''}`
}

export const readableCO2 = (ceo2InGrams: number) => {
    if (ceo2InGrams < 1) {
        return ceo2InGrams?.toFixed(1)
    }

    const kilograms = ceo2InGrams
    if (kilograms >= 10) return `${Math.round(kilograms)}`

    return `${kilograms?.toFixed(1)}`
}

type contentType = { position: number, component: JSX.Element, contentBlock: ContentBlock }

export const sortSticky = (content: contentType[]): contentType[] => [
    ...filter(content, (i) => i.contentBlock?.sticky === 'sticky-top'),
    ...filter(content, (i) => !i.contentBlock?.sticky),
    ...filter(content, (i) => i.contentBlock?.sticky === 'sticky-bottom'),
]

export const sortStickyDashboard = (content: IDashboardColumn[]): IDashboardColumn[] => [
    ...filter(content, (i) => i.contentBlock?.sticky === 'sticky-top'),
    ...filter(content, (i) => !i.contentBlock?.sticky),
    ...filter(content, (i) => i.contentBlock?.sticky === 'sticky-bottom'),
]

export const replaceAll = (text: string, search: string, replacement?: string) => text.split(search).join(replacement)

export function convertSecondsToMinutesAndHours(seconds: number) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)

    const minutesString = minutes === 1 ? 'min' : 'mins'

    const result = []

    if (hours) {
        result.push(`${hours} hours`)
    }

    result.push(`${minutes} ${minutesString}`)

    return result.join(' ')
}

export const replacePartsOfText = (text: string, replacements: any) => {
    let newText = text

    Object.keys(replacements).map((i) => {
        newText = newText.replace(i, replacements[i])
        return 0
    })

    return newText
}

export function capitalizeFirstLetterOfEachWord(str: string) {
    return str.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
}
