import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import uniqueBy from 'lodash/uniqBy'
import { ArticleState } from './types'
import { FETCH_ARTICLE_START,
    FETCH_ARTICLE_ERROR,
    ARTICLE_MY_BOOKMARK_LIST,
    ARTICLE_CATEGORY_LIST,
    ARTICLE_BY_CATEGORY_LIST,
    ARTICLE_HOME_PAGE_LIST,
    POST_BOOKMARK_SUCCESS,
    FETCH_ARTICLE_START_BOOKMARK,
    FETCH_ARTICLE_ERROR_BOOKMARK,
    GET_ARTICLE_SEARCH_LIST,
    GET_ARTICLE_BY_ID,
    ARTICLE_ANSWER_SUCCESS,
    ARTICLE_SEARCH_COLLECTION_SUCCESS,
    CLEAR_ARTICLE_DETAIL, FETCH_COMPANY_ARTICLE_SUCCESS } from './consts'

const initialState: ArticleState = {
    articles: [],
    myBookmarkArticle: { count: 0, results: [] },
    category: [],
    byCategoryList: [],
    // @ts-ignore
    articleHomePage: [],
    ArticlesBySearch: [],
    searchCollection: [],
    companyArticle: [],
    // @ts-ignore
    articleById: {},
    count: 0,
    lastPage: 1,
    next: null,
    previous: null,
    loading: false,
    loadingByCategoryList: false,
    loadingArticleSearch: false,
    loadingArticleById: undefined,
    loadingRelatedArticles: false,
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AnyAction): ArticleState => {
    const { payload } = action

    switch (action.type) {
    case FETCH_ARTICLE_START:
        return {
            ...state,
            loading: true,
            loadingByCategoryList: true,
            loadingArticleSearch: true,
            loadingRelatedArticles: true,
        }

    case ARTICLE_MY_BOOKMARK_LIST:
        return {
            ...state,
            // eslint-disable-next-line no-unsafe-optional-chaining
            myBookmarkArticle: { ...payload, results: uniqueBy([...state?.myBookmarkArticle?.results, ...payload?.results], (e) => e.id) },
            loading: false,
        }

    case ARTICLE_CATEGORY_LIST:
        return {
            ...state,
            category: payload,
            loading: false,
        }

    case CLEAR_ARTICLE_DETAIL:
        return {
            ...state,
            // @ts-ignore
            articleById: {},
        }

    case ARTICLE_BY_CATEGORY_LIST:
        return {
            ...state,
            byCategoryList: payload,
            loadingByCategoryList: false,
        }

    case ARTICLE_HOME_PAGE_LIST:
        return {
            ...state,
            articleHomePage: payload,
            loading: false,
        }

    case ARTICLE_ANSWER_SUCCESS:
        const newAnswer = state.articleById.id === payload.article
            ? {
                ...state.articleById,
                quizzCompleted: payload.response.quizzCompleted,
            }
            : state.articleById
        return {
            ...state,
            articleById: newAnswer,
            loading: false,
        }

    case FETCH_ARTICLE_START_BOOKMARK:
        return {
            ...state,
        }
    case GET_ARTICLE_SEARCH_LIST:
        return {
            ...state,
            ArticlesBySearch: payload,
            loadingArticleSearch: false,
        }
    case POST_BOOKMARK_SUCCESS:
        const newArticlesBySearch = state.ArticlesBySearch.map((item) => (item.id === payload.bookmarkedId ? {
            ...item,
            isBookmark: payload.isBookmark,
        }
            : item))

        const newArticleById = state.articleById.id === payload.bookmarkedId ? {
            ...state.articleById,
            isBookmark: payload.isBookmark,
        }
            : state.articleById

        const newByCategoryList = state.byCategoryList.map((item) => (item.id === payload.bookmarkedId ? {
            ...item,
            isBookmark: payload.isBookmark,
        }
            : item))

        const newFeaturedOne = state.articleHomePage.featuredArticleOne.id === payload.bookmarkedId
            ? {
                ...state.articleHomePage.featuredArticleOne,
                isBookmark: payload.isBookmark,
            }
            : state.articleHomePage.featuredArticleOne

        const newFeaturedTwo = state.articleHomePage.featuredArticleTwo.id === payload.bookmarkedId
            ? {
                ...state.articleHomePage.featuredArticleTwo,
                isBookmark: payload.isBookmark,
            }
            : state.articleHomePage.featuredArticleTwo

        const newFeaturedThree = state.articleHomePage.featuredArticleThree.id === payload.bookmarkedId
            ? {
                ...state.articleHomePage.featuredArticleThree,
                isBookmark: payload.isBookmark,
            }
            : state.articleHomePage.featuredArticleThree

        const newFeaturedFour = state.articleHomePage.featuredArticleFour.id === payload.bookmarkedId
            ? {
                ...state.articleHomePage.featuredArticleFour,
                isBookmark: payload.isBookmark,
            }
            : state.articleHomePage.featuredArticleFour

        const newBookmarkCollectionOne = state.articleHomePage.collectionOne.articles.map((item) => (item.id === payload.bookmarkedId
            ? {
                ...item,
                isBookmark: payload.isBookmark,
            }
            : item))

        const newBookmarkCollectionTwo = state.articleHomePage.collectionTwo.articles.map((item) => (item.id === payload.bookmarkedId
            ? {
                ...item,
                isBookmark: payload.isBookmark,
            }
            : item))

        const newBookmarkArticle = state.myBookmarkArticle?.results?.map((item) => (item.id === payload.bookmarkedId
            ? {
                ...item,
                isBookmark: payload.isBookmark,
            }
            : item))

        return {
            ...state,
            ArticlesBySearch: newArticlesBySearch,
            byCategoryList: newByCategoryList,
            articleById: newArticleById,
            myBookmarkArticle: { count: state.myBookmarkArticle?.count, results: newBookmarkArticle },
            articleHomePage: {
                ...state.articleHomePage,
                featuredArticleOne: newFeaturedOne,
                featuredArticleTwo: newFeaturedTwo,
                featuredArticleThree: newFeaturedThree,
                featuredArticleFour: newFeaturedFour,
                collectionOne: { ...state.articleHomePage.collectionOne, articles: newBookmarkCollectionOne },
                collectionTwo: { ...state.articleHomePage.collectionTwo, articles: newBookmarkCollectionTwo },
            },
        }

    case FETCH_ARTICLE_ERROR_BOOKMARK:
        return { ...state }

    case GET_ARTICLE_BY_ID:
        return {
            ...state,
            articleById: payload,
            loadingArticleById: false,
        }

    case ARTICLE_SEARCH_COLLECTION_SUCCESS:
        return {
            ...state,
            searchCollection: payload,
            loading: false,
        }

    case FETCH_COMPANY_ARTICLE_SUCCESS:
        return {
            ...state,
            companyArticle: payload,
            loading: false,
        }

    case FETCH_ARTICLE_ERROR:
        return { ...state, loading: false }
    default:
        return state
    }
}

const persistConfig: PersistConfig<ArticleState> = {
    key: 'articles',
    storage,
    whitelist: [
        'articles',
        'articleHomePage',
        'searchCollection',
        'category',
    ],
}

export default persistReducer(persistConfig, reducer)
