import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import zigZag from '../static/win-zig-zag.png'
import { IMonthlyData } from '../types/data'
import Skeleton from './common/Skeleton'

interface IProps {
    item: IMonthlyData
    homeFan?: boolean
}

export default function PrizeDrawCard({ item, homeFan }: IProps) {
    const [loading, setLoading] = useState(true)
    const [t] = useTranslation('global')
    const handleImageLoad = () => setLoading(false)

    return (
        <div className={`cursor-pointer ${!homeFan && 'max-w-full'}`}>
            <div className={`mt-3 card ${homeFan && 'pr-0.5'}`} style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                <div className="flex flex-col justify-end">
                    {loading && <Skeleton className={`rounded-t-lg ${!homeFan && 'w-[326px] h-[165px]'} object-cover aspect-w-16 aspect-h-9 aspect-video`} />}
                    <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-t-lg ${!homeFan && 'w-full h-[165px]'} object-cover aspect-w-16 aspect-h-9 aspect-video`} src={item?.heroImage} alt="" />

                    <div className="flex items-center justify-center ml-4 absolute">
                        <img src={zigZag} alt="" className="h-[31px]" />

                        <span className="absolute font-semibold tracking-tight text-14 text-black">
                            {t('home.win')}
                        </span>
                    </div>

                    <div className="border-4 border-solid border-lime" />
                </div>

                <div className="p-4 rounded-b-lg bg-pistachio">
                    <button className={`font-medium leading-[100%] ${!homeFan && 'uppercase'} ${homeFan ? 'text-18 font text-black' : 'text-12 text-grey01'}`}>
                        {loading ? (
                            <Skeleton className="w-5/12 h-[10px] rounded-sm" />
                        ) : (
                            <div>
                                {homeFan ? t('global.this-month’s-prize') : item?.subHeadline}
                            </div>
                        )}
                    </button>

                    <h5 className={`tracking-tight mt-1 ${homeFan ? 'text-grey01 text-14 font-normal' : 'text-black text-16 font font-medium'} leading-[20.8px]`}>
                        {loading ? (
                            <>
                                <Skeleton className="w-full h-[10px] rounded-sm mt-2" />
                                <Skeleton className="w-6/12 h-[10px] rounded-sm mt-1" />
                            </>
                        ) : (
                            <div>
                                {item?.headline}
                            </div>
                        )}
                    </h5>
                </div>
            </div>
        </div>
    )
}
