import { IHomePage, IHowToCollectPointsData, InitializeResponse, SponsorshipBlock } from '../../types/data'
import apiClient from '../instance'
import { HOME_PAGE_URL,
    HOW_TO_COLLECT_POINTS_URL, INITIALIZE_RESPONSE_URL,
    PERKS_BOOKMARK_URL,
    SPONSORSHIP_BLOCKS_URL } from '../../urls'

/**
 * Get home page list
 */
export const getHomePageList = async (): Promise<IHomePage | null> => {
    try {
        const { data: response } = await apiClient.get<IHomePage>(HOME_PAGE_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post book mark item
 */
export const postBookmarkItem = async (id: number): Promise<{ bookmarked: boolean } | null> => {
    try {
        const { data: response } = await apiClient.post<{ bookmarked: boolean } | null>(PERKS_BOOKMARK_URL, {
            perksId: id,
        })
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get how to collect points
 */
export const getHowToCollectPoints = async (): Promise<IHowToCollectPointsData> => {
    try {
        const { data: response } = await apiClient.get<IHowToCollectPointsData>(HOW_TO_COLLECT_POINTS_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getSponsoredByBlocks = async (): Promise<SponsorshipBlock[]> => {
    try {
        const { data: response } = await apiClient.get<SponsorshipBlock[]>(SPONSORSHIP_BLOCKS_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getInitializeResponse = async (): Promise<InitializeResponse> => {
    try {
        const { data: response } = await apiClient.get<InitializeResponse>(INITIALIZE_RESPONSE_URL)

        return response
    } catch (e) {
        throw e
    }
}
