import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IChallenges } from '../../types/data'
import SecondButton from '../common/SecondButton'
import Skeleton from '../common/Skeleton'
import { Context } from '../common/BaseContext'

interface IProps {
    item: IChallenges
}

export default function ChallengeCard({ item }: IProps) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    // eslint-disable-next-line no-unsafe-optional-chaining
    const percentage = Math.trunc((item?.loggedDaysCount * 100) / item?.challengeDuration)
    // eslint-disable-next-line no-unsafe-optional-chaining
    const day = item?.challengeDuration - item?.loggedDaysCount <= 1

    const navigateChallengeDetail = (challengeId: number) => {
        navigate(`/lower/pledges/challenge-detail/${challengeId}`)
    }

    return (
        <div onClick={() => navigateChallengeDetail(item?.id)}>
            <div className="cursor-pointer hidden mobile:block" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                <div className="mt-3 flex items-end">
                    <div className="w-[448px] bg-pistachio px-6 pb-6 absolute rounded-lg ml-8 mb-8 flex flex-col justify-between">
                        <div className="w-[52px] h-[40px] ml-1">
                            <img src={item?.activeBadgeIcon} alt="" className="w-[43px] h-[52px] absolute" style={{ top: -13 }} />
                        </div>

                        <div>
                            <span className="text-black font tracking-tight font-medium text-22">
                                {item?.title}
                            </span>
                        </div>

                        <span className="font-normal text-grey01 text-16">
                            {item?.shortDescription}
                        </span>

                        {item?.isJoined && (
                            <>
                                <div className="rounded-full flex-1 h-3 mt-2.5 overflow-hidden" style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.25)' }}>
                                    <div className="z-10 relative flex rounded-r-sm rounded-l-xl h-3 bg-raspberry" style={{ width: `${percentage}%` }} />
                                    <div className="w-full relative z-0 rounded-full bg-white h-2.5" style={{ marginTop: -11 }} />
                                </div>

                                <span className="mt-1.5 text-spinach font-semibold text-14 tracking-tight">
                                    {percentage}% {t('lower.complete')}!&nbsp;

                                    {!day
                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                        ? item?.challengeDuration - item?.loggedDaysCount !== 0 && (
                                            // eslint-disable-next-line no-unsafe-optional-chaining
                                            <span>{item?.challengeDuration - item?.loggedDaysCount} {t('global.days-left')}</span>
                                        )
                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                        : item?.challengeDuration - item?.loggedDaysCount !== 0 && (
                                            // eslint-disable-next-line no-unsafe-optional-chaining
                                            <span>{item?.challengeDuration - item?.loggedDaysCount} {t('global.day-left')}</span>
                                        )}
                                </span>
                            </>
                        )}

                        {item?.isActive && !item?.isJoined
                        && (
                            <SecondButton
                                onClick={() => navigateChallengeDetail(item?.id)}
                                text={t('global.join-the-challenge')}
                                className="mt-2.5 w-[184px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                            />
                        )}
                    </div>

                    {loading && <Skeleton className="w-[994px] rounded-t-lg h-[560px] object-cover" />}
                    <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-t-lg w-screen h-[560px] object-cover`} src={item?.image} alt="" />
                </div>
            </div>

            <div className="mt-3 cursor-pointer w-full mobile:hidden" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                <div className="flex flex-col justify-end">
                    {screenWidth > 600 ? (
                        <img className={`${loading ? 'hidden' : 'block'} aspect-w-16 aspect-h-9 aspect-video rounded-t-lg object-cover`} src={item?.image} alt="" />
                    ) : (
                        !loading && <Skeleton className="aspect-w-16 aspect-h-9 aspect-video rounded-t-lg object-cover" />
                    )}

                    <div className="w-[44px] h-[17px] flex items-center justify-center ml-4 absolute">
                        <img src={item?.activeBadgeIcon} alt="" className="w-[36px] h-[44px]" />
                    </div>
                </div>

                <div className="p-4 rounded-b-lg bg-pistachio">
                    <div className="">
                        <h1 className="text-darken font tracking-tight font-medium mobile:text-22 text-18">
                            {item?.title}
                        </h1>

                        <h1 className="font-normal text-grey01 mobile:text-16 text-14">
                            {item?.shortDescription}
                        </h1>
                    </div>

                    {item?.isJoined && (
                        <>
                            <div className="rounded-full flex-1 h-3 mt-2.5 mb-1.5" style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.25)' }}>
                                <div className="z-10 relative flex rounded-r-sm rounded-l-xl h-3 bg-raspberry" style={{ width: `${item?.loggedDaysCount}%` }} />
                                <div className="w-full relative z-0 rounded-full bg-white h-2.5" style={{ marginTop: -11 }} />
                            </div>

                            <span className="text-spinach font-semibold text-14 tracking-tight">
                                {percentage}% complete!&nbsp;

                                {!day
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    ? item?.challengeDuration - item?.loggedDaysCount !== 0 && (
                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                        <span>{item?.challengeDuration - item?.loggedDaysCount} {t('global.days-left')}</span>
                                    )
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    : item?.challengeDuration - item?.loggedDaysCount !== 0 && (
                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                        <span>{item?.challengeDuration - item?.loggedDaysCount} {t('global.day-left')}</span>
                                    )}

                            </span>
                        </>
                    )}

                    {item?.isActive && !item?.isJoined
                    && (
                        <SecondButton
                            onClick={() => navigateChallengeDetail(item?.id)}
                            text={t('global.join-the-challenge')}
                            className="mt-2.5 w-full h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
