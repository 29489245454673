import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { postPerkBookMark } from '../../state/perks/actions'
import { IPerks } from '../../types/data'
import { ArrowBottomIcon, SavedIconActive, SavedIconDefault, StarIcon } from '../common/Svg'
import { postHomeBookMark } from '../../state/home/actions'
import Skeleton from '../common/Skeleton'
import { readableC02 } from '../../utils/helpers'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item: IPerks
    type?: 'home' | 'perk'
}

export default function PerkOfTheDay({ item, type }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [t] = useTranslation('global')
    const firstImage = item?.perksImages && item?.perksImages.map((items) => items?.image)
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)
    // @ts-ignore
    const isTotal = item?.isTotalRating?.new

    const onPressBookmark = async (id: number) => {
        if (type === 'perk') await dispatch(postPerkBookMark(id))
        if (type === 'home') await dispatch(postHomeBookMark(id))
    }

    const navigatePerkDetail = (detailId: number) => {
        navigate(`/lower/swaps/${detailId}`)
    }

    return (
        <div className="mt-3 card cursor-pointer w-full" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div className="flex justify-end mr-4">
                <div onClick={() => onPressBookmark(item?.id)} className="absolute rounded-full flex justify-center items-center mt-4 bg-black w-[28px] h-[28px]">
                    {item?.isBookmark ? (
                        <SavedIconActive width={12} height={15} />
                    ) : (
                        <SavedIconDefault width={12} height={15} />
                    )}
                </div>
            </div>

            <div onClick={() => navigatePerkDetail(item?.id)}>
                <div className="flex flex-col justify-end">
                    {loading && <Skeleton className="rounded-t-lg object-cover aspect-w-16 aspect-h-9 aspect-video" />}

                    <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-t-lg object-cover aspect-w-16 aspect-h-9 aspect-video`} src={firstImage ? firstImage[0] : ''} alt="" />
                </div>

                <div className={cn('rounded-b-lg bg-pistachio mobile:pt-5', { 'pt-5': !item?.sponsorshipBlock })}>
                    {item?.sponsorshipBlock && <SponsoredByBlock className="px-5 mb-1" fontColor="dark" noBackground noBorder item={item.sponsorshipBlock} />}

                    <button className={cn('flex mb-1 px-5 font-medium font leading-[132%] tracking-[-0.02rem] text-black text-18 mobile:pt-5', { 'pt-5': !item?.sponsorshipBlock })}>
                        {loading ? (
                            <div>
                                <Skeleton className="w-full mr-[45%] h-[13px] rounded-sm" />
                            </div>
                        ) : (
                            <ContentBlockTitle text={`${item?.perkBrand?.discount} ${item?.perkBrand?.name}`} />
                        )}
                    </button>

                    <p className="mb-2.5 px-5 font-light tracking-tight text-grey01 text-14">
                        {loading ? (
                            <>
                                <Skeleton className="w-full mr-[60%] h-[10px] rounded-sm mt-3" />
                                {type !== 'home' && (
                                    <Skeleton className="w-8/12 h-[10px] rounded-sm mt-3" />
                                )}
                            </>
                        ) : (
                            <div>
                                {item?.shortDescription}
                            </div>

                        )}
                    </p>

                    <div className="flex items-center px-5 pb-5">
                        {loading ? (
                            <Skeleton className="w-full mr-[75%] h-[10px] rounded-sm mt-2" />
                        ) : (
                            item?.ceo2Kg !== 0 ? (
                                <div className="flex items-center">
                                    <ArrowBottomIcon width={15} height={15} />

                                    <p className="font-semibold not-heading-font text-grey01 ml-1.5 text-12">
                                        {readableC02(item?.ceo2Kg || 0)}
                                    </p>
                                </div>
                            ) : (
                                <div className="flex items-center">
                                    <StarIcon width={18} height={19} />

                                    <p className="font-semibold not-heading-font text-grey01 text-12">
                                        {!isTotal ? Math.floor(Number(item?.totalRating || 0)) : t('lower.new')}
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
