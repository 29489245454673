import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { PencilIconSmall } from '../../components/common/Svg'
import TravelGraph from '../../components/travel/TravelGraph'
import { Context } from '../../components/common/BaseContext'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import { fetchGame, fetchRecentRoutes, fetchSavedRoutes, fetchTransportCeo2 } from '../../state/travel/actions'
import { travelSelector } from '../../state/travel/selectors'
import { getDateTime } from '../../utils/date'
import { ITravelData } from '../../types/data'
import { clearTravelData, saveCommuteInstanceToLocalstorage } from '../../utils/travel'
import { displayDistanceWithUnit, readableC02 } from '../../utils/helpers'

import Skeleton from '../../components/common/Skeleton'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'

export default function Travel() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { navbarHeight, distanceUnit, screenWidth } = useContext(Context)
    const [t] = useTranslation('global')
    const [showSwitch, setShowSwitch] = useState<number>(0)
    const { user } = useSelector(getUser)
    const { savedRoutes, recentRoutes, transportCeo2 } = useSelector(travelSelector)
    const [loading, setLoading] = useState<boolean>(true)
    const { gameList } = useSelector(travelSelector)

    const switchData = [
        { id: 1, name: t('me.this-month') },
        { id: 2, name: t('me.lifetime') },
    ]

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Travel' })
    }, [])

    const switchSelectorFunc = async (index: number) => {
        await setShowSwitch(index)
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        dispatch(fetchSavedRoutes())
        dispatch(fetchRecentRoutes())
        dispatch(fetchTransportCeo2())
        dispatch(fetchGame())
        // eslint-disable-next-line
    }, [])

    function navigateToTrackTravel() {
        clearTravelData(dispatch)

        if ((gameList?.length === 1 && user?.company?.autoForwardIfSingleEvent) && user?.company?.travelTrackingType !== 'standard') {
            const today = new Date()
            localStorage.setItem('travelData', JSON.stringify({
                date: today,
                gameDate: gameList[0]?.date,
                gameId: gameList[0]?.id,
                legs: [],
                gameData: gameList[0],
            }))

            navigate('/log', { state: { prevUrl: '/me/impact' } })
        } else {
            navigate('/log/track-travel-fan')
        }
    }

    function onEdit(commute: ITravelData) {
        saveCommuteInstanceToLocalstorage(commute, { editCommute: true })

        navigate('/log/track-travel-overview', { state: { oneWay: false } })
    }

    const transportCeo2Results = transportCeo2?.results?.sort(
        (a, b) => (showSwitch === 0 ? b.thisMonthCeo2 - a.thisMonthCeo2 : b.lifetimeCeo2 - a.lifetimeCeo2),
    )

    useEffect(() => {
        if (transportCeo2?.thisMonthTotalCeo2Kg || transportCeo2?.thisMonthTotalCeo2Kg === 0) {
            setLoading(false)
        }
    }, [transportCeo2])

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Impact')

    return (
        <Layout contentContainerClassName="flex-col h-screen">
            {sponsoredBlock && <div className="mobile:hidden w-screen left-[-24px]"><SponsoredByBlock item={sponsoredBlock} /></div>}

            <div style={{ top: navbarHeight }} className={`overflow-x-hidden ${screenWidth < 1500 && 'laptop:mx-[223px] tablet:mx-[103px]'} mobile:mx-10 mx-4 mobile:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px]`}>
                <h1 className="heading-uppercase max-medium:hidden laptop:text-36 medium:text-36 mobile:text-32 text-26 tracking-tight font-bold">
                    {t('me.my-travel-impact')}
                </h1>

                <div className={`medium:flex items-center justify-between w-full ${screenWidth < 1300 ? 'laptop:mx-[223px] tablet:mx-[103px]' : ''}`}>
                    {/** ********************************************************************************************** */}
                    <TravelGraph />
                    {/** ********************************************************************************************** */}

                    {/** ********************************************************************************************** */}
                    <div className="medium:w-[48%]">
                        <div className="flex justify-around items-center rounded-[18px] bg-pistachio w-[210px] py-1 mt-[29px]">
                            {switchData?.map((item, index) => (
                                <div key={item?.id} onClick={() => switchSelectorFunc(index)} className={`flex items-center h-[28px] ${showSwitch === index ? 'bg-rocket' : 'bg-pistachio'} rounded-[13px] mx-[6px] cursor-pointer`}>
                                    <h1 className={`text-16 ${showSwitch === index ? 'text-pistachio px-[9px]' : 'text-rocket px-[4px]'} font-semibold not-heading-font tracking-[-0.01em]`}>
                                        {item?.name}
                                    </h1>
                                </div>
                            ))}
                        </div>

                        {loading ? (
                            <div className="w-full flex overflow-x-scroll overflow-y-hidden">
                                <Skeleton className="w-[1142px] h-[103px] bg-pistachio px-[16px] py-[8px] rounded-lg mt-[17px] mr-[8px]" />
                                <Skeleton className="w-[1142px] h-[103px] bg-pistachio px-[16px] py-[8px] rounded-lg mt-[17px] mr-[8px]" />
                                <Skeleton className="w-[1142px] h-[103px] bg-pistachio px-[16px] py-[8px] rounded-lg mt-[17px] mr-[8px] " />
                                <Skeleton className="w-[1142px] h-[103px] bg-pistachio px-[16px] py-[8px] rounded-lg mt-[17px] mr-[8px] " />
                            </div>
                        ) : (
                            <div className="w-full flex overflow-x-scroll overflow-y-hidden">
                                <div className="xs-mobile:w-screen">
                                    <div
                                        className="w-[124px] h-[110px] bg-pistachio px-[16px] py-[8px] rounded-lg mt-[17px] mr-[8px]">
                                        <p className="text-18 font-medium not-heading-font font text-black">
                                            {readableC02(showSwitch === 0 ? transportCeo2.thisMonthTotalCeo2Kg : transportCeo2.lifetimeTotalCeo2Kg, false)}
                                        </p>

                                        <p className="font-normal text-14 text-grey01">
                                            {t('me.total-CO2e-from-travel')}
                                        </p>
                                    </div>
                                </div>

                                {transportCeo2Results?.map((item) => (
                                    <div key={item?.id} className="xs-mobile:w-screen">
                                        <div key={item?.id} className="w-[124px] h-[110px] bg-pistachio px-[16px] py-[8px] rounded-lg mt-[17px] mr-[8px]">
                                            <p className="text-18 font-medium not-heading-font font text-black">
                                                {readableC02(Number(showSwitch === 0 ? item?.thisMonthCeo2 : item.lifetimeCeo2), false)}
                                            </p>

                                            <p className="font-normal text-14 text-grey01">
                                                {t('me.CO2e-by')} {item?.name}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {/** ********************************************************************************************** */}
                </div>

                {user.company.isTravelTypeEvent ? (
                    <button
                        className="xs-max:w-6/12 w-full h-[48px] font-semibold not-heading-font text-14 text-white flex items-center justify-center rounded-[5px] bg-rocket mt-[14px] hover:bg-spinach hover:ease-in hover:duration-200"
                        onClick={() => navigateToTrackTravel()}>
                        {t('me.log-your-travel-to-collect-points')}
                    </button>
                ) : (
                    <button
                        className="xs-max:w-6/12 w-full h-[48px] font-semibold not-heading-font text-14 text-white flex items-center justify-center rounded-[5px] bg-rocket mt-[14px] hover:bg-spinach hover:ease-in hover:duration-200"
                        onClick={() => navigateToTrackTravel()}>
                        {t('me.log-your-commute-to-collect-points')}
                    </button>
                )}

                <div className="medium:flex justify-between w-full">
                    {/** ********************************************************************************************** */}
                    {user?.company?.travelLogByRecent === 'enabled_expanded' || user?.company?.travelLogByRecent === 'enabled_collapsed' ? (
                        <div className="medium:w-[48%]">
                            <div className="flex items-center justify-between mt-[38px] mb-[3px]">
                                <h1 className="heading-font font-bold text-22 text-[rgba(0, 0, 0, 1)]">
                                    {t('global.recent-routes')}
                                </h1>

                                {recentRoutes?.length > 0 && (
                                    <button
                                        onClick={() => navigate('/log/track-travel-recent-routes')}
                                        className="font-semibold not-heading-font leading-[150%] tracking-[-0.02rem] underline underline-offset-2 text-14 text-rocket">
                                        {t('global.view-all')}
                                    </button>
                                )}
                            </div>

                            {recentRoutes?.length > 0 ? (
                                recentRoutes?.slice(0, 3)?.map((item) => (
                                    <div key={item?.id} className="mt-[16px]">
                                        <div className="flex items-center justify-between">
                                            <p className="text-14 font-bold not-heading-font text-black">
                                                {item?.game?.name}
                                                {/* {user?.company?.isTravelTypeEvent ? item.teamNameOnImpact : item?.year} */}
                                            </p>

                                            <div onClick={() => item?.startPlaceId && onEdit(item)} className={`flex items-center ${item?.startPlaceId && 'cursor-pointer'}`}>
                                                <p className={`text-14 font-bold not-heading-font ${item?.startPlaceId ? 'text-black' : 'text-[#999]'} mr-[4px]`}>
                                                    {t('global.edit')}
                                                </p>

                                                <PencilIconSmall width={16} height={16} color={item?.startPlaceId ? whiteLabeling.rocket : whiteLabeling.grey02} />
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between mt-[4px]">
                                            <p className="text-14 not-heading-font text-black">
                                                {/* @ts-ignore */}
                                                {getDateTime(item?.date || item?.game?.date)}
                                            </p>
                                            {/* <p className="font-normal text-14 text-grey01"> */}
                                            {/*    {displayDistanceWithUnit(item.distanceInKm || 0, distanceUnit, t)} */}
                                            {/* </p> */}

                                            {/* {user?.company?.isTravelTypeEvent ? item.teamNameOnImpact : item?.year} */}

                                            <p className="font-normal text-14 text-grey01">
                                                {t('me.by')}&nbsp;
                                                {lodash
                                                    .uniq(item?.legs?.map((i) => i?.transport?.name))
                                                    .join(', ')}
                                            </p>

                                            <p className="font-normal text-14 text-grey01">
                                                +{readableC02(item?.totalCeo2Kg || 0)}
                                            </p>
                                        </div>

                                        <div className="w-full h-[1px] bg-grey01 mt-[6px]" />
                                    </div>
                                ))
                            ) : (
                                <h1 className="mt-2.5 text-14 text-black pb-2.5">
                                    {t('me.no-recent-journeys-available')}
                                </h1>
                            )}
                        </div>
                    ) : null}
                    {/** ********************************************************************************************** */}

                    {/** ********************************************************************************************** */}
                    {user?.company?.travelLogByFavourite === 'enabled_expanded' || user?.company?.travelLogByFavourite === 'enabled_collapsed' ? (
                        <div className="medium:w-[48%]">
                            <div className="flex items-center justify-between mt-[38px]">
                                <h1 className="heading-font font-bold text-22 text-black">
                                    {t('global.saved-routes')}
                                </h1>

                                {savedRoutes.length > 0 && (
                                    <button
                                        onClick={() => navigate('/log/track-travel-saved-routes')}
                                        className="font-semibold not-heading-font leading-[150%] tracking-[-0.02rem] underline underline-offset-2 text-14 text-rocket">
                                        {t('global.view-all')}
                                    </button>
                                )}
                            </div>

                            {savedRoutes?.length > 0 ? (
                                savedRoutes?.slice(0, 3)?.map((item) => (
                                    <div key={item?.id} className="mt-[16px]">
                                        <div className="flex items-center justify-between">
                                            <p className="text-14 font-bold not-heading-font text-black">
                                                {item?.name}
                                            </p>

                                            <div onClick={() => item?.commute.startPlaceId && navigate('/log/track-travel/log-route', { state: { commute: item.commute } })} className={`flex items-center ${item?.commute.startPlaceId && 'cursor-pointer'}`}>
                                                <p className={`text-14 font-bold not-heading-font ${item?.commute.startPlaceId ? 'text-black' : 'text-[#999]'} mr-[4px]`}>
                                                    + {t('global.log-route')}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between mt-[4px]">
                                            <p className="font-normal text-14 text-grey01">
                                                {displayDistanceWithUnit(item.commute.distanceInKm || 0, distanceUnit, t)}
                                            </p>

                                            <p className="font-normal text-14 text-grey01">
                                                {t('me.by')}&nbsp;
                                                {lodash
                                                    .uniq(item?.commute?.legs?.map((i) => i?.transport?.name))
                                                    .join(', ')}
                                            </p>

                                            <p className="font-normal text-14 text-grey01">
                                                +{readableC02(item?.commute?.totalCeo2Kg || 0)}
                                            </p>
                                        </div>

                                        <div className="w-full h-[1px] bg-grey01 mt-[6px]" />
                                    </div>
                                ))
                            ) : (
                                <h1 className="mt-2.5 text-14 text-black pb-2.5">
                                    {t('global.no-favourite-journeys-available-log-a-new-journey-and-save-as-favourite-on-the-summary-screen')}
                                </h1>
                            )}
                        </div>
                    ) : null}
                    {/** ********************************************************************************************** */}
                </div>
            </div>
        </Layout>
    )
}
