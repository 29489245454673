const BASE = 'article/'
const BASE_BOOKMARK = 'article/bookmark'

export const FETCH_ARTICLE_START = `${BASE}FETCH_ARTICLE_START`

export const ARTICLE_MY_BOOKMARK_LIST = `${BASE}ARTICLE_MY_BOOKMARK_LIST`
export const ARTICLE_CATEGORY_LIST = `${BASE}ARTICLE_CATEGORY_LIST`
export const CLEAR_ARTICLE_DETAIL = `${BASE}CLEAR_ARTICLE_DETAIL`
export const ARTICLE_BY_CATEGORY_LIST = `${BASE}ARTICLE_BY_CATEGORY_LIST`
export const ARTICLE_HOME_PAGE_LIST = `${BASE}ARTICLE_HOME_PAGE_LIST`
export const ARTICLE_ANSWER_SUCCESS = `${BASE}ARTICLE_ANSWER_SUCCESS`

export const GET_ARTICLE_SEARCH_LIST = `${BASE}GET_ARTICLE_SEARCH_LIST`

export const GET_ARTICLE_BY_ID = `${BASE}GET_ARTICLE_BY_ID`

export const FETCH_ARTICLE_ERROR = `${BASE}NUTRITION_ARTICLE_LIST_ERROR`

export const FETCH_ARTICLE_START_BOOKMARK = `${BASE_BOOKMARK}FETCH_ARTICLE_START`
export const ARTICLE_SEARCH_COLLECTION_SUCCESS = `${BASE_BOOKMARK}ARTICLE_SEARCH_COLLECTION_SUCCESS`
export const FETCH_COMPANY_ARTICLE_SUCCESS = `${BASE_BOOKMARK}FETCH_COMPANY_ARTICLE_SUCCESS`
export const POST_BOOKMARK_SUCCESS = `${BASE_BOOKMARK}POST_BOOKMARK_SUCCESS`
export const FETCH_ARTICLE_ERROR_BOOKMARK = `${BASE_BOOKMARK}FETCH_ARTICLE_ERROR_BOOKMARK`
