import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { getChallengeList } from '../../state/challenges/selectors'
import { postChallengeJoin,
    postChallengeLogDay,
    postChallengeRemindOnStart,
    removeChallengeRemindOnStart } from '../../state/challenges/actions'
import ChallengeNotification from './ChallengeNotification'
import SecondButton from '../common/SecondButton'
import ChallengeCalendar from './ChallengeCalendar'
import { DATE_LOG_DAY_AUTO } from '../../utils/date'
import ChallengeDayLogModal from '../common/ChallengeDayLogModal'
import { fetchUser } from '../../state/user/actions'
import ChallengePointModal from '../common/ChallengePointModal'
import ChallengeBadgeModal from '../common/ChallengeBadgeModal'
import { ArrowTravel } from '../common/Svg'

interface IProps {
    setShowModal: (value: boolean) => void
    showModal: boolean
    rewards?: boolean
    data?: any
}

export default function ChallengeLeftSidebar({ setShowModal, showModal, rewards, data }:IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let buttonName
    const { challenge, loggedDays } = useSelector(getChallengeList)
    const { challengeId } = useParams()
    const [loading, setLoading] = useState(false)
    const challengeData = challenge?.find((item) => item.id === Number(challengeId))
    const [t] = useTranslation('global')

    const [selectedDay, setSelectedDay] = useState<number>(new Date().getDate())
    const [logDayModalShown, setLogDayModalShown] = useState<boolean>(false)
    const [isPointModalShown, setIsPointModalShown] = useState<boolean>(false)
    const [isBadgeModalShown, setIsBadgeModalShown] = useState<boolean>(false)

    const onPressButton = async (id: number | undefined) => {
        setLoading(true)

        if (!challengeData?.isJoined && challengeData?.isActive) {
            await dispatch(postChallengeJoin(id))
            await setShowModal(true)
        }

        if (challengeData?.isJoined) {
            const selectedDate = new Date()
            selectedDate.setDate(selectedDay)

            dispatch(postChallengeLogDay({
                challenge: challengeData?.id,
                date: moment(selectedDate).format(DATE_LOG_DAY_AUTO),
            }, (response) => {
                if (response.challengeCompleted) {
                    dispatch(fetchUser)
                    setIsBadgeModalShown(true)
                    return
                }

                setLogDayModalShown(true)
            }))
        }

        if (!challengeData?.isActive && !challengeData?.isJoined) {
            await dispatch(postChallengeRemindOnStart(id))
        }

        setLoading(false)
    }

    if (challengeData?.isJoined) {
        buttonName = t('lower.log-a-day')
    }
    if (!challengeData?.isJoined) {
        buttonName = t('lower.join-the-challenge')
    }
    if (!challengeData?.isActive && !challengeData?.isJoined) {
        buttonName = t('lower.remind-me-to-joi')
    }

    const deleteRemindOnStart = (remindId: number | undefined) => {
        dispatch(removeChallengeRemindOnStart(remindId))
    }

    const isTodaySelected = loggedDays.filter((i) => Number(i.date.slice(8)) === selectedDay).length

    const navigateBack = () => {
        navigate('/')
    }

    return (
        <div className="mobile:bg-pistachio bg-white tablet:w-[476px] medium:w-[404px] flex flex-col items-center mb-[0px] medium:mb-0 medium:h-auto medium:p-10 max-mobile:w-[calc(100vw-32px)] max-medium:w-[calc(100vw-80px)] tablet:ml-0 medium:flex relative medium:static z-50 top-[50vw] mb-[50vw] max-mobile:border-b max-mobile:border-avocado">
            <div className="max-mobile:bg-pistachio max-mobile:px-4 p-8 w-full flex flex-col justify-center medium:p-0">
                {!rewards ? (
                    <span onClick={() => navigate('/lower/pledges')} className="text-center medium:pt-[36px] text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('lower.challenges')}
                    </span>
                ) : (
                    <div onClick={navigateBack} className="flex justify-center cursor-pointer">
                        <ArrowTravel width={22} height={22} />
                    </div>
                )}

                <div className="flex flex-col items-center">
                    <div className="flex flex-col items-center">
                        {!rewards && (
                            <div className="medium:mt-[129px] mt-8">
                                <img src={challengeData?.activeBadgeIcon} alt="" className="w-[45px] h-[55px]" />
                            </div>
                        )}

                        <span className="font-normal text-grey01 text-14 mt-3">
                            {challengeData?.challengeName}
                        </span>

                        <span className="text-black font tracking-tight font-medium medium:text-32 text-26 text-center">
                            {challengeData?.title}
                        </span>
                    </div>
                </div>
            </div>

            <div className="p-8 mobile:pt-0 mobile:pb-10 max-mobile:px-0 medium:p-0 medium:w-full">
                {challengeData?.isJoined && (
                    <>
                        <p className="medium:mt-[53px] mt-8 mb-4 font-normal font text-18 laptop:text-22 tablet:text-22 tracking-tight text-black text-center w-full">
                            {t('lower.select-a-day-to-log')}
                        </p>

                        <ChallengeCalendar
                            challenge={challengeData}
                            selectedDay={selectedDay}
                            onSelect={setSelectedDay}
                            year={new Date(challengeData?.startDate).getFullYear()}
                            month={new Date(challengeData?.startDate).getMonth()} />
                    </>
                )}

                {challengeData?.remindOnStart === null || challengeData?.isActive ? (
                    <SecondButton
                        disabled={loading || !!isTodaySelected}
                        loading={loading}
                        onClick={() => onPressButton(challengeData?.id)}
                        text={buttonName}
                        className={cn(
                            'w-full h-[48px] font-semibold rounded-md text-14 text-pistachio',
                            {
                                'bg-rocket': !(loading || !!isTodaySelected),
                                'hover:bg-spinach hover:ease-in hover:duration-200': !isTodaySelected,
                                'mt-6': challengeData?.isJoined,
                                'medium:mt-[126px] mt-8': !challengeData?.isJoined,
                            },
                        )}
                    />
                ) : (
                    <div className="medium:mt-[126px] mt-8 text-center">
                        {!rewards ? (
                            <h1 className="text-16 font-normal font tracking-tight text-black">
                                {t('lower.we’ll-notify-you-when-the-challenge-starts')}
                            </h1>
                        ) : (
                            <span className="text-black font tracking-tight font-medium medium:text-32 text-26 text-center">
                                {data.title}
                            </span>
                        )}

                        <h1 onClick={() => deleteRemindOnStart(challengeData?.id)} className="mt-1.5 text-center text-rocket tracking-tight font font-medium cursor-pointer underline underline-offset-2 text-14">
                            {!rewards && t('lower.turn-off-reminder')}
                        </h1>
                    </div>
                )}

                {challengeData?.isActive && (
                    <p className="mt-2 text-14 font-light text-grey01 text-center">
                        {challengeData?.joinedPeopleCount} {t('lower.people-joined-so-far')}
                    </p>
                )}

                {(challengeData && logDayModalShown) && (
                    <ChallengeDayLogModal
                        challenge={challengeData}
                        isShown={logDayModalShown}
                        onClose={() => setLogDayModalShown(false)} />
                )}

                {(challengeData && isPointModalShown) && (
                    <ChallengePointModal
                        challengeName={challengeData.challengeName}
                        point={challengeData.completePoints}
                        onClose={() => setIsPointModalShown(false)} />
                )}

                {(challengeData && isBadgeModalShown) && (
                    <ChallengeBadgeModal
                        challenge={challengeData}
                        onClose={() => {
                            setIsBadgeModalShown(false)
                            setIsPointModalShown(true)
                        }} />
                )}

                {showModal && (
                    <ChallengeNotification items={challengeData} setShowModal={setShowModal} showModal={showModal} />
                )}
            </div>
        </div>
    )
}
