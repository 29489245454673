import { IAnswersResponse,
    IArticleCategory,
    IArticleHomePage,
    IArticles, ICompanyArticle,
    IQuizData,
    ISearchCollection } from '../../types/data'
import apiClient from '../instance'
import { ARTICLE_ANSWER_URL, ARTICLE_BOOKMARK_URL, ARTICLE_BY_CATEGORY_URL,
    ARTICLE_CATEGORY_URL,
    ARTICLE_HOME_PAGE_URL, ARTICLES_BY_ID_URL,
    ARTICLES_MY_BOOKMARK_URL, ARTICLES_SEARCH_URL, ARTICLES_SEARCH_COLLECTION_URL, COMPANY_ARTICLES_URL } from '../../urls'

/**
 * Get article my bookmark item
 */
export const getArticleMyBookmarkList = async (params: { page: number }): Promise<IArticles | null> => {
    try {
        const { data: response } = await apiClient.get<IArticles>(ARTICLES_MY_BOOKMARK_URL, {
            params,
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get article category list
 */
export const getArticleCategoryList = async (): Promise<IArticleCategory | null> => {
    try {
        const { data: response } = await apiClient.get<IArticleCategory>(ARTICLE_CATEGORY_URL, {
            params: { page: 1, pageSize: 5 },
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get article  list by category
 */
export const getArticleListByCategory = async (category: number| string | undefined): Promise<IArticles | null> => {
    try {
        const { data: response } = await apiClient.get<IArticles>(ARTICLE_BY_CATEGORY_URL, {
            params: { category },
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get article home page item
 */
export const getArticleHomePageList = async (): Promise<IArticleHomePage | null> => {
    try {
        const { data: response } = await apiClient.get<IArticleHomePage>(ARTICLE_HOME_PAGE_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post bookmark item
 */
export const postBookmarkItem = async (id: number | undefined): Promise<{ isBookmark: boolean } | null> => {
    try {
        const { data: response } = await apiClient.post<boolean | null>(ARTICLE_BOOKMARK_URL, { articleId: id })
        return response === null ? null : { isBookmark: response }
    } catch (e) {
        throw e
    }
}

/**
 * Get article search list
 */
export const getArticleSearchList = async (text: string): Promise<IArticles | null> => {
    try {
        const { data: response } = await apiClient.get<IArticles>(ARTICLES_SEARCH_URL, {
            params: { text },
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get discover deeplink list
 */
export const getArticleById = async (articleId: number | string): Promise<IArticles | null> => {
    try {
        const { data: response } = await apiClient.get<IArticles>(ARTICLES_BY_ID_URL, {
            params: { articleId },
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post Answers
 */
export const postAnswerItem = async (data: IQuizData): Promise<IAnswersResponse | null> => {
    try {
        const { data: response } = await apiClient.post<IAnswersResponse | null>(ARTICLE_ANSWER_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get article search collection item
 */
export const getArticleSearchCollectionList = async (): Promise<ISearchCollection | null> => {
    try {
        const { data: response } = await apiClient.get<ISearchCollection>(ARTICLES_SEARCH_COLLECTION_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get company article item
 */
export const getCompanyArticleList = async (): Promise<ICompanyArticle | null> => {
    try {
        const { data: response } = await apiClient.get<ICompanyArticle>(COMPANY_ARTICLES_URL)

        return response
    } catch (e) {
        throw e
    }
}
