import React, { useCallback, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RWebShare } from 'react-web-share'
import { InviteIcon } from '../../components/common/Svg'
import { fetchReferral } from '../../state/user/actions'
import { getReferralList, getUser } from '../../state/user/selectors'
import AccountSidebar from '../../components/account/AccountSidebar'

export default function Referral() {
    const dispatch = useDispatch()
    const { referral } = useSelector(getReferralList)
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')

    function isMobileDevice() {
        const userAgent = navigator.userAgent.toLowerCase()
        return /mobile|android|iphone|ipad|ipod|blackberry|windows phone/.test(userAgent)
    }

    const shareData = {
        title: document.title,
        text: referral?.sharingText || '',
        url: `${window.location.origin}/refer/${user?.id}`,
    }

    const handleClick = async () => {
        try {
            await navigator.share(shareData)
        } catch (err) {
            console.log(`Error: ${err}`)
        }
    }

    const onRestartLoad = useCallback(() => {
        dispatch(fetchReferral)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    return (
        <AccountSidebar>
            <div className="tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 mobile:mr-10 mobile:w-[562px]">
                <style>{'html, body {height: 100%; overflow: hidden;}'}</style>

                <div className="max-w-[450px] w-full flex flex-col items-start">
                    <h1 className="heading-uppercase text-black font tracking-tight font-medium tablet:text-36 mobile:text-26 text-24 mb-[26px]">
                        {t('referral.refer-friends-earn-rewards')}
                    </h1>

                    <h1 className="text-grey01 text-14 font-normal">
                        {t('referral.the-more-people-you-refer-the-more-rewards-you-can-earn')}
                    </h1>

                    <h1 className="text-grey01 text-14 font-normal mt-3">
                        {referral?.descriptiveText}
                    </h1>

                    {isMobileDevice() ? (
                        <button
                            type="submit"
                            className="w-full h-12 bg-rocket mt-4 rounded-md flex items-center justify-center"
                            style={{ boxShadow: '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' }}
                            onClick={handleClick}>
                            <InviteIcon />

                            <h1 className="ml-2.5 text-16 font-bold tracking-tight text-pistachio">
                                {t('referral.invite-someone')}
                            </h1>
                        </button>
                    ) : (
                        <RWebShare
                            data={{
                                url: `${window.location.origin}/refer/${user?.id}`,
                                title: shareData?.title,
                            }}
                            onClick={() => console.info('share successful!')}
                        >
                            <button
                                type="submit"
                                className="w-full h-12 bg-rocket mt-4 rounded-md flex items-center justify-center"
                                style={{ boxShadow: '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' }}>
                                <InviteIcon />

                                <h1 className="ml-2.5 text-16 font-bold tracking-tight text-pistachio">
                                    {t('referral.invite-someone')}
                                </h1>
                            </button>
                        </RWebShare>
                    )}
                </div>
            </div>
        </AccountSidebar>
    )
}
