import apiClient from '../instance'
import { MONTHLY_COMPETITION_URL, PRIZE_WHEEL_TYPE_URL, WINNERS_PRICE, WINNERS_PRICE_UPDATE } from '../../urls'
import { IWinnersPriceUpdate, IMonthlyData, IPrizeWheelType } from '../../types/data'

/**
 * Get winners price
 */

export const getWinnersPrice = async (): Promise<any> => {
    try {
        const { data: response } = await apiClient.get(WINNERS_PRICE)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Update user
 */
export const updateWinnerPrice = async (data: IWinnersPriceUpdate): Promise<IWinnersPriceUpdate> => {
    try {
        const { data: response } = await apiClient.put<IWinnersPriceUpdate>(WINNERS_PRICE_UPDATE, data)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * get prize draw
 */
export const getPrizeDraw = async (): Promise<IMonthlyData | null> => {
    try {
        const { data: response } = await apiClient.get<IMonthlyData>(MONTHLY_COMPETITION_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get prize wheel list
 */
export const getPrizeWheelList = async (): Promise<IPrizeWheelType | null> => {
    try {
        const { data: response } = await apiClient.get<IPrizeWheelType>(PRIZE_WHEEL_TYPE_URL)

        return response
    } catch (e) {
        throw e
    }
}
