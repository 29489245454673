import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { ArrowRight, Trash } from '../common/Svg'
import { ILegs } from '../../types/data'
import { travelSelector } from '../../state/travel/selectors'
import { capitalizeFirstLetterOfEachWord, replaceAll, replacePartsOfText } from '../../utils/helpers'
import { COLOR_CONST } from '../../constants'
import { getWhiteLabelingList } from '../../state/user/selectors'
import DeleteLegModal from './DeleteLegModal'
import { legStartEnd } from '../../state/travel/actions'
import { changeLegFromLocalstorage } from '../../utils/travel'

interface IProps {
    onClose?: () => void | undefined;
    unknownLegData?: {
        createLegPosition: number
        nextLeg?: ILegs
        previousLeg?: ILegs
    }
    reloadStoredData?: () => void
    leg?: ILegs
}

export default function DeleteLeg({ onClose, unknownLegData, reloadStoredData, leg }: IProps) {
    const [t] = useTranslation('global')
    const { transportModes } = useSelector(travelSelector)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [showDeleteLegModal, setShowDeleteLegModal] = useState<boolean>(false)

    const previousLeg = unknownLegData?.previousLeg
    const nextLeg = unknownLegData?.nextLeg

    const previousLegTransportMode = transportModes?.find((i) => i?.name === previousLeg?.transportName?.toLowerCase())
    const nextLegTransportMode = transportModes?.find((i) => i?.name === nextLeg?.transportName?.toLowerCase())

    const dispatch = useDispatch()

    const onFirstOption = () => {
        const legStartEndData = {
            leg: previousLeg?.backendLegId,
            startPlaceId: previousLeg?.startPlaceId,
            startPlaceName: previousLeg?.startPlaceName,
            startPlaceLon: previousLeg?.startPlaceLon,
            startPlaceLat: previousLeg?.startPlaceLat,

            endPlaceId: nextLeg?.startPlaceId,
            endPlaceName: nextLeg?.startPlaceName,
            endPlaceLon: nextLeg?.startPlaceLon,
            endPlaceLat: nextLeg?.startPlaceLat,
        }

        dispatch(legStartEnd(legStartEndData, () => {
            changeLegFromLocalstorage(!!previousLeg?.isGoingBack, (i) => ({
                ...i,
                endPlaceId: nextLeg?.startPlaceId,
                endPlaceName: nextLeg?.startPlaceName,
                endPlaceLat: nextLeg?.startPlaceLon,
                endPlaceLon: nextLeg?.startPlaceLat,
            }), previousLeg?.backendLegId)

            reloadStoredData?.()
            onClose?.()
        }))
    }

    const onSecondOption = () => {
        const legStartEndData = {
            leg: nextLeg?.backendLegId,
            startPlaceId: previousLeg?.endPlaceId,
            startPlaceName: previousLeg?.endPlaceName,
            startPlaceLon: previousLeg?.endPlaceLon,
            startPlaceLat: previousLeg?.endPlaceLat,

            endPlaceId: nextLeg?.endPlaceId,
            endPlaceName: nextLeg?.endPlaceName,
            endPlaceLon: nextLeg?.endPlaceLon,
            endPlaceLat: nextLeg?.endPlaceLat,
        }

        dispatch(legStartEnd(legStartEndData, () => {
            changeLegFromLocalstorage(!!nextLeg?.isGoingBack, (i) => ({
                ...i,
                startPlaceId: previousLeg?.endPlaceId,
                startPlaceName: previousLeg?.endPlaceName,
                startPlaceLon: previousLeg?.endPlaceLon,
                startPlaceLat: previousLeg?.endPlaceLat,
            }), nextLeg?.backendLegId)

            reloadStoredData?.()
            onClose?.()
        }))
    }

    const onDelete = () => {
        setShowDeleteLegModal(false)
        reloadStoredData?.()
        onClose?.()
    }

    const isDeleteLegDisabled = previousLeg?.backendLegId || nextLeg?.backendLegId

    return (
        <div>
            {previousLeg?.backendLegId && (
                <div onClick={onFirstOption} className="flex items-center border-b border-b-spinach pb-[14px] min-h-[82px] mt-4 cursor-pointer">
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: replaceAll(previousLegTransportMode?.icon || '', COLOR_CONST, whiteLabeling?.cavolo) }} />

                    <h1 className="mx-4 text-14 text-cavolo font-normal tracking-tight w-full">
                        {replacePartsOfText(t('log.delete-leg-option-1'), {
                            '[verb]': capitalizeFirstLetterOfEachWord(previousLegTransportMode?.verb || ''),
                            '[previous-leg-start]': previousLeg.startPlaceName,
                            '[subsequent-leg-start]': nextLeg?.startPlaceName,
                            '[previous-leg-end]': previousLeg.endPlaceName,
                        })}
                    </h1>

                    <div className="flex items-center justify-center w-[24px] h-[24px]">
                        <ArrowRight />
                    </div>
                </div>
            )}

            {nextLeg?.backendLegId && (
                <div onClick={onSecondOption} className="flex items-center border-b border-b-spinach pb-[14px] min-h-[82px] mt-4 cursor-pointer">
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: replaceAll(nextLegTransportMode?.icon || '', COLOR_CONST, whiteLabeling?.cavolo) }} />

                    <h1 className="mx-4 text-14 text-cavolo font-normal tracking-tight w-full">
                        {replacePartsOfText(t('log.delete-leg-option-2'), {
                            '[verb]': capitalizeFirstLetterOfEachWord(nextLegTransportMode?.verb || ''),
                            '[subsequent-leg-end]': nextLeg.endPlaceName,
                            '[previous-leg-end]': previousLeg?.endPlaceName,
                            '[subsequent-leg-start]': nextLeg.startPlaceName,
                        })}
                    </h1>

                    <div className="flex items-center justify-center w-[24px] h-[24px]">
                        <ArrowRight />
                    </div>
                </div>
            )}

            <div onClick={() => !isDeleteLegDisabled && setShowDeleteLegModal(true)} className={cn('flex items-center justify-between border-b border-b-spinach pb-[14px] min-h-[82px] mt-4', { 'cursor-pointer': !isDeleteLegDisabled, 'opacity-50': isDeleteLegDisabled })}>
                <div className="flex items-center">
                    <div className="flex items-center justify-center w-[42px] h-[42px]">
                        <Trash />
                    </div>

                    <h1 className="mx-4 text-14 text-cavolo font-normal tracking-tight">
                        Delete leg
                    </h1>
                </div>

                <div className="flex items-center justify-center w-[24px] h-[24px]">
                    <ArrowRight />
                </div>
            </div>

            <div className="flex items-center justify-between mt-4">
                <button
                    onClick={onClose}
                    className="w-[48%] h-[48px] border border-grey01 rounded-md font-semibold not-heading-font leading-[150%] tracking-[-0.02rem] text-14 text-grey01">
                    {t('global.cancel')}
                </button>

                <button
                    onClick={() => console.log('test')}
                    className="w-[48%] h-[48px] bg-rocket rounded-md font-semibold not-heading-font leading-[150%] tracking-[-0.02rem] text-14 text-white">
                    {t('global.save')}
                </button>
            </div>

            <DeleteLegModal
                leg={leg}
                overviewLeg
                title={t('log.are-you-sure-you-want-to-delete-this-leg')}
                isShown={showDeleteLegModal}
                onContinue={onDelete} />
        </div>
    )
}
