import React from 'react'
import Select, { StylesConfig } from 'react-select'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getLanguageList, getWhiteLabelingList } from '../state/user/selectors'

interface ColourOption {
  value: string;
  label: JSX.Element;
  color: string;
}

interface IProps{
    handleSelectChange: any
}

export default function LanguageDropdown({ handleSelectChange }: IProps) {
    const { languageItem } = useSelector(getLanguageList)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')
    const sortedLanguageItem = [...languageItem].sort((a, b) => {
        if (a.isDefault && !b.isDefault) return -1
        if (!a.isDefault && b.isDefault) return 1
        return 0
    })

    const language = sortedLanguageItem.map((item) => item.language)

    const options = language?.map((item) => (
        {
            id: item?.id,
            value: item?.shortName || '',
            label:
    <div className="flex justify-between">
        <span className="not-heading-font font-bold text-14">{item?.name}</span>
        <img
            className="w-10 h-6"
            src={item?.icon}
            alt=""
        />
    </div>,
            color: whiteLabeling?.black || 'rgba(0, 0, 0, 1)',
            item,
        }
    ))

    const colourStyles: StylesConfig<ColourOption> = {
        dropdownIndicator: (base: any) => ({
            ...base,
            color: whiteLabeling?.rocket,
            cursor: 'pointer',
        }),
        // @ts-ignore
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            cursor: 'pointer',
            color: isSelected
                ? whiteLabeling?.grey01
                : (whiteLabeling?.grey01 && isFocused
                    ? whiteLabeling?.marrow
                    : whiteLabeling?.grey01),
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? whiteLabeling?.white
                    : isFocused
                        ? whiteLabeling?.mint
                        : whiteLabeling?.white,
        }),
        input: (styles) => ({ ...styles }),
        control: (styles) => ({ ...styles, padding: 7, borderRadius: 10, borderColor: whiteLabeling.avocado }),
        placeholder: (styles) => ({ ...styles }),
        indicatorSeparator: () => ({ display: 'none' }),
    }

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    const defaultValue = options && options.length > 0
        ? options.find((option) => option.value === languageData)
        : null

    return (
        <Select
            maxMenuHeight={200}
            options={options}
            placeholder={t('log.select-an-option')}
            styles={colourStyles}
            isSearchable={false}
            onChange={handleSelectChange}
            defaultValue={defaultValue}
        />
    )
}
