import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import uniqBy from 'lodash/uniqBy'
import { DELETE_CHALLENGE_REMIND_ON_START_SUCCESS,
    FETCH_CHALLENGE_ERROR,
    FETCH_CHALLENGE_LIST_SUCCESS,
    FETCH_CHALLENGE_LOGGED_DAYS_SUCCESS,
    FETCH_CHALLENGE_START,
    FETCH_LAST_DAY_TO_TRACK_LIST_SUCCESS,
    FETCH_MY_PLEDGES_LIST_SUCCESS,
    FETCH_PLEDGE_DETAIL_SUCCESS,
    FETCH_PLEDGES_LIST_SUCCESS,
    POST_CHALLENGE_JOIN_SUCCESS,
    POST_CHALLENGE_LOG_DAY_SUCCESS,
    POST_CHALLENGE_REMIND_ON_START_SUCCESS,
    POST_REMIND_ME_NOTIFICATION_SUCCESS } from './consts'
import { ChallengeState } from './types'

const initialState: ChallengeState = {
    challenge: [],
    loggedDays: [],
    // @ts-ignore
    lastDayToTrack: [],
    myPledges: [],
    pledges: {},
    loading: false,

    // @ts-ignore
    pledgeDetail: {},
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AnyAction): ChallengeState => {
    const { payload } = action

    switch (action.type) {
    case FETCH_CHALLENGE_START:
        return { ...state, loading: true }

    case FETCH_CHALLENGE_LIST_SUCCESS:
        return {
            ...state,
            challenge: payload,
            loading: false,
        }

    case FETCH_PLEDGE_DETAIL_SUCCESS:
        return {
            ...state,
            pledgeDetail: payload,
            loading: false,
        }

    case POST_CHALLENGE_JOIN_SUCCESS:
        const newChallenge = state.challenge.map((item) => (item.id === payload.challenge
            ? {
                ...item,
                isJoined: true,
            }
            : item))
        return {
            ...state,
            challenge: newChallenge,
            loading: false,
        }

    case POST_REMIND_ME_NOTIFICATION_SUCCESS:
        const newChallengeRemindMe = state.challenge.map((item) => (item.id === payload.challenge
            ? {
                ...item,
                notification: {
                    id: payload.id,
                    emailEveryNDays: payload.emailEveryNDays,
                    notificationEveryNDays: payload.notificationEveryNDays,
                },
            }
            : item))
        return {
            ...state,
            challenge: newChallengeRemindMe,
            loading: false,
        }

    case POST_CHALLENGE_REMIND_ON_START_SUCCESS:
        const newChallengeRemindOnStart = state.challenge.map((item) => (item.id === payload.challenge
            ? {
                ...item,
                remindOnStart: payload,
            }
            : item))
        return {
            ...state,
            challenge: newChallengeRemindOnStart,
            loading: false,
        }

    case DELETE_CHALLENGE_REMIND_ON_START_SUCCESS:
        const deleteChallengeRemindOnStart = state.challenge.map((item) => (item.id === payload
            ? {
                ...item,
                remindOnStart: null,
            }
            : item))

        return <ChallengeState>{
            ...state,
            challenge: deleteChallengeRemindOnStart,
            loading: false,
        }

    case FETCH_CHALLENGE_LOGGED_DAYS_SUCCESS:
        return <ChallengeState>{
            ...state,
            loggedDays: payload,
            loading: false,
        }

    case POST_CHALLENGE_LOG_DAY_SUCCESS:
        const loggedDaysCount = uniqBy([...state.loggedDays, payload], 'id')

        const newChallengeLoggedDay = state.challenge.map((item) => (item.id === payload.challenge ? {
            ...item,
            loggedDaysCount: loggedDaysCount.length,
            unloggedDaysCount: item.loggedDaysCount === loggedDaysCount.length ? item.unloggedDaysCount : item.unloggedDaysCount - 1,
        } : item))

        return {
            ...state,
            loggedDays: loggedDaysCount,
            challenge: newChallengeLoggedDay,
            loading: false,
        }

    case FETCH_LAST_DAY_TO_TRACK_LIST_SUCCESS:
        return {
            ...state,
            lastDayToTrack: payload,
            loading: false,
        }

    case FETCH_MY_PLEDGES_LIST_SUCCESS:
        return {
            ...state,
            myPledges: payload,
            loading: false,
        }

    case FETCH_PLEDGES_LIST_SUCCESS:
        return {
            ...state,
            pledges: payload,
            loading: false,
        }

    case FETCH_CHALLENGE_ERROR:
        return { ...state, loading: false }

    default:
        return state
    }
}

const persistConfig: PersistConfig<ChallengeState> = {
    key: 'challenge',
    storage,
    whitelist: ['challenge', 'myPledges', 'pledges'],
}

export default persistReducer(persistConfig, reducer)
