import React from 'react'
import Select, { StylesConfig } from 'react-select'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { travelSelector } from '../../state/travel/selectors'
import { getDates } from '../../utils/date'
import { getWhiteLabelingList } from '../../state/user/selectors'

interface ColourOption {
  value: string;
  label: JSX.Element;
  color: string;
}

interface IProps{
    handleSelectChange: any
}

export default function Dropdown({ handleSelectChange }: IProps) {
    const { gameList } = useSelector(travelSelector)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')
    const options = gameList.filter((i) => !i.isDisabled)?.map((item) => (
        {
            id: item?.id,
            value: item.date,
            label:
    <div className="flex justify-between">
        <span className="font-bold not-heading-font text-14">{item?.name}</span>
        <p className="font-normal text-14">{getDates(item?.date)}</p>
    </div>,
            color: whiteLabeling?.black || 'rgba(0, 0, 0, 1)',
            isDisabled: item?.isDisabled,
            item,
        }
    ))

    const colourStyles: StylesConfig<ColourOption> = {
        dropdownIndicator: (base: any) => ({
            ...base,
            color: whiteLabeling?.rocket,
            cursor: 'pointer',
        }),
        // @ts-ignore
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            cursor: 'pointer',
            color: isSelected
                ? whiteLabeling?.grey01
                : (whiteLabeling?.grey01 && isFocused
                    ? whiteLabeling?.marrow
                    : whiteLabeling?.grey01),
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? whiteLabeling?.white
                    : isFocused
                        ? whiteLabeling?.mint
                        : whiteLabeling?.white,
        }),
        input: (styles) => ({ ...styles }),
        control: (styles) => ({ ...styles, padding: 7, borderRadius: 10, borderColor: whiteLabeling.avocado }),
        placeholder: (styles) => ({ ...styles }),
        indicatorSeparator: () => ({ display: 'none' }),
    }

    return (
        <Select
            maxMenuHeight={200}
            options={options}
            placeholder={t('log.select-an-option')}
            styles={colourStyles}
            isSearchable={false}
            onChange={handleSelectChange}
        />
    )
}
