import { IPerkCommentList,
    IPerkCommentsListParams,
    IPerkFilterTag,
    IPerkHomePage,
    IPerkLastComment, IPerkPledgeLogResponse,
    IPerkRate,
    IPerkRateResponse,
    IPerks, IPerksBolCouponData,
    IPerksType,
    IPerkWriteReviewData, IRecentSearchesResponse,
    ISearchCollection,
    IWriteReviewResponse } from '../../types/data'
import apiClient from '../instance'
import { PERK_ID_DEEPLINK_URL,
    PERKS_BOL_GET_COUPON, PERKS_BOOKMARK_URL, PERKS_BRANDS_URL,
    PERKS_BY_TYPE_URL, PERKS_COMMENT_LIST_URL,
    PERKS_COMMENT_URL,
    PERKS_HOME_PAGE_URL, PERKS_LAST_COMMENT_URL, PERKS_LOG,
    PERKS_MY_BOOKMARK_URL,
    PERKS_RATE_URL,
    PERKS_SEARCH_COLLECTION_URL,
    PERKS_SEARCH_URL,
    PERKS_TAG_URL,
    PERKS_TYPE_URL, SEARCHES_HISTORY_URL } from '../../urls'

/**
 * Get perks all brand
 */
export const getPerksBrandList = async (): Promise<IPerks | null> => {
    try {
        const { data: response } = await apiClient.get<IPerks>(PERKS_BRANDS_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get perks all brand
 */
export const getPerksBookmarkedList = async (params: { page: number }): Promise<IPerks | null> => {
    try {
        const { data: response } = await apiClient.get<IPerks>(PERKS_MY_BOOKMARK_URL, {
            params,
        })
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get perks by type
 */
export const getPerksByTypeList = async (): Promise<IPerksType | null> => {
    try {
        const { data: response } = await apiClient.get<IPerksType>(PERKS_TYPE_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get perks filter type
 */
export const getPerksFilterTagList = async (): Promise<IPerkFilterTag | null> => {
    try {
        const { data: response } = await apiClient.get<IPerkFilterTag>(PERKS_TAG_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getSearchList = async (text: string): Promise<IPerks | null> => {
    try {
        const { data: response } = await apiClient.get<IPerks>(PERKS_SEARCH_URL, {
            params: { text },
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get perk list by type
 */
export const getPerkByListType = async (typeId: number): Promise<IPerks | null> => {
    try {
        const { data: response } = await apiClient.get<IPerks>(PERKS_BY_TYPE_URL, {
            params: { typeId },
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get perk by tag list
 */
export const getByPerkByTagList = async (tags: string | undefined): Promise<IPerks | null> => {
    try {
        const { data: response } = await apiClient.get<IPerks>(PERKS_BY_TYPE_URL, {
            params: { tags },
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get perks home page item
 */
export const getPerksHomePageList = async (): Promise<IPerkHomePage | null> => {
    try {
        const { data: response } = await apiClient.get<IPerkHomePage>(PERKS_HOME_PAGE_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get perk last comment list
 */
export const getPerkLastCommentList = async (perk: number | string): Promise<IPerkLastComment | null> => {
    try {
        const { data: response } = await apiClient.get<IPerkLastComment>(PERKS_LAST_COMMENT_URL, {
            params: { perk },
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post book mark item
 */
export const postBookmarkItem = async (id: number): Promise<{ bookmarked: boolean } | null> => {
    try {
        const { data: response } = await apiClient.post<{ bookmarked: boolean } | null>(PERKS_BOOKMARK_URL, {
            perksId: id,
        })
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get perk comment list
 */
export const getPerkCommentList = async (params: IPerkCommentsListParams): Promise<IPerkCommentList | null> => {
    try {
        const { data: response } = await apiClient.get<IPerkCommentList>(PERKS_COMMENT_LIST_URL, {
            params,
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post perk rate item
 */
export const postPerkRateItem = async (data: IPerkRate): Promise<IPerkRateResponse | null> => {
    try {
        const { data: response } = await apiClient.post<IPerkRateResponse | null>(PERKS_RATE_URL, data)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post perk comment item
 */
export const postPerkCommentItem = async (data: IPerkWriteReviewData | undefined): Promise<IWriteReviewResponse | null> => {
    try {
        const { data: response } = await apiClient.post<IWriteReviewResponse | null>(PERKS_COMMENT_URL, data)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get recent searches item
 */
export const getRecentSearchesItem = async (): Promise<IRecentSearchesResponse | null> => {
    try {
        const { data: response } = await apiClient.get<IRecentSearchesResponse>(SEARCHES_HISTORY_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get perk search collection list
 */
export const getSearchCollectionList = async (): Promise<ISearchCollection | null> => {
    try {
        const { data: response } = await apiClient.get<ISearchCollection>(PERKS_SEARCH_COLLECTION_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const postCouponData = async (data:IPerksBolCouponData) => {
    try {
        const { data: response } = await apiClient.post<{message: string}>(PERKS_BOL_GET_COUPON, data)

        return { response }

        // @ts-ignore
    } catch (e: AxiosError) {
        return { error: e.response?.data }
    }
}

/**
 * Post perk pledge log
 */
export const postPerkPledgeLogItem = async (id: number | undefined): Promise<IPerkPledgeLogResponse | null> => {
    try {
        const { data: response } = await apiClient.post<IPerkPledgeLogResponse | null>(PERKS_LOG, {
            perk: id,
        })
        return response
    } catch (e) {
        throw e
    }
}

export const getPerkDetail = async (id: number): Promise<IPerks | null> => {
    try {
        const { data: response } = await apiClient.get<IPerks | null>(PERK_ID_DEEPLINK_URL, { params: { perk_id: id } })
        return response
    } catch (e) {
        throw e
    }
}
