import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ArrowBottomIcon, CommentPerkIcon } from '../common/Svg'
import { getPerkDetailLists, getPerkLists } from '../../state/perks/selectors'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import { fetchPerksLastComment, postPerkPledgeLog } from '../../state/perks/actions'
import SecondButton from '../common/SecondButton'
import ModalPopupWindow from '../ModalPopupWindow'
import { readableC02 } from '../../utils/helpers'

export default function PerkDetailBody() {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { perkLastComment } = useSelector(getPerkLists)
    const { perkDetail: perksData } = useSelector(getPerkDetailLists)
    const perkBrandUrl = perksData?.perkBrand?.brandUrl
    const brandUrl = perkBrandUrl?.includes('?')
    const [showModalPoint, setShowModalPoint] = useState<boolean>(false)
    const [error, setError] = useState({ message: '' })
    const [loading, setLoading] = useState<boolean>(false)
    const [t] = useTranslation('global')

    useEffect(() => {
        dispatch(fetchPerksLastComment(Number(perksData?.id)))
        // eslint-disable-next-line
    }, [])

    const showVisitButton = perksData?.buttonActionType !== 'show_bol_meal_form_modal'

    const onSubmit = async () => {
        setLoading(true)
        try {
            await dispatch(
                postPerkPledgeLog(
                    perksData?.id,
                    () => {
                        setShowModalPoint(true)
                    },
                    setError,
                ),
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex justify-center medium:flex-row flex-col bg-pistachio pt-5">
            <div className="tablet:w-[764px] medium:pr-0 xs-mobile:mr-10 xs-mobile:ml-10 ml-5 mr-5 medium:mt-10 mobile:mt-5 mt-3">
                {perksData?.ceo2Kg !== 0 && (
                    user?.company?.gamification && (
                        <>
                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <p className="font-normal text-grey01 text-14">
                                        {t('lower.did-you-purchase-this')}
                                    </p>

                                    <div className="flex items-center">
                                        <ArrowBottomIcon width={15} height={15} />

                                        <p className="font-semibold not-heading-font text-grey01 ml-1.5 text-12">
                                            {readableC02(perksData?.ceo2Kg || 0)}
                                        </p>
                                    </div>
                                </div>

                                <SecondButton
                                    loading={loading}
                                    onClick={onSubmit}
                                    text={t('lower.log-this-swap')}
                                    className="mt-2.5 w-full h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                                />

                                <p className="mt-2 text-14 text-[#ff3333] font-semibold">
                                    {error?.message}
                                </p>
                            </div>

                            <div>
                                <p className="font-normal text-grey01 text-14">
                                    {t('lower.did-you-purchase-a-similar-product-elsewhere')}
                                </p>

                                <button
                                    onClick={onSubmit}
                                    className="border border-solid border-rocket w-full h-[48px] mt-2.5 bg-mint font-semibold hover:bg-[#A7D49D] hover:text-spinach hover:ease-in hover:duration-200 px-8 border rounded-md text-14 text-marrow">
                                    {t('lower.log-the-CO2e-saving')}
                                </button>

                                <p className="mt-2 text-14 text-error font-semibold">
                                    {error?.message}
                                </p>
                            </div>
                        </>
                    )
                )}

                <h1 className="font tracking-tight font-medium text-22 text-black mb-2 mt-10">
                    {perksData?.shortDescription}
                </h1>

                {/* eslint-disable-next-line react/no-danger */}
                <h1 className="rich-text" dangerouslySetInnerHTML={{ __html: perksData?.body || '' }} />

                {perkLastComment?.details?.length !== 0 && (
                    <div className={`flex flex-col justify-center items-center tablet:w-[764px] medium:w-[508px] mt-10 pt-4 pb-4 ${perkBrandUrl?.length === 0 && 'mb-[60px]'}`}
                        style={{ borderWidth: showVisitButton ? '1px 0px 1px' : '1px 0px 0px', borderColor: whiteLabeling?.avocado }}>
                        {perkLastComment?.details?.length !== 0 && <CommentPerkIcon width={24} height={24} />}

                        <span className="pb-3 text-center text-22 font-bold font tracking-tight italic">
                            {perkLastComment?.details}
                        </span>

                        <span className="text-14 text-grey01">
                            {perkLastComment?.ownerFirstName} {perkLastComment?.ownerLastName?.slice(0, 1)}.
                        </span>
                    </div>
                )}

                {showVisitButton && (
                    <a href={brandUrl ? `${perksData?.perkBrand?.brandUrl}&pref1=app&pref2=${user?.id}` : perksData?.perkBrand?.brandUrl}
                        target="_blank" rel="noreferrer">
                        {perkBrandUrl?.length !== 0 && (
                            <button
                                className="border-solid border-rocket bg-rocket font-semibold py-4 px-8 border rounded-md mt-10 text-14 text-white mb-[60px]"
                                style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)' }}>
                                {t('lower.visit')} {perksData?.perkBrand?.name}
                            </button>
                        )}
                    </a>
                )}

                <ModalPopupWindow
                    isShown={showModalPoint}
                    onClose={() => setShowModalPoint(false)}
                    point={5}
                    description={(
                        <h1 className="text-center mobile:text-22 text-18 text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                            {t('lower.you’ve-saved')} <span className="text-center mobile:text-22 text-18 text-black tracking-tight font-semibold not-heading-font font mobile:leading-[28.6px] leading-[23.6px]">{readableC02(perksData?.ceo2Kg || 0)}</span> {t('lower.and-received-5-points-for-logging-this-swap')}
                        </h1>
                    )} />
            </div>
        </div>
    )
}
