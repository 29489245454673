import React, { useEffect } from 'react'
import { useQueryParams } from '../hooks/queryString'
import { baseUrl } from '../state/instance'

export default function SSOLogin() {
    const params = useQueryParams()

    useEffect(() => {
        window.location.replace(`${baseUrl}/api/v1/users/sso-redirect/${params.utm_source}?frontend_domain=${window.location.origin}`)
        // eslint-disable-next-line
    }, [])

    return (
        <div />
    )
}
