import React, { useCallback, useEffect, useState } from 'react'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Search } from './Svg'
import { fetchRecentSearches } from '../../state/perks/actions'
import { getWhiteLabelingList } from '../../state/user/selectors'

interface IProps {
    placeholder: string
    onSearch?: (search: string) => void
    action?: 'perks' | 'recipes' | 'articles'
    autoFocus?: boolean
    defaultValue?: string
    onFocus?: () => void
    smallWidthUntil?: string
}

export const searchNavigateOptions = { state: { searchFocused: true } }

export function perkSearch(value: string, navigate: NavigateFunction) {
    if (value) navigate(`/lower/swaps/search/${value}`, searchNavigateOptions)
    else navigate('/lower/swaps', searchNavigateOptions)
}

export function recipeSearch(value: string, navigate: NavigateFunction) {
    if (value) navigate(`/lower/recipes/search/${value}`, searchNavigateOptions)
    else navigate('/recipes', searchNavigateOptions)
}

export function articleSearch(value: string, navigate: NavigateFunction) {
    if (value) navigate(`/learn/articles/search/${value}`, searchNavigateOptions)
    else navigate('/learn/articles', searchNavigateOptions)
}

export default function SearchInput({ placeholder, onSearch, action, autoFocus, defaultValue = '', onFocus, smallWidthUntil = '' }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation()
    const [value, setValue] = useState(defaultValue)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    useEffect(() => {
        dispatch(fetchRecentSearches)

        // eslint-disable-next-line
    }, [])

    const handleSearch = useCallback(
        (searchValue: string) => {
            if (typeof onSearch === 'function') {
                onSearch(searchValue)
            }

            if (action === 'perks') {
                perkSearch(searchValue, navigate)
            }

            if (action === 'recipes') {
                recipeSearch(searchValue, navigate)
            }

            if (action === 'articles') {
                articleSearch(searchValue, navigate)
            }
        },
        // eslint-disable-next-line
    [])

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        handleSearch(value)
    }

    async function handleChange(event: React.FormEvent<HTMLInputElement>) {
        // @ts-ignore
        setValue(event.target.value)
    }

    return (
        <form className={smallWidthUntil ? `${smallWidthUntil}:w-[343px] w-full` : 'xs-mobile:w-[343px] w-full'} onSubmit={handleSubmit}>
            <div className="relative">
                <input
                    style={{ borderColor: whiteLabeling.avocado }}
                    defaultValue={defaultValue}
                    autoFocus={state?.searchFocused || autoFocus}
                    type="text"
                    placeholder={placeholder}
                    className="w-full py-3 font text-[0.9em] italic pr-4 text-grey01 border
                    rounded-lg outline-none focus:ring-avocado bg-white focus:border-avocado"
                    onChange={handleChange}
                    onFocus={onFocus}
                />

                <div className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 right-3">
                    <Search height={24} width={24} />
                </div>
            </div>
        </form>
    )
}
