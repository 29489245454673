import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import Layout from '../components/layout/Layout'

export default function NotFound404Page() {
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: '404' })
    }, [])

    return (
        <Layout>
            <div className="h-screen mt-4">Page not found</div>
        </Layout>
    )
}
