import React, { useContext, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MoreButton, TravelTreeRedLine, TravelTreeSmallEllipse } from '../common/Svg'
import Button from '../common/Button'
import { displayDistanceWithUnit, readableC02, replaceAll } from '../../utils/helpers'
import { ILegs } from '../../types/data'
import { travelSelector } from '../../state/travel/selectors'
import { COLOR_CONST } from '../../constants'
import DeleteLegModal from './DeleteLegModal'
import QuizQuitOverlayModal from '../quiz/QuizQuitOverlayModal'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import { Context } from '../common/BaseContext'
import EditLegModal from './EditLegModal'

interface IProps {
    missingLegFromTop: boolean
    isLast: boolean
    goingBackCommute?: any
    item: ILegs
    index: number
    impact?: boolean
    legs: ILegs[]
    overviewLeg?: boolean
    reloadStoredData?: () => void
}

interface IMissingLegProps {
    legStartEnd: {
        startPlaceName?: string
        startPlaceId?: string
        startPlaceLon?: string
        startPlaceLat?: string
        endPlaceName?: string
        endPlaceId?: string
        endPlaceLon?: string
        endPlaceLat?: string
    }
    createLegPosition: number
    t: any
    impact: any
    goingBackCommute: boolean
    navigate: any
    storedData: any
    reloadStoredData?: () => void
    previousLeg: ILegs
    nextLeg?: ILegs
}

function MissingLegComponent({ legStartEnd, createLegPosition, t, impact, goingBackCommute, navigate, storedData, reloadStoredData, nextLeg, previousLeg }: IMissingLegProps) {
    const [showEditLegModal, setShowEditLegModal] = useState<boolean>(false)

    return (
        <div className="flex pl-3 my-3 justify-between items-center">
            <div className="flex items-center ">
                <TravelTreeRedLine />
                <div className="ml-5 pl-1 text-16 text-raspberry">
                    {t('log.unknown-journey-leg')}
                </div>
            </div>

            <Button text="Add leg details" className="w-max bg-lime border-raspberry text-cavolo" style={{ marginTop: 0 }} onClick={() => {
                if (impact) {
                    navigate('/log/track-travel-overview', { state: { impact: true, goingBackCommute } })
                    return
                }

                setShowEditLegModal(true)

                // localStorage.setItem(goingBackCommute ? 'returnTravelData' : 'travelData', JSON.stringify({
                //     ...storedData,
                //     createLegPosition,
                //     legStartEnd,
                // }))
                //
                // navigate('/log/track-travel-modality', { state: { goingBackCommute } })
            }} />

            {showEditLegModal && (
                <EditLegModal goingBackCommute={goingBackCommute} unknownLegData={{
                    createLegPosition,
                    nextLeg: nextLeg || {
                        startPlaceId: storedData.endPlaceId,
                        startPlaceName: storedData.endPlaceName,
                        startPlaceLon: storedData.endPlaceLon,
                        startPlaceLat: storedData.endPlaceLat,
                    },
                    previousLeg: previousLeg || {
                        endPlaceId: storedData.startPlaceId,
                        endPlaceName: storedData.startPlaceName,
                        endPlaceLon: storedData.startPlaceLon,
                        endPlaceLat: storedData.startPlaceLat,
                    },
                    legStartEnd,
                }} reloadStoredData={reloadStoredData} onClose={() => {
                    setShowEditLegModal(false)
                }} />
            )}
        </div>
    )
}

export default function LegItem({ missingLegFromTop, isLast, goingBackCommute, item, index, impact, overviewLeg, legs, reloadStoredData }: IProps) {
    const { transportModes } = useSelector(travelSelector)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')
    const { distanceUnit } = useContext(Context)
    const [showEditLegModal, setShowEditLegModal] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<ILegs>()
    const { user } = useSelector(getUser)

    const navigate = useNavigate()
    const isFirst = index === 0
    const storedDataString = localStorage.getItem(goingBackCommute ? 'returnTravelData' : 'travelData')
    // @ts-ignore
    const storedData = JSON.parse(storedDataString)

    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const [showDeleteLegOutboundModal, setShowDeleteLegOutboundModal] = useState<boolean>(false)

    const lowerTransportName = item?.transportName?.toLowerCase()

    const transportMode = transportModes.find((i) => i.name === lowerTransportName || i?.synonyms?.split(',').includes(lowerTransportName))

    const previousLeg = !isFirst ? legs[index - 1] : undefined

    // const travelData = JSON.parse(localStorage.getItem(goingBackCommute ? 'returnTravelData' : 'travelData') || '{}')

    // const handleDelete = () => {
    //     const isLastLeg = travelData.legs?.length === 1
    //     setShowQuizQuitOverlayModal(isLastLeg)
    //     setShowDeleteLegOutboundModal(!isLastLeg)
    // }

    const missingLegFromBottom = item.endPlaceName !== storedData.endPlaceName && isLast

    const onSubmit = (items: ILegs) => {
        setEditItem(items)
        setShowEditLegModal(true)
    }

    return (
        <div>
            {(missingLegFromTop) && (
                <MissingLegComponent
                    previousLeg={legs[index - 1]}
                    nextLeg={legs[index]}
                    reloadStoredData={reloadStoredData}
                    storedData={storedData}
                    navigate={navigate}
                    goingBackCommute={goingBackCommute}
                    impact={impact}
                    t={t}
                    createLegPosition={item.position || 0}
                    legStartEnd={{
                        endPlaceName: item.startPlaceName,
                        endPlaceId: item.startPlaceId,
                        endPlaceLon: item.startPlaceLon,
                        endPlaceLat: item.startPlaceLat,
                        ...(!previousLeg ? {
                            startPlaceName: storedData.startPlaceName,
                            startPlaceId: storedData.startPlaceId,
                            startPlaceLon: storedData.startPlaceLon,
                            startPlaceLat: storedData.startPlaceLat,
                        } : {
                            startPlaceName: previousLeg.endPlaceName,
                            startPlaceId: previousLeg.endPlaceId,
                            startPlaceLon: previousLeg.endPlaceLon,
                            startPlaceLat: previousLeg.endPlaceLat,
                        }),
                    }} />
            )}

            <div key={item?.backendLegId} className={cn('border-spinach border-l-[10px] ml-3 pl-5', { 'pb-7': isLast && !missingLegFromBottom, 'pt-6': !missingLegFromTop, 'mt-[-3px]': isFirst })}>
                {missingLegFromTop && (
                    <div className="flex relative top-[-8px] items-center pb-4">
                        <div className="absolute left-[-33.7px]">
                            <TravelTreeSmallEllipse />
                        </div>

                        <div className="text-16 text-cavolo font-semibold not-heading-font">
                            {item.startPlaceName}
                        </div>
                    </div>
                )}

                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        {/* eslint-disable-next-line react/no-danger */}
                        <div className="mr-1.5" dangerouslySetInnerHTML={{ __html: replaceAll(transportMode?.icon || '', COLOR_CONST, whiteLabeling.cavolo) }} />

                        <div>
                            <h1 className="text-14 tracking-tight text-cavolo xs-mobile:w-[337px]">
                                {item?.transportName}, {displayDistanceWithUnit(Number(item?.travelDistanceKilometres || 0), distanceUnit, t)}
                            </h1>

                            <h1 className="text-14 tracking-tight text-cavolo xs-mobile:w-[337px]">
                                {readableC02(item?.ceo2Kg || 0)}
                            </h1>
                        </div>
                    </div>

                    {!impact && (
                        <div className="flex items-center cursor-pointer" onClick={() => onSubmit(item)}>
                            {/* <div onClick={() => edit(item, index)}> */}
                            {/*   <EditIcon /> */}
                            {/* </div> */}

                            {/* <div onClick={handleDelete}> */}
                            {/*    <TrashIcon /> */}
                            {/* </div> */}
                            <MoreButton />
                        </div>
                    )}
                </div>

                {(!isLast || missingLegFromBottom) && (
                    <div className="flex relative bottom-[-8px] pt-7 items-center">
                        <div className="absolute left-[-33.7px]">
                            <TravelTreeSmallEllipse />
                        </div>

                        <div className="text-16 text-cavolo font-semibold not-heading-font">
                            {item.endPlaceName}
                        </div>
                    </div>
                )}
            </div>

            {missingLegFromBottom && (
                <MissingLegComponent
                    previousLeg={legs[index]}
                    reloadStoredData={reloadStoredData}
                    storedData={storedData}
                    navigate={navigate}
                    goingBackCommute={goingBackCommute}
                    impact={impact}
                    t={t}
                    createLegPosition={legs.length + 1}
                    legStartEnd={{
                        startPlaceName: item.endPlaceName,
                        startPlaceId: item.endPlaceId,
                        startPlaceLon: item.endPlaceLon,
                        startPlaceLat: item.endPlaceLat,
                        endPlaceName: storedData.endPlaceName,
                        endPlaceId: storedData.endPlaceId,
                        endPlaceLon: storedData.endPlaceLon,
                        endPlaceLat: storedData.endPlaceLat,
                    }}
                />
            )}

            <DeleteLegModal
                goingBackCommute={goingBackCommute}
                overviewLeg={overviewLeg}
                title={t('log.are-you-sure-you-want-to-delete-this-leg?')}
                leg={item}
                outboundLeg={false}
                isShown={showDeleteLegOutboundModal}
                onContinue={() => setShowDeleteLegOutboundModal(false)}
            />

            <QuizQuitOverlayModal
                description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                isShown={showQuizQuitOverlayModal}
                onClose={() => navigate('/me/impact')}
                onContinue={() => setShowQuizQuitOverlayModal(false)} />

            {showEditLegModal && (
                <EditLegModal goingBackCommute={goingBackCommute} reloadStoredData={reloadStoredData} onClose={() => setShowEditLegModal(false)}
                    editItem={editItem} />
            )}
        </div>
    )
}
