import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Dashboard from '../pages/Dashboard'
import { fetchWhiteLabeling } from '../state/user/actions'
import Loader from './common/Loader'

export default function FanAndEmployee() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        dispatch(fetchWhiteLabeling(() => {
            setLoading(false)
        }))
    }, [dispatch])

    return (
        <div>
            {loading ? (
                <div className="flex items-center justify-center h-screen">
                    <Loader width={100} height={100} color="#00362B" />
                </div>
            ) : (
                <Dashboard />
            )}
        </div>
    )
}
