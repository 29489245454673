import React from 'react'
import { IBadge } from '../types/data'

interface IProps {
    item: IBadge
}

export default function BadgeItem({ item }: IProps) {
    return (
        <>
            <div key={item?.id} className="flex mt-3 items-center">
                <img src={item?.image} className="mobile:w-[60px] w-[46px] mobile:h-[60px] h-[46px] object-cover" alt="" />

                <div className="w-full ml-3">
                    <p className="not-heading-font font-bold tracking-[-0.02rem] text-black text-14 mobile:text-16">
                        {item.title}
                    </p>

                    <p className="tracking-[-0.01rem] text-grey01 font-normal text-14">
                        {item.description}
                    </p>
                </div>
            </div>

            <div className="border-[0.9px] mt-3.5 border-avocado" />
        </>
    )
}
