import { Dispatch } from 'redux'
import { ICategoryResponse,
    IRecipe,
    IRecipeComment,
    IRecipeCommentResponse,
    IRecipeHomePage, IRecipePledgeLogResponse,
    IRecipeRateResponse,
    IRecipeRatingData, ISearchCollection,
    ITags, IWriteRecipeReviewData } from '../../types/data'
import { getByCategoryList, getRecipeBookmarkedList, getRecipeByCategory,
    getRecipeCategoryList, getRecipeCommentsList, getRecipeDetail,
    getRecipeHomePage, getRecipesByTags, getRecipeSearchCollection, getRecipeSearchList,
    getRecipeTagsList, postRateRecipe,
    postRecipeBookmarkItem, postRecipePledgeLogItem, recipePostComment } from './api'
import { CLEAR_RECIPE_DETAIL,
    FETCH_RECIPE_LIST_ERROR,
    FETCH_RECIPE_START,
    GET_RECIPE_BY_CATEGORY_LIST,
    GET_RECIPE_CATEGORY_LIST,
    GET_RECIPE_COMMENTS_SUCCESS,
    GET_RECIPE_DETAIL_SUCCESS,
    GET_RECIPE_HOME_PAGE_LIST,
    GET_RECIPE_SEARCH_LIST,
    GET_RECIPE_TAGS_LIST,
    GET_RECIPE_USER_BOOKMARKED_LIST,
    GET_RECIPES_BY_CATEGORY_SUCCESS,
    GET_RECIPES_BY_TAGS_SUCCESS,
    POST_BOOKMARK_SUCCESS,
    POST_RECIPE_COMMENT_SUCCESS,
    POST_RECIPE_RATE_SUCCESS,
    GET_RECIPE_SEARCH_COLLECTION_LIST,
    CLEAR_RECIPE_SEARCH_LIST,
    FETCH_RECIPE_SEARCH_START,
    POST_RECIPE_PLEDGE_LOG_SUCCESS } from './consts'
import { updateUserPoint } from '../user/actions'

const fetchRecipeStart = () => ({
    type: FETCH_RECIPE_START,
})

const fetchRecipeSearchStart = () => ({
    type: FETCH_RECIPE_SEARCH_START,
})

export const fetchRecipeBookmarkedSuccess = (response: IRecipe) => ({
    type: GET_RECIPE_USER_BOOKMARKED_LIST,
    payload: response,
})

const postRecipeBookmarkSuccess = (isBookmark: boolean, bookmarkedId: number | string) => ({
    type: POST_BOOKMARK_SUCCESS,
    payload: { bookmarkedId, isBookmark },
})

const fetchEnd = () => ({
    type: FETCH_RECIPE_LIST_ERROR,
})

export const fetchRecipeHomePageSuccess = (response: IRecipeHomePage) => ({
    type: GET_RECIPE_HOME_PAGE_LIST,
    payload: response,
})

export const fetchCategorySuccess = (response: ICategoryResponse) => ({
    type: GET_RECIPE_CATEGORY_LIST,
    payload: response,
})

export const fetchRecipeTagsSuccess = (response: ITags) => ({
    type: GET_RECIPE_TAGS_LIST,
    payload: response,
})

const fetchRecipeSearchSuccess = (response: IRecipe) => ({
    type: GET_RECIPE_SEARCH_LIST,
    payload: response,
})

export const clearRecipeSearch = () => ({
    type: CLEAR_RECIPE_SEARCH_LIST,
})

const fetchRecipeDetailSuccess = (response: IRecipe) => ({
    type: GET_RECIPE_DETAIL_SUCCESS,
    payload: response,
})

const fetchRecipeReviewSuccess = (response: IRecipeComment) => ({
    type: GET_RECIPE_COMMENTS_SUCCESS,
    payload: response,
})

export const clearRecipeDetail = () => ({
    type: CLEAR_RECIPE_DETAIL,
})

export const fetchByCategorySuccess = (response: IRecipe) => ({
    type: GET_RECIPE_BY_CATEGORY_LIST,
    payload: response,
})

const postRateSuccess = (response: IRecipeRateResponse) => ({
    type: POST_RECIPE_RATE_SUCCESS,
    payload: response,
})

const postCommentSuccess = (response: any) => ({
    type: POST_RECIPE_COMMENT_SUCCESS,
    payload: { response },
})

const fetchRecipeByCategorySuccess = (response: IRecipe) => ({
    type: GET_RECIPES_BY_CATEGORY_SUCCESS,
    payload: response,
})

export const fetchRecipeByTagSuccess = (response: IRecipe) => ({
    type: GET_RECIPES_BY_TAGS_SUCCESS,
    payload: response,
})

export const fetchRecipeCollectionSuccess = (response: ISearchCollection) => ({
    type: GET_RECIPE_SEARCH_COLLECTION_LIST,
    payload: response,
})

const postRecipePledgeLogSuccess = (response: IRecipePledgeLogResponse) => ({
    type: POST_RECIPE_PLEDGE_LOG_SUCCESS,
    payload: response,
})

/**
 * Fetch user bookmarked recipe list
 * @returns
 */
export const fetchBookmarkedRecipe = (params: { page: number }) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await getRecipeBookmarkedList(params)

        if (response) {
            dispatch(fetchRecipeBookmarkedSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchBookmarkedRecipe: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post recipe bookmark
 * @returns
 */

export const postRecipeBookmark = (id: number) => async (dispatch: Dispatch) => {
    try {
        const response = await postRecipeBookmarkItem(id)

        if (response) {
            dispatch(postRecipeBookmarkSuccess(response.bookmarked, id))
        }
    } catch (e) {
        console.log('error: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchRecipeHomePage = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await getRecipeHomePage()

        if (response) {
            dispatch(fetchRecipeHomePageSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchRecipeHomePage: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchRecipeCategoryList = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await getRecipeCategoryList()

        if (response) {
            dispatch(fetchCategorySuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchRecipeCategoryList: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchRecipeTagsList = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await getRecipeTagsList()

        if (response) {
            dispatch(fetchRecipeTagsSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchRecipeTagsList: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchRecipeSearchList = (text: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeSearchStart())

        const response = await getRecipeSearchList(text)

        if (response) {
            dispatch(fetchRecipeSearchSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchRecipeSearchList: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchRecipeDetail = (id: number) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await getRecipeDetail(id)

        if (response) {
            dispatch(fetchRecipeDetailSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchRecipeDetail: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchRecipeComments = (recipe: object) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await getRecipeCommentsList(recipe)

        if (response) {
            dispatch(fetchRecipeReviewSuccess(response))
        }
        // @ts-ignore
    } catch (e) {
        dispatch(fetchEnd())
    }
}

export const rateRecipe = (
    data: IRecipeRatingData,
    onSuccess?: (res: IRecipeRateResponse) => void,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await postRateRecipe(data)

        if (response) {
            dispatch(postRateSuccess(response))
            dispatch(updateUserPoint(response.thisMonthPoints))
            if (typeof onSuccess === 'function') onSuccess(response)
        }
    } catch (e) {
        console.log('ERROR, rateRecipe: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * get by category tags
 * @returns
 */

export const fetchByCategoryTagList = (tags: string | undefined) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await getByCategoryList(tags)

        if (response) {
            dispatch(fetchByCategorySuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchByCategoryTagList: ', e)
        dispatch(fetchEnd())
    }
}

export const postCommentCategory = (
    data: IWriteRecipeReviewData,
    onSuccess: (hasCommented: IRecipeCommentResponse) => void,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await recipePostComment(data)

        if (response) {
            dispatch(postCommentSuccess(response))
            dispatch(updateUserPoint(response.thisMonthPoints))
            onSuccess(response)
        }
    } catch (e) {
        console.log('ERROR, postCommentCategory: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchRecipesByCategory = (category: number | string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await getRecipeByCategory(category)

        if (response) {
            dispatch(fetchRecipeByCategorySuccess(response))
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
    }
}

export const fetchRecipeByTags = (tags: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await getRecipesByTags(tags)

        if (response) {
            dispatch(fetchRecipeByTagSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, postCommentCategory: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchRecipeCollection = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await getRecipeSearchCollection()

        if (response) {
            dispatch(fetchRecipeCollectionSuccess(response))
        }
    } catch (e) {
        dispatch(fetchEnd())
    }
}

/**
 * post recipe pledge log
 * @returns
 */

export const postRecipePledgeLog = (id: number | undefined, onSuccess: () => void, setError?: (value: (((prevState: { message: string }) => { message: string }) | { message: string })) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchRecipeStart())
        const response = await postRecipePledgeLogItem(id)

        if (response) {
            dispatch(postRecipePledgeLogSuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('ERROR, postRecipePledgeLog: ', e)
        dispatch(fetchEnd())

        if (setError) {
            // @ts-ignore
            setError(e?.response?.data)
        }
    }
}
