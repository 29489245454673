import React, { useCallback, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getUser } from '../../state/user/selectors'
import AccountInfoButton from '../AccountInfoButton'
import { readableC02 } from '../../utils/helpers'
import { formatDate } from '../../utils/date'
import { getPrizeDraw } from '../../state/prize/selectors'
import GoldZoneBlock from '../GoldZoneBlock'
import { fetchPrizeDraw } from '../../state/prize/actions'
import { fetchLeaderboard } from '../../state/leaderboard/actions'
import { getLeaderboard } from '../../state/leaderboard/selectors'
import Skeleton from '../common/Skeleton'

export default function MyStats() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { leaderboard, loading } = useSelector(getLeaderboard)
    const { prizeDraw } = useSelector(getPrizeDraw)
    const [t] = useTranslation('global')

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : ''

    const goldZoneOptionLength = leaderboard?.leaderboards?.filter((item) => item?.goldZoneOption !== 'off')
    const leaderboards = leaderboard.leaderboards.find((item) => item.id === leaderboard?.currentLeaderboard)

    const onRestartLoad = useCallback(() => {
        dispatch(fetchPrizeDraw)
        dispatch(fetchLeaderboard({ page: 1 }))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        if (leaderboard.results.length === 0) onRestartLoad()
        // eslint-disable-next-line
    }, [])

    return (
        <div onClick={() => navigate('/me/leaderboard')} className="columns-2 gap-3 cursor-pointer max-mobile:px-4 pb-3">
            <AccountInfoButton className="mb-3" label={`${t('global.since')} ${formatDate(prizeDraw.startsAt, languageData)}`} dataPoint={`${user?.thisMonthPoints || 0} pts`} home />
            <AccountInfoButton label={t('global.logged-lifetime')} dataPoint={readableC02(Number(user?.totalCeoKg))} home />
            <AccountInfoButton className="mb-3" label={`${t('global.saved-since')} ${formatDate(prizeDraw.startsAt, languageData)}`} dataPoint={readableC02(user?.thisMonthCeoKg || 0)} home />
            {loading ? <Skeleton className="h-[55px] rounded-lg" /> : (
                goldZoneOptionLength?.length > 1 || leaderboards?.goldZoneOption === 'off' ? <div className="min-h-[71px]" /> : <GoldZoneBlock home />
            )}
        </div>
    )
}
