import React from 'react'
import { IBadgeTask } from '../types/data'

interface IProps {
    item: IBadgeTask
}

export default function BadgeTaskItem({ item }: IProps) {
    return (
        <>
            <div className="flex mt-4">
                <div className="flex flex-col items-center">
                    <img src={item.disabledIcon} className="mobile:w-[60px] w-[46px] mobile:h-[60px] h-[46px] object-cover" alt="" />

                    <div className="mobile:hidden mt-0.5 font-bold text-spinach text-12 tracking-[-0.02rem]">
                        {item.currentProgress}/{item.requiredCompletionsCount}
                    </div>
                </div>

                <div className="ml-3 flex-1">
                    <p className="not-heading-font font-bold tracking-[-0.02rem] text-black text-16 mt-0.5">
                        {item.title}
                    </p>

                    <div className="flex items-center justify-between">
                        <p className="tracking-[-0.01rem] text-grey01 font-normal text-14">
                            {item.description}
                        </p>

                        <div className="not-heading-font mobile:flex hidden font-bold text-spinach text-14 tracking-[-0.02rem]">
                            {item.currentProgress}/{item.requiredCompletionsCount}
                        </div>
                    </div>

                    <div className="rounded-full flex-1 h-3 mt-2" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                        <div className="z-10 relative flex rounded-r-sm rounded-l-xl h-3 bg-raspberry" style={{ width: `${item.completionPercentage}%` }} />
                        <div className="w-full relative z-0 rounded-full h-3 bg-mint" style={{ marginTop: -12 }} />
                    </div>
                </div>
            </div>

            <div className="border-[0.9px] mt-5 border-avocado" />
        </>
    )
}
