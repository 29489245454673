import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import GoogleAutoComplete from './GoogleAutoComplete'
import SecondButton from './common/SecondButton'
import { Trash } from './common/Svg'
import Button from './common/Button'
import { Colors } from '../themes'
import { deleteSavedPlace, editSavedPlace, fetchSavedPlaces, postSavedPlaces } from '../state/user/actions'
import { ISavedPlaces } from '../types/data'

interface IProps {
    setShowModal: (value: boolean) => void;
    showModal: boolean
    enteredViaMoreButton: boolean
    editItem?: ISavedPlaces | undefined
    viaHeart?: boolean
    setEditItem?: (value: ISavedPlaces) => void
}

export default function SavedPlaceOverlay({ setEditItem, setShowModal, showModal, enteredViaMoreButton, editItem, viaHeart }: IProps) {
    const dispatch = useDispatch()
    const [t] = useTranslation('global')
    const [enterPlaceName, setEnterPlaceName] = useState({ placeId: '', lon: '', lat: '', name: '' })
    const [chooseName, setChooseName] = useState<string>(editItem?.name || '')
    const [loading, setLoading] = useState<boolean>(false)
    const isDisabled = !(enterPlaceName.name && chooseName)

    useEffect(() => {
        if (editItem) {
            setEnterPlaceName({ placeId: editItem?.placeId || '', lon: '', lat: '', name: (editItem?.placeName || editItem?.name) || '' })
            setChooseName(!viaHeart ? (editItem?.name || '') : '')
        }
        // eslint-disable-next-line
    }, [editItem, setEnterPlaceName])

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChooseName(event.target.value)
    }

    const deleteSavedPlaceFunction = async () => {
        if (editItem?.id) {
            await dispatch(deleteSavedPlace(editItem?.id))
            await dispatch(fetchSavedPlaces)
        }
        setShowModal(false)
        resetForm()
    }

    const cancelSavedPlaceFunction = () => {
        setShowModal(false)
        resetForm()
    }

    const resetForm = () => {
        setEnterPlaceName({ placeId: '', lon: '', lat: '', name: '' })
        setChooseName('')
        setEditItem?.({ id: '', name: '', placeId: '', placeName: '' })
    }

    const onSubmit = async () => {
        if (editItem?.id) {
            setLoading(true)
            await dispatch(editSavedPlace({
                id: editItem?.id,
                name: chooseName,
                placeName: enterPlaceName?.name,
                placeId: enterPlaceName?.placeId,
            }))
            setLoading(false)
        } else {
            setLoading(true)
            await dispatch(postSavedPlaces({
                name: chooseName,
                placeName: enterPlaceName?.name,
                placeId: enterPlaceName?.placeId,
            }))
            setLoading(false)
        }

        dispatch(fetchSavedPlaces)
        resetForm()

        setShowModal(false)
    }

    return (
        showModal && (
            <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                    <div className="bg-white relative px-4 py-6 xs-mobile:w-[500px] w-11/12 rounded-lg"
                        style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                        <h1 className="heading-uppercase text-22 text-cavolo font-semibold tracking-[-0.02rem]">
                            {t('savedPlaces.saved-Places')}
                        </h1>

                        <div>
                            <div className="flex items-center justify-between mt-5 mb-2.5">
                                <h1 className="font-medium not-heading-font text-cavolo text-16">
                                    {t('savedPlaces.enter-a-place')}
                                </h1>
                            </div>

                            <GoogleAutoComplete
                                savePlace
                                placeholder={t('log.start-typing')}
                                defaultValue={enterPlaceName}
                                setPlace={setEnterPlaceName}
                                showHeart={false} />
                        </div>

                        <div>
                            <div className="flex items-center justify-between mt-5 mb-1.5">
                                <h1 className="font-medium not-heading-font text-cavolo text-16">
                                    {t('savedPlaces.choose–a-name')}
                                </h1>
                            </div>

                            <input
                                value={chooseName}
                                onChange={handleChange}
                                placeholder={t('log.start-typing')}
                                type="text"
                                className="border border-spinach rounded-lg font-medium text-base leading-7 w-full p-[0px] py-[9px] pl-[17px] text-[#000] mb-4"
                            />
                        </div>

                        <Button
                            loading={loading}
                            onClick={onSubmit}
                            // @ts-ignore
                            disabled={isDisabled}
                            style={isDisabled ? disabledStyles : undefined}
                            className={`${!isDisabled && 'cursor-pointer'} mt-5 w-full h-[48px] font-semibold rounded-md text-14`}
                            text={t('global.save')} />

                        <SecondButton
                            onClick={cancelSavedPlaceFunction}
                            text={t('global.cancel')}
                            className="mt-5 w-full h-[48px] font-semibold bg-grey01 rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />

                        {(enteredViaMoreButton && !viaHeart) && (
                            <button onClick={deleteSavedPlaceFunction} className="flex items-center justify-center mt-5">
                                <Trash />

                                <h1 className="ml-1.5 text-16 tracking-tight font-normal text-raspberry">
                                    {t('savedPlaces.delete-saved-place')}
                                </h1>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        )
    )
}
