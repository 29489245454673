import apiClient from '../instance'
import { IQuestionData, IQuizAnswerPost, IQuizAnswerResponse } from '../../types/data'
import { QUIZ_ANSWER_URL, QUIZ_URL } from '../../urls'

/**
 * Get quiz
 */

export const getQuiz = async (id: number | string): Promise<IQuestionData | null> => {
    try {
        const { data: response } = await apiClient.get(QUIZ_URL.replace('{id}', String(id)))
        return response
    } catch (e) {
        throw e
    }
}

export const postQuizAnswerItem = async (
    data: IQuizAnswerPost | null | undefined,
): Promise<IQuizAnswerResponse | null | undefined> => {
    try {
        const { data: response } = await apiClient.post<IQuizAnswerResponse | null | undefined>(
            QUIZ_ANSWER_URL,
            data,
        )
        return response
    } catch (e) {
        throw e
    }
}
