import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { getWhiteLabelingList } from '../state/user/selectors'

interface IProps {
    text: string | undefined
}

export default function ContentBlockTitle({ text }: IProps) {
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    return <div style={{ textTransform: whiteLabeling.contentBlockTitlesInCaps ? 'uppercase' : 'unset' }} className={cn({ 'heading-font': whiteLabeling.contentBlockTitlesToHeadings })}>{text}</div>
}
