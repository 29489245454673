import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import BrowseByTopic from '../../components/BrowseByTopic'
import { fetchArticleCategoryList, fetchArticleSearch } from '../../state/articles/actions'
import { FilterIcon } from '../../components/common/Svg'
import Layout from '../../components/layout/Layout'
import SearchInput from '../../components/common/SearchInput'
import ArticleCard from '../../components/article/ArticleCard'
import { articles } from '../../state/articles/selectors'

export default function DiscoverSearch() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useParams()
    const [t] = useTranslation('global')
    const { loadingArticleSearch, ArticlesBySearch } = useSelector(articles)

    const data = [
        { id: 1, title: t('article.browse-by-topic'), content: <BrowseByTopic />, loadContent: () => dispatch(fetchArticleCategoryList) },
    ]

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Article search' })
    }, [])

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        openedMenu: 1,
        buttonName: t('article.articles'),
    }

    useEffect(() => {
        if (search) dispatch(fetchArticleSearch(search))

        // eslint-disable-next-line
    }, [search])

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="min-h-screen mx-10 max-w-6xl medium:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px]">
                <div className="hidden xs-mobile:block">
                    <button onClick={() => navigate('/learn/articles')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2" style={{ fontSize: 14 }}>
                        {t('article.title')}
                    </button>

                    <div className="flex items-end justify-between">
                        <h1 className="xs-mobile:block hidden font tracking-[-0.02em] leading-[126%] font-medium tablet:text-36 mobile:text-32 text-26 text-black">
                            {search} {t('article.search-results')}
                        </h1>

                        <SearchInput autoFocus defaultValue={search} action="articles" placeholder={t('article.what-would-you-like-to-know?')} />
                    </div>
                </div>

                <div className="xs-mobile:hidden block">
                    <SearchInput autoFocus defaultValue={search} action="articles" placeholder={t('article.what-would-you-like-to-know?')} />

                    <button onClick={() => navigate('/learn/articles')} className="text-rocket pt-[32px] tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('article.title')}
                    </button>

                    <h1 className="font tracking-[-0.02em] leading-[126%] font-medium tablet:text-36 mobile:text-32 text-26 text-black">
                        {search} {t('article.search-results')}
                    </h1>
                </div>

                {ArticlesBySearch.length > 0 && (
                    <>
                        <h1 className="font tracking-tight leading-[132%] font-medium mt-[32px] text-18 text-black">
                            {ArticlesBySearch.length} {t('article.results')}
                        </h1>

                        <div className="grid grid-cols-1 mobile:gap-8 mobile:grid-cols-3 xs-mobile:grid-cols-2 gap-4 mt-3">
                            {ArticlesBySearch?.map((item) => (
                                <ArticleCard key={item.id} item={item} type="article" />
                            ))}
                        </div>
                    </>
                )}

                {!loadingArticleSearch && ArticlesBySearch.length === 0 && (
                    <h1 className="font tracking-[-0.02em] font-medium text-18 leading-[132%] text-black mt-[32px]">
                        {t('article.your-search-returned-no-results')}
                    </h1>
                )}
            </div>
        </Layout>
    )
}
