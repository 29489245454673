import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import ReactGA from 'react-ga4'
import { getUser } from '../state/user/selectors'
import { fetchPrivacyPolicy } from '../state/user/actions'
import AccountSidebar from '../components/account/AccountSidebar'

export default function PrivacyPolicy() {
    const dispatch = useDispatch()
    const { privacyPolicy } = useSelector(getUser)

    const onRestartLoad = useCallback(() => {
        dispatch(fetchPrivacyPolicy)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Privacy police' })
    }, [])

    return (
        <AccountSidebar>
            {privacyPolicy?.id && (
                <div className="tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 tablet:mr-[92px] medium:mr-[88px] mobile:mr-10 laptop:w-[760px]">
                    <h1 className="font-normal text-12 text-grey01">
                        Last updated {privacyPolicy?.lastUpdate ? moment(privacyPolicy?.lastUpdate).format('DD MMM YYYY') : 'n/a'}
                    </h1>

                    <h1 className="font-normal mb-8 mobile:text-32 tablet:text-36 laptop:text-36 text-26 tracking-[-0.02rem] text-darken font">
                        {privacyPolicy?.title}
                    </h1>

                    {/* eslint-disable-next-line react/no-danger */}
                    <h1 className="rich-text" dangerouslySetInnerHTML={{ __html: privacyPolicy?.body || '' }} />
                </div>
            )}
        </AccountSidebar>
    )
}
