import React, { useContext, useEffect, useState } from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TravelTreeSmallEllipse } from './common/Svg'
import { displayDistanceWithUnit, convertSecondsToMinutesAndHours, readableC02, replaceAll } from '../utils/helpers'
import { IPlanJourneyLeg } from '../types/data'
import { COLOR_CONST } from '../constants'
import { getWhiteLabelingList } from '../state/user/selectors'
import { getPlaceInfoByLocation, getTextBeforeFirstComma } from '../utils/travel'
import { Context } from './common/BaseContext'

interface IProps {
    isLast: boolean
    item: IPlanJourneyLeg
    index: number
    legs: IPlanJourneyLeg[]
    disableDropdown?: boolean
    setLegsEndPlaceNames?: (value: object) => void
}

export default function PlanJourneyLegItem({ isLast, item, index, legs, disableDropdown, setLegsEndPlaceNames }: IProps) {
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')
    const { distanceUnit } = useContext(Context)

    const [endPlaceName, setEndPlaceName] = useState('')

    const isFirst = index === 0

    useEffect(() => {
        if (!isLast) fetchEndPlaceName()

        // eslint-disable-next-line
    }, [])

    const fetchEndPlaceName = async () => {
        const nextLeg = legs[index + 1]
        const endLocation = nextLeg.namePriority > item.namePriority ? nextLeg.startLocation : item.endLocation
        const endPlace = nextLeg.namePriority > item.namePriority ? nextLeg.startLocation?.placeName : item.endLocation?.placeName

        const end = endPlace || await getPlaceInfoByLocation(endLocation.latLng.latitude, endLocation.latLng.longitude)
        setEndPlaceName(end)
        setLegsEndPlaceNames?.((oldValue: object) => ({ ...oldValue, [index]: end }))
    }

    return (
        <div>
            <div className={cn('border-spinach border-l-[10px] ml-3 pl-5 pt-6', { 'pb-7': isLast, 'mt-[-3px]': isFirst })}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        {/* eslint-disable-next-line react/no-danger */}
                        <div className="mr-1.5" dangerouslySetInnerHTML={{ __html: replaceAll(item?.icon || '', COLOR_CONST, whiteLabeling.cavolo) }} />

                        <div>
                            <h1 className={`${!disableDropdown ? 'text-18' : 'text-14'} tracking-tight text-caovolo xs-mobile:w-[337px]`}>
                                {item?.transportName},&nbsp;

                                {!disableDropdown && (
                                    <>
                                        {convertSecondsToMinutesAndHours(item.durationSeconds)},&nbsp;
                                    </>
                                )}

                                {displayDistanceWithUnit((item?.distanceMeters || 0) / 1000, distanceUnit, t)}
                            </h1>

                            <h1 className={`${!disableDropdown ? 'text-18' : 'text-14'} tracking-tight not-heading-font text-caovolo xs-mobile:w-[337px]`}>
                                {readableC02(item?.ceo2Kg || 0)}
                            </h1>
                        </div>
                    </div>
                </div>

                {!isLast && (
                    <div className="flex relative bottom-[-8px] pt-7 items-center">
                        <div className="absolute left-[-33.7px]">
                            <TravelTreeSmallEllipse />
                        </div>

                        <div className="text-16 text-cavolo font-semibold not-heading-font">
                            {getTextBeforeFirstComma(endPlaceName)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
