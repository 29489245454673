import { Dispatch } from 'redux'
import { FETCH_HOME_START,
    HOME_LIST_ERROR,
    GET_HOME_PAGE_LIST,
    POST_HOME_BOOKMARK_SUCCESS,
    GET_HOW_TO_COLLECT_POINTS, GET_SPONSORSHIP_BLOCKS_SUCCESS } from './consts'
import { IHomePage, IHowToCollectPointsData, InitializeResponse, SponsorshipBlock } from '../../types/data'
import { getHomePageList,
    getHowToCollectPoints,
    getInitializeResponse,
    getSponsoredByBlocks,
    postBookmarkItem } from './api'

const fetchHomeStart = () => ({
    type: FETCH_HOME_START,
})

export const fetchHomePageSuccess = (response: IHomePage) => ({
    type: GET_HOME_PAGE_LIST,
    payload: response,
})

const postPerkBookmarkSuccess = (isBookmark: boolean, bookmarkedId: number | string) => ({
    type: POST_HOME_BOOKMARK_SUCCESS,
    payload: { bookmarkedId, isBookmark },
})

export const fetchHowToCollectPointsSuccess = (response: IHowToCollectPointsData) => ({
    type: GET_HOW_TO_COLLECT_POINTS,
    payload: response,
})

export const fetchSponsorshipBlocksSuccess = (response: SponsorshipBlock[]) => ({
    type: GET_SPONSORSHIP_BLOCKS_SUCCESS,
    payload: response,
})

const fetchEnd = () => ({
    type: HOME_LIST_ERROR,
})

/**
 * Fetch home page list
 * @returns
 */
export const fetchHomePage = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchHomeStart())
        const response = await getHomePageList()

        if (response) {
            dispatch(fetchHomePageSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchHomePage: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post home perk book mark
 * @returns
 */

export const postHomeBookMark = (id: number) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchHomeStart())
        const response = await postBookmarkItem(id)
        if (response) {
            dispatch(postPerkBookmarkSuccess(response.bookmarked, id))
        }
    } catch (e) {
        console.log('ERROR, postHomeBookMark: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch how to collect points
 * @returns
 */
export const fetchHowToCollectPoints = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchHomeStart())
        const response = await getHowToCollectPoints()

        if (response) {
            dispatch(fetchHowToCollectPointsSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchHowToCollectPoints: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchSponsoredByBlocks = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchHomeStart())
        const response = await getSponsoredByBlocks()

        if (response) {
            dispatch(fetchSponsorshipBlocksSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchSponsoredByBlocks: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchInitializeResponse = (onSuccess: (response: InitializeResponse) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchHomeStart())
        const response = await getInitializeResponse()

        if (response) {
            onSuccess(response)
        }
    } catch (e) {
        console.log('ERROR, fetchInitializeResponse: ', e)
        dispatch(fetchEnd())
    }
}
