import React from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { Colors } from '../../themes'
import { getLoginConfig, getWhiteLabelingList } from '../../state/user/selectors'

interface IProps {
    text: string
    onClick?: (event: any) => void
    icon?: React.ReactNode
    iconPosition?: 'left' | 'right'
    iconStyle?: object

    type?: 'submit' | 'reset' | 'button' | undefined;
    backgroundColor?: string
    secondaryBackgroundColor?: string
    secondaryTextColor?: string
    textColor?: string
    style?: object
    className?: string
    disabled?: boolean
    secondary?: boolean
    loadingColor?: string
    loading?: boolean
    hoverBackground?: string
    borderColor?: string
    error?: boolean
    hover?: boolean
    defaultStyles?: boolean
    buttonRef?: any
}

export default function Button({
    text,
    icon,
    iconPosition = 'left',
    iconStyle,
    style,
    textColor = 'white',
    backgroundColor = '#00362B',
    className,
    disabled,
    buttonRef,
    type = 'submit',
    secondary = false,
    secondaryBackgroundColor = Colors.mint,
    secondaryTextColor = Colors.rocket,
    onClick,
    loading = false,
    loadingColor = '#fff',
    hoverBackground = '',
    borderColor = '',
    error,
    hover = false,
    defaultStyles = true,
    ...attributes
}: IProps) {
    const { loginConfig } = useSelector(getLoginConfig)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const buttonStyles = {
        backgroundColor: secondary ? (whiteLabeling?.mint || loginConfig?.whiteLabeling?.mint || secondaryBackgroundColor) : (whiteLabeling?.rocket || loginConfig?.whiteLabeling?.rocket || backgroundColor),
        color: secondary ? secondaryTextColor : textColor,
        borderColor: borderColor || (secondary ? secondaryTextColor : (whiteLabeling?.rocket || loginConfig?.whiteLabeling?.rocket || backgroundColor)),
        ...styles.button,
        ...style,
    }

    const buttonHoverStyle = {
        backgroundColor: hoverBackground || (secondary ? (whiteLabeling?.avocado || loginConfig?.whiteLabeling?.avocado || '#A7D49D') : (whiteLabeling?.spinach || loginConfig?.whiteLabeling?.spinach || '#00483a')),
        color: secondary ? (whiteLabeling?.spinach || loginConfig?.whiteLabeling?.spinach || '#30542A') : textColor,
        transition: 'background-color 200ms ease-in, color 200ms ease-in',
    }

    const buttonErrorStyle = {
        backgroundColor: (whiteLabeling?.error || loginConfig?.whiteLabeling?.error || '#BD4823'),
        color: secondary ? (whiteLabeling?.spinach || loginConfig?.whiteLabeling?.spinach || '#30542A') : textColor,
        ...styles.button,
        ...style,
    }

    const iconStyles = {
        ...styles.icon,
        ...iconStyle,
    }
    const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
        const hoverStyle = hover ? {} : buttonHoverStyle;
        // @ts-ignore
        (e.target as HTMLButtonElement).style.backgroundColor = (error ? buttonErrorStyle : hoverStyle).backgroundColor;
        // @ts-ignore
        (e.target as HTMLButtonElement).style.color = (error ? buttonErrorStyle : hoverStyle).color
    }

    const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
        // @ts-ignore
        (e.target as HTMLButtonElement).style.backgroundColor = (error ? buttonErrorStyle : buttonStyles).backgroundColor;
        // @ts-ignore
        (e.target as HTMLButtonElement).style.color = (error ? buttonErrorStyle : buttonStyles).color
    }

    return (
        <button
            ref={buttonRef}
            disabled={disabled || loading}
            type={type}
            style={defaultStyles ? (error ? buttonErrorStyle : buttonStyles) : undefined}
            onClick={onClick}
            {...attributes}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={cn(className, { 'font-semibold w-full px-4 rounded border-2 border-solid border-rocket not-heading-font': defaultStyles })}>
            {
                icon && iconPosition === 'left' && (
                    <span style={iconStyles} className="mr-2 hover:text-red-600">
                        {icon}
                    </span>
                )
            }
            {
                !loading ? text : (
                    <div role="status">
                        <svg aria-hidden="true"
                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill={loadingColor} />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                )
            }
            {
                icon && iconPosition === 'right' && (
                    <span style={iconStyles} className="ml-1">
                        {icon}
                    </span>
                )
            }
        </button>
    )
}

const styles = {
    button: {
        fontSize: 14,
        marginTop: 16,
        height: 48,
    },
    icon: {
        display: 'inline-block',
        verticalAlign: 'middle',
    },
}
