import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Select, { StylesConfig } from 'react-select'
import ReactGA from 'react-ga4'
import SecondButton from '../components/common/SecondButton'
import { getOrganisationalUnitsList, getUser, getWhiteLabelingList } from '../state/user/selectors'
import { fetchOrganisationalUnitsList, fetchUpdateUserSetting, fetchUser } from '../state/user/actions'
import { ShieldIcon } from '../components/common/Svg'
import AccountSidebar from '../components/account/AccountSidebar'

interface ColourOption {
  value: string;
  label: JSX.Element;
  color: string;
}

export default function OrganisationUnit() {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [search, setSearch] = useState<Date | string | any>('')
    const [name, setName] = useState()
    const [selectedOrganisationUnit, setSelectedOrganisationUnit] = useState()
    const [loading, setLoading] = useState<boolean>(false)
    const [clearInput, setClearInput] = useState<boolean>(false)
    const { organisationUnitsSearchResults } = useSelector(getOrganisationalUnitsList)
    const debouncedSearch = useDebounce(search, 500)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Organisation units' })
    }, [])

    function useDebounce(value: string, delay: number) {
        const [debouncedValue, setDebouncedValue] = useState(value)

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)

            return () => {
                clearTimeout(handler)
            }
        }, [value, delay])

        return debouncedValue
    }

    const options = organisationUnitsSearchResults?.map((item: any) => (
        {
            id: item?.id,
            value: item.name,
            label:
    <div className="flex items-center">
        {item?.image !== null ? (
            <img className="w-[26px] h-[28px] object-cover" src={item?.image} alt="" />
        ) : (
            <ShieldIcon />
        )}

        <span className="font-bold not-heading-font text-14 ml-[13px]">{item?.name}</span>
    </div>,
            color: whiteLabeling?.black || 'rgba(0, 0, 0, 1)',
            item,
        }
    ))

    const colourStyles: StylesConfig<ColourOption> = {
        dropdownIndicator: (base: any) => ({
            ...base,
            color: whiteLabeling?.rocket,
            cursor: 'pointer',
        }),
        // @ts-ignore
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            cursor: 'pointer',
            color: isSelected
                ? whiteLabeling?.grey01
                : (whiteLabeling?.grey01 && isFocused ? whiteLabeling?.marrow : whiteLabeling?.grey01),
            backgroundColor: isSelected
                ? whiteLabeling?.white
                : isFocused
                    ? whiteLabeling?.mint
                    : whiteLabeling?.white,
        }),
        input: (styles) => ({ ...styles }),
        control: (styles) => ({ ...styles, padding: 7, borderRadius: 10, borderColor: whiteLabeling?.avocado }),
        placeholder: (styles) => ({ ...styles }),
        indicatorSeparator: () => ({ display: 'none' }),
    }

    const defaultValue = options && options.length > 0
        ? options.find((option) => option.value === user?.organisationUnit?.name)
        : null

    const onSubmit = async () => {
        const data = {
            firstName: user?.firstName,
            lastName: user?.lastName,
            displayName: user?.displayName || '.',
            timezone: user?.timezone,
            organisationUnit: selectedOrganisationUnit || '',
        }
        setLoading(true)
        await dispatch(fetchUpdateUserSetting(data))
        setLoading(false)

        dispatch(fetchUser)
    }

    const handleInputChange = (value: string) => {
        setSearch(value)
    }

    const handleSelectChange = (selectedOption: any) => {
        setName(selectedOption)
        setSelectedOrganisationUnit(selectedOption?.item?.id)
    }

    useEffect(() => {
        if (debouncedSearch) dispatch(fetchOrganisationalUnitsList(debouncedSearch))
    }, [debouncedSearch, dispatch])

    const selectProps = {
        value: clearInput ? null : name,
        isClearable: false,
        onMenuOpen: () => setClearInput(true),
        onMenuClose: () => setClearInput(false),
        maxMenuHeight: 200,
        options,
        styles: colourStyles,
        onChange: handleSelectChange,
    }

    return (
        <AccountSidebar>
            <div className="tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 mobile:mr-10 mobile:w-[562px]">
                <h1 className="heading-uppercase text-black font tracking-tight font-medium tablet:text-36 mobile:text-26 text-24 mb-[22px]">
                    {t('account.your-name').replace('[name]', user?.company?.organisationalUnitName || 'club')}
                </h1>

                <div className="flex items-center mb-[22px]">
                    {user?.organisationUnit?.image ? (
                        <img className="w-[31px] h-[34px] object-cover" src={user?.organisationUnit?.image} alt="" />
                    ) : (
                        <ShieldIcon />
                    )}

                    <h1 className="tracking-tight text-black font-normal text-18 ml-2">
                        {user?.organisationUnit?.name}
                    </h1>
                </div>

                <h1 className="line text-14 tracking-tight font-medium text-black">
                    {t('account.change-my-name').replace('[name]', user?.company?.organisationalUnitName || 'club')}
                </h1>

                <div className="mt-1.5">
                    <Select
                        {...selectProps}
                        placeholder={t('account.search')}
                        styles={colourStyles}
                        isSearchable
                        onChange={handleSelectChange}
                        onInputChange={handleInputChange}
                        defaultValue={defaultValue}
                        noOptionsMessage={() => 'Type the first three characters'}
                    />
                </div>

                <SecondButton
                    loading={loading}
                    onClick={onSubmit}
                    className="w-full mt-[22px] bg-rocket h-[48px] font-semibold border rounded-md text-14 text-pistachio"
                    text={t('global.save')}
                />
            </div>
        </AccountSidebar>
    )
}
