import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { FilterIcon } from '../../components/common/Svg'
import SearchInput from '../../components/common/SearchInput'
import { IRecipe } from '../../types/data'
import RecipeCategories from '../../components/common/RecipeCategories'
import RecipeTags from '../../components/common/RecipeTags'
import { getRecipe } from '../../state/recipe/selectors'
import { fetchRecipeCategoryList, fetchRecipeHomePage, fetchRecipeTagsList } from '../../state/recipe/actions'
import RecipeCard from '../../components/recipe/RecipeCard'
import { getPerkLists } from '../../state/perks/selectors'
import RecentSearches from '../../components/RecentSearches'

export default function RecipesViewAll() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { slot } = useParams()
    const { recipeHomePage, searchCollection } = useSelector(getRecipe)
    // @ts-ignore
    const newRecipeData = recipeHomePage[slot]
    const { recentSearches } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Recipe view all' })
    }, [])

    const data = [
        { id: 1, title: 'All categories', content: <RecipeCategories />, loadContent: () => dispatch(fetchRecipeCategoryList) },
        { id: 2, title: 'Tags', content: <RecipeTags />, loadContent: () => dispatch(fetchRecipeTagsList) },
    ]

    useLayoutEffect(() => {
        if (!newRecipeData) dispatch(fetchRecipeHomePage)

        // eslint-disable-next-line
    }, [])

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: 'Filter',
        buttonName: 'recipes',
    }

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }, [])

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="mt-2 mobile:mx-10 mx-16 mobile:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px]">
                <span onClick={() => navigate('/lower/recipes')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2" style={{ fontSize: 14 }}>
                    Recipes
                </span>

                <div className="flex items-center justify-between">
                    <h1 className="text-black font tracking-tight font-medium text-36">
                        {newRecipeData?.name}
                    </h1>

                    <SearchInput action="recipes" placeholder="What would you like to make?" onFocus={handleFocus} />
                </div>

                {onFocus && (
                    <RecentSearches data={recentSearches?.recipes} type="recipes" collection={searchCollection} />
                )}

                <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-10">
                    {newRecipeData?.recipe?.map((item: IRecipe) => (
                        <div key={item.id}>
                            <RecipeCard item={item} type="recipe" />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}
