import React from 'react'
import { Field } from 'formik'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import Tooltip from './ToolTip'
import { Colors } from '../../themes'
import ValidationErrorMessage from './ValidationErrorMessage'
import ServerError from './ServerError'
import { InfoIcon } from './Svg'
import { getLoginConfig, getWhiteLabelingList } from '../../state/user/selectors'

interface IProps {
    name: string
    label?: string
    component?: string
    size?: string
    placeholder?: string
    type?: string
    validate?: any
    className?: string
    tooltipText:any
    error?: string | {
        nonFieldErrors: string[]
        detail: string[]
    }
  hasError?: boolean
}
// eslint-disable-next-line react/function-component-definition
const InputWithTooltip: React.FC<IProps> = ({
    label, validate, className, size = 'small', name, component = 'input', tooltipText, error, hasError, ...attributes
}) => {
    const { loginConfig } = useSelector(getLoginConfig)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    return (
    // @ts-ignore
        <div>
            <label style={styles.label} htmlFor={name} className="block font-medium font">
                <div style={{ color: loginConfig?.whiteLabeling?.black }} className={`cursor-pointer flex font ${hasError ? 'font-bold tracking-[-.02em] leading-[130%] text-14' : ''}`}>
                    {label}
                    <Tooltip text={tooltipText}>
                        <InfoIcon />
                    </Tooltip>
                </div>
            </label>

            <Field
                id="inputID"
                name={name}
                as={component}
                size={size}
                className={cn('inputID rounded-lg font-semibold text-[15px] leading-[140%] w-full p-2.5 text-black tracking-[-.02em] stroke-1 placeholder:italic placeholder:font-normal placeholder:font', className, { 'border-2 text-error stroke-error': hasError })}
                style={{ borderColor: hasError ? loginConfig?.whiteLabeling?.error || whiteLabeling.error : loginConfig?.whiteLabeling?.avocado || whiteLabeling.avocado, marginBottom: 13 }}
                validate={validate}
                {...attributes} />

            {hasError && (
                <div className="pb-6">
                    {/* @ts-ignore */}
                    <ServerError error={typeof error === 'object' ? error[name] : error} />
                </div>
            )}

            <ValidationErrorMessage name={name} />
        </div>
    )
}

const styles = {
    label: {
        color: Colors.black,
        fontSize: 14,
        marginBottom: 6,
    },
}
export default InputWithTooltip
