import React from 'react'
import { motion } from 'framer-motion'

interface IProps {
    label:string
    value:string
    width: string
    color: string
    labelColor: string
}
export default function ImpactBar({ label, value, width, color, labelColor }: IProps) {
    const percentageValue = parseFloat(width.replace('%', ''))

    return (
        <>
            <h1 className="text-14 font-normal text-cavolo tracking-tight mt-4">
                {label}
            </h1>

            <div className="flex items-center">
                <motion.div
                    className={`flex items-center justify-end h-[34px] mt-1 ${color}`}
                    initial={{ width: 0 }}
                    animate={{ width }}
                    transition={{ duration: 0.7 }}
                >
                    {percentageValue > 15 ? (
                        <motion.span
                            className={`text-14 font-normal text-${labelColor} tracking-tight mr-1.5`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.7, duration: 0.7 }}
                        >
                            {value}
                        </motion.span>
                    ) : null}
                </motion.div>

                {percentageValue < 15 ? (
                    <span className="text-14 font-normal text-cavolo tracking-tight ml-1.5">
                        {value}
                    </span>
                ) : null}
            </div>
        </>
    )
}
