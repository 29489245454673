import React from 'react'
import { Field } from 'formik'
import { useSelector } from 'react-redux'
import '../../static/index.css'
import ServerError from './ServerError'
import ValidationErrorMessage from './ValidationErrorMessage'
import { getLoginConfig, getWhiteLabelingList } from '../../state/user/selectors'

interface IProps {
  name: string;
  label?: string;
  component?: React.ElementType;
  size?: string;
  placeholder?: string;
  type?: string;
  validate?: (value: any) => string | Promise<void> | undefined;
  className?: string;
  error?: string | {
    nonFieldErrors: string[];
    detail: string[];
  };
  hasError?: boolean;
  disabled?: boolean;
  onChange?: any
  value?: any
}

// eslint-disable-next-line react/function-component-definition
const Input: React.FC<IProps> = ({ label, validate, className, size = 'small', name, component = 'input', error, hasError, ...attributes }) => {
    const { loginConfig } = useSelector(getLoginConfig)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    return (
        <>
            {label && (
                <label htmlFor={name}>
                    <div
                        style={{ color: loginConfig?.whiteLabeling?.black || whiteLabeling?.black }}
                        className={`text-14 mb-[6px] block font ${hasError ? 'text-error font-bold tracking-[-.02em] leading-[130%] text-14' : 'font-medium'}`}>
                        {label}
                    </div>
                </label>
            )}

            <Field
                style={{ borderColor: hasError ? loginConfig?.whiteLabeling?.error || whiteLabeling?.error : loginConfig?.whiteLabeling?.avocado || whiteLabeling?.avocado }}
                id={name}
                name={name}
                as={component}
                size={size}
                className={`border inputID rounded-lg font-medium text-base leading-7 w-full p-2.5 text-black tracking-wide stroke-1 placeholder-italic placeholder-normal placeholder-font mb-4 ${className}`}
                validate={validate}
                {...attributes}
            />

            {hasError && (
                <div className="pb-6">
                    {/* @ts-ignore */}
                    <ServerError error={typeof error === 'object' ? error[name] : error} />
                </div>
            )}

            <ValidationErrorMessage name={name} />
        </>
    )
}

export default Input
