import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { FilterIcon } from '../../components/common/Svg'
import SearchInput from '../../components/common/SearchInput'
import RecipeCategories from '../../components/common/RecipeCategories'
import { clearRecipeSearch,
    fetchRecipeCategoryList,
    fetchRecipeSearchList,
    fetchRecipeTagsList } from '../../state/recipe/actions'
import RecipeTags from '../../components/common/RecipeTags'
import { getRecipe } from '../../state/recipe/selectors'
import RecipeCard from '../../components/recipe/RecipeCard'

export default function RecipeFilterBySearch() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useParams()
    const [t] = useTranslation('global')
    const { recipeSearchResults, searchLoading } = useSelector(getRecipe)

    const data = [
        { id: 1, title: t('lower.all-categories'), content: <RecipeCategories />, loadContent: () => dispatch(fetchRecipeCategoryList) },
        { id: 2, title: t('lower.tags'), content: <RecipeTags />, loadContent: () => dispatch(fetchRecipeTagsList) },
    ]

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Recipe search' })
    }, [])

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        buttonName: t('lower.recipes'),
    }

    useEffect(() => {
        if (search) dispatch(fetchRecipeSearchList(search))

        return () => {
            dispatch(clearRecipeSearch)
        }

        // eslint-disable-next-line
    }, [search])

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="min-h-screen mobile:mx-10 mx-16 mobile:mt-[52px] mt-[32px] tablet:mb-[120px] mb-[80px]">
                <span onClick={() => navigate('/lower/recipes')} className="xs-mobile:block hidden text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                    {t('account.Recipes')}
                </span>

                <div className="flex items-end justify-between">
                    <h1 className="text-black xs-mobile:block hidden font tracking-[-0.02em] leading-[126%] font-medium tablet:text-36 mobile:text-32 text-26">
                        {search} {t('article.search-results')}
                    </h1>

                    <SearchInput autoFocus defaultValue={search} action="recipes" placeholder="What would you like to make?" />
                </div>

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/lower/recipes')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('account.Recipes')}
                    </span>

                    <h1 className="font tracking-[-0.02em] leading-[126%] font-medium tablet:text-36 mobile:text-32 text-26 text-black">
                        {search} {t('article.search-results')}
                    </h1>
                </div>

                {(recipeSearchResults.length > 0 || searchLoading) && (
                    <h1 className="text-black font tracking-tight leading-[132%] font-medium text-18 mt-[32px]">
                        {searchLoading ? t('lower.searching') : `${recipeSearchResults.length} ${t('article.results')}`}
                    </h1>
                )}

                {searchLoading && (
                    <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-3">
                        <div><RecipeCard isLoading /></div>
                        <div><RecipeCard isLoading /></div>
                        <div><RecipeCard isLoading /></div>
                    </div>
                )}

                {recipeSearchResults.length > 0 && !searchLoading && (
                    <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-3">
                        {recipeSearchResults?.map((item) => (
                            <div key={item.id}>
                                <RecipeCard type="recipe" item={item} />
                            </div>
                        ))}
                    </div>
                )}

                {!searchLoading && recipeSearchResults.length === 0 && (
                    <h1 className="font tracking-[-0.02em] font-medium text-18 leading-[132%] text-black mt-[32px]">
                        {t('article.your-search-returned-no-results')}
                    </h1>
                )}
            </div>
        </Layout>
    )
}
