import React, { useState } from 'react'
import { ActiveArrow, DefaultArrow } from '../common/Svg'

interface IProps {
    question: string
    answer: any
}

export default function DropDownMenuFAQ({ question, answer }: IProps) {
    const [openedMenu, setOpenedMenu] = useState(true)

    return (
        <div className="border-b-avocado border-b ">
            <button
                className="flex items-center justify-between w-full pt-[16px] rounded-lg text-sm font-medium leading-[1.6rem] focus:outline-none focus:shadow-outline"
                onClick={() => setOpenedMenu(!openedMenu)}>
                <span className="text-start font tracking-[-.02em] text-black font-medium text-18 mb-[-12px]">
                    {question}
                </span>

                <div className="flex items-center justify-center ml-2 w-[22px] h-[22px]">
                    {openedMenu ? (
                        <DefaultArrow width={13} height={8} />
                    ) : (
                        <ActiveArrow width={13} height={8} />
                    )}
                </div>
            </button>

            <div className={`animation-y py-[12px] text-16 text-grey01 ${openedMenu ? 'hide' : ''}`}>
                {answer}
            </div>
        </div>
    )
}
