import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import { commuteSaveList, legTransportList, travelSelector } from '../../state/travel/selectors'
import Input from '../../components/common/Input'
import { required } from '../../utils/validators'
import SecondButton from '../../components/common/SecondButton'
import TicketModal from '../../components/common/TicketModal'
import { getPrizeDrawEntry } from '../../state/travel/actions'
import { Context } from '../../components/common/BaseContext'
import ArticleModal from '../../components/article/ArticleModal'
import { getUser } from '../../state/user/selectors'
import ContentBlockMini from '../../components/ContentBlockMini'

interface FormData {
  name: string;
}

export default function TravelPrizeDraw() {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const { navbarHeight, screenWidth, setShowArticleModal, showArticleModal } = useContext(Context)
    const { pathname } = useLocation()
    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const { commuteSave } = useSelector(commuteSaveList)
    const { travelContentBlock } = useSelector(travelSelector)
    const [t] = useTranslation('global')
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')
    const [loading, setLoading] = useState<boolean>(false)
    const [firstTicketModal, setFirstTicketModal] = useState<boolean>(false)
    const [secondTicketModal, setSecondTicketModal] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [phone, setPhone] = useState<string | number | undefined>()
    const [email, setEmail] = useState<string | number | undefined>()

    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')
    const { commuteStartEnd } = useSelector(legTransportList)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Travel prize draw' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value)
    }

    const handleChangeEmail = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    const onSubmit = async (data: FormData) => {
        setLoading(true)
        if (phone || email) {
            await dispatch(getPrizeDrawEntry({
                userName: data.name,
                email,
                phone,
                game: outboundData?.gameId || commuteSave?.game?.id || undefined,
                travelCommute: (outboundData?.backendCommuteId || commuteStartEnd.id) || commuteSave.id,
            }, () => {
                setSecondTicketModal(true)
            }))
        }

        setLoading(false)
    }

    return (
        <Layout showFooter={false}>
            <div className="w-full h-screen">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <Formik onSubmit={onSubmit} initialValues={{ name: '' }}>
                    <Form>
                        <div style={{ marginTop: screenWidth > 768 ? navbarHeight : 0 }} className="h-screen flex flex-col items-center pt-5 mobile:px-[42px] px-4">
                            <div className="max-w-[600px] w-full pb-[300px]">
                                <h1 className="heading-font text-22 text-cavolo tracking-tight font-semibold">
                                    {t('prizeDraw.prize-draw')}
                                </h1>

                                {travelContentBlock.title && (
                                    <ContentBlockMini />
                                )}

                                <p className="text-14 text-grey01 font-normal mt-[11px]">
                                    {t('prizeDraw.prize-draw-description')}
                                </p>

                                <div className="mt-[11px]">
                                    <Input
                                        className="mb-[11px]"
                                        placeholder={t('prizeDraw.full-name')}
                                        name="name"
                                        type="text"
                                        validate={required(t)} />

                                    <input
                                        className="mb-[11px] border border-avocado inputID rounded-lg font-medium text-base leading-7 w-full p-2.5 text-black tracking-wide stroke-1 placeholder-italic placeholder-normal placeholder-font"
                                        name="email"
                                        placeholder={t('global.email-address')}
                                        type="email"
                                        onChange={handleChangeEmail}
                                    />

                                    <input
                                        className="mb-[11px] border border-avocado inputID rounded-lg font-medium text-base leading-7 w-full p-2.5 text-black tracking-wide stroke-1 placeholder-italic placeholder-normal placeholder-font"
                                        name="phone"
                                        placeholder={t('global.phone-number')}
                                        type="text"
                                        onChange={handleChange}
                                    />

                                    {(!phone && !email && error) && (
                                        <div className="text-[#BD4823] tracking-[-0.02em] leading-[140%] text-[15px] font-semibold">
                                            {t('forms.email-or-phone-number-is-required')}
                                        </div>
                                    )}
                                </div>

                                <div className="flex">
                                    <p className="text-14 text-grey01 font-normal">
                                        {t('prizeDraw.full-prize-draw-terms-conditions-are')}
                                    </p>

                                    <div className="flex">
                                        <a
                                            className="text-14 text-grey01 font-normal underline ml-1 cursor-pointer"
                                            href={user?.company?.prizeDrawTerms}
                                            target="_blank"
                                            rel="noreferrer">
                                            {t('prizeDraw.here')}
                                        </a>
                                        <p className="text-14 text-grey01 font-normal">.</p>
                                    </div>

                                </div>

                                <SecondButton
                                    onClick={() => setError(true)}
                                    loading={loading}
                                    text={t('prizeDraw.enter-prize-draw')}
                                    className="mt-2.5 w-full h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                                />

                                <SecondButton
                                    type="reset"
                                    onClick={() => setFirstTicketModal(true)}
                                    text={t('prizeDraw.submit-without-entering')}
                                    className="mt-2.5 w-full h-[48px] font-semibold bg-grey01 rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                                />
                            </div>
                        </div>
                    </Form>
                </Formik>

                {firstTicketModal && (
                    <TicketModal
                        prizeDraw
                        width
                        descriptionFirst={t('prizeDraw.thank-you-for-helping-us-understand-our-environmental-impact')}
                        descriptionSecond=" "
                        onClose={() => setFirstTicketModal(false)}
                        title={t('prizeDraw.thank-you')} />
                )}

                {secondTicketModal && (
                    <TicketModal
                        prizeDraw
                        width
                        descriptionFirst={t('prizeDraw.by-telling-us-about-your-travel-you’ve-got-a-ticket-for-the-prize-draw')}
                        descriptionSecond={t('prizeDraw.thank-you-for-helping-us-understand-our-environmental-impact')}
                        onClose={() => setSecondTicketModal(false)}
                        title={t('prizeDraw.you’re-in-the-draw')} />
                )}

                {showArticleModal && showArticleModal === travelContentBlock?.overlayArticle ? (
                    <ArticleModal setShowModal={setShowArticleModal} articleId={travelContentBlock?.overlayArticle} />
                ) : undefined}
            </div>
        </Layout>
    )
}
