import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RightArrow } from './Svg'
import { getRecipe } from '../../state/recipe/selectors'
import Skeleton from './Skeleton'
import { fetchRecipeCategoryList } from '../../state/recipe/actions'
import { getWhiteLabelingList } from '../../state/user/selectors'

const data = [{ size: 10 }, { size: 8 }, { size: 9 }, { size: 6 }, { size: 5 }, { size: 9 }, { size: 4 }, { size: 6 }, { size: 3 }, { size: 8 }, { size: 4 }]

export default function RecipeCategories() {
    const navigate = useNavigate()
    const { categoryId } = useParams()
    const dispatch = useDispatch()
    const { recipeCategories } = useSelector(getRecipe)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    useEffect(() => {
        if (recipeCategories.length === 0) dispatch(fetchRecipeCategoryList)

        // eslint-disable-next-line
    }, [])

    const navigateRecipeCategory = (id: number) => {
        navigate(`/lower/recipes/category/${id}`)
    }

    return (
        <div>
            {recipeCategories.length !== 0 ? (
                recipeCategories.map((item) => (
                    <div key={item.id} onClick={() => navigateRecipeCategory(item.id)}>
                        <h1
                            style={item.id === Number(categoryId) ? { borderBottomWidth: 1, borderBottomColor: whiteLabeling.avocado } : { borderBottomWidth: 1, borderBottomColor: whiteLabeling.avocado }}
                            className={`${item.id === Number(categoryId) ? 'text-black text-18 font-bold' : 'text-black text-18'} mt-2 pb-1 cursor-pointer font tracking-tight flex items-center hover:font-bold hover:ease-in hover:duration-200`}>
                            {item.id === Number(categoryId) && (
                                <div className="mr-2">
                                    <RightArrow width={13} height={8} />
                                </div>
                            )}

                            {item.name}
                        </h1>
                    </div>
                ))
            ) : (
                data.map((item) => (
                    <h1 style={{ borderBottomColor: whiteLabeling.avocado, borderBottomWidth: 1 }} className="text-18 text-black font-bold mt-2 pb-1 cursor-pointer font tracking-tight flex items-center hover:font-bold hover:ease-in hover:duration-200">
                        <Skeleton className={`w-${item?.size}/12 h-[16px] rounded-sm mt-1`} />
                    </h1>
                ))
            )}
        </div>
    )
}
