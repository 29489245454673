const BASE = 'home/'

export const FETCH_HOME_START = `${BASE}FETCH_HOME_START`

export const GET_HOME_PAGE_LIST = `${BASE}GET_HOME_PAGE_LIST`
export const POST_HOME_BOOKMARK_SUCCESS = `${BASE}POST_HOME_BOOKMARK_SUCCESS`
export const GET_HOW_TO_COLLECT_POINTS = `${BASE}GET_HOW_TO_COLLECT_POINTS`
export const GET_SPONSORSHIP_BLOCKS_SUCCESS = `${BASE}GET_SPONSORSHIP_BLOCKS`
export const POST_CHALLENGE_JOIN_HOME_SUCCESS = `${BASE}POST_CHALLENGE_JOIN_HOME_SUCCESS`
export const POST_CHALLENGE_LOG_DAY_HOME_SUCCESS = `${BASE}POST_CHALLENGE_LOG_DAY_HOME_SUCCESS`

export const HOME_LIST_ERROR = `${BASE}HOME_LIST_ERROR`
