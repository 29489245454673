import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Radio } from '../common/Radio'
import Button from '../common/Button'
import { Union } from '../common/Svg'
import { IArticles, IQuizData } from '../../types/data'
import { postQuizzAnswer } from '../../state/articles/actions'
import { fetchUser } from '../../state/user/actions'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'

interface props {
    article: IArticles
    showModalPoint?: boolean
    setShowModalPoint: (value: boolean) => void
}

export default function ArticleDetailQuizz({ article, showModalPoint, setShowModalPoint }: props) {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')
    const [selectedValue, setSelectedValue] = useState<string | number>('')
    const [wrongAnswer, setWrongAnswer] = useState<boolean | null>(null)

    const handleRadioChange = (value: string | number) => {
        setSelectedValue(value)
        setWrongAnswer(null)
    }

    const quizz = article?.questions[0]

    const onSubmit = async (data: IQuizData) => {
        await dispatch(
            postQuizzAnswer(data, (response) => {
                dispatch(fetchUser)
                if (response) setWrongAnswer(response.quizzCompleted)
                if (response.quizzCompleted) {
                    setShowModalPoint(true)
                    setWrongAnswer(null)
                }
            }),
        )
    }

    return (
        <div className="w-full bg-pistachio rounded-md tablet:p-[32px] medium:p-[24px] py-[20px] px-[16px] relative"
            style={{ boxShadow: '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' }}>

            <div className="w-full h-full">
                <h1 className="tablet:mt-0 text-12 text-grey01">
                    {t('article.test-your-knowledge')}
                </h1>

                <h1 className="font tablet:text-[22px] tablet:mt-0 text-18 text-cavolo" style={{ letterSpacing: '-0.36px;' }}>
                    {quizz?.title}
                </h1>

                <div className="tablet:mt-[16px] mt-[10px]">
                    {quizz?.answer?.map((item) => (
                        <div>

                            <Radio
                            // containerClassName={`${(Number(selectedValue) === item.id && !wrongAnswer && wrongAnswer !== null) ? 'bg-raspberry' : 'bg-rocket'}`}
                                inputClassName={`mb-4 tablet:mb-[20px] ${(Number(selectedValue) === item.id && !wrongAnswer && wrongAnswer !== null) ? 'border-raspberry' : 'border-rocket'}`}
                                key={item.id}
                                label={item.answerText}
                                value={item.id}
                                labelClassName={`text-14 mb-4 tablet:mb-[20px] ${(Number(selectedValue) === item.id && !wrongAnswer && wrongAnswer !== null) ? 'text-raspberry' : 'text-grey01'}`}
                                checked={Number(selectedValue) === item.id}
                                onChange={handleRadioChange}
                            />

                            <style>
                                {`
                .radio-style input[type='radio'] {
            outline: none;
            appearance: none;
            width: 24px;
            height: 24px;
            padding: 3px; /* Control the space between border and background */
        }

        .btnd:active,
        .btnd:focus,
        .btnd:focus:active {
            background-image: none;
            outline: 0;
            box-shadow: none;
        }

        .radio-style input[type="radio"]:checked {
            width: 24px;
            height: 24px;
            background: ${!wrongAnswer && wrongAnswer !== null ? whiteLabeling?.raspberry : whiteLabeling?.rocket} content-box;
        }
                `}
                            </style>
                        </div>
                    ))}
                </div>

                <Button icon={user?.company?.gamification && <Union width={11.96} height={10.46} />}
                    iconPosition="right"
                    className="medium:w-[195px] w-full h-[48px]"
                    style={{ marginTop: '4px' }}
                    text={`Check answer ${user?.company?.gamification ? '+5' : ''}`}
                    onClick={() => onSubmit({
                        article: article?.id,
                        answers: [
                            {
                                question: quizz?.id,
                                answer: Number(selectedValue),
                            },
                        ],
                    })}
                />

                {!wrongAnswer && wrongAnswer !== null ? (
                    <h4 className="border-t-2 mt-4 border-raspberry text-raspberry">
                        {t('article.that’s-not-quite-right')}
                    </h4>
                ) : ''}

                {showModalPoint && (
                    <h4 className="border-t-2 mt-4 border-spinach text-spinach">
                        {t('article.you-earned-point-points')}
                    </h4>
                )}
            </div>
        </div>
    )
}
