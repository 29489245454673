import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { getChallengeList } from '../../state/challenges/selectors'
import { ChallengeLoggedStar } from '../common/Svg'
import { IChallenges } from '../../types/data'

type CalendarProps = {
    year: number;
    month: number;
    selectedDay: number,
    onSelect: (day: number) => void
    challenge: IChallenges
};

type DayProps = {
    day: Date;
    startDay: Date;
    endDay: Date;
    isSelected: boolean;
    onSelect: () => void
};

const daysInMonth = (year: number, month: number): number => new Date(year, month + 1, 0).getDate()

function ChallengeCalendar({ year, month, selectedDay, onSelect, challenge }: CalendarProps) {
    const days = daysInMonth(year, month)
    const firstDayOfMonth = new Date(year, month, 1).getDay()

    const daysArray = Array.from({ length: days }, (_, i) => i + 1)
    const emptyDaysArray = Array.from({ length: firstDayOfMonth }, (_, i) => i)

    return (
        <div className="grid grid-cols-7 gap-y-4 gap-x-3 tablet:gap-x-6 w-full max-w-[400px]">
            {/* <div className="text-center font-medium text-gray-500">Sun</div> */}
            {/* <div className="text-center font-medium text-gray-500">Mon</div> */}
            {/* <div className="text-center font-medium text-gray-500">Tue</div> */}
            {/* <div className="text-center font-medium text-gray-500">Wed</div> */}
            {/* <div className="text-center font-medium text-gray-500">Thu</div> */}
            {/* <div className="text-center font-medium text-gray-500">Fri</div> */}
            {/* <div className="text-center font-medium text-gray-500">Sat</div> */}

            {emptyDaysArray.map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`empty-${i}`} className="text-center text-gray-300">
                    {' '}
                </div>
            ))}

            {daysArray.map((day) => (
                <Day
                    startDay={new Date(challenge.startDate)}
                    endDay={new Date(challenge.endDate)}
                    key={day}
                    day={new Date(new Date(challenge.startDate).setDate(day))}
                    isSelected={selectedDay === day}
                    onSelect={() => onSelect(day)}
                />
            ))}
        </div>
    )
}

function Day({ day, isSelected, onSelect, startDay, endDay }: DayProps) {
    const { loggedDays, loading } = useSelector(getChallengeList)
    const isLogged = loggedDays.filter((i) => Number(i.date.slice(8)) === day.getDate()).length
    const today = new Date()
    const isDisabled = day > today || startDay > day || endDay < day
    const textColor = isSelected && !isDisabled ? 'text-white' : 'text-rocket'
    const backgroundColor = isSelected && !isDisabled ? 'bg-rocket' : isDisabled ? 'bg-transparent' : 'bg-white'

    if (isLogged) {
        return (
            <div className="items-center justify-center flex">
                <ChallengeLoggedStar />
            </div>
        )
    }

    return (
        <button
            disabled={isDisabled || loading}
            className={cn('font-bold text-14 flex items-center justify-center rounded-full w-9 h-9 border-rocket', backgroundColor, textColor, { 'border-0': isDisabled, 'border-[1.5px]': !isDisabled })}
            onClick={onSelect}>
            {day.getDate()}
        </button>
    )
}

export default ChallengeCalendar
