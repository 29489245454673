import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/layout/Layout'
import { FilterIcon } from '../../components/common/Svg'
import BrowseByTopic from '../../components/BrowseByTopic'
import { fetchArticleListByCategory } from '../../state/articles/actions'
import { articles } from '../../state/articles/selectors'
import ArticleCard from '../../components/article/ArticleCard'
import SearchInput from '../../components/common/SearchInput'
import { getPerkLists } from '../../state/perks/selectors'
import RecentSearches from '../../components/RecentSearches'

export default function BrowseByTopicList() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { topicId } = useParams()
    const { byCategoryList, category, searchCollection } = useSelector(articles)
    const topic = category?.find((item) => item.id === Number(topicId))
    const { recentSearches } = useSelector(getPerkLists)
    const [t] = useTranslation('global')
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)

    useEffect(() => {
        dispatch(fetchArticleListByCategory(Number(topicId)))
        // eslint-disable-next-line
    }, [topicId])

    const data = [
        { id: 1, title: t('article.browse-by-topic'), content: <BrowseByTopic /> },
    ]

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        openedMenu: 1,
        buttonName: t('article.articles'),
        closeAfterChangeUrl: true,
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [topicId])

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="min-h-screen mobile:mx-10 mx-4 mobile:mb-[120px] mb-[80px] mt-4 medium:mt-[52px] xs-mobile:mt-[32px]">
                <span onClick={() => navigate('/learn/articles')} className="text-rocket max-xs-mobile:hidden tracking-tight italic font font-normal cursor-pointer underline underline-offset-2" style={{ fontSize: 14 }}>
                    {t('article.title')}
                </span>

                <div className="flex flex-row items-end justify-between xs-mobile:mt-[8px]">
                    <h1 className="font tracking-tight max-xs-mobile:hidden font-medium text-26 mr-2 mobile:text-3xl tablet:text-4xl">
                        {topic?.name}
                    </h1>

                    <SearchInput action="articles" placeholder={t('article.what-would-you-like-to-know?')} onFocus={handleFocus} />
                </div>

                <p onClick={() => navigate('/learn/articles')} className="xs-mobile:hidden mt-8 text-rocket xs-mobile:block tracking-tight italic font font-normal cursor-pointer underline underline-offset-2" style={{ fontSize: 14 }}>
                    {t('article.title')}
                </p>

                <h1 className="xs-mobile:hidden font tracking-tight mt-2 font-medium text-26">
                    {topic?.name}
                </h1>

                {onFocus && (
                    <RecentSearches data={recentSearches?.articles} type="articles" collection={searchCollection} />
                )}

                <div className="xs-mobile:grid grid-cols-2 grid-rows-2 mobile:grid-rows-1 mobile:grid-cols-3 gap-[24px] mt-4 mb-4">
                    {byCategoryList?.map((item) => (
                        <ArticleCard key={item.id} item={item} type="article" />
                    ))}
                </div>
            </div>
        </Layout>
    )
}
