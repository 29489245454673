import React from 'react'
import cn from 'classnames'

interface IProps {
    text: string
    to?: string
    className?: string
    target?: string
}

// eslint-disable-next-line react/function-component-definition
const LinkText: React.FC<IProps> = ({ text, to, target, className }) => (
    <a href={to} target={target} className={cn('font-medium text-center text-blue-600 underline dark:text-blue-500 hover:no-underline', className)} rel="noreferrer">
        {text}
    </a>
)

export default LinkText
