import React, { useCallback, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Input from '../components/common/Input'
import Button from '../components/common/Button'
import AuthContainer from '../components/auth/LoginContainer'
import { Colors } from '../themes'
import { IResetEmail } from '../types/data'
import { getLoginConfig, getUser } from '../state/user/selectors'
import { fetchUserResetLink } from '../state/user/actions'
import ServerError from '../components/common/ServerError'
import logo from '../static/lowr-black.png'

export default function ResetPasswordEmail() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [error, setError] = useState('')
    const { loading } = useSelector(getUser)
    const { loginConfig } = useSelector(getLoginConfig)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Reset password' })
    }, [])

    const onSubmit = useCallback(async (data: IResetEmail) => {
        dispatch(
            await fetchUserResetLink(
                data,
                () => navigate(`/reset-link-sent?email=${data.email}`),
                setError,
            ),
        )
        // eslint-disable-next-line
    }, [])

    const initialValues: IResetEmail = {
        email: '',
    }

    return (
        <AuthContainer title={t('login.reset-your-password')}>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}>
                <Form>
                    <Input
                        name="email"
                        label={loginConfig?.signUpEmail === 'company_email_address' ? t('login.Company-email-address') : t('global.email-address')}
                        placeholder={loginConfig?.signUpEmail === 'company_email_address' ? t('register.company-email-placeholder') : t('register.email-placeholder')}
                        type="email" />

                    <ServerError error={error} />

                    <Button loading={loading} style={{ marginTop: 27 }} text={t('login.send-reset-link')} />
                    {loginConfig?.showPoweredByOnAuth && (
                        <div className="flex item-center justify-end mt-2">
                            <h1 style={{ color: loginConfig?.whiteLabeling?.black }} className="text-14 font">
                                {t('register.powered-by')}
                            </h1>

                            <img src={logo} className="w-[60px] h-[15px] object-contain" alt="" />
                        </div>
                    )}

                    <p style={{ color: loginConfig?.whiteLabeling?.grey01 || Colors.grey01 }} className={css(styles.helpText)}>
                        {t('login.enter-your-email-and-we’ll-send-you-a-link-to-reset-your-password')}
                    </p>
                </Form>
            </Formik>
        </AuthContainer>

    )
}

const styles = StyleSheet.create({
    helpText: {
        marginTop: 24,
        fontSize: 14,
        textAlign: 'center',
    },
})
