import React from 'react'
import cn from 'classnames'

interface RadioProps {
  label: string | number | undefined
  value: string | number
  checked: boolean
  disabled?: boolean
  readOnly?: boolean
  onChange: (value: string | number) => void
  inputClassName?: string
  labelClassName?: string
  containerClassName?: string
  containerStyles?: object
}

// eslint-disable-next-line react/function-component-definition
export const Radio: React.FC<RadioProps> = ({ label, disabled, readOnly = false, value, checked, onChange, containerStyles, containerClassName, inputClassName, labelClassName }) => (
    <div onClick={() => {
        if (!disabled) onChange(value)
    }} className={cn('flex items-center radio-style', containerClassName)} style={containerStyles}>
        <input
            readOnly={readOnly}
            type="radio"
            className={cn('w-[24px] h-[24px] btnd border-rocket transition duration-300 ease-in-out', inputClassName)}
            value={value}
            checked={checked}
        />

        <label className={cn('ml-3', labelClassName)}>
            {label}
        </label>
    </div>
)
