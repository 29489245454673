import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { IArticles } from '../../types/data'
import { getDatesLocalization } from '../../utils/date'
import { SavedIconActive, SavedIconDefault } from '../common/Svg'
import { articleBookMark } from '../../state/articles/actions'
import Skeleton from '../common/Skeleton'
import { postHomeBookMark } from '../../state/home/actions'
import SponsoredByBlock from '../SponsoredByBlock'
import { travelArticleBookMark } from '../../state/travel/actions'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item: IArticles
    style?: React.CSSProperties
    fan?: boolean
    type?: 'article' | 'home' | 'travel'
}

export default function ArticleCard({ item, style, fan, type }: IProps) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [t] = useTranslation('global')
    const handleImageLoad = () => setLoading(false)
    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    const { topicId } = useParams()

    const navigateArticleDetail = (articleId: number | string) => {
        navigate(`/learn/articles/${articleId}${topicId ? `/${topicId}` : ''}`)
    }

    const onPressBookmark = (id: number) => {
        if (!item) return

        if (type === 'article') dispatch(articleBookMark(id))
        if (type === 'home') dispatch(postHomeBookMark(item?.id))
        if (type === 'travel') dispatch(travelArticleBookMark(item?.id))
    }

    const [formattedDate, setFormattedDate] = useState<string | undefined>(undefined)

    useEffect(() => {
        const fetchData = async () => {
            if (item?.createdAt) {
                const formatted = await getDatesLocalization(item?.createdAt, languageData)
                setFormattedDate(formatted)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [item?.createdAt])

    let extractedNumber: number | null = null

    if (typeof item?.getReadTime === 'string') {
        const matchResult = item?.getReadTime.match(/\d+/)
        if (matchResult) {
            extractedNumber = parseInt(matchResult[0], 10)
        }
    }

    return (
        <div className="cursor-pointer relative bg-white rounded-lg max-mobile:mb-4" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div className="flex justify-end mr-3">
                <button onClick={() => onPressBookmark(item?.id)} className="absolute rounded-full flex justify-center items-center mt-3 bg-black w-[28px] h-[28px]">
                    {item?.isBookmark ? (
                        <SavedIconActive width={12} height={15} />
                    ) : (
                        <SavedIconDefault width={12} height={15} />
                    )}
                </button>
            </div>

            <div onClick={() => navigateArticleDetail(item.id)}>
                {loading && <Skeleton className={`rounded-lg ${fan ? 'aspect-w-16 aspect-h-9 aspect-video' : 'aspect-[16/9]'} object-cover`} />}
                <img onLoad={handleImageLoad} src={item?.image} className={cn(`${loading ? 'hidden' : 'block'} rounded-t-lg ${fan ? 'aspect-w-16 aspect-h-9 aspect-video' : 'aspect-[16/9]'} object-cover`, { 'rounded-t-lg w-full': !item.sponsorshipBlock })} alt="" />

                <div className="mx-3 pb-5 flex flex-col justify-between" style={style}>
                    {item?.sponsorshipBlock && <SponsoredByBlock noBorder noBackground item={item.sponsorshipBlock} />}

                    <h5 className="text-12 mt-3 font-light my-1 text-grey01">
                        {loading ? (
                            <Skeleton className="w-4/12 h-[8px] rounded-sm" />
                        ) : (
                            <span>
                                {item?.isDateStampShown && formattedDate} {item?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                            </span>
                        )}
                    </h5>

                    <h1 className="line-clamp-two font-medium font tracking-tight text-black max-mobile:text-15 text-18 mb-2 leading-[132%]">
                        {loading ? (
                            <Skeleton className="w-9/12 h-[12px] rounded-sm mt-1" />
                        ) : (
                            <ContentBlockTitle text={item?.title} />
                        )}
                    </h1>

                    <h1 style={{ wordBreak: 'break-word' }} className="tracking-[-.01em] leading-[150%] text-grey01 text-14 whitespace-normal overflow-hidden text-ellipsis max-w-[350ch] line-clamp-3">
                        {loading ? (
                            <>
                                <Skeleton className="w-full h-[12px] rounded-sm" />
                                <Skeleton className="w-9/12 h-[12px] rounded-sm mt-1.5" />
                            </>
                        ) : (
                            <div>
                                {item?.subTitle?.replace(/(<([^>]+)>)/ig, '').replace(/&#39;/g, "'")}
                            </div>
                        )}
                    </h1>
                </div>
            </div>
        </div>
    )
}
