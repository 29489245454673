import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RightArrow } from './Svg'
import { getPerkLists } from '../../state/perks/selectors'
import Skeleton from './Skeleton'
import { getWhiteLabelingList } from '../../state/user/selectors'

const data = [{ size: 10 }, { size: 8 }, { size: 9 }, { size: 6 }, { size: 5 }, { size: 9 }, { size: 4 }, { size: 6 }, { size: 3 }, { size: 8 }, { size: 4 }, { size: 6 }, { size: 9 }, { size: 11 }]

export default function PerkByTypeItem() {
    const navigate = useNavigate()
    const { perksByType } = useSelector(getPerkLists)
    const { perkId } = useParams()
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    const navigationPerksByType = (perksId: number) => {
        navigate(`/lower/swaps/swap-by-type/${perksId}`)
    }

    return (
        <div>
            {perksByType?.length !== 0 ? (
                perksByType?.map((item) => (
                    <div key={item.id} onClick={() => navigationPerksByType(item.id)}>
                        <h1
                            style={item.id === Number(perkId) ? { borderBottomWidth: 1, borderBottomColor: whiteLabeling.avocado } : { borderBottomWidth: 1, borderBottomColor: whiteLabeling.avocado }}
                            className={`${item.id === Number(perkId) ? 'text-black text-18 font-bold' : 'text-18 text-black'} mt-2 pb-1 cursor-pointer font tracking-tight flex items-center hover:font-bold hover:ease-in hover:duration-200`}>
                            {item.id === Number(perkId) && (
                                <div className="mr-2">
                                    <RightArrow width={13} height={8} />
                                </div>
                            )}

                            {item.name} ({item.perksCount})
                        </h1>
                    </div>
                ))
            ) : (
                data.map((item) => (
                    <h1 className="text-black border-b border-b-avocado text-18 mt-2 pb-1 cursor-pointer font tracking-tight flex items-center hover:font-bold hover:ease-in hover:duration-200">
                        <Skeleton className={`w-${item?.size}/12 h-[16px] rounded-sm mt-1`} />
                    </h1>
                ))
            )}
        </div>
    )
}
