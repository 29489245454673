import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DismissIcon } from '../common/Svg'
import { Context } from '../common/BaseContext'
import { getUser } from '../../state/user/selectors'
import SecondButton from '../common/SecondButton'
import { replacePartsOfText } from '../../utils/helpers'

interface IProps {
    isShown?: boolean
    onClose: () => void
    destination: any
    nameLog?: string
}

export default function LogRouteEndPlaceInvalidModal({ isShown = true, onClose, destination, nameLog }: IProps) {
    const { screenWidth } = useContext(Context)
    const { user } = useSelector(getUser)
    const travelData = JSON.parse(localStorage.getItem('travelData') || '{}')
    const [t] = useTranslation('global')

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={onClose} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex flex-col justify-center items-center pb-[32px]">
                                    <div className="xs-mobile:w-[296px] w-[242px] flex flex-col items-center justify-center mobile:mt-[22px] mt-[24px]">
                                        <h1 className="pb-5 text-center mobile:text-22 text-18 text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                            {replacePartsOfText(t('log.log-route-end-place-invalid-error-message'), {
                                                '[travel-event-name]': user?.company?.travelEventName,
                                                '[fan-company-game]': travelData?.gameData?.place?.slice(0, travelData?.gameData?.place?.indexOf(',')),
                                                '[saved-favourite-destination]': destination?.endPlaceName?.slice(0, destination?.endPlaceName?.indexOf(',')),
                                                '[recent/favourite]': nameLog,
                                            })}
                                        </h1>
                                    </div>

                                    <SecondButton onClick={onClose} text={t('global.continue')} className="mt-6 w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200 not-heading-font" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
