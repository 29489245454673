import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DismissIcon } from './Svg'
import SecondButton from './SecondButton'
import { IChallenges } from '../../types/data'
import { Context } from './BaseContext'

interface IProps {
    isShown?: boolean
    onClose: () => void
    data: IChallenges
}

export default function LastDayToTrackModal({ isShown = true, onClose, data }: IProps) {
    const navigate = useNavigate()
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    const navigateChallengeDetail = (challengeId: number) => {
        navigate(`/lower/pledges/challenge-detail/${challengeId}`)
        onClose()
    }

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-[#ECFFFC] relative xs-mobile:w-[500px] w-11/12 pb-10 mobile:pt-4 pt-3 mobile:px-4 px-3 rounded-2xl" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={onClose} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex items-end justify-center mobile:mt-[54px] mt-[55px]" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.25))' }}>
                                    <div className="flex flex-col items-center justify-center absolute">
                                        <img className="object-contain mobile:w-[123px] mobile:h-[123px] w-[100px] h-[100px]" src={data?.activeBadgeIcon} alt="" />
                                    </div>
                                </div>

                                <div className="flex flex-col justify-center items-center">
                                    <div className="xs-mobile:w-[370px] w-[242px] flex flex-col items-center justify-center mt-[24px]">
                                        <h1 className="mobile:text-22 text-18 text-darken font-medium tracking-[-0.02rem] font">
                                            {t('global.last-day-to-track')}
                                        </h1>

                                        <h1 className="text-center mobile:text-16 text-14 mt-[2.5px] text-grey01 tracking-tight font-normal mobile:leading-[24px]">
                                            {t('global.today’s-the-last-day-of-the')} <span>{data?.challengeName}</span>. {t('global.log-your-progress-now-so-you-don’t-lose-your-plant-points')}
                                        </h1>
                                    </div>

                                    <div className="flex mt-[30px]">
                                        <SecondButton
                                            onClick={() => navigateChallengeDetail(data?.id)}
                                            text={t('global.log-progress')}
                                            className="px-[30px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio xs-mobile:mr-[24px] mr-[12px] hover:bg-spinach hover:ease-in hover:duration-200"
                                        />

                                        <button
                                            onClick={onClose}
                                            className="px-[30px] bg-mint h-[48px] font-semibold border rounded-md text-14 text-marrow hover:ease-in hover:duration-200 hover:bg-[#A7D49D]"
                                            style={{ border: '2px solid #417339' }}>
                                            {t('global.dismiss')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
