import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export default function ReferralAuthorize() {
    const navigate = useNavigate()
    const { referId } = useParams()

    useEffect(() => {
        navigate('/register', { state: { referId } })
        // eslint-disable-next-line
    }, [])

    return (
        <div />
    )
}
