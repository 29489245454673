import React from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { EditIcon, TrashIcon, TravelTreeBigEllipse } from '../common/Svg'
import { ILegs } from '../../types/data'
import LegItem from './LegItem'
import { combineLegs } from '../../utils/travel'

interface IProps {
    journeyData: any
    onDelete?: () => void
    impact?: boolean
    goingBackCommute?: boolean
    overviewLeg?: boolean
    reloadStoredData?: () => void
    containerStyle?: object
}

export default function JourneyLegs({ journeyData, onDelete, impact, goingBackCommute, overviewLeg, reloadStoredData, containerStyle }: IProps) {
    const legs = combineLegs(journeyData?.legs.sort((a: any, b: any) => a.position - b.position))
    const navigate = useNavigate()

    return (
        <div className={cn('mt-5', { 'flex justify-between': impact })} style={containerStyle}>
            <div>
                <div className="flex items-end">
                    <div>
                        <TravelTreeBigEllipse />
                    </div>

                    <div className="text-16 text-cavolo font-semibold not-heading-font ml-2.5">{journeyData?.startPlaceName}</div>
                </div>

                {legs?.map((item: ILegs, index: number) => {
                    const isLast = index + 1 === legs?.length
                    const previousEnd = index > 0 ? legs[index - 1].endPlaceName : journeyData.startPlaceName
                    const missingLegFromTop = item.startPlaceName !== previousEnd

                    return (
                        <div key={item?.backendLegId}>
                            <LegItem
                                reloadStoredData={reloadStoredData}
                                legs={legs}
                                goingBackCommute={goingBackCommute}
                                impact={impact}
                                overviewLeg={overviewLeg}
                                item={item}
                                missingLegFromTop={missingLegFromTop}
                                isLast={isLast}
                                index={index} />
                        </div>
                    )
                })}

                <div className="flex mt-[-2px]">
                    <div>
                        <TravelTreeBigEllipse />
                    </div>

                    <div className="text-16 text-cavolo font-semibold not-heading-font ml-2.5">
                        {journeyData?.endPlaceName}
                    </div>
                </div>
            </div>

            {impact && (
                <div className="flex items-center cursor-pointer">
                    <div onClick={() => navigate('/log/track-travel-overview', {
                        state: {
                            impact: true,
                            goingBackCommute,
                            goingBackFromImpact: impact,
                        },
                    })}>
                        <EditIcon />
                    </div>

                    <div onClick={onDelete}>
                        <TrashIcon />
                    </div>
                </div>
            )}
        </div>
    )
}
