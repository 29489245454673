import React, { useContext, useRef } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { DismissIcon } from './Svg'
import SecondButton from './SecondButton'
import { Context } from './BaseContext'
import intro1 from '../../static/intro1.png'
import intro2 from '../../static/intro2.png'
import intro3 from '../../static/intro3.png'
import intro4 from '../../static/intro4.png'

interface IProps {
    onClose: () => void
}

export default function IntroModal({ onClose }: IProps) {
    const carouselRef = useRef(null)
    const { screenWidth } = useContext(Context)

    const data = [
        {
            title: 'Rewarding you for tracking and lowering your carbon emissions',
            image: intro1,
            imageWidth: 160,
            imageHeight: 196,
            imageMobileWidth: 120,
            imageMobileHeight: 152,
        },
        {
            title: 'Track your carbon emissions by logging daily actions',
            image: intro2,
            imageWidth: 386,
            imageHeight: 260,
            imageMobileWidth: 295,
            imageMobileHeight: 200,
        },
        {
            title: 'Earn points as you progress along your sustainability journey',
            image: intro3,
            imageWidth: 386,
            imageHeight: 260,
            imageMobileWidth: 295,
            imageMobileHeight: 200,
        },
        {
            title: 'Points get you tickets into the monthly prize draw',
            image: intro4,
            imageWidth: 386,
            imageHeight: 275,
            imageMobileWidth: 295,
            imageMobileHeight: 200,
        },
    ]

    return (
        <div>
            <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                    <div className="bg-pistachio flex flex-col relative xs-mobile:w-[500px] w-11/12 pt-4 mobile:h-[532px] h-[426px] rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                        <div onClick={onClose} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center mobile:mx-4 mx-3">
                            <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                        </div>

                        <Carousel
                            showIndicators
                            infiniteLoop
                            interval={3000}
                            showArrows={false}
                            showStatus={false}
                            showThumbs={false}
                            ref={carouselRef}
                            className="flex h-full">
                            {data.map((i, index) => (
                                <div className="h-full flex flex-col justify-between items-center h-[390px] mobile:h-[480px]">
                                    <img src={i.image} style={{ width: screenWidth < 768 ? i.imageMobileWidth : i.imageWidth, height: screenWidth < 768 ? i.imageMobileHeight : i.imageHeight }} />

                                    <div className="flex flex-col justify-center items-center pb-10">
                                        <div className="xs-mobile:w-[296px] w-[242px] flex flex-col items-center justify-center">
                                            <h1 className="text-center text-18 text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                                {i.title}
                                            </h1>
                                        </div>

                                        <SecondButton onClick={() => {
                                            if (index + 1 !== data.length) {
                                                // @ts-ignore
                                                if (typeof carouselRef.current?.moveTo === 'function') carouselRef.current?.moveTo(index + 1)
                                                return
                                            }

                                            onClose()
                                        }} text="Continue" className="mt-6 w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}
