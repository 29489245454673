import axios, { AxiosRequestConfig } from 'axios'
import humps from 'humps'
import { store } from './index'

export const baseUrl = process.env.REACT_APP_BASE_URL || 'https://cms.dev.lowr.co'
// export const baseUrl = process.env.REACT_APP_BASE_URL || 'https://cms.lowr.co'
// export const baseUrl = process.env.REACT_APP_BASE_URL || 'http://127.0.0.1:8000'

const apiClient = axios.create({
    baseURL: `${baseUrl}/api/v1/`,
    // @ts-ignore
    transformResponse: [...axios.defaults.transformResponse, humps.camelizeKeys],
    // @ts-ignore
    transformRequest: [decamelize, ...axios.defaults.transformRequest],
})

apiClient.interceptors.request.use((config) => ({
    ...config,

    params: humps.decamelizeKeys({
        ...config.params,
        embedded_domain: window.location.ancestorOrigins[0],
    }),
}))

apiClient.interceptors.request.use((req: AxiosRequestConfig): any => {
    const token = store.getState().user?.token?.token

    if (req.headers === undefined) {
        req.headers = {}
    }

    if (token) {
        req.headers.authorization = `Token ${token}`
    }

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : null

    if (languageData) {
        req.headers['Accept-Language'] = languageData
    }

    return req
})

apiClient.interceptors.response.use(
    (response) => response,

    (error) => {
        const errorData = error.response?.data

        if (errorData?.logout === 'true') {
            localStorage.clear()

            if (errorData.ssoLogout) {
                window.location.href = '/sso-login?utm_source=watford'
                return Promise.reject(error)
            }

            window.location.href = '/login'

            return Promise.reject(error)
        }

        return Promise.reject(error)
    },
)

// eslint-disable-next-line consistent-return
function decamelize(object: any) {
    if (!(object && !(object instanceof File))) {
        return object
    }

    if (object instanceof FormData) {
        return object
    }

    if (typeof object === 'object') {
        return humps.decamelizeKeys(object)
    }
}

export default apiClient
