import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import uniqueBy from 'lodash/uniqBy'
import { RecipeState } from './types'
import { CLEAR_RECIPE_DETAIL, FETCH_RECIPE_LIST_ERROR,
    FETCH_RECIPE_START,
    GET_RECIPE_BY_CATEGORY_LIST,
    GET_RECIPE_CATEGORY_LIST,
    GET_RECIPE_COMMENTS_SUCCESS,
    GET_RECIPE_DETAIL_SUCCESS,
    GET_RECIPE_HOME_PAGE_LIST,
    GET_RECIPE_SEARCH_LIST,
    GET_RECIPE_TAGS_LIST,
    GET_RECIPE_USER_BOOKMARKED_LIST, GET_RECIPES_BY_CATEGORY_SUCCESS, GET_RECIPES_BY_TAGS_SUCCESS,
    POST_BOOKMARK_SUCCESS, POST_RECIPE_COMMENT_SUCCESS,
    POST_RECIPE_RATE_SUCCESS,
    GET_RECIPE_SEARCH_COLLECTION_LIST, CLEAR_RECIPE_SEARCH_LIST, FETCH_RECIPE_SEARCH_START } from './consts'

const initialState: RecipeState = {
    recipe: [],
    myBookmarks: { count: 0, results: [] },
    count: 0,
    lastPage: 1,
    next: null,
    previous: null,
    loading: false,
    recipeCategories: [],
    recipesByTags: [],
    recipeTags: [],
    recipeSearchResults: [],
    recipeDetail: null,
    recipeCommentList: { count: 0, results: [] },
    byCategory: [],
    recipesListByCategory: [],
    searchCollection: [],
    // @ts-ignore
    recipeHomePage: {},
    searchLoading: true,
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AnyAction): RecipeState => {
    const { payload } = action

    switch (action.type) {
    case FETCH_RECIPE_START:
        return {
            ...state,
            loading: true,
        }
    case FETCH_RECIPE_SEARCH_START:
        return {
            ...state,
            searchLoading: true,
        }
    case GET_RECIPE_USER_BOOKMARKED_LIST:

        return {
            ...state,
            // eslint-disable-next-line no-unsafe-optional-chaining
            myBookmarks: { ...payload, results: uniqueBy([...state?.myBookmarks?.results, ...payload?.results], (e) => e.id) },
            loading: false,
        }

    case GET_RECIPE_DETAIL_SUCCESS:
        return {
            ...state,
            recipeDetail: payload,
            loading: false,
        }

    case GET_RECIPE_HOME_PAGE_LIST:
        return {
            ...state,
            recipeHomePage: payload,
            loading: false,
        }

    case GET_RECIPE_CATEGORY_LIST:
        return {
            ...state,
            recipeCategories: payload,
            loading: false,
        }

    case GET_RECIPE_TAGS_LIST:
        return {
            ...state,
            recipeTags: payload,
            loading: false,
        }

    case GET_RECIPE_COMMENTS_SUCCESS:
        return {
            ...state,
            recipeCommentList: { ...payload, results: uniqueBy([...state.recipeCommentList.results, ...payload.results], (e) => e.id) },
            loading: false,
        }

    case CLEAR_RECIPE_DETAIL:
        return {
            ...state,
            recipeCommentList: { count: 0, results: [] },
            recipeDetail: null,
            loading: false,
        }

    case POST_RECIPE_RATE_SUCCESS:
        const newRateRecipeWeLove = state?.recipeHomePage?.recipeWeLove?.id === payload?.response?.recipe
            ? {
                ...state?.recipeHomePage?.recipeWeLove,
                isUserRated: payload?.response?.rate,
            }
            : state?.recipeHomePage?.recipeWeLove
        const newRateSlotOne = state?.recipeHomePage?.slotOne?.recipe?.map((item) => (item?.id === payload?.response?.recipe
            ? {
                ...item,
                isUserRated: payload?.response?.rate,
            } : item))
        const newRateSlotTwo = state?.recipeHomePage?.slotTwo?.recipe?.map((item) => (item?.id === payload?.response?.recipe
            ? {
                ...item,
                isUserRated: payload?.response?.rate,
            } : item))
        const newRateSlotThree = state?.recipeHomePage?.slotThree?.recipe?.map((item) => (item?.id === payload?.response?.recipe
            ? {
                ...item,
                isUserRated: payload?.response?.rate,
            }
            : item))
        const newRateSlotFour = state?.recipeHomePage?.slotFour?.recipe?.map((item) => (item?.id === payload?.response?.recipe
            ? {
                ...item,
                isUserRated: payload?.response?.rate,
            }
            : item))
        const newRateSearch = state?.recipeSearchResults?.map((item) => (item?.id === payload?.response?.recipe
            ? {
                ...item,
                isUserRated: payload?.response?.rate,
            }
            : item))
        // const newRateByTags = state?.recipeByTags.map((item) => (item.id === payload.response.recipe ? { ...item, isUserRated: payload.response.rate } : item))
        const newRateMyBookmark = state?.myBookmarks?.results?.map((item) => (item?.id === payload?.response?.recipe
            ? {
                ...item,
                isUserRated: payload?.response?.rate,
            }
            : item))
        return {
            ...state,
            recipeSearchResults: newRateSearch,
            myBookmarks: { count: state.myBookmarks?.count, results: newRateMyBookmark },
            recipeHomePage: {
                ...state?.recipeHomePage,
                recipeWeLove: newRateRecipeWeLove,
                slotOne: { ...state?.recipeHomePage?.slotOne, recipe: newRateSlotOne },
                slotTwo: { ...state?.recipeHomePage?.slotTwo, recipe: newRateSlotTwo },
                slotThree: { ...state?.recipeHomePage?.slotThree, recipe: newRateSlotThree },
                slotFour: { ...state?.recipeHomePage?.slotFour, recipe: newRateSlotFour },
            },
            loading: false,
        }

    case POST_BOOKMARK_SUCCESS:
        const newBookmarkedRecipe = state?.myBookmarks?.results?.map((item) => (item.id === payload.bookmarkedId ? {
            ...item,
            isBookmark: payload.isBookmark,
        } : item))

        const newBookmarkedRecipeCategory = state?.recipesListByCategory?.map((item) => (item.id === payload.bookmarkedId ? {
            ...item,
            isBookmark: payload.isBookmark,
        } : item))

        const newBookmarkRecipeWeLove = state.recipeHomePage?.recipeWeLove?.id === payload.bookmarkedId ? {
            ...state.recipeHomePage.recipeWeLove,
            isBookmark: payload.isBookmark,
        } : state.recipeHomePage.recipeWeLove

        const newBookmarkSlotOne = state.recipeHomePage?.slotOne?.recipe?.map((item) => (item.id === payload.bookmarkedId ? {
            ...item,
            isBookmark: payload.isBookmark,
        } : item))

        const newBookmarkSlotTwo = state.recipeHomePage?.slotTwo?.recipe?.map((item) => (item.id === payload.bookmarkedId ? {
            ...item,
            isBookmark: payload.isBookmark,
        } : item))

        const newBookmarkSlotThree = state.recipeHomePage?.slotThree?.recipe?.map((item) => (item.id === payload.bookmarkedId ? {
            ...item,
            isBookmark: payload.isBookmark,
        } : item))

        const newBookmarkSlotFour = state.recipeHomePage?.slotFour?.recipe?.map((item) => (item.id === payload.bookmarkedId ? {
            ...item,
            isBookmark: payload.isBookmark,
        } : item))

        const newBookmarkByCategory = state.byCategory?.map((item) => (item.id === payload.bookmarkedId ? {
            ...item,
            isBookmark: payload.isBookmark,
        } : item))

        const newRecipeDetail = state.recipeDetail && state.recipeDetail.id === payload.bookmarkedId ? {
            ...state.recipeDetail,
            isBookmark: payload.isBookmark,
        } : state.recipeDetail
        const newRecipeBookmarkSearch = state?.recipeSearchResults?.map((item) => (item?.id === payload?.bookmarkedId
            ? {
                ...item,
                isBookmark: payload?.isBookmark,
            }
            : item))

        return {
            ...state,
            myBookmarks: { count: state.myBookmarks?.count, results: newBookmarkedRecipe },
            byCategory: newBookmarkByCategory,
            recipesListByCategory: newBookmarkedRecipeCategory,
            recipeSearchResults: newRecipeBookmarkSearch,
            recipeHomePage: {
                ...state.recipeHomePage,
                recipeWeLove: newBookmarkRecipeWeLove,
                slotOne: { ...state.recipeHomePage.slotOne, recipe: newBookmarkSlotOne },
                slotTwo: { ...state.recipeHomePage.slotTwo, recipe: newBookmarkSlotTwo },
                slotThree: { ...state.recipeHomePage.slotThree, recipe: newBookmarkSlotThree },
                slotFour: { ...state.recipeHomePage.slotFour, recipe: newBookmarkSlotFour },
            },
            loading: false,
            recipeDetail: newRecipeDetail,
        }

    case POST_RECIPE_COMMENT_SUCCESS:
        const newByCategory = state?.byCategory?.map((item) => (item?.id === payload?.response?.recipe ? { ...item, hasCommented: true } : item))

        const newCommentRecipeWeLove = state?.recipeHomePage?.recipeWeLove?.id === payload?.response?.recipe ? {
            ...state.recipeHomePage?.recipeWeLove,
            hasCommented: true,
        } : state?.recipeHomePage?.recipeWeLove

        const newCommentSlotOne = state?.recipeHomePage?.slotOne?.recipe?.map((item) => (item?.id === payload?.response?.recipe ? {
            ...item,
            hasCommented: true,
        } : item))

        const newCommentSlotTwo = state?.recipeHomePage?.slotTwo?.recipe?.map((item) => (item?.id === payload?.response?.recipe ? {
            ...item,
            hasCommented: true,
        } : item))

        const newCommentSlotThree = state?.recipeHomePage?.slotThree?.recipe?.map((item) => (item?.id === payload?.response?.recipe ? {
            ...item,
            hasCommented: true,
        } : item))

        const newCommentSlotFour = state?.recipeHomePage?.slotFour?.recipe?.map((item) => (item?.id === payload?.response?.recipe ? {
            ...item,
            hasCommented: true,
        } : item))

        const newCommentSearch = state?.recipeSearchResults?.map((item) => (item?.id === payload?.response?.recipe ? {
            ...item,
            hasCommented: true,
        } : item))

        const newCommentMyBookmark = state?.myBookmarks?.results?.map((item) => (item?.id === payload?.response?.recipe ? {
            ...item,
            hasCommented: true,
        } : item))

        const newRecipeDetailComment = state.recipeDetail && state.recipeDetail.id === payload.response?.recipe ? {
            ...state.recipeDetail,
            hasCommented: true,
        } : state.recipeDetail
        return {
            ...state,
            byCategory: newByCategory,
            recipeSearchResults: newCommentSearch,
            myBookmarks: { count: state.myBookmarks?.count, results: newCommentMyBookmark },
            recipeHomePage: {
                ...state?.recipeHomePage,
                recipeWeLove: newCommentRecipeWeLove,
                slotOne: { ...state?.recipeHomePage?.slotOne, recipe: newCommentSlotOne },
                slotTwo: { ...state?.recipeHomePage?.slotTwo, recipe: newCommentSlotTwo },
                slotThree: { ...state?.recipeHomePage?.slotThree, recipe: newCommentSlotThree },
                slotFour: { ...state?.recipeHomePage?.slotFour, recipe: newCommentSlotFour },
            },
            recipeDetail: newRecipeDetailComment,
            loading: false,
        }

    case GET_RECIPE_SEARCH_LIST:
        return {
            ...state,
            recipeSearchResults: payload,
            searchLoading: false,
        }

    case CLEAR_RECIPE_SEARCH_LIST:
        return {
            ...state,
            recipeSearchResults: [],
        }

    case GET_RECIPE_BY_CATEGORY_LIST:
        return {
            ...state,
            byCategory: payload,
            loading: false,
        }

    case GET_RECIPES_BY_CATEGORY_SUCCESS:
        return {
            ...state,
            recipesListByCategory: payload,
            loading: false,
        }

    case GET_RECIPES_BY_TAGS_SUCCESS:
        return {
            ...state,
            recipesByTags: payload,
            loading: false,
        }

    case GET_RECIPE_SEARCH_COLLECTION_LIST:
        return {
            ...state,
            searchCollection: payload,
            loading: false,
        }

    case FETCH_RECIPE_LIST_ERROR:
        return { ...state, loading: false }
    default:
        return state
    }
}

const persistConfig: PersistConfig<RecipeState> = {
    key: 'recipe',
    storage,
    whitelist: ['recipe', 'recipeCategories'],
}

export default persistReducer(persistConfig, reducer)
