import { Dispatch } from 'redux'

import { getPrizeDraw, getPrizeWheelList, getWinnersPrice, updateWinnerPrice } from './api'
import { FETCH_PRIZE_DRAW_SUCCESS, FETCH_PRIZE_ERROR,
    FETCH_PRIZE_START,
    FETCH_WINNERS_PRICE_SUCCESS,
    FETCH_WINNERS_PRICE_UPDATE_SUCCESS,
    GET_PRIZE_WHEEL_TYPE_LIST } from './consts'
import { IMonthlyData, IPrizeWheelType, IWinnersPrice, IWinnersPriceUpdate } from '../../types/data'
import { updateUserPoint } from '../user/actions'

const fetchPrizeStart = () => ({
    type: FETCH_PRIZE_START,
})

export const fetchWinnersPriceSuccess = (response: IWinnersPrice) => ({
    type: FETCH_WINNERS_PRICE_SUCCESS,
    payload: response,
})

export const fetchUpdateWinnersPrice = (winnersPrice: IWinnersPriceUpdate) => ({
    type: FETCH_WINNERS_PRICE_UPDATE_SUCCESS,
    payload: winnersPrice,
})

export const fetchPrizeDrawSuccess = (response: IMonthlyData) => ({
    type: FETCH_PRIZE_DRAW_SUCCESS,
    payload: response,
})

export const fetchPrizeWheelTypeSuccess = (response: IPrizeWheelType) => ({
    type: GET_PRIZE_WHEEL_TYPE_LIST,
    payload: response,
})

const fetchEnd = () => ({
    type: FETCH_PRIZE_ERROR,
})

/**
 * Fetch winners price
 * @returns
 */

export const fetchWinnersPrice = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPrizeStart())
        const response = await getWinnersPrice()

        if (response) {
            dispatch(fetchWinnersPriceSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchWinnersPrice: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Winner price update
 * @returns
 */

export const updatePrize = (data: IWinnersPriceUpdate) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPrizeStart())
        const response = await updateWinnerPrice(data)

        if (response) {
            dispatch(fetchUpdateWinnersPrice(response))
            dispatch(updateUserPoint(response.thisMonthPoints))
        }
    } catch (e) {
        console.log('ERROR, updatePrize: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch prize draw
 * @returns
 */

export const fetchPrizeDraw = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPrizeStart())

        const response = await getPrizeDraw()

        if (response) {
            dispatch(fetchPrizeDrawSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPrizeDraw: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch prize wheel list
 * @returns
 */
export const fetchPrizeWheelList = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPrizeStart())
        const response = await getPrizeWheelList()

        if (response) {
            dispatch(fetchPrizeWheelTypeSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPrizeWheelList: ', e)
        dispatch(fetchEnd())
    }
}
