import { Dispatch } from 'redux'
import { IChallengeLogDayData,
    IChallengeLoggedDay, IChallengePledgesData,
    IChallengeResponse,
    IChallenges, ICommitPledgeResponse, ILastDayToTrackChallenge, IMyPledgesData,
    IRemindMeNotificationPost,
    IRemindMeNotificationResponse,
    IRemindMeResponse } from '../../types/data'
import { DELETE_CHALLENGE_REMIND_ON_START_SUCCESS,
    FETCH_CHALLENGE_ERROR,
    FETCH_CHALLENGE_LIST_SUCCESS,
    FETCH_CHALLENGE_LOGGED_DAYS_SUCCESS,
    FETCH_CHALLENGE_START,
    FETCH_LAST_DAY_TO_TRACK_LIST_SUCCESS,
    FETCH_MY_PLEDGES_LIST_SUCCESS,
    FETCH_PLEDGE_DETAIL_SUCCESS,
    FETCH_PLEDGES_LIST_SUCCESS,
    POST_CHALLENGE_JOIN_SUCCESS,
    POST_CHALLENGE_LOG_DAY_SUCCESS,
    POST_CHALLENGE_REMIND_ON_START_SUCCESS,
    POST_COMMIT_PLEDGE_SUCCESS,
    POST_REMIND_ME_NOTIFICATION_SUCCESS } from './consts'
import { deleteChallengeRemindOnStart,
    getChallengeItem,
    getChallengeLoggedDays, getLastDayToTrackItem, getMyPledgesItem, getPledgeDetail, getPledgesItem,
    postChallengeJoinItem, postChallengeLogDayItem,
    postChallengeRemindOnStartItem, postCommitPledgeItem,
    postRemindMeNotificationItem } from './api'
import { POST_CHALLENGE_JOIN_HOME_SUCCESS, POST_CHALLENGE_LOG_DAY_HOME_SUCCESS } from '../home/consts'

const fetchChallengeStart = () => ({
    type: FETCH_CHALLENGE_START,
})

export const fetchChallengeSuccess = (response: IChallenges) => ({
    type: FETCH_CHALLENGE_LIST_SUCCESS,
    payload: response,
})

export const fetchPledgeDetailSuccess = (response: IChallenges) => ({
    type: FETCH_PLEDGE_DETAIL_SUCCESS,
    payload: response,
})

export const postChallengeJoinSuccess = (response: IChallengeResponse) => ({
    type: POST_CHALLENGE_JOIN_SUCCESS,
    payload: response,
})

export const postRemindMeNotificationSuccess = (response: IRemindMeNotificationResponse) => ({
    type: POST_REMIND_ME_NOTIFICATION_SUCCESS,
    payload: response,
})

export const postChallengeRemindOnStartSuccess = (response: IRemindMeResponse) => ({
    type: POST_CHALLENGE_REMIND_ON_START_SUCCESS,
    payload: response,
})

export const deleteChallengeRemindOnStartSuccess = (challengeId: string | number | undefined) => ({
    type: DELETE_CHALLENGE_REMIND_ON_START_SUCCESS,
    payload: challengeId,
})

export const postChallengeJoinHomeSuccess = (response: IChallengeResponse) => ({
    type: POST_CHALLENGE_JOIN_HOME_SUCCESS,
    payload: response,
})

const fetchEnd = () => ({
    type: FETCH_CHALLENGE_ERROR,
})

export const fetchChallengeLogDaySuccess = (response: IChallengeLoggedDay) => ({
    type: FETCH_CHALLENGE_LOGGED_DAYS_SUCCESS,
    payload: response,
})

export const postChallengeLogDaySuccess = (response: IChallengeLoggedDay) => ({
    type: POST_CHALLENGE_LOG_DAY_SUCCESS,
    payload: response,
})

export const postChallengeLogDayHomeSuccess = (response: IChallengeLoggedDay) => ({
    type: POST_CHALLENGE_LOG_DAY_HOME_SUCCESS,
    payload: response,
})

export const fetchLastDayToTrackSuccess = (response: ILastDayToTrackChallenge) => ({
    type: FETCH_LAST_DAY_TO_TRACK_LIST_SUCCESS,
    payload: response,
})

export const fetchMyPledgesSuccess = (response: IMyPledgesData) => ({
    type: FETCH_MY_PLEDGES_LIST_SUCCESS,
    payload: response,
})

export const fetchPledgesSuccess = (response: IChallengePledgesData) => ({
    type: FETCH_PLEDGES_LIST_SUCCESS,
    payload: response,
})

export const postCommitPledgeSuccess = (response: ICommitPledgeResponse) => ({
    type: POST_COMMIT_PLEDGE_SUCCESS,
    payload: response,
})

/**
 * Fetch challenges list
 * @returns
 */

export const fetchChallengeList = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await getChallengeItem()

        if (response) {
            dispatch(fetchChallengeSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchChallengeList: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchPledgeDetail = (pledgeId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await getPledgeDetail(pledgeId)

        if (response) {
            dispatch(fetchPledgeDetailSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchChallengeList: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post challenge join
 * @returns
 */

export const postChallengeJoin = (challenge: number | undefined) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await postChallengeJoinItem(challenge)

        if (response) {
            dispatch(postChallengeJoinSuccess(response))
            dispatch(postChallengeJoinHomeSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, postChallengeJoin: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post challenge remind me notification
 * @returns
 */

export const postRemindMeNotification = (data: IRemindMeNotificationPost) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await postRemindMeNotificationItem(data)

        if (response) {
            dispatch(postRemindMeNotificationSuccess(response))
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
    }
}

/**
 * post challenge remind on start
 * @returns
 */

export const postChallengeRemindOnStart = (challenge: number | undefined) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await postChallengeRemindOnStartItem(challenge)

        if (response) {
            dispatch(postChallengeRemindOnStartSuccess(response))
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
    }
}

/**
 * delete challenge remind on start
 * @returns
 */
export const removeChallengeRemindOnStart = (challengeId: number | string | undefined) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())

        await deleteChallengeRemindOnStart(challengeId)

        dispatch(deleteChallengeRemindOnStartSuccess(challengeId))

        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
    }
}

export const fetchChallengeLoggedDaysList = (challenge: number | string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await getChallengeLoggedDays(challenge)

        if (response) {
            dispatch(fetchChallengeLogDaySuccess(response))
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
    }
}

export const postChallengeLogDay = (
    data: IChallengeLogDayData,
    onSuccess?: (response: IChallengeLoggedDay) => void,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await postChallengeLogDayItem(data)

        if (response) {
            dispatch(postChallengeLogDaySuccess(response))
            dispatch(postChallengeLogDayHomeSuccess(response))
            if (typeof onSuccess === 'function') onSuccess(response)
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
    }
}

/**
 * Fetch challenges list
 * @returns
 */

export const fetchLastDayToTrack = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await getLastDayToTrackItem()

        if (response) {
            dispatch(fetchLastDayToTrackSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchChallengeList: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch my pledges list
 * @returns
 */

export const fetchMyPledges = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await getMyPledgesItem()

        if (response) {
            dispatch(fetchMyPledgesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchMyPledges: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch pledges list
 * @returns
 */

export const fetchPledges = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await getPledgesItem()

        if (response) {
            dispatch(fetchPledgesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchMyPledges: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post challenge remind on start
 * @returns
 */

export const postCommitPledge = (pledge: number | undefined, onSuccess: () => void, setError?: (
        value: ((prevState: { message: string }) => { message: string }) | { message: string },
    ) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchChallengeStart())
        const response = await postCommitPledgeItem(pledge)

        if (response) {
            dispatch(postCommitPledgeSuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('ERROR, postCommitPledge: ', e)
        dispatch(fetchEnd())

        if (setError) {
            // @ts-ignore
            setError(e?.response?.data)
        }
    }
}
