import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ICompanyArticle } from '../../../types/data'
import Skeleton from '../../common/Skeleton'
import { getUser } from '../../../state/user/selectors'
import { getDatesLocalization } from '../../../utils/date'
import ContentBlockTitle from '../../ContentBlockTitle'

interface IProps {
    item: ICompanyArticle
}

export default function CompanyCardMedium({ item }: IProps) {
    const navigate = useNavigate()
    const [t] = useTranslation('global')
    const { user } = useSelector(getUser)
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    const [formattedDate, setFormattedDate] = useState<string | undefined>(undefined)

    useEffect(() => {
        const fetchData = async () => {
            if (item?.createdAt) {
                const formatted = await getDatesLocalization(item?.createdAt, languageData)
                setFormattedDate(formatted)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [item?.createdAt])

    let extractedNumber: number | null = null

    if (typeof item?.getReadTime === 'string') {
        const matchResult = item?.getReadTime.match(/\d+/)
        if (matchResult) {
            extractedNumber = parseInt(matchResult[0], 10)
        }
    }

    return (
        <div className="cursor-pointer relative">
            <div onClick={() => navigate(`/learn/organisation-news/${item.id}`)}>
                {loading && <Skeleton className="rounded-lg mobile:h-[169px] medium:h-[210px] laptop:w-[481px] laptop:h-[271px] w-full tablet:h-[223px] object-cover" />}
                <img onLoad={handleImageLoad} src={item?.image} className={`${loading ? 'hidden' : 'block'} rounded-lg mobile:h-[169px] medium:h-[210px] laptop:w-[481px] laptop:h-[271px] w-full tablet:h-[223px] object-cover`} alt="" />

                <div className="flex flex-col justify-between bg-white">
                    <h5 className="text-12 font-light my-2 text-grey01">
                        {loading ? (
                            <Skeleton className="w-4/12 h-[8px] rounded-sm mt-2" />
                        ) : (
                            <div>
                                {user?.company?.isDateStampShown && formattedDate} {user?.company?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                            </div>
                        )}

                    </h5>

                    <h1 className="mt-0.5 font-medium font heading-font tracking-tight text-black text-18">
                        {loading ? (
                            <Skeleton className="w-8/12 h-[12px] rounded-sm" />
                        ) : (
                            <ContentBlockTitle text={item?.title} />
                        )}
                    </h1>

                    <h2 className="mt-1 tracking-[-.01em] leading-[150%] text-grey01 text-14">
                        {loading ? (
                            <>
                                <Skeleton className="w-11/12 h-[12px] rounded-sm mt-1" />
                                <Skeleton className="w-6/12 h-[12px] rounded-sm mt-2" />
                            </>
                        ) : (
                            item?.subTitle
                        )}
                    </h2>
                </div>
            </div>
        </div>
    )
}
