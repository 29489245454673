import React from 'react'
import cn from 'classnames'

interface IProps {
    className?: string
    style?: object
}

export default function Skeleton({ className, style }: IProps) {
    return (
        <div style={style} data-placeholder="" className={cn('overflow-hidden relative bg-gray-200', className)} />
    )
}
