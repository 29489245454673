import React, { useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { Colors } from '../themes'
import { TicketIconActive, TicketIconActiveProgress, TicketIconDefault, TicketIconDefaultProgress } from './common/Svg'
import { getUser } from '../state/user/selectors'
import { getPrizeDraw } from '../state/prize/selectors'

interface IProps {
    containerStyles?: object
    progress?: boolean
}

export default function ProgressBar({ containerStyles = {}, progress } : IProps) {
    const { user } = useSelector(getUser)
    const { prizeDraw } = useSelector(getPrizeDraw)

    const pointsArray = prizeDraw?.pointsTargets?.map((item) => Number(item.points)) || []
    const maxPrizeNum = Math.max(...pointsArray)

    const currentPoints = user?.thisMonthPoints
    const maxPoints = maxPrizeNum
    const prizes = prizeDraw?.pointsTargets?.map((item) => item?.points) || []
    const filledPercent = ((currentPoints < maxPoints ? currentPoints : maxPoints) * 100) / maxPoints
    // const unFilledPercent = 100 - filledPercent

    const progressWidthRef = useRef<HTMLDivElement | null>()

    // const { width: progressWidth } = useResize({
    //     changedWidth: () => progressWidthRef?.current?.offsetWidth || 0,
    //     changedHeight: () => progressWidthRef?.current?.offsetHeight || 0,
    // })

    return (
        <div className="relative pr-5" style={{ margin: '24px auto 35px auto', ...containerStyles }}>
            {/* <div style={{ marginLeft: (progressWidth * filledPercent) / 100 < 36 ? 0 : (progressWidth * unFilledPercent) / 100 < 36 ? 'calc(100% - 80px)' : `${filledPercent}%` }} className="w-max"> */}
            {/*    <div style={{ display: 'inline-block', transform: `translateX(${(progressWidth * filledPercent) / 100 < 36 || (progressWidth * unFilledPercent) / 100 < 36 ? 0 : '-50%'})` }} className=""> */}
            {/*        <div className={cn(css(styles.talkBubbleSquare), 'items-center justify-center flex z-20 font-medium')}> */}
            {/*            <PointsIcon /> */}
            {/*            <p className="ml-1 font-medium font">{currentPoints} pts</p> */}
            {/*        </div> */}
            {/*    </div> */}
            {/* </div> */}

            {/* <TriangleIcon style={{ marginTop: -1, left: `calc(min(max(${filledPercent}%, 6px), 100% - 13px))`, transform: `translateX(${(progressWidth * filledPercent) / 100 < 5 ? 0 : -5}px)` }} className="z-10 relative" /> */}

            {/* <div style={{ paddingTop: 5 }} /> */}

            <div className="rounded-full h-5 overflow-hidden" ref={(ref) => {
                if (!progressWidthRef.current) progressWidthRef.current = ref
            }}>
                <div className="z-10 relative flex rounded-r-sm rounded-l-xl bg-raspberry h-[20px]" style={{ width: `${filledPercent}%` }} />
                <div className={cn('w-full relative z-0 rounded-full bg-mint h-[16px]', css(styles.unfilledBar))} />
            </div>

            <div className="relative flex">
                {prizes.map((item) => {
                    const positionPercent = (item * 100) / maxPoints
                    const icon = progress ? (item <= currentPoints && item === maxPoints ? <TicketIconActiveProgress style={{ display: 'initial' }} /> : <TicketIconDefaultProgress style={{ display: 'initial' }} />) : (item <= currentPoints && item === maxPoints ? <TicketIconActive style={{ display: 'initial' }} /> : <TicketIconDefault style={{ display: 'initial' }} />)

                    return (
                        <div key={item} className="absolute flex items-center flex-col z-50" style={{ left: `${positionPercent}%`, marginTop: -28, transform: 'translateX(-50%)' }}>
                            <p className="relative z-50 m-auto">
                                {item <= currentPoints && item !== maxPoints
                                    ? progress ? <TicketIconActiveProgress style={{ display: 'initial' }} /> : <TicketIconActive style={{ display: 'initial' }} /> : icon}
                            </p>

                            <span className={`not-heading-font font-bold absolute ${progress ? 'top-[45px]' : 'top-[40px]'} text-cavolo text-13 leading-[150%] tracking-[-0.02rem]`}>
                                {item}
                            </span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    unfilledBar: {
        marginTop: -18,
    },
    talkBubbleSquare: {
        height: 25,
        backgroundColor: Colors.spinach,
        borderRadius: 4,
        color: '#FFFFFF',
        fontSize: 14,
        paddingLeft: 8,
        paddingRight: 8,
    },
})
