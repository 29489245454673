import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { LeftArrow, SavedIconActive, SavedIconDefault } from '../../components/common/Svg'
import { articles } from '../../state/articles/selectors'
import { articleBookMark,
    clearArticleDetail,
    fetchArticleById,
    fetchArticleCategoryList, fetchArticleListByCategory } from '../../state/articles/actions'
import { getDatesLocalization } from '../../utils/date'
import '../../static/radio.css'
import ArticleDetailQuizz from '../../components/article/ArticleDetailQuizz'
import { DetailBottomSlider } from '../../components/article/DetailBottomSlider'
import PointModal from '../../components/common/PointModal'
import { Context } from '../../components/common/BaseContext'
import Skeleton from '../../components/common/Skeleton'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import SponsoredByBlock from '../../components/SponsoredByBlock'

interface IProps{
    articlesId?: number | undefined
    modal?: boolean
}

export default function ArticleDetail({ articlesId, modal }: IProps) {
    const { category, articleById, loadingArticleById, byCategoryList } = useSelector(articles)
    const [t] = useTranslation('global')
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const dispatch = useDispatch()
    const prevArticleByIdRef = useRef<string | undefined>(undefined)
    const { articleId, topicId } = useParams()
    const prevArticleIdRef = useRef(articleId)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const categories = articleById?.category?.map((item) => item.id).join(',') || 'none'
    const [showModalPoint, setShowModalPoint] = useState<boolean | undefined>(false)
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)
    const { screenWidth } = useContext(Context)

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    const [formattedDate, setFormattedDate] = useState<string | undefined>(undefined)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Article detail' })
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            if (articleById?.createdAt) {
                const formatted = await getDatesLocalization(articleById?.createdAt, languageData)
                setFormattedDate(formatted)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [articleById?.createdAt])

    const onRestartLoad = useCallback(async () => {
        await dispatch(fetchArticleCategoryList)

        if (articleId || articlesId) {
            dispatch(fetchArticleById(Number(articleId || articlesId)))
        }
    }, [dispatch, articleId, articlesId])

    const onPressBookmark = async (id: number | undefined) => {
        await dispatch(articleBookMark(id))
    }

    const navigationArticleByTopic = (articleCategoryId: number) => {
        navigate(`/learn/articles/browse-by-topic-list/${articleCategoryId}`)
    }

    const navigateDiscover = () => {
        navigate('/learn/articles')
    }

    const categoryName = useMemo(() => category.find((item) => item.id === Number(topicId)), [category, topicId])

    const fetchCategory = useCallback(async () => {
        if (categories !== undefined) {
            await dispatch(fetchArticleListByCategory(categories))
        }
    }, [categories, dispatch])

    useEffect(() => {
        dispatch(clearArticleDetail())
        onRestartLoad()

        return () => {
            dispatch(clearArticleDetail())
        }

        // eslint-disable-next-line
    }, [articleId, articlesId])

    useEffect(() => {
        fetchCategory()
    }, [fetchCategory])

    useEffect(() => {
        if ((articleId || articlesId) !== prevArticleIdRef.current) {
            dispatch(fetchArticleById(Number(articleId || articlesId)))
        }
        prevArticleIdRef.current = articleId
    }, [articleId, dispatch, articlesId])

    useLayoutEffect(() => {
        if ((articleId || articlesId) !== prevArticleByIdRef.current) {
            if (!articlesId) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            }

            prevArticleByIdRef.current = articleId
        }
    }, [articleId, articlesId])

    let extractedNumber: number | null = null

    if (typeof articleById?.getReadTime === 'string') {
        const matchResult = articleById?.getReadTime.match(/\d+/)
        if (matchResult) {
            extractedNumber = parseInt(matchResult[0], 10)
        }
    }

    if (!articleById.id && loadingArticleById === false) return <Layout><div className="p-4 min-h-screen">Article not found</div></Layout>

    return (
        <Layout showFooter={modal} showNavbar={modal} showSustainabilityMenu={modal}>
            <div className="text-black min-h-screen w-full">
                {screenWidth < 912 ? (
                    <div className="flex flex-col items-center relative">
                        {loading && <Skeleton className="object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]" />}
                        <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]`} src={articleById?.image} alt="any" />

                        <div className="mt-[-10%] tablet911:w-[476px] w-11/12 flex flex-col tablet911:relative bg-pistachio items-center text-center tablet911:h-[540px]">
                            {topicId ? (
                                <div className="flex flex-row gap-1 mt-[32px] tablet911:absolute tablet911:mt-[70px]">
                                    <button onClick={() => navigateDiscover()}
                                        className="text-rocket  tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                                        {t('article.title')}
                                    </button>

                                    <div className="flex mt-1 items-center">
                                        <LeftArrow height={12} width={12} />
                                    </div>

                                    <button onClick={() => navigationArticleByTopic(Number(topicId))}
                                        className="text-rocket  tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                                        {categoryName?.name}
                                    </button>
                                </div>
                            ) : (
                                <button onClick={() => navigateDiscover()}
                                    className="text-rocket mt-[32px] tablet911:absolute tablet911:mt-[70px] tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                                    {t('article.title')}
                                </button>
                            )}

                            <div className="flex px-5 mobile:mt-10 mt-[32px] tablet911:absolute medium:mt-[182px] tablet:mt-[223px] medium:mx-8 mt-0.5 flex-col justify-between">
                                <h5 className="text-14 hidden tablet911:block font-light my-2 text-grey01">
                                    {articleById?.isDateStampShown && formattedDate} {articleById?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                                </h5>

                                <h1 className="font-medium font tracking-tight text-black mobile:text-32 text-26">
                                    {loading ? (
                                        <div className="flex flex-col items-center">
                                            <Skeleton className="medium:w-[280%] w-full h-[18px] rounded-sm mt-3" />
                                            <Skeleton className="medium:w-[170%] w-full h-[18px] rounded-sm mt-4" />
                                        </div>
                                    ) : (
                                        articleById?.title
                                    )}

                                </h1>
                            </div>

                            <button onClick={() => onPressBookmark(articleById?.id)} className="rounded-full mobile:mb-[43.5px] mb-[28.5px] mobile:mt-[36.5px] mt-[28.5px] tablet911:absolute tablet911:mt-[470px] flex justify-center items-center bg-black w-[28px] h-[28px]">
                                {articleById?.isBookmark ? (
                                    <SavedIconActive width={12} height={15} />
                                ) : (
                                    <SavedIconDefault width={12} height={15} />
                                )}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="flex relative items-center tablet911:flex-row flex-col-reverse">
                        <div className="tablet911:w-[476px] w-11/12 flex -bottom-28 absolute tablet911:bottom-0 h-[280px] flex-col tablet911:relative bg-pistachio items-center text-center tablet911:h-[540px]">
                            {topicId ? (
                                <div className="flex flex-row gap-1 mt-9 tablet911:absolute tablet911:mt-[70px]">
                                    <button onClick={() => navigateDiscover()}
                                        className="text-rocket  tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                                        {t('article.title')}
                                    </button>

                                    <div className="flex mt-1 items-center">
                                        <LeftArrow height={12} width={12} />
                                    </div>

                                    <button onClick={() => navigationArticleByTopic(Number(topicId))}
                                        className="text-rocket  tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                                        {categoryName?.name}
                                    </button>
                                </div>
                            ) : (
                                <button onClick={() => navigateDiscover()} className="text-rocket mt-9 tablet911:absolute tablet911:mt-[70px] tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                                    {t('article.title')}
                                </button>
                            )}

                            <div className="flex px-5 mt-10 tablet911:absolute medium:mt-[182px] tablet:mt-[223px] medium:mx-8 mt-0.5 flex-col justify-between">
                                <h5 className="text-14 hidden tablet911:block font-light my-2 text-grey01">
                                    {articleById?.isDateStampShown && formattedDate} {articleById?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                                </h5>

                                <h1 className="font-medium font tracking-tight text-black mobile:text-32 text-26">
                                    {loading ? (
                                        <div className="flex flex-col items-center">
                                            <Skeleton className="medium:w-[280%] w-full h-[18px] rounded-sm mt-3" />
                                            <Skeleton className="medium:w-[170%] w-full h-[18px] rounded-sm mt-4" />
                                        </div>
                                    ) : (
                                        articleById?.title
                                    )}

                                </h1>
                            </div>

                            <button onClick={() => onPressBookmark(articleById?.id)} className="rounded-full mt-5 tablet911:absolute tablet911:mt-[470px] flex justify-center items-center m-3 bg-black w-[28px] h-[28px]">
                                {articleById?.isBookmark ? (
                                    <SavedIconActive width={12} height={15} />
                                ) : (
                                    <SavedIconDefault width={12} height={15} />
                                )}
                            </button>
                        </div>

                        {loading && <Skeleton className="object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]" />}
                        <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]`} src={articleById?.image} alt="any" />
                    </div>
                )}

                {articleById.sponsorshipBlock && <div className="mb-3 mobile:hidden w-11/12" style={{ margin: '0 auto' }}><SponsoredByBlock item={articleById.sponsorshipBlock} /></div>}

                <div className={`${screenWidth < 1440 && 'flex justify-center'} medium:mt-8 mobile:mt-[32px] mt-[16px]`}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <h1 className="w-[764px] rich-text laptop:ml-[476px] tablet:mx-[74px] mx-[16px]" dangerouslySetInnerHTML={{ __html: articleById?.body }} />
                </div>

                <div className="flex mb-10 mt-[84px]">
                    <div className="medium:w-[364px] tablet:w-[449px] hidden medium:flex laptop:w-[476px] items-end pl-10">
                        <div className="w-[284px]">
                            <h1 className="font tracking-tight font-medium text-18 text-rocket decoration-4 underline underline-offset-[10px] mb-5">
                                {t('article.browse-by-topic')}
                            </h1>

                            {category.map((item) => (
                                <h1 key={item.id} onClick={() => navigationArticleByTopic(item.id)}
                                    className="border-b border-b- mt-2 cursor-pointer font tracking-tight flex items-center text-black text-18"
                                    style={{ borderBottomWidth: 1, borderBottomColor: whiteLabeling?.avocado }}>
                                    {item.name}
                                </h1>
                            ))}
                        </div>
                    </div>

                    {user?.company?.gamification && (
                        <div className="laptop:w-[736px] tablet:w-[680px] medium:w-[508px] w-full medium:mx-[0px] mx-[16px]">
                            {!articleById.quizzCompleted && articleById.questions && articleById.questions.length
                                ? <ArticleDetailQuizz article={articleById} setShowModalPoint={setShowModalPoint} showModalPoint={showModalPoint} /> : ''}
                        </div>
                    )}

                </div>

                <div className="w-full bg-pistachio pt-10">
                    <DetailBottomSlider items={byCategoryList} />
                </div>

                <PointModal
                    isShown={showModalPoint}
                    onClose={() => setShowModalPoint(false)}
                    point={5}
                    showModal
                    description={t('article.just-for-answering-the-quiz-correctly,-you’ve-earned')} />
            </div>
        </Layout>
    )
}
