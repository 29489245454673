import { RootState } from '../index'

export const getUser = (state: RootState) => state.user
export const getFinalPointRewardsList = (state: RootState) => state.user
export const getSettingsList = (state: RootState) => state.user
export const getWhiteLabelingList = (state: RootState) => state.user
export const getLoginConfig = (state: RootState) => state.user
export const getMenuItems = (state: RootState) => state.user
export const getLandingPageList = (state: RootState) => state.user
export const getLanguageList = (state: RootState) => state.user
export const getOrganisationalUnitsList = (state: RootState) => state.user
export const getReferralList = (state: RootState) => state.user
export const getSavedPlacesList = (state: RootState) => state.user
export const getActivitiesList = (state: RootState) => state.user
