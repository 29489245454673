import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import ChallengeLeftSidebar from '../../components/challenge/ChallengeLeftSidebar'
import { Termometr } from '../../components/common/Svg'
import Skeleton from '../../components/common/Skeleton'
import { finalPointRewards } from '../../state/user/actions'
import { updatePrize } from '../../state/prize/actions'
import { getPrize } from '../../state/prize/selectors'

export default function RewardsDetail() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [showModal, setShowModal] = useState(false)
    const { winnersPrice } = useSelector(getPrize)
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)
    const item = location?.state?.item

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Rewards detail' })
    }, [])

    const postFunction = () => {
        dispatch(
            finalPointRewards(item?.id, () => {
                navigate('/')
            }),
        )

        dispatch(
            updatePrize({
                id: winnersPrice.id,
                isShown: true,
                pointsTarget: winnersPrice?.pointsTarget?.id,
                points: 0,
            }),
        )
    }

    return (
        <div>
            <Layout leftBackground>
                <div className="medium:pb-0 z-10 w-full w-full flex flex-col medium:flex-row medium:items-stretch items-center medium:justify-between relative">
                    <ChallengeLeftSidebar setShowModal={setShowModal} showModal={showModal} rewards data={item} />

                    <div className="flex flex-col medium:flex-1 max-medium:w-full">
                        {loading && <Skeleton className="medium:h-[540px] min-medium:aspect-video w-full object-cover medium:static absolute top-0 left-0" />}
                        <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} medium:h-[540px] min-medium:aspect-video w-full object-cover medium:static absolute top-0 left-0`} src={item?.image} alt="" />

                        <div className="mobile:ml-10 ml-4 mt-8 mobile:mt-10 tablet:mr-[160px] mobile:mr-10 mr-4 mobile:mb-[120px] mb-[80px]">
                            <div className="mt-6 flex items-center">
                                <Termometr width={24} height={24} />

                                <h1 className="ml-[8px] font-bold text-grey01 text-14">
                                    Save 1 kg of CO2E&nbsp;
                                    <span className="font-normal text-grey01 text-14">
                                        by claiming this reward
                                    </span>
                                </h1>
                            </div>

                            <h1 className="mt-[30px] text-22 font font-medium tracking-tight text-darken">
                                {item?.title}
                            </h1>

                            <h1 className="mt-[18px] text-16 font-normal tracking-tight text-grey01" style={{ lineHeight: '24px' }}>
                                {item?.description}
                            </h1>

                            <button
                                onClick={postFunction}
                                className="w-full h-[48px] mt-[32px] bg-mint font-semibold hover:bg-[#A7D49D] hover:text-spinach hover:ease-in hover:duration-200 px-8 border rounded-md text-14 text-marrow"
                                style={{ border: '2px solid #417339' }}>
                                Claim this reward
                            </button>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
