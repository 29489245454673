import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { postPerkBookMark } from '../../state/perks/actions'
import { IPerks } from '../../types/data'
import { ArrowBottomIcon, SavedIconActive, SavedIconDefault, StarIcon } from '../common/Svg'
import { postHomeBookMark } from '../../state/home/actions'
import Skeleton from '../common/Skeleton'
import { readableC02 } from '../../utils/helpers'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item: IPerks
    image?: boolean
    type?: 'home' | 'perk'
}

export default function PerkOfTheMonth({ item, image, type }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const firstImage = item?.perksImages && item?.perksImages.map((items) => items?.image)
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)
    const [t] = useTranslation('global')
    // @ts-ignore
    const isTotal = item?.isTotalRating?.new

    const onPressBookmark = async (id: number) => {
        if (type === 'perk') await dispatch(postPerkBookMark(id))
        if (type === 'home') await dispatch(postHomeBookMark(id))
    }

    const navigatePerkDetail = (detailId: number) => {
        navigate(`/lower/swaps/${detailId}`)
    }

    return (
        <div className="cursor-pointer w-full">
            <div className="mt-3 card" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                <div className="flex justify-end mr-4">
                    <div onClick={() => onPressBookmark(item?.id)} className="absolute rounded-full flex justify-center items-center mt-4 bg-black w-[28px] h-[28px]">
                        {item?.isBookmark ? (
                            <SavedIconActive width={12} height={15} />
                        ) : (
                            <SavedIconDefault width={12} height={15} />
                        )}
                    </div>
                </div>

                <div onClick={() => navigatePerkDetail(item?.id)}>
                    {loading && <Skeleton className="rounded-t-lg w-full object-cover aspect-w-16 aspect-h-9 aspect-video" />}

                    <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-t-lg w-full object-cover aspect-w-16 aspect-h-9 aspect-video`} src={firstImage ? firstImage[0] : ''} alt="" />

                    <div className={`flex flex-col justify-between rounded-b-lg bg-white ${image && 'h-[137px]'}`}>
                        {item?.sponsorshipBlock && <SponsoredByBlock className="px-5 mb-1" fontColor="dark" noBackground noBorder item={item.sponsorshipBlock} />}

                        <div className={cn('px-5 mobile:pt-5', { 'pt-5': !item?.sponsorshipBlock })}>
                            <button className="flex mb-1 font-medium font leading-[132%] tracking-[-0.02rem] text-black text-18">
                                {loading ? (
                                    <Skeleton className="w-full mr-[40%] h-[13px] rounded-sm" />
                                ) : (
                                    <ContentBlockTitle text={`${item?.perkBrand?.discount} ${item?.perkBrand?.name}`} />
                                )}

                            </button>

                            <p className="flex mb-2.5 font-light tracking-tight text-grey01 text-14">
                                {loading ? (
                                    <Skeleton className="w-full mr-[60%] h-[13px] rounded-sm mt-1" />
                                ) : (
                                    item?.shortDescription
                                )}
                            </p>
                        </div>

                        <div className="flex items-center px-5 pb-5">
                            {loading ? (
                                <Skeleton className="w-full mr-[75%] h-[10px] rounded-sm" />
                            ) : (
                                item?.ceo2Kg !== 0 ? (
                                    <div className="flex items-center">
                                        <ArrowBottomIcon width={15} height={15} />

                                        <p className="font-semibold not-heading-font text-grey01 ml-1.5 text-12">
                                            {readableC02(item?.ceo2Kg || 0)}
                                        </p>
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <StarIcon width={18} height={19} />

                                        <p className="font-semibold text-grey01 text-12">
                                            {!isTotal ? Math.floor(Number(item?.totalRating || 0)) : t('lower.new')}
                                        </p>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
