import { Dispatch } from 'redux'

import { IQuestionData, IQuizAnswerPost, IQuizAnswerResponse } from '../../types/data'
import { FETCH_QUIZ_ERROR, FETCH_QUIZ_LIST_SUCCESS, FETCH_QUIZ_START, POST_QUIZ_ANSWER_SUCCESS } from './consts'
import { getQuiz, postQuizAnswerItem } from './api'

const fetchQuizStart = () => ({
    type: FETCH_QUIZ_START,
})

export const fetchQuizSuccess = (response: IQuestionData) => ({
    type: FETCH_QUIZ_LIST_SUCCESS,
    payload: response,
})

const fetchEnd = () => ({
    type: FETCH_QUIZ_ERROR,
})

export const postQuizAnswerSuccess = (response: IQuizAnswerResponse) => ({
    type: POST_QUIZ_ANSWER_SUCCESS,
    payload: response,
})

/**
 * Fetch quiz
 * @returns
 */

export const fetchQuiz = (id: number | string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchQuizStart())
        const response = await getQuiz(id)

        if (response) {
            dispatch(fetchQuizSuccess(response))
        }
        // @ts-ignore
    } catch (e) {
        console.log('ERROR, fetchQuiz: ', e)
        dispatch(fetchEnd())
    }
}

export const postQuizAnswer = (
    data: IQuizAnswerPost | null | undefined,
    onSuccess: () => void,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchQuizStart())
        const response = await postQuizAnswerItem(data)

        if (response) {
            dispatch(postQuizAnswerSuccess(response))
            onSuccess()
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
    }
}
