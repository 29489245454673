import React, { useMemo } from 'react'

interface IProps {
    isShown: boolean
    children: React.ReactNode
}

export default function ShowWithAnimation({ isShown, children }: IProps) {
    const id = useMemo(() => (Math.random() + 1).toString(36).substring(2), [])

    return (
        <div style={{ height: isShown ? document.getElementById(id)?.offsetHeight : 0, overflow: 'hidden', transition: 'height 0.5s ease' }}>
            <div id={id} className="pb-10">
                {children}
            </div>
        </div>
    )
}
