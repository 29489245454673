import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPerkLists } from '../../state/perks/selectors'
import { getWhiteLabelingList } from '../../state/user/selectors'

export default function AllBrandsItem() {
    const navigate = useNavigate()
    const { perksBrand } = useSelector(getPerkLists)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    const navigatePerkDetail = (detailId: number) => {
        navigate(`/lower/swaps/${detailId}`)
    }

    return (
        <div>
            {perksBrand?.map((items: any) => (
                <div style={{ borderBottomWidth: 1, borderBottomColor: whiteLabeling?.avocado }} onClick={() => navigatePerkDetail(items.id)} key={items.id} className="flex justify-between items-center">
                    <span className="w-[68%] text-black text-18 font-medium line-clamp-1 mt-2 pb-1 cursor-pointer font tracking-[-0.02rem] flex items-center hover:font-bold hover:ease-in hover:duration-200 leading-[132%]">
                        {items.perkBrand.name}
                    </span>

                    <span className="w-[30%] text-grey01 text-12 pr-2 mt-2 text-right font-bold line-clamp-1 cursor-pointer tracking-[-0.02rem] leading-[150%]">
                        {items.perkBrand.discount}
                    </span>
                </div>
            ))}
        </div>
    )
}
