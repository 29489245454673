import { useEffect, RefObject } from 'react'
import ReactGA from 'react-ga4'

const useAdViewTracking = (ref: RefObject<HTMLElement>, gaId: string, measurementId: string | undefined) => {
    useEffect(() => {
        if (!measurementId) {
            console.log('GA ID is not available, skipping ad view tracking.')
            return
        }

        const observerOptions: IntersectionObserverInit = {
            root: null,
            rootMargin: '0px',
            threshold: 1,
        }

        const observerCallback: IntersectionObserverCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    ReactGA.event({
                        category: 'Sponsorship',
                        action: 'ad_view',
                        label: gaId,
                    })

                    observer.unobserve(entry.target)
                }
            })
        }

        const observer = new IntersectionObserver(observerCallback, observerOptions)

        if (ref.current) {
            observer.observe(ref.current)
        }

        // eslint-disable-next-line consistent-return
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }
    }, [ref, gaId, measurementId])
}

export default useAdViewTracking
