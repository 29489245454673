import React from 'react'

interface IProps {
    leftElement: React.ReactNode
    rightElement: React.ReactNode
}

export default function RecipeDataContainer({ leftElement, rightElement }: IProps) {
    return (
        <div className="flex medium:flex-row w-full flex flex-col mb-14 mt-10">
            <div className="tablet:w-[396px] medium:w-[284px] medium:pr-0 xs-mobile:mr-10 xs-mobile:ml-10 ml-5 mr-5">
                <div className="">
                    {leftElement}
                </div>
            </div>

            <div className="mt-10 medium:mt-0 tablet:w-[764px] medium:w-[508px] medium:ml-0 xs-mobile:ml-10 xs-mobile:mr-10 ml-5 mr-5">
                {rightElement}
            </div>
        </div>
    )
}
