import apiClient from '../instance'
import { LEADERBOARD, MY_RANGE_BELOW_TEN_URL } from '../../urls'
import { ILeaderboard, IMyRangeData } from '../../types/data'

/**
 * Get leaderboard list
 */
export const getLeaderboardList = async (params: { page: number, leaderboard?: number | string }): Promise<ILeaderboard | null> => {
    try {
        const { data: response } = await apiClient.get<ILeaderboard>(LEADERBOARD, {
            params,
        })

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get my lex range or below ten list
 */
export const getMyBadge = async (params?: { leaderboard?: number | string }): Promise<IMyRangeData | null> => {
    try {
        const { data: response } = await apiClient.get<IMyRangeData>(MY_RANGE_BELOW_TEN_URL, {
            params,
        })

        return response
    } catch (e) {
        throw e
    }
}
