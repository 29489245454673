import moment from 'moment'

export const DATETIME_FORMAT_AUTO = 'DD MMM YYYY'
export const DAY_AUTO_FORMAT = 'DD'
export const MONTH_YEAR_FORMAT_AUTO = 'MMM, YYYY'
export const DATE_AUTO_FORMAT_MONTHS = 'MMMM YYYY'
export const DATE_LOG_DAY_AUTO = 'YYYY-MM-DD'
export const DATE_TRAVEL = 'YYYY-MM-DD'
export const DATE_STATISTICS = 'DD MMM'
export const DATE_STATISTICS_EMPLOYEE = 'MMMM'
export const DATE_MONTH_FORMAT_AUTO = 'DD MMM'

export function getDateTime(date: string | undefined) {
    return moment(date).format(DATETIME_FORMAT_AUTO)
}

export function getDates(date: Date | undefined | string) {
    return moment(date).format(DATETIME_FORMAT_AUTO)
}

export function travelDate(date: Date | undefined | string) {
    return moment(date).format(DATE_TRAVEL)
}

export function getDateTimes(date: any) {
    return moment(date).format(DATE_MONTH_FORMAT_AUTO)
}

export function getMonths(date?: string) {
    return moment(date).format(DATE_AUTO_FORMAT_MONTHS)
}

export function getDateTimeChallenge(startDate: string | undefined, endDate: string | undefined) {
    const firstDay = moment(startDate).format(DAY_AUTO_FORMAT)
    const lastDay = moment(endDate).format(DAY_AUTO_FORMAT)
    const monthAndYear = moment(endDate).format(MONTH_YEAR_FORMAT_AUTO)
    return `${firstDay} - ${lastDay} ${monthAndYear}`
}

export function dash(name: string | undefined) {
    // @ts-ignore
    return name?.split(' ').join('-').split('(').join('').split(')').join('')
}

export function getDate(date: string) {
    return moment(date).format(DATETIME_FORMAT_AUTO)
}

export function formatDate(date: string | number, locale: string) {
    moment.locale(locale)
    return moment(date).format('D MMM')
}

export async function getDatesLocalization(date: Date | undefined | string, language: string) {
    if (language !== 'en') {
        await import(`moment/locale/${language}`)
        moment.locale(language)
    }
    return moment(date).format(DATETIME_FORMAT_AUTO)
}

export async function statisticsDate(date: Date | undefined | string, language: string) {
    if (language !== 'en') {
        await import(`moment/locale/${language}`)
        moment.locale(language)
    }
    return moment(date).format(DATE_STATISTICS)
}

export async function statisticsDateEmployee(date: number | string | undefined, language: string) {
    if (language !== 'en') {
        await import(`moment/locale/${language}`)
        moment.locale(language)
    }
    return moment(date, 'M').format(DATE_STATISTICS_EMPLOYEE)
}

export const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const
export const daysOfWeekShort = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
