import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Layout from '../components/layout/Layout'
import { FilterIcon } from '../components/common/Svg'
import SearchInput from '../components/common/SearchInput'
import { IRecipe } from '../types/data'
import RecipeCategories from '../components/common/RecipeCategories'
import RecipeTags from '../components/common/RecipeTags'
import { getRecipe } from '../state/recipe/selectors'
import { fetchRecipeCategoryList, fetchRecipeTagsList } from '../state/recipe/actions'
import RecipeCard from '../components/recipe/RecipeCard'
import { getPerkLists } from '../state/perks/selectors'
import RecentSearches from '../components/RecentSearches'
import { getHomePageLists } from '../state/home/selectors'

export default function HomeEmployeeRecipeViewAll() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { homePage } = useSelector(getHomePageLists)
    const { searchCollection } = useSelector(getRecipe)
    const { recentSearches } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)
    const [t] = useTranslation('global')

    const data = [
        { id: 1, title: t('lower.all-categories'), content: <RecipeCategories />, loadContent: () => dispatch(fetchRecipeCategoryList) },
        { id: 2, title: t('lower.tags'), content: <RecipeTags />, loadContent: () => dispatch(fetchRecipeTagsList) },
    ]

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        buttonName: t('lower.recipes'),
    }

    useEffect(() => {
        dispatch(fetchRecipeCategoryList)
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        // eslint-disable-next-line
    }, [])

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="mt-2 mobile:mx-10 mx-16 mobile:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px]">
                <span onClick={() => navigate('/')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2" style={{ fontSize: 14 }}>
                    {t('navbar.Home')}
                </span>

                <div className="flex items-center justify-between">
                    <h1 className="font tracking-tight font-medium text-36">
                        {homePage?.recipeCollection?.name}
                    </h1>

                    <SearchInput action="recipes" placeholder={t('lower.what-would-you-like-to-make')} onFocus={handleFocus} />
                </div>

                {onFocus && (
                    <RecentSearches data={recentSearches?.recipes} type="recipes" collection={searchCollection} />
                )}

                <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-10">
                    {homePage?.recipeCollection?.recipe?.map((item: IRecipe) => (
                        <div key={item.id}>
                            <RecipeCard item={item} type="home" />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}
