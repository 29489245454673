import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CopyIcon, SavedIconActive, SavedIconDefault, ShareIcon } from '../common/Svg'
import { getUser } from '../../state/user/selectors'
import { getPerkDetailLists } from '../../state/perks/selectors'
import { postPerkBookMark } from '../../state/perks/actions'
import PerkRateModal from './PerkRateModal'
import PerkWriteReviewModal from './PerkWriteReviewModal'

interface IProps {
    onActionButtonClick: () => void
    perkId?: number | undefined
}

export default function PerkDetailSideBar({ onActionButtonClick, perkId }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { perkDetail: perksData } = useSelector(getPerkDetailLists)
    const perkBrandUrl = perksData?.perkBrand?.brandUrl
    const brandUrl = perkBrandUrl?.includes('?')
    const discountCode = perksData?.perkBrand?.discountCode
    const [t] = useTranslation('global')

    const onPressBookmark = async (id: number) => {
        await dispatch(postPerkBookMark(id))
    }

    const navigation = () => {
        if (perkId) {
            navigate('/lower/swaps')
        } else {
            navigate(-1)
        }
    }

    return (
        <div className="flex flex-col items-center justify-between p-10 tablet:w-[480px] w-[364px] tablet:ml-0 medium:flex hidden">
            <span onClick={navigation} className="tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-rocket mt-10 text-14">
                {t('account.Swaps')}
            </span>

            <div className="w-full" style={{ lineHeight: 1.2 }}>
                <h1 className="text-black font tracking-tight font-medium text-26 text-center line-clamp-2">
                    {perksData?.perkBrand?.discount}
                </h1>

                <h1 className="text-black font tracking-tight font-medium text-26 text-center line-clamp-2">
                    {perksData?.perkBrand?.name}
                </h1>
            </div>

            <div className="tablet:w-[396px] w-[284px]">
                {perksData?.buttonText ? (
                    <div onClick={onActionButtonClick} className="cursor-pointer flex flex-col items-center justify-center bg-rocket rounded-lg p-5 min-w-[150px]" style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)' }}>
                        <h1 className="font-medium not-heading-font tracking-tight font text-center text-18 line-clamp-3 text-white">
                            {perksData.buttonText}
                        </h1>
                    </div>
                ) : !perksData?.perkBrand?.brandUrl ? (
                    <CopyToClipboard text={discountCode || ''}>
                        <div className="cursor-pointer flex flex-col items-center justify-center bg-rocket rounded-lg p-4" style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)' }}>
                            <div className="flex items-center">
                                <h1 className="font-medium not-heading-font tracking-tight font text-18 text-white">
                                    {t('lower.redeem-your-20%-off-now')}
                                </h1>

                                <div className="ml-2">
                                    <CopyIcon width={16} height={16} />
                                </div>
                            </div>

                            <h1 className="font-normal text-white text-14">
                                {t('lower.copy-the-code')} <span className="font-semibold">{t('lower.greenshoot')}</span> {t('lower.now')}
                            </h1>
                        </div>
                    </CopyToClipboard>
                ) : (
                    <a href={brandUrl ? `${perksData?.perkBrand?.brandUrl}&pref1=app&pref2=${user?.id}` : perksData?.perkBrand?.brandUrl} target="_blank" rel="noreferrer">
                        <div className="cursor-pointer flex flex-col items-center justify-center bg-rocket rounded-lg p-5" style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)' }}>
                            <div className="flex items-center">
                                <h1 className="text-white font-medium not-heading-font tracking-tight font text-center text-18">
                                    {t('lower.get')} {perksData?.perkBrand?.discount} {perksData?.perkBrand?.name}
                                </h1>

                                <div className="ml-3">
                                    <ShareIcon width={16} height={16} />
                                </div>
                            </div>
                        </div>
                    </a>
                )}

                <div className="flex justify-between w-full mt-8">
                    <div className="flex">
                        <PerkRateModal />

                        <PerkWriteReviewModal />
                    </div>

                    <button onClick={() => onPressBookmark(Number(perksData?.id))} className="rounded-full flex justify-center items-center bg-black w-[28px] h-[28px]">
                        {perksData?.isBookmark ? (
                            <SavedIconActive width={12} height={15} />
                        ) : (
                            <SavedIconDefault width={12} height={15} />
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}
