import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ArrowBottomIcon, SavedIconActive, SavedIconDefault } from '../common/Svg'
import { IRecipe } from '../../types/data'
import { postRecipeBookmark } from '../../state/recipe/actions'
import Skeleton from '../common/Skeleton'
import { postHomeBookMark } from '../../state/home/actions'
import { readableC02 } from '../../utils/helpers'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item?: IRecipe
    isLoading?: boolean
    type?: 'home' | 'recipe'
}

export default function RecipeCard({ item, isLoading = false, type }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(isLoading || true)
    const handleImageLoad = () => setLoading(false)

    const onPressBookmark = async (id: number) => {
        if (!item) return
        if (type === 'recipe') await dispatch(postRecipeBookmark(id))
        if (type === 'home') await dispatch(postHomeBookMark(item.id))
    }

    const navigateRecipeDetail = () => {
        navigate(`/lower/recipes/${item?.id}`)
    }

    return (
        <div className="cursor-pointer" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div className="flex justify-end mr-3">
                <div onClick={(e) => {
                    e.preventDefault()
                    if (item) onPressBookmark(item?.id)
                }} className="absolute rounded-full flex justify-center items-center mt-3 bg-black w-[28px] h-[28px]">
                    {item?.isBookmark ? (
                        <SavedIconActive width={12} height={15} />
                    ) : (
                        <SavedIconDefault width={12} height={15} />
                    )}
                </div>
            </div>

            <div onClick={navigateRecipeDetail}>
                {loading && <Skeleton className="rounded-t-lg object-cover aspect-video" />}
                <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-t-lg object-cover aspect-video`} src={item?.image || item?.thumbnail} alt="" />

                <div className="flex flex-col justify-between rounded-b-lg bg-white h-[150px] pb-3 hover:bg-[#F2F2F2] hover:ease-in hover:duration-200 hover:drop-shadow-[0.3] hover:delay-200 ">
                    <div>
                        {item?.sponsorshipBlock && <SponsoredByBlock noBackground noBorder fontColor="black" item={item.sponsorshipBlock} />}

                        <button className="text-black text-left mb-0.5 font-medium font tracking-tight line-clamp-2 text-16 leading-[20.8px] px-4 pt-3">
                            {loading ? (
                                <>
                                    <Skeleton className="w-10/12 h-[13px] rounded-sm mt-2" />
                                    <Skeleton className="w-8/12 h-[13px] rounded-sm mt-2" />
                                </>
                            ) : (
                                <ContentBlockTitle text={item?.title} />
                            )}

                        </button>
                    </div>

                    {loading ? (
                        <div>
                            <Skeleton className="w-2/12 h-[13px] rounded-sm mt-2 px-4" />
                        </div>
                    ) : (
                        <div className="flex items-center px-4">
                            <ArrowBottomIcon width={15} height={15} />

                            <p className="font-semibold not-heading-font text-grey01 ml-1.5 text-12">
                                {readableC02(item?.ceo2Kg || 0)}
                            </p>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}
