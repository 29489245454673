import React from 'react'
import { random } from 'lodash'

interface IServerError {
    error?: string | {
        nonFieldErrors: string[]
        detail: string[]
    }
}

export default function ServerError({ error }: IServerError) {
    if (!error) return <div />

    if (typeof error === 'string') {
        return (
            <div className="text-[#BD4823] tracking-[-0.02em] leading-[140%] text-[15px] font-semibold">
                {error}
            </div>
        )
    }

    if (error.nonFieldErrors) {
        return (
            <div className="text-[#BD4823] tracking-[-0.02em] leading-[140%] text-[15px] font-semibold">
                {error.nonFieldErrors[0]}
            </div>
        )
    }

    if (error.detail) {
        return (
            <div className="text-[#BD4823] tracking-[-0.02em] leading-[140%] text-[15px] font-semibold">
                {error.detail[0]}
            </div>
        )
    }

    return (
        <div className="text-[#BD4823] tracking-[-0.02em] leading-[140%] text-[15px] font-semibold">
            {Object.keys(error).map((key) => (
                // @ts-ignore
                <div key={random(0, 100)}>{error[key]}</div>
            ))}
        </div>
    )
}
