import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import { SponsorshipBlock } from '../types/data'
import { getUser } from '../state/user/selectors'
import useAdViewTracking from './common/AddView'

interface IProps {
    item: SponsorshipBlock
    noBorder?: boolean
    noBackground?: boolean
    containerStyles?: object
    fontColor?: string
    className?: string
}

export default function SponsoredByBlock({ item, noBorder = false, noBackground = false, containerStyles, className }: IProps) {
    const { user } = useSelector(getUser)
    const navigate = useNavigate()
    const blockRef = useRef(null)

    const handleAdClick = (gaId: string) => {
        if (user?.company?.googleAnalyticsMeasurementId) {
            ReactGA.event({
                category: 'Sponsorship',
                action: 'ad_click',
                label: gaId,
            })
        }
    }

    useAdViewTracking(blockRef, item.gAId, user?.company?.googleAnalyticsMeasurementId)

    const getData = (position: 'Left' | 'Right') => [
        {
            position: item[`text1${position}`] || 0,
            component: <p className="max-mobile:text-13">{item.text1}</p>,
        },
        {
            position: item[`text2${position}`] || 0,
            component: <p className="max-mobile:text-13">{item.text2}</p>,
        },
        {
            position: item[`logo${position}`] || 0,
            component: <img className="object-contain h-[38px]" src={item.logo} alt="" />,
        },
    ].filter((i) => i.position).sort((a, b) => a.position - b.position)

    return (
        <div
            ref={blockRef}
            style={containerStyles}
            className={cn(`px-4 flex justify-between py-2 text-${item.fontColor} mobile:hidden`, className, {
                'border-b-2 border-[#D9D9D9]': item.showBorderBottom && !noBorder,
                [`bg-${item.backgroundColor}`]: !noBackground,
                'cursor-pointer': item.linkedArticle || item.link,
            })}
            onClick={() => {
                handleAdClick(item.gAId)
                if (item.linkedArticle) {
                    navigate(`/learn/articles/${item.linkedArticle}`)
                    return
                }

                if (item.link) {
                    window.open(item.link, '_event')
                }
            }}>
            <div className="flex items-center">
                {getData('Left').map((i, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="mr-2.5" key={`left_${index}`}>{i.component}</div>
                ))}
            </div>

            <div className="flex items-center">
                {getData('Right').map((i, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="mr-2.5" key={`right_${index}`}>{i.component}</div>
                ))}
            </div>
        </div>
    )
}
