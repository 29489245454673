import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { getPerkLists } from '../../state/perks/selectors'
import { fetchBookmarkedPerks, fetchPerksBrand, fetchPerksByType, fetchPerksFilterTag } from '../../state/perks/actions'
import PerkCard from '../../components/perk/PerkCard'
import PerkByTypeItem from '../../components/common/PerkByTypeItem'
import AllBrandsItem from '../../components/common/AllBrandsItem'
import PerkTags from '../../components/common/PerkTags'
import { FilterIcon } from '../../components/common/Svg'
import SearchInput from '../../components/common/SearchInput'
import Pagination from '../../components/common/Pagination'
import RecentSearches from '../../components/RecentSearches'

export default function PerksBookmark() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { bookmarkedPerks, loading, perksByType, recentSearches, searchCollection } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Swaps bookmark' })
    }, [])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchBookmarkedPerks({ page: 1 }))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        if (bookmarkedPerks?.results?.length === 0) onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (perksByType.length === 0) dispatch(fetchPerksByType)
        // eslint-disable-next-line
    }, [])

    const data = [
        { id: 1, title: t('lower.swaps-by-type'), content: <PerkByTypeItem />, loadContent: () => dispatch(fetchPerksByType) },
        { id: 2, title: t('lower.all-brands'), content: <AllBrandsItem />, loadContent: () => dispatch(fetchPerksBrand) },
        { id: 3, title: t('lower.tags'), content: <PerkTags />, loadContent: () => dispatch(fetchPerksFilterTag) },
    ]

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        buttonName: t('lower.swaps'),
    }

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="medium:mt-[52px] mt-[32px] min-h-screen mx-12 tablet:mb-[120px] mb-[80px] mobile:mx-10 mx-4">
                <span onClick={() => navigate('/lower/swaps')} className="xs-mobile:block hidden text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                    {t('navbar.Swaps')}
                </span>

                <div className="flex items-center justify-between">
                    <h1 className="heading-uppercase text-black xs-mobile:block hidden font tracking-tight font-medium tablet:text-36 mobile:text-32 text-26">
                        {t('lower.my-swaps')}
                    </h1>

                    <SearchInput action="perks" placeholder={t('lower.find-a-great-swap')} onFocus={handleFocus} />
                </div>

                {onFocus && (
                    <RecentSearches data={recentSearches?.perks} type="perks" collection={searchCollection} />
                )}

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/lower/swaps')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('navbar.Swaps')}
                    </span>

                    <h1 className="text-black font tracking-tight font-medium mt-2 text-26">
                        {t('lower.my-swaps')}
                    </h1>
                </div>

                <div className="grid md:grid-cols-3 sm:grid-cols-1 mobile:gap-[24px] tablet:gap-[24px] laptop:gap-[32px] mt-8">
                    { bookmarkedPerks?.results?.length !== 0 && bookmarkedPerks?.results?.map((item) => <PerkCard key={item.id} item={item} type="perk" />)}
                </div>

                <Pagination
                    loading={loading}
                    loadMore={(page) => dispatch(fetchBookmarkedPerks({ page }))}
                    containerClassName="mt-[60px]"
                    totalCount={bookmarkedPerks?.count}
                    currentCount={bookmarkedPerks?.results?.length} />
            </div>
        </Layout>
    )
}
