import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { replaceAll } from '../../utils/helpers'
import { COLOR_CONST } from '../../constants'
import { travelSelector } from '../../state/travel/selectors'
import { ILegs, ITrackTravelCarType } from '../../types/data'
import { getWhiteLabelingList } from '../../state/user/selectors'
import { LegDetailsUnknownLegLine, Trash, TravelTreeSmallEllipse } from '../common/Svg'
import GoogleAutoComplete from '../GoogleAutoComplete'

interface IProps {
    index: number
    items: (ILegs | undefined)[]
    item?: ILegs
    setSelectedTransports: (value: any) => void
    selectedTransports: any
    onChangePlace: (value: any, leg: ILegs, side: 'start' | 'end', additionalData?: any) => void
    placesData: any

    setDeleteLegIds: (value: number[]) => void
    deleteLegIds: number[]
}

export default function LegDetailsCollapsedLeg({ index, items, item, setSelectedTransports, placesData, selectedTransports, onChangePlace, setDeleteLegIds, deleteLegIds }: IProps) {
    const { transportModes, transportList, loading } = useSelector(travelSelector)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')

    const selectedTransportId = selectedTransports[item?.backendLegId || 0]?.id || item?.transportId || 0

    const onChangeValueType = (transport: ITrackTravelCarType) => {
        setSelectedTransports({ ...selectedTransports, [item?.backendLegId || 0]: transport.id === item?.transportId ? undefined : transport })
    }

    const onDelete = () => {
        if (!item) return

        const nextLeg = items[index + 1]

        onChangePlace(
            {
                placeId: nextLeg?.endPlaceId,
                lat: nextLeg?.endPlaceLat,
                lon: nextLeg?.endPlaceLon,
                name: nextLeg?.endPlaceName,
            },
            item,
            'end',
        )

        setDeleteLegIds([...deleteLegIds, (nextLeg?.backendLegId || 0)])
    }

    return (
        <div className={cn('w-full pr-4 mb-[-10px] mt-[-10px]', { 'border-spinach border-l-[10px]': item, flex: !item })}>
            {!item && <div className="flex items-center"><LegDetailsUnknownLegLine /></div>}

            <div className="flex pl-[26px] overflow-x-auto no-scrollbar">
                <div className="scroll-container flex py-3">
                    {transportList.map((transport) => {
                        const transportMode = transportModes?.find((i) => i?.name === transport?.name?.toLowerCase())
                        const isSelected = selectedTransportId === transport.id

                        return (
                            <button
                                key={transport?.id}
                                disabled={loading}
                                onClick={() => onChangeValueType(transport)}
                                style={{ opacity: 1 }}
                                className={`mr-4 w-[103px] h-[103px] flex items items-end justify-center rounded-[10px] border-solid border-2 ${isSelected ? 'border-cavolo' : 'border-mint'} mt-3 mb-3 ${isSelected ? 'bg-rocket' : 'bg-pistachio'} cursor-pointer ${transport.name === 'e-bike/e-scooter' ? 'pb-[3px]' : 'pb-[15px]'}`}>
                                <span className="flex flex-col items-center">
                                    {/* eslint-disable-next-line react/no-danger */}
                                    <div dangerouslySetInnerHTML={{ __html: replaceAll(transportMode?.icon || '', COLOR_CONST, isSelected ? whiteLabeling?.white : whiteLabeling?.cavolo) }} />

                                    <span className={`mt-2 text-14 font-semibold ${isSelected ? 'text-white' : 'text-cavolo'} not-heading-font`}>
                                        {transport.name}
                                    </span>
                                </span>
                            </button>
                        )
                    })}
                </div>
            </div>

            {(index + 1 !== items.length && item) && (
                <div className="flex relative items-center pb-2">
                    <div className="absolute left-[-14px]">
                        <TravelTreeSmallEllipse />
                    </div>

                    <div className="text-16 flex w-full ml-7 text-cavolo not-heading-font">
                        <GoogleAutoComplete
                            containerClassName="flex-1"
                            dontEmpty
                            placeholder={t('log.start-typing')}
                            defaultValueLeg={placesData[item?.backendLegId || 0]?.endPlaceName || item.endPlaceName}
                            setPlace={(value) => {
                                const nextLeg = items[index + 1]

                                onChangePlace(value, item, 'end', {
                                    [nextLeg?.backendLegId || 0]: {
                                        ...(placesData[nextLeg?.backendLegId || 0] || {}),
                                        startPlaceId: value.placeId,
                                        startPlaceLat: value.lat,
                                        startPlaceLon: value.lon,
                                        startPlaceName: value.name,
                                    },
                                })
                            }} />

                        <div onClick={onDelete} className="flex-0 cursor-pointer items-center flex w-[60px] justify-center">
                            <Trash color={whiteLabeling.rocket} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
