import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Context } from '../../../components/common/BaseContext'
import { fetchArticleCategoryList, fetchCompanyArticle } from '../../../state/articles/actions'
import Layout from '../../../components/layout/Layout'
import { getDatesLocalization } from '../../../utils/date'
import Skeleton from '../../../components/common/Skeleton'
import { articles } from '../../../state/articles/selectors'
import { getUser } from '../../../state/user/selectors'

export default function CompanyArticleDetail() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { screenWidth } = useContext(Context)
    const { articleId } = useParams()
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { articleById, companyArticle } = useSelector(articles)
    const articleData = companyArticle?.find((item) => item.id === Number(articleId))
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    const onRestartLoad = useCallback(() => {
        dispatch(fetchCompanyArticle)
        dispatch(fetchArticleCategoryList)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const navigateDiscover = () => {
        navigate('/learn/organisation-news')
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const [formattedDate, setFormattedDate] = useState<string | undefined>(undefined)

    useEffect(() => {
        const fetchData = async () => {
            if (articleById?.createdAt) {
                const formatted = await getDatesLocalization(articleById?.createdAt, languageData)
                setFormattedDate(formatted)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [articleById?.createdAt])

    let extractedNumber: number | null = null

    if (typeof articleData?.getReadTime === 'string') {
        const matchResult = articleData?.getReadTime.match(/\d+/)
        if (matchResult) {
            extractedNumber = parseInt(matchResult[0], 10)
        }
    }

    return (
        <div>
            <Layout>
                <div className="text-black min-h-screen w-full">
                    {screenWidth < 912 ? (
                        <div className="flex flex-col items-center relative">
                            {loading && <Skeleton className="object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]" />}
                            <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]`} src={articleData?.image} alt="any" />

                            <div className="mt-[-10%] tablet911:w-[476px] w-11/12 flex flex-col tablet911:relative bg-pistachio items-center text-center tablet911:h-[540px]">
                                <button onClick={() => navigateDiscover()}
                                    className="text-rocket mt-9 tablet911:absolute tablet911:mt-[70px] tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                                    {t('article.news')}
                                </button>

                                <div className="flex px-5 mobile:mt-10 mt-[32px] tablet911:absolute medium:mt-[182px] tablet:mt-[223px] medium:mx-8 flex-col justify-between">
                                    <h5 className="text-14 hidden tablet911:block font-light my-2 text-grey01">
                                        {user?.company?.isDateStampShown && formattedDate} {user?.company?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                                    </h5>

                                    <h1 className="font-medium font tracking-tight text-black mobile:text-32 text-26 mobile:mb-[43.5px] mb-[28.5px]">
                                        {loading ? (
                                            <div className="flex flex-col items-center">
                                                <Skeleton className="medium:w-[280%] w-full h-[18px] rounded-sm mt-3" />
                                                <Skeleton className="medium:w-[170%] w-full h-[18px] rounded-sm mt-4" />
                                            </div>
                                        ) : (
                                            articleData?.title
                                        )}

                                    </h1>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex relative items-center tablet911:flex-row flex-col-reverse">
                            <div className="tablet911:w-[476px] w-11/12 flex -bottom-28 absolute tablet911:bottom-0 h-[280px] flex-col tablet911:relative bg-pistachio items-center text-center tablet911:h-[540px] justify-between">
                                <button onClick={() => navigateDiscover()}
                                    className="text-rocket mt-9 tablet911:mt-[70px] tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                                    {t('article.news')}
                                </button>

                                <div className="flex px-5 medium:mx-8 flex-col justify-between">
                                    <h5 className="text-14 hidden tablet911:block font-light my-2 text-grey01">
                                        {user?.company?.isDateStampShown && formattedDate} {user?.company?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                                    </h5>

                                    <h1 className="font-medium font tracking-tight text-black mobile:text-32 text-26">
                                        {loading ? (
                                            <div className="flex flex-col items-center">
                                                <Skeleton className="medium:w-[280%] w-full h-[18px] rounded-sm mt-3" />
                                                <Skeleton className="medium:w-[170%] w-full h-[18px] rounded-sm mt-4" />
                                            </div>
                                        ) : (
                                            articleData?.title
                                        )}

                                    </h1>
                                </div>

                                <div className="pb-10" />
                            </div>

                            {loading && <Skeleton className="object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]" />}
                            <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]`} src={articleData?.image} alt="any" />
                        </div>
                    )}

                    <div className={`${screenWidth < 1440 && 'flex justify-center'} medium:mt-8 mobile:mt-[32px] mt-[16px] tablet:mb-[120px] mb-[80px]`}>
                        {/* eslint-disable-next-line react/no-danger */}
                        <h1 className="w-[764px] rich-text laptop:ml-[476px] tablet:mx-[74px] mx-[16px]" dangerouslySetInnerHTML={{ __html: articleData ? articleData.body : articleById?.body }} />
                    </div>
                </div>
            </Layout>
        </div>
    )
}
