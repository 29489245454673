import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Context } from '../common/BaseContext'
import { DismissIcon } from '../common/Svg'
import RewardsCard from './RewardsCard'
import { getFinalPointRewardsList } from '../../state/user/selectors'

interface IProps {
    isShown: boolean
    setShowModalPoint: (value: boolean) => void
}

export default function RewardsModal({ isShown = true, setShowModalPoint }: IProps) {
    const navigate = useNavigate()
    const { screenWidth } = useContext(Context)
    const { finalPointRewards } = useSelector(getFinalPointRewardsList)

    const navigateRewardsDetail = (item: any) => {
        navigate(`/rewards-detail/${item?.id}`, { state: { item } })
        setShowModalPoint(false)
    }

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-[#fff] relative xs-mobile:w-[500px] w-11/12 mobile:px-[24px] pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={() => setShowModalPoint(false)} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex flex-col justify-center items-center pb-[32px]">
                                    <div className="flex flex-col items-center justify-center mobile:mt-[22px] mt-[24px]">
                                        <h1 className="font-normal text-grey01 mb-[26px] text-16 text-center">
                                            Congratulations, you hit the monthly points target! Claim your reward now.
                                        </h1>

                                        <div className="w-full grid lg-gap-8 md:grid-cols-2 grid-cols-2 lg:gap-8 gap-4 mt-3">
                                            {finalPointRewards?.rewards?.map((item) => (
                                                <div key={item.id} onClick={() => navigateRewardsDetail(item)}>
                                                    <RewardsCard item={item} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
