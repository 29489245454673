import React from 'react'
import cn from 'classnames'

interface IProps {
    label: string
    dataPoint: string | number
    home?: boolean
    className?: string
}

export default function AccountInfoButton({ dataPoint, label, home, className }: IProps) {
    return (
        <div className={cn('py-2 px-3 rounded-lg bg-grey04 min-h-[55px]', className)}>
            <p className={`${home ? 'text-14' : 'text-16'} font font-medium not-heading-font tracking-[-0.02rem] text-black`}>{dataPoint}</p>
            <p className="text-grey01 text-xs tracking-[-0.01rem]">{label}</p>
        </div>
    )
}
