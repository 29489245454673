import React from 'react'
import cn from 'classnames'
import unlock from '../static/unlock.png'

interface IProps {
    label: string
    dataPoint: string | number
    locked?: boolean
    containerClassName?: string
}

export default function ProgressAccountInfoButton({ dataPoint, label, locked, containerClassName }: IProps) {
    return (
        <div className={cn('relative py-2 px-3 mobile:p-4 rounded-lg mb-4 bg-pistachio min-h-[55px] mobile:min-h-[82px] laptop:min-h-[90px] flex flex-col items-center justify-between', containerClassName)}>
            {locked && (
                <img src={unlock} className="absolute laptop:w-[120px] mobile:w-[104px] w-[89px] laptop:h-[52px] mobile:h-[45px] h-[42px] mt-[3px] mobile:mt-0" alt="" />
            )}

            <p className={cn('not-heading-font font font-medium w-full tracking-[-0.02rem] leading-[126%] text-16 mobile:text-22 laptop:text-26', { 'text-[#00000026]': locked, 'text-black': !locked })}>
                {dataPoint}
            </p>

            <p className={cn('tracking-[-0.01rem] w-full leading-[150%] text-12 mobile:text-14', { 'text-[#5b5b5b26]': locked, 'text-grey01': !locked })}>
                {label}
            </p>
        </div>
    )
}
