import React, { useContext } from 'react'
import isEmpty from 'lodash/isEmpty'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ArticleIcon, HomeIcon, LanguageIcon,
    MyInfoIcon,
    PerkIcon,
    PledgeIcon,
    RecipeIcon,
    ReferralIcon, RightArrow, ScheduleIcon,
    Shield,
    SignOutIcon } from './common/Svg'
import { Context } from './common/BaseContext'
import { getUser, getWhiteLabelingList } from '../state/user/selectors'
import { signOutUser } from '../state/user/actions'

interface IProps {
    right?: boolean
}

export default function AccountMenuContent({ right }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const pathName = window?.location?.pathname
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { user, isSso } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { collectedLinks } = useContext(Context)

    const onLogout = () => {
        localStorage.removeItem('language')
        dispatch(signOutUser(() => navigate('/login')))
    }

    const accountMenuReferral = [
        {
            id: 1,
            menuItem: t('navbar.Refer a friend'),
            href: '/me/refer-a-friend',
            icon:
    <div className="mr-2 flex justify-center w-6 h-6">
        <ReferralIcon />
    </div>,
        },
    ]

    const accountMenuBookmarks = [
        collectedLinks.includes('/lower/swaps') ? {
            id: 2,
            menuItem: 'Swaps',
            href: '/lower/swaps/swaps-bookmark',
            icon:
            <div className="mr-2 flex justify-center w-6 h-6">
                <PerkIcon width={22} height={21} />
            </div>,
        } : null,
        collectedLinks.includes('/learn/articles') ? {
            id: 3,
            menuItem: 'Articles',
            href: '/learn/articles/article-bookmark',
            icon:
            <div className="mr-2 flex justify-center w-6 h-6">
                <ArticleIcon width={22}
                    height={22} />
            </div>,
        } : null,
        collectedLinks.includes('/lower/recipes') ? {
            id: 4,
            menuItem: 'Recipes',
            href: '/lower/recipes/recipe-bookmark',
            icon:
            <div className="mr-2 flex justify-center w-6 h-6">
                <RecipeIcon width={24}
                    height={24} />
            </div>,
        } : null,
        collectedLinks.includes('/lower/pledges') ? {
            id: 5,
            menuItem: 'Pledges',
            href: '/lower/pledges/pledges-list',
            icon:
            <div className="mr-2 flex justify-center w-6 h-6">
                <PledgeIcon width={24} height={24} />
            </div>,
        } : null,
    ].filter((item) => item !== null)

    const accountMenuSettings = [
        {
            id: 6,
            menuItem: t('navbar.My info'),
            href: '/me/account-settings',
            icon:
    <div className="mr-2 flex justify-center w-6 h-6">
        <MyInfoIcon />
    </div>,
        },
    ]

    if (user?.company?.organisationalUnitsLevels && user.company.organisationalUnitsLevels > 1) {
        accountMenuSettings.push({
            id: 7,
            menuItem: `${t('navbar.My')} ${user?.company?.organisationalUnitName || 'club'}`,
            href: '/me/organisation-unit',
            icon: (
                <div className="mr-2 flex justify-center w-6 h-6">
                    <Shield />
                </div>
            ),
        })
    }

    accountMenuSettings.push({
        id: 8,
        menuItem: t('savedPlaces.saved-places'),
        href: '/me/saved-places',
        icon: (
            <div className="mr-2 flex justify-center w-6 h-6">
                <HomeIcon />
            </div>
        ),
    })

    if (user?.company?.enableSchedule) {
        accountMenuSettings.push({
            id: 9,
            menuItem: t('schedule.schedule'),
            href: '/me/schedule',
            icon: (
                <div className="mr-2 flex justify-center w-6 h-6">
                    <ScheduleIcon />
                </div>
            ),
        })
    }

    accountMenuSettings.push({
        id: 10,
        menuItem: t('navbar.Preferences'),
        href: '/me/preferences',
        icon: (
            <div className="mr-2 flex justify-center w-6 h-6">
                <LanguageIcon />
            </div>
        ),
    })

    const legalData = [
        {
            id: 11,
            menuItem: t('footer.terms-of–service'),
            href: '/terms-of-services',
            icon: '',
        },
        {
            id: 12,
            menuItem: t('footer.privacy-policy'),
            href: '/privacy-policy',
            icon: '',
        },
    ]

    function classNames(...classes: any[]) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div>
            {user?.company?.referralCampaignIsActive && (
                <div>
                    <div>
                        <span className="tracking-wider w-full h-[18px] text-12 font-medium text-grey01">
                            {t('navbar.referral')}
                        </span>
                    </div>

                    {accountMenuReferral?.map((item) => (
                        <div key={item.id} onClick={() => navigate(`${item.href}`)}>
                            <div>
                                <a href="#" className={classNames('border-b border-spinach text-black not-heading-font flex items-center text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font')}>
                                    {right && (
                                        pathName.startsWith(item?.href || 'home') && (
                                            <div className="mr-2">
                                                <RightArrow width={13} height={8} />
                                            </div>
                                        )
                                    )}

                                    {item.icon}
                                    {item?.menuItem}
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <div className={user?.company?.referralCampaignIsActive ? 'mt-[29px]' : ''}>
                <span className="tracking-wider w-full h-[18px] text-12 font-medium text-grey01">
                    {t('navbar.account-settings')}
                </span>
            </div>

            {accountMenuSettings?.map((item) => (
                <div key={item.id} onClick={() => navigate(`${item.href}`)}>
                    <div>
                        <a href="#" className={classNames('border-b border-spinach text-black not-heading-font flex items-center text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font')}>
                            {right && (
                                pathName.startsWith(item?.href || 'home') && (
                                    <div className="mr-2">
                                        <RightArrow width={13} height={8} />
                                    </div>
                                )
                            )}

                            {item.icon}
                            {item?.menuItem}
                        </a>
                    </div>
                </div>
            ))}

            {!isSso && (
                <div className="border-b border-spinach mt-[29px]" onClick={onLogout}>
                    <div className="flex items-center">
                        <div className="mr-2 flex justify-center items-center w-6 h-6">
                            <SignOutIcon color={whiteLabeling.raspberry} width={19} height={20} />
                        </div>

                        <a href="#"
                            className="flex not-heading-font items-center text-18 leading-[23.76px] tracking-[-.02em] font-medium pb-[4px] font text-raspberry">
                            {t('global.sign-out')}
                        </a>
                    </div>
                </div>
            )}

            {isEmpty(accountMenuBookmarks) ? null : (
                <p className="tracking-wider w-full h-[18px] text-12 font-medium text-grey01 mt-[29px]">
                    {t('navbar.bookmarks')}
                </p>
            )}

            {accountMenuBookmarks.map((item) => (
                <div key={item?.id}>
                    <div>
                        <NavLink to={item?.href || '/'} className={classNames('border-b border-spinach text-black not-heading-font flex items-center text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font')}>
                            {item?.icon}
                            {t(`navbar.${item?.menuItem}`)}
                        </NavLink>
                    </div>
                </div>
            ))}

            <div>
                <div className="mt-[29px]">
                    <span className="tracking-wider w-full h-[18px] text-12 font-medium text-grey01">
                        {t('navbar.support')}
                    </span>
                </div>

                {user?.company?.gamification && (
                    <div>
                        <h1 className="border-b border-spinach cursor-pointer flex items-center not-heading-font text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font text-black"
                            onClick={() => navigate('/me/earn-points')}>
                            {pathName.startsWith('/me/earn-points') && (
                                <div className="mr-2">
                                    <RightArrow width={13} height={8} />
                                </div>
                            )}

                            {t('navbar.how-to-earn-points')}
                        </h1>
                    </div>
                )}

                <div>
                    <h1 className="border-b border-spinach cursor-pointer flex items-center not-heading-font text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font text-black"
                        onClick={() => window.open('https://www.lowr.co/contact-us', '_blank')}>
                        {t('footer.contact-us')}
                    </h1>
                </div>

                <div>
                    <h1 className="border-b border-spinach cursor-pointer flex items-center not-heading-font text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font text-black"
                        onClick={() => navigate('/faqs')}>
                        {pathName.startsWith('/faqs') && (
                            <div className="mr-2">
                                <RightArrow width={13} height={8} />
                            </div>
                        )}

                        {t('footer.faqs')}
                    </h1>
                </div>
            </div>

            <div>
                <div className="mt-[29px]">
                    <span className="tracking-wider w-full h-[18px] text-12 font-medium text-grey01">
                        {t('navbar.legal')}
                    </span>
                </div>

                {legalData.map((item) => (
                    <div key={item?.id}>
                        <NavLink to={item?.href || '/'} className={classNames('border-b border-spinach text-black not-heading-font flex items-center text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font')}>
                            {right && (
                                pathName.startsWith(item?.href || 'home') && (
                                    <div className="mr-2">
                                        <RightArrow width={13} height={8} />
                                    </div>
                                )
                            )}

                            {item?.menuItem}
                        </NavLink>
                    </div>
                ))}

                <p className="mt-7 text-[11px] text-[#5B5B5B]">
                    © The Green Shoot Inst. Ltd.<br />
                    2022.<br />
                    All Rights Reserved.
                </p>
            </div>
        </div>
    )
}
