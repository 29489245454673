import React, { useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import Button from './Button'

interface IProps {
    totalCount: number
    currentCount: number
    containerClassName: string
    loadMore: (page: number) => void
    loading?: boolean
}

export default function Pagination({ totalCount, currentCount, containerClassName, loadMore, loading }: IProps) {
    const [hasMore, setHasMore] = useState(false)
    const [page, setPage] = useState(1)
    const [t] = useTranslation('global')
    const percentage = (currentCount * 100) / totalCount

    useEffect(() => {
        if (totalCount > currentCount) setHasMore(true)
        // eslint-disable-next-line
    }, [totalCount])

    useEffect(() => {
        if (totalCount === currentCount) setHasMore(false)
        // eslint-disable-next-line
    }, [currentCount])

    const onLoadMore = useCallback((newPage: number) => {
        setPage(newPage)
        loadMore(newPage)
        // eslint-disable-next-line
    }, [])

    if (totalCount === currentCount && totalCount === 0) return <div />

    return (
        <div className={cn('items-center flex flex-col', containerClassName)}>
            <p className="text-black font italic">
                {t('article.showing')} {currentCount} {t('article.of')} {totalCount} {t('article.items')}
            </p>

            <div className="h-1 mt-[7px] mb-[15px] xr-mobile:w-[320px] w-screen px-[16px]">
                <div className="w-[4px] h-[4px] bg-rocket relative" style={{ left: `calc(${percentage}% - 4px)` }} />
                <div className="z-10 relative flex h-1 bg-rocket" style={{ width: `${percentage}%` }} />
                <div className="w-full relative z-0 h-1 bg-mint mt-[-4px]" />
            </div>

            {hasMore && (
                <div className="flex flex-col items-center">
                    <div className="w-[132px]">
                        <Button text={t('article.load-more')} loading={loading} onClick={() => onLoadMore(page + 1)} />
                    </div>
                </div>
            )}
        </div>
    )
}
