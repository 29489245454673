import React, { useState } from 'react'
import { Field } from 'formik'
import cn from 'classnames'
import '../../static/index.css'
import { useSelector } from 'react-redux'
import ValidationErrorMessage from './ValidationErrorMessage'
import { EyeActive, EyeDefault } from './Svg'
import { getLoginConfig, getWhiteLabelingList } from '../../state/user/selectors'

interface IProps {
    name: string
    label?: string
    component?: string
    size?: string
    placeholder?: string
    type?: string
    validate?: any
    className?: string
}

// eslint-disable-next-line react/function-component-definition
const InputPassword: React.FC<IProps> = ({ label, type, validate, className, size = 'small', name, component = 'input', ...attributes }) => {
    const [showPassword, setShowPassword] = useState(true)
    const { loginConfig } = useSelector(getLoginConfig)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    return (
        <>
            <label style={styles.label} htmlFor={name} className="text-black block font-medium font">
                {label}
            </label>

            <div className="flex items-center justify-end">
                <Field
                    style={{ borderColor: loginConfig?.whiteLabeling?.avocado || whiteLabeling.avocado }}
                    id="inputID"
                    name={name}
                    as={component}
                    size={size}
                    className={cn('inputID font italic border text-sm rounded-lg h-12 focus:ring-blue-500 block w-full p-2.5 dark:placeholder-gray-400', className)}
                    validate={validate}
                    type={showPassword ? type : 'text'}
                    {...attributes}
                />

                <button type="button" onClick={() => setShowPassword(!showPassword)} className="cursor-pointer absolute pr-[12px]">
                    {!showPassword ? (
                        <EyeActive width={24} height={24} />
                    ) : (
                        <EyeDefault width={24} height={24} />
                    )}
                </button>
            </div>

            <ValidationErrorMessage name={name} />
        </>
    )
}

const styles = {
    label: {
        fontSize: 14,
        marginBottom: 6,
    },
}

export default InputPassword
