import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SecondButton from '../common/SecondButton'
import { IChallenges } from '../../types/data'
import Skeleton from '../common/Skeleton'

interface IProps {
    item: IChallenges
    home?: boolean
}

export default function ChallengeCardMini({ item, home }: IProps) {
    const navigate = useNavigate()
    // eslint-disable-next-line no-unsafe-optional-chaining
    const percentage = Math.trunc((item?.loggedDaysCount * 100) / item?.challengeDuration)
    // eslint-disable-next-line no-unsafe-optional-chaining
    const day = item?.challengeDuration - item?.loggedDaysCount <= 1
    const [t] = useTranslation('global')

    const navigateChallengeDetail = (challengeId: number) => {
        navigate(`/lower/pledges/challenge-detail/${challengeId}`)
    }

    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    return (
        <div onClick={() => navigateChallengeDetail(item?.id)}>
            <div className="mt-3 cursor-pointer w-full" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                <div className="flex flex-col justify-end">
                    {loading && <Skeleton className={`${home ? 'mobile:h-[351px]' : 'mobile:h-[270px]'} rounded-t-lg object-cover`} />}

                    <img onLoad={handleImageLoad} className={`rounded-t-lg ${home ? 'mobile:h-[351px]' : 'mobile:h-[270px]'} ${loading ? 'hidden' : 'block'} object-cover`} src={item?.image} alt="" />

                    {!loading && (
                        <div className="w-[44px] h-[17px] flex items-center justify-center ml-4 absolute">
                            <img src={item?.activeBadgeIcon} alt="" className="w-[36px] h-[44px]" />
                        </div>
                    )}
                </div>

                <div className="p-4 rounded-b-lg bg-pistachio">
                    <div>
                        {!home && (
                            <h1 className="font-normal text-grey01 text-14">
                                {item?.challengeName}
                            </h1>
                        )}

                        <h1 className={`text-black font tracking-tight font-medium ${home ? 'text-18' : 'mobile:text-18 text-22'}`}>
                            {loading ? (
                                <Skeleton className="w-full mr-[45%] h-[13px] rounded-sm mt-2" />
                            ) : (
                                item?.title
                            )}
                        </h1>

                        <h1 className="font-normal text-grey01 text-14">
                            {loading ? (
                                <Skeleton className="w-8/12 mr-[45%] h-[13px] rounded-sm mt-2" />
                            ) : (
                                item?.shortDescription
                            )}
                        </h1>
                    </div>

                    {item?.isJoined && (
                        <>
                            <div className="rounded-full flex-1 h-3 mt-2.5 mb-1.5" style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.25)' }}>
                                <div className="z-10 relative flex rounded-r-sm rounded-l-xl h-3 bg-raspberry" style={{ width: `${item?.loggedDaysCount}%` }} />
                                <div className="w-full relative z-0 rounded-full bg-white h-2.5" style={{ marginTop: -11 }} />
                            </div>

                            <span className="text-spinach font-semibold text-14 tracking-tight">
                                {percentage}% {t('global.complete')}!&nbsp;

                                {!day
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    ? item?.challengeDuration - item?.loggedDaysCount !== 0 && (
                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                        <span>{item?.challengeDuration - item?.loggedDaysCount} {t('global.days-left')}</span>
                                    )
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    : item?.challengeDuration - item?.loggedDaysCount !== 0 && (
                                        // eslint-disable-next-line no-unsafe-optional-chaining
                                        <span>{item?.challengeDuration - item?.loggedDaysCount} {t('global.day-left')}</span>
                                    )}
                            </span>
                        </>
                    )}

                    {item?.isActive && !item?.isJoined
                    && (
                        <SecondButton
                            disabled={loading}
                            text={t('global.join-the-challenge')}
                            className={`mt-2.5 ${home ? 'mobile:w-[184px] w-full' : 'w-full'} h-[48px] font-medium bg-rocket rounded-md text-14 text-pistachio ${loading && 'hover:bg-spinach hover:ease-in hover:duration-200'}`}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
