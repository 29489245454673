import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ICompanyArticle } from '../../../types/data'
import { getDateTime } from '../../../utils/date'
import Skeleton from '../../common/Skeleton'
import { getUser } from '../../../state/user/selectors'
import ContentBlockTitle from '../../ContentBlockTitle'

interface IProps {
    item: ICompanyArticle
}

export default function CompanyInPartnershipCard({ item }: IProps) {
    const navigate = useNavigate()
    const [t] = useTranslation('global')
    const { user } = useSelector(getUser)
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    let extractedNumber: number | null = null

    if (typeof item?.getReadTime === 'string') {
        const matchResult = item?.getReadTime.match(/\d+/)
        if (matchResult) {
            extractedNumber = parseInt(matchResult[0], 10)
        }
    }

    return (
        <div onClick={() => navigate(`/learn/organisation-news/${item.id}`)} className="w-full mb-8 xs-mobile:mt-8 mt-6 font cursor-pointer" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div className="relative">
                <div className="bg-pistachio hidden tablet911:block w-[456px] rounded-lg absolute right-6 bottom-6">
                    <div className="p-5 flex flex-col">
                        <h4 className="font-light tracking-tight text-grey01 text-12">
                            {user?.company?.isDateStampShown && getDateTime(item?.createdAt)} {user?.company?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                        </h4>

                        <ContentBlockTitle text={item?.title} />
                        {/* <h2 className="mt-0.5 font-medium font heading-font tracking-tight text-black text-[22px]"> */}
                        {/*    {item?.title} */}
                        {/* </h2> */}

                        <p className="mt-1 font-light tracking-tight text-grey01 text-16">
                            {item?.subTitle}
                        </p>
                    </div>
                </div>

                {loading && <Skeleton className="object-cover laptop:h-[560px] tablet:h-[460px] tablet911:h-[434px] mobile:h-[353px] h-410 rounded-t-lg tablet911:rounded-lg w-full" />}
                <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} object-cover laptop:h-[560px] tablet:h-[460px] tablet911:h-[434px] mobile:h-[353px] h-410 rounded-t-lg tablet911:rounded-lg w-full`} src={item?.image} alt="" />

                {/* bottom */}
                <div className="py-4 rounded-b-lg block tablet911:hidden bg-pistachio">
                    <p className="font-light px-4 tracking-tight text-grey01 font text-12">
                        {user?.company?.isDateStampShown && getDateTime(item?.createdAt)} {user?.company?.isDateStampShown && '|'} {extractedNumber} {t('global.min')} {t('global.read')}
                    </p>

                    <h5 className="mt-0.5 px-4 font-medium font heading-font tracking-tight text-black text-18">
                        {item?.title}
                    </h5>

                    <p className="mt-0.5 px-4 font-light tracking-tight text-grey01 text-14">
                        {item?.subTitle}
                    </p>
                </div>
            </div>

        </div>
    )
}
