import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { HomeState } from './types'
import { FETCH_HOME_START,
    GET_HOME_PAGE_LIST,
    GET_HOW_TO_COLLECT_POINTS,
    GET_SPONSORSHIP_BLOCKS_SUCCESS,
    HOME_LIST_ERROR,
    POST_CHALLENGE_JOIN_HOME_SUCCESS,
    POST_CHALLENGE_LOG_DAY_HOME_SUCCESS,
    POST_HOME_BOOKMARK_SUCCESS } from './consts'

const initialState: HomeState = {
    home: [],
    // @ts-ignore
    homePage: {},
    // @ts-ignore
    howToCollectPoints: [],
    count: 0,
    lastPage: 1,
    next: null,
    previous: null,
    loading: false,
    sponsorshipBlocks: [],
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AnyAction): HomeState => {
    const { payload } = action

    switch (action.type) {
    case FETCH_HOME_START:
        return {
            ...state,
            loading: true,
        }
    case GET_HOME_PAGE_LIST:
        return {
            ...state,
            homePage: payload,
            loading: false,
        }

    case POST_HOME_BOOKMARK_SUCCESS:
        const newBookmarkHomePerkOfTheDay = state.homePage.perkOfTheDay.id === payload.bookmarkedId ? {
            ...state.homePage.perkOfTheDay,
            isBookmark: payload.isBookmark,
        } : state.homePage.perkOfTheDay

        const newBookmarkHomeOfTheMonth = state.homePage.freePerkOfTheMonth.id === payload.bookmarkedId ? {
            ...state.homePage.freePerkOfTheMonth,
            isBookmark: payload.isBookmark,
        } : state.homePage.freePerkOfTheMonth

        const newBookmarkHomePerkCollection = state.homePage.perkCollection.perks.map((item) => (item.id === payload.bookmarkedId
            ? {
                ...item,
                isBookmark: payload.isBookmark,
            }
            : item))

        const newBookmarkHomeRecipeCollection = state.homePage.recipeCollection.recipe.map((item) => (item.id === payload.bookmarkedId
            ? {
                ...item,
                isBookmark: payload.isBookmark,
            }
            : item))

        return {
            ...state,
            homePage: {
                ...state.homePage,
                perkOfTheDay: newBookmarkHomePerkOfTheDay,
                freePerkOfTheMonth: newBookmarkHomeOfTheMonth,
                perkCollection: {
                    ...state.homePage.perkCollection,
                    perks: newBookmarkHomePerkCollection,
                },
                recipeCollection: {
                    ...state.homePage.recipeCollection,
                    recipe: newBookmarkHomeRecipeCollection,
                },
            },
            loading: false,
        }

    case POST_CHALLENGE_JOIN_HOME_SUCCESS:
        const newChallengeHomePage = state.homePage.challenge.id === payload.challenge
            ? {
                ...state.homePage.challenge,
                isJoined: true,
            }
            : state.homePage.challenge
        return {
            ...state,
            homePage: {
                ...state.homePage,
                challenge: newChallengeHomePage,
            },
            loading: false,
        }

    case GET_SPONSORSHIP_BLOCKS_SUCCESS:
        return {
            ...state,
            sponsorshipBlocks: payload,
            loading: false,
        }

    case POST_CHALLENGE_LOG_DAY_HOME_SUCCESS:
        const newChallengeLoggedDayHomePage = state.homePage.challenge.id === payload.challenge
            ? {
                ...state.homePage.challenge,
                loggedDaysCount: state.homePage.challenge.loggedDaysCount + 1,
            }
            : state.homePage.challenge

        return {
            ...state,
            homePage: {
                ...state.homePage,
                challenge: newChallengeLoggedDayHomePage,
            },
            loading: false,
        }

    case GET_HOW_TO_COLLECT_POINTS:
        return {
            ...state,
            howToCollectPoints: payload,
            loading: false,
        }

    case HOME_LIST_ERROR:
        return { ...state, loading: false }
    default:
        return state
    }
}

const persistConfig: PersistConfig<HomeState> = {
    key: 'home',
    storage,
    whitelist: ['home', 'homePage', 'sponsorshipBlocks'],
}

export default persistReducer(persistConfig, reducer)
