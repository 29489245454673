import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/layout/Layout'
import { FilterIcon } from '../../components/common/Svg'
import SearchInput from '../../components/common/SearchInput'
import RecipeCategories from '../../components/common/RecipeCategories'
import { fetchRecipeCategoryList, fetchRecipesByCategory, fetchRecipeTagsList } from '../../state/recipe/actions'
import RecipeTags from '../../components/common/RecipeTags'
import { getRecipe } from '../../state/recipe/selectors'
import RecipeCard from '../../components/recipe/RecipeCard'
import { getPerkLists } from '../../state/perks/selectors'
import RecentSearches from '../../components/RecentSearches'

export default function RecipeByCategory() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { categoryId } = useParams()
    const { recipeCategories, recipesListByCategory, searchCollection } = useSelector(getRecipe)
    const recipeCategory = recipeCategories?.find((item) => item.id === Number(categoryId))
    const { recentSearches } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const [t] = useTranslation('global')
    const handleFocus = () => setOnFocus(true)

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        dispatch(fetchRecipesByCategory(Number(categoryId)))
        if (recipeCategories.length === 0) dispatch(fetchRecipeCategoryList)
        // eslint-disable-next-line
    }, [categoryId])

    const data = [
        { id: 1, title: t('lower.all-categories'), content: <RecipeCategories />, loadContent: () => dispatch(fetchRecipeCategoryList) },
        { id: 2, title: t('lower.tags'), content: <RecipeTags />, loadContent: () => dispatch(fetchRecipeTagsList) },
    ]

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        openedMenu: 1,
        buttonName: t('lower.recipes'),
    }

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="pb-16 mobile:mx-10 mx-16 medium:mt-[52px] mt-[32px]">
                <span onClick={() => navigate('/lower/recipes')} className="xs-mobile:block hidden text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                    {t('account.Recipes')}
                </span>

                <div className="flex items-center flex-row justify-between mt-[-20px] pull-right">
                    <SearchInput action="recipes" placeholder={t('lower.what-would-you-like-to-make')} onFocus={handleFocus} />
                </div>

                {onFocus && (
                    <RecentSearches data={recentSearches?.recipes} type="recipes" collection={searchCollection} />
                )}

                <div className="flex items-center justify-between">
                    <h1 className="xs-mobile:block hidden font tracking-tight font-medium text-36">
                        {recipeCategory?.name}
                    </h1>
                </div>

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/lower/recipes')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('account.Recipes')}
                    </span>

                    <h1 className="font tracking-tight font-medium mt-2 text-36">
                        {recipeCategory?.name}
                    </h1>
                </div>

                <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mobile:mt-10 mt-6">
                    {recipesListByCategory?.map((item) => (
                        <div key={item.id}>
                            <RecipeCard item={item} type="recipe" />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}
