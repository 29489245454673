import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AuthContainer from '../components/auth/LoginContainer'
import { getLoginConfig } from '../state/user/selectors'
import { Colors } from '../themes'

export default function ResetLinkSent() {
    const navigate = useNavigate()
    const { loginConfig } = useSelector(getLoginConfig)
    const [t] = useTranslation('global')

    const backSign = () => {
        // @ts-ignore
        navigate('/login')
    }

    return (
        <AuthContainer title={(
            <>
                {t('login.check-your-inbox')}
                <div style={{ color: loginConfig?.whiteLabeling?.grey01 }} className="mt-[32px] xs-mobile:w-[343px]">
                    <h1 style={{ color: loginConfig?.whiteLabeling?.grey01 }} className="text-16 leading-[150%] font-normal">
                        {t('login.reset-password-description-first')}
                    </h1>

                    <h1 style={{ color: loginConfig?.whiteLabeling?.grey01 }} className="text-16 leading-[150%] font-normal">
                        {t('login.reset-password-description-second')}
                    </h1>
                </div>
            </>
        )}>

            <div className="flex justify-center">
                <button style={{ color: loginConfig?.whiteLabeling?.rocket || Colors.rocket }} onClick={backSign} className="font-semibold tracking-tight underline underline-offset-2 text-14">
                    {t('login.go-back-to-the-login-page')}
                </button>
            </div>
        </AuthContainer>
    )
}
