import React, { useCallback, useContext, useLayoutEffect, useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import { useDispatch, useSelector } from 'react-redux'
import { DismissIcon } from './common/Svg'
import SecondButton from './common/SecondButton'
import '../static/index.css'
import { getPrize } from '../state/prize/selectors'
import { fetchPrizeWheelList, updatePrize } from '../state/prize/actions'
import { Context } from './common/BaseContext'
import { fetchUser } from '../state/user/actions'

interface IProps {
    isShown: boolean,
    onClose: () => void
}

export default function SpinTheWheelModal({ isShown, onClose }: IProps) {
    const dispatch = useDispatch()
    const { prizeWheelType, winnersPrice } = useSelector(getPrize)
    const { setPointModal } = useContext(Context)
    const prizePoints = prizeWheelType?.prizePoints
    const { screenWidth } = useContext(Context)

    const onRestartLoad = useCallback(() => {
        dispatch(fetchPrizeWheelList)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const data = prizePoints ? [
        { id: prizePoints[6]?.id, option: prizePoints[6]?.amount.toString(), style: { backgroundColor: '#ECFFFC' } },
        { id: prizePoints[7]?.id, option: prizePoints[7]?.amount.toString(), style: { backgroundColor: '#C8F2EC' } },
        { id: prizePoints[8]?.id, option: prizePoints[8]?.amount.toString(), style: { backgroundColor: '#FE9787' } },
        { id: prizePoints[0]?.id, option: prizePoints[0]?.amount.toString(), style: { backgroundColor: '#ECFFFC' } },
        { id: prizePoints[1]?.id, option: prizePoints[1]?.amount.toString(), style: { backgroundColor: '#C8F2EC' } },
        { id: prizePoints[2]?.id, option: prizePoints[2]?.amount.toString(), style: { backgroundColor: '#ECFFFC' } },
        { id: prizePoints[3]?.id, option: prizePoints[3]?.amount.toString(), style: { backgroundColor: '#C8F2EC' } },
        { id: prizePoints[4]?.id, option: prizePoints[4]?.amount.toString(), style: { backgroundColor: '#ECFFFC' } },
        { id: prizePoints[5]?.id, option: prizePoints[5]?.amount.toString(), style: { backgroundColor: '#C8F2EC' } },
    ] : []

    const winPrizes = prizeWheelType?.prizePoints?.filter((x) => x.winPrize).map((i) => data?.findIndex((x) => x?.id === i?.id))

    const [mustSpin, setMustSpin] = useState(false)
    const [prizeNumber, setPrizeNumber] = useState(0)

    const handleSpinClick = async () => {
        const newPrizeNumber = winPrizes[Math.floor(Math.random() * winPrizes.length)]
        setPrizeNumber(newPrizeNumber)
        setMustSpin(true)
    }

    const onStopSpinning = async () => {
        setMustSpin(false)

        setPointModal({ show: true, data: { points: Number(data[prizeNumber].option), description: "For spinning the wheel you've earned" } })
        onClose()

        await dispatch(
            updatePrize({
                id: winnersPrice.id,
                isShown: true,
                pointsTarget: winnersPrice?.pointsTarget?.id || 0,
                points: Number(data[prizeNumber].option),
            }),
        )
        dispatch(fetchUser)
    }

    return (
        <div>
            {isShown ? (
                <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                    <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                    <div className="mobile:mt-[60px] flex items-center justify-center min-h-screen">
                        <div className="bg-pistachio relative xs-mobile:w-[500px] mobile:h-[431px] w-11/12 h-[369px] bg-white pb-10 mobile:pt-4 pt-3 mobile:px-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                            <div onClick={onClose} className="mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                            </div>

                            <div className="flex items-end justify-center mobile:mt-[135px] mt-[100px]">
                                <div className="parent-container mobile:w-[325px] mobile:h-[325px] w-[220px] h-[220px] absolute rounded-full" style={{ transform: 'rotate(123deg)' }}>
                                    <Wheel
                                        outerBorderColor="#052F11"
                                        outerBorderWidth={window.self.innerWidth > 768 ? 14 : 18}
                                        innerBorderWidth={0}
                                        radiusLineWidth={0}
                                        mustStartSpinning={mustSpin}
                                        prizeNumber={prizeNumber}
                                        data={data}
                                        textColors={['#313131']}
                                        fontSize={22}
                                        perpendicularText
                                        onStopSpinning={onStopSpinning}
                                        textDistance={window.self.innerWidth > 768 ? 77 : 75}
                                        spinDuration={0.3}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col justify-center items-center">
                                <div className="xs-mobile:w-[355px] w-[242px] flex flex-col items-center justify-center">
                                    <h1 className="mobile:text-22 text-18 text-darken font-semibold tracking-tight font mobile:mt-[41px] mt-[25px]">
                                        Power up!
                                    </h1>

                                    <h1 className="text-center mobile:text-22 text-18 text-darken tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                        Spin the wheel for a chance to win bonus PLANTS POINTS!
                                    </h1>
                                </div>

                                <SecondButton disabled={mustSpin} onClick={handleSpinClick} text="Spin the wheel" className="mt-6 w-[160px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                {/* <h1>{!mustSpin ? data[prizeNumber].option : '0'}</h1> */}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
