import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Cross } from './Svg'
import { ITags } from '../../types/data'
import { useQueryParams } from '../../hooks/queryString'
import { getRecipe } from '../../state/recipe/selectors'

export default function RecipeTags() {
    const navigate = useNavigate()
    const { tags } = useQueryParams()
    const tagsList = typeof tags === 'string' ? tags?.split(',') : []

    const { recipeTags } = useSelector(getRecipe)

    const onChangeValueTag = (item: ITags) => {
        const newTags = tagsList.find((i) => i === item.id.toString())
            ? tagsList.filter((i) => i !== item.id.toString())
            : [...tagsList, item.id]

        if (newTags.length > 0) {
            navigate(`/lower/recipes/filter-by-tag?tags=${newTags.join(',')}`)
        } else {
            navigate('/lower/recipes')
        }
    }

    return (
        <div className="flex flex-wrap">
            {recipeTags.map((item) => {
                const isSelected = tagsList.includes(String(item.id))

                return (
                    <button onClick={() => onChangeValueTag(item)} key={item.id}
                        className={`h-[32px] rounded-[30px] flex items-center justify-center cursor-pointer mt-[8px] mb-[4px] mr-2 hover:font-bold hover:ease-in hover:duration-200 ${isSelected ? 'px-3 bg-cavolo' : 'px-5 bg-rocket'} `}>

                        <span className="font-semibold text-pistachio text-12">
                            {item.title}
                        </span>

                        {isSelected ? (
                            <div className="ml-1.5">
                                <Cross width={10} height={10} />
                            </div>
                        ) : null}
                    </button>
                )
            })}
        </div>
    )
}
