const BASE = 'user/'

export const FETCH_CHALLENGE_START = `${BASE}FETCH_CHALLENGE_START`

export const FETCH_CHALLENGE_LIST_SUCCESS = `${BASE}FETCH_CHALLENGE_LIST_SUCCESS`
export const FETCH_PLEDGE_DETAIL_SUCCESS = `${BASE}FETCH_PLEDGE_DETAIL_SUCCESS`
export const POST_CHALLENGE_JOIN_SUCCESS = `${BASE}POST_CHALLENGE_JOIN_SUCCESS`
export const POST_REMIND_ME_NOTIFICATION_SUCCESS = `${BASE}POST_REMIND_ME_NOTIFICATION_SUCCESS`
export const POST_CHALLENGE_REMIND_ON_START_SUCCESS = `${BASE}POST_CHALLENGE_REMIND_ON_START_SUCCESS`
export const DELETE_CHALLENGE_REMIND_ON_START_SUCCESS = `${BASE}DELETE_CHALLENGE_REMIND_ON_START_SUCCESS`
export const FETCH_CHALLENGE_LOGGED_DAYS_SUCCESS = `${BASE}FETCH_CHALLENGE_LOGGED_DAYS_SUCCESS`
export const POST_CHALLENGE_LOG_DAY_SUCCESS = `${BASE}POST_CHALLENGE_LOG_DAY_SUCCESS`
export const FETCH_LAST_DAY_TO_TRACK_LIST_SUCCESS = `${BASE}FETCH_LAST_DAY_TO_TRACK_LIST_SUCCESS`
export const FETCH_MY_PLEDGES_LIST_SUCCESS = `${BASE}FETCH_MY_PLEDGES_LIST_SUCCESS`
export const FETCH_PLEDGES_LIST_SUCCESS = `${BASE}FETCH_PLEDGES_LIST_SUCCESS`
export const POST_COMMIT_PLEDGE_SUCCESS = `${BASE}POST_COMMIT_PLEDGE_SUCCESS`

export const FETCH_CHALLENGE_ERROR = `${BASE}FETCH_CHALLENGE_ERROR`
