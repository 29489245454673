import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import bolMealCoupon from '../../static/bol-meal-coupon.png'
import Input from '../common/Input'
import { IPerksBolCouponData } from '../../types/data'
import CustomPhoneInput from '../common/CustomPhoneInput'
import Checkbox from '../common/Checkbox'
import BlueLink from '../common/BlueLink'
import { required } from '../../utils/validators'
import Button from '../common/Button'
import { getUser } from '../../state/user/selectors'
import { postCouponData } from '../../state/perks/api'
import { DismissIcon } from '../common/Svg'
import ServerError from '../common/ServerError'
import { updatePrize } from '../../state/prize/actions'
import { getPrize } from '../../state/prize/selectors'

interface IProps {
    onClose?: () => void
}

interface IResponse {
    error?: {message: string}
    response?: {message: string}
    loading: boolean
}

const initialResponse = { error: { message: '' }, response: { message: '' }, loading: false }

export default function PerkBolCouponModal({ onClose }: IProps) {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const { winnersPrice } = useSelector(getPrize)
    const [response, setResponse] = useState<IResponse>(initialResponse)
    const [t] = useTranslation('global')

    const onSubmit = async (formData:IPerksBolCouponData) => {
        setResponse({ ...initialResponse, loading: true })
        const data = await postCouponData(formData)

        setResponse({ ...data, loading: false })

        await dispatch(
            updatePrize({
                id: winnersPrice.id,
                isShown: true,
                pointsTarget: winnersPrice?.pointsTarget?.id || 0,
                points: 0,
            }),
        )
    }

    const initialValues: IPerksBolCouponData = {
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: '+44',
        email: user.email,
        privacy: false,
        hasOptinPrimary: false,
        hasOptinSecondary: false,
    }

    return (
        <div className="fixed inset-0 z-[1000000] overflow-y-auto">
            <div onClick={onClose} className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

            <div className="flex items-center justify-center min-h-screen">
                <div className="relative w-11/12 mobile:w-[540px] bg-white mobile:p-5 p-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                    <div onClick={onClose} className="mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center cursor-pointer mb-3">
                        <DismissIcon width={window.self.innerWidth > 768 ? 20 : 15} height={window.self.innerWidth > 768 ? 20 : 15} />
                    </div>

                    <div className="min-[600px]:flex p-3 bg-grey04 rounded-lg mb-5">
                        <img className="rounded-md mx-auto max-w-[230px] w-full" src={bolMealCoupon} />

                        <div className="pl-2.5 mt-2.5 min-[600px]:mt-0">
                            <p className="font-semibold text-center text-[21px] tracking-[-0.02rem]">
                                {t('lower.grab-a-free-bol-meal')}
                            </p>

                            <p className="tracking-tight mt-2.5 text-center text-14 min-[600px]:text-16">
                                {t('lower.available-on-all-bol-products-at-participating-tesco-stores-with-this-coupon')}
                            </p>

                            <BlueLink
                                text={t('lower.find-a-participating-tesco-store')}
                                to="https://bolfoods.zptr-m.im/map/5031"
                                className="mt-2.5 block"
                                target="_blank" />
                        </div>
                    </div>

                    {!response.response?.message ? (
                        <>
                            <p className="text-center text-14 min-[600px]:text-16">
                                {t('lower.please-enter-your-contact-information-and-we-will-send-you-the-offer')}
                            </p>

                            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                                <Form>
                                    <div className="flex justify-between mt-6">
                                        <Input
                                            name="firstName"
                                            placeholder={t('lower.first-name')}
                                            type="text"
                                            className="w-[49%]" />

                                        <Input
                                            name="lastName"
                                            placeholder={t('lower.last-name')}
                                            type="text"
                                            className="w-[49%]" />
                                    </div>

                                    {response?.error?.message && response.error.message.includes('Name cannot') && (
                                        <ServerError error={response?.error?.message} />
                                    )}

                                    <Input
                                        hasError={!!response?.error?.message && response.error.message.includes('Email address')}
                                        name="email"
                                        placeholder={t('lower.email')}
                                        error={response?.error?.message}
                                        type="email" />

                                    <CustomPhoneInput
                                        validate={required(t)}
                                        name="phoneNumber"
                                        placeholder={t('lower.mobile-number-(required)')} />

                                    {response?.error?.message && response.error.message.includes('Phone number') && (
                                        <div className="my-2">
                                            <ServerError error={response?.error?.message} />
                                        </div>
                                    )}

                                    <p className="text-12 text-[#aaa] mt-1 mb-2">
                                        {t('lower.your-information-will-not-be-used-without-your-full-consent')}
                                    </p>

                                    <Checkbox
                                        name="privacy"
                                        validate={required(t)}
                                        label={(
                                            <>
                                                I accept the Terms and&nbsp;

                                                <BlueLink
                                                    text={t('lower.privacy-policy')}
                                                    to="https://bolfoods.com/pages/privacy-policy"
                                                    className="mt-2.5"
                                                    target="_blank" />
                                            </>
                                        )} />

                                    <Checkbox name="hasOptinPrimary"
                                        label={t('lower.yes-i-would-like-to-opt-in-to-receive-more-offers-from-lowr-in-the-future')} />
                                    <Checkbox name="hasOptinSecondary"
                                        label={t('lower.yes-i-would-like-to-opt-in-to-receive-more-offers-from-bol-foods-in-the-future')} />

                                    <Button loading={response.loading} text={t('lower.get-coupon')} />
                                </Form>
                            </Formik>
                        </>
                    ) : (
                        <div className="text-center">
                            {response.response?.message}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
