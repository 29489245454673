import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { getPerkLists } from '../../state/perks/selectors'
import { Cross, FilterIcon } from '../../components/common/Svg'
import AllBrandsItem from '../../components/common/AllBrandsItem'
import PerkByTypeItem from '../../components/common/PerkByTypeItem'
import PerkTags from '../../components/common/PerkTags'
import PerkCard from '../../components/perk/PerkCard'
import { useQueryParams } from '../../hooks/queryString'
import SearchInput from '../../components/common/SearchInput'
import { IPerkFilterTag } from '../../types/data'
import { fetchByPerkByTag } from '../../state/perks/actions'
import RecentSearches from '../../components/RecentSearches'

export default function PerkFilterByTag() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { tags } = useQueryParams()
    const tagsList = typeof tags === 'string' ? tags?.split(',') : []
    const { perksByFilterTag, loading, recentSearches } = useSelector(getPerkLists)
    const { perksFilterTag, searchCollection } = useSelector(getPerkLists)
    const filteredData = perksFilterTag?.filter((item) => tagsList.includes(item.id.toString()))
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Swaps filter by tag' })
    }, [])

    const data = [
        { id: 1, title: t('lower.swaps-by-type'), content: <PerkByTypeItem /> },
        { id: 2, title: t('lower.all-brands'), content: <AllBrandsItem /> },
        { id: 3, title: t('lower.tags'), content: <PerkTags /> },
    ]
    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        openedMenu: 3,
        buttonName: t('lower.swaps'),
    }

    const onChangeValueTag = (item: IPerkFilterTag) => {
        const newTags = tagsList.find((i) => i === item.id.toString())
            ? tagsList.filter((i) => i !== item.id.toString())
            : [...tagsList, item.id]

        if (newTags.length > 0) {
            navigate(`/lower/swaps/swap-filter-by-tag?tags=${newTags.join(',')}`)
        } else {
            navigate('/lower/swaps')
        }

        dispatch(fetchByPerkByTag(newTags.toString()))
    }

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className={`mobile:mt-[52px] mt-[32px] pb-16 mt-2 mobile:mx-10 mx-16 ${perksByFilterTag.length === 0 && !loading && 'h-screen'}`}>
                <span onClick={() => navigate('/lower/swaps')} className="xs-mobile:block hidden text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                    {t('navbar.Swaps')}
                </span>

                <div className="flex items-center justify-between">
                    <h1 className="xs-mobile:block hidden font tracking-tight font-medium tablet:text-36 mobile:text-32 text-26 text-black">
                        {t('lower.filter-results-for')}
                    </h1>

                    <SearchInput
                        action="perks"
                        placeholder={t('lower.find-a-great-swap')}
                        onFocus={handleFocus} />
                </div>

                {onFocus && (
                    <RecentSearches
                        data={recentSearches?.perks}
                        type="perks"
                        collection={searchCollection} />
                )}

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/lower/swaps')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('navbar.Swaps')}
                    </span>

                    <h1 className="text-black font tracking-tight font-medium mt-2 text-26">
                        {t('lower.filter-results-for')}
                    </h1>
                </div>

                <div className="flex flex-wrap mt-3">
                    {filteredData?.map((item) => {
                        const isSelected = tagsList.includes(String(item.id))

                        return (
                            <button onClick={() => onChangeValueTag(item)} key={item.id}
                                className={`h-[32px] rounded-[30px] flex items-center justify-center cursor-pointer mb-4 mr-2 ${isSelected ? 'px-3 bg-cavolo' : 'px-5 bg-rocket'} `}>

                                <span className="font-semibold text-pistachio text-12">
                                    {item.title}
                                </span>

                                {isSelected ? (
                                    <div className="ml-1.5">
                                        <Cross width={10} height={10} />
                                    </div>
                                ) : null}
                            </button>
                        )
                    })}
                </div>

                {perksByFilterTag.length === 0 && !loading && (
                    <h1 className="font tracking-tight font-medium mt-4 text-18">
                        {t('lower.your-filters-returned-no-results')}
                    </h1>
                )}

                {perksByFilterTag.length !== 0 && !loading && (
                    <h1 className="font tracking-tight font-medium text-black mt-4 text-18">
                        {perksByFilterTag.length} {t('article.results')}
                    </h1>
                )}

                <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-3">
                    {perksByFilterTag?.map((item, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index}>
                            <PerkCard item={item} type="perk" />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}
