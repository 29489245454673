import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IFactOfTheDay } from '../types/data'
import { MindIcon } from './common/Svg'
import Skeleton from './common/Skeleton'
import { getHomePageLists } from '../state/home/selectors'
import FactOfTheDayModal from './FactOfTheDayModal'
import { Context } from './common/BaseContext'

interface IProps {
    item: IFactOfTheDay
}

export default function FactOfTheDay({ item }: IProps) {
    const { homePage } = useSelector(getHomePageLists)
    const [t] = useTranslation('global')
    const [loading, setLoading] = useState(true)
    const { showFactOfTheDayModal, setShowFactOfTheDayModal } = useContext(Context)

    useEffect(() => {
        if (item?.body?.length > 0) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [item])

    const onPress = () => {
        setShowFactOfTheDayModal(true)
    }

    return (
        <div className="w-full rounded-lg p-4 mobile:p-5 bg-pistachio" style={{ boxShadow: '3px 3px 0px 0px rgba(0, 0, 0, 0.15) inset, 2px 2px 0px 0px rgba(0, 0, 0, 0.15)' }}>
            <div className="flex items-center">
                <MindIcon width={24} height={24} />

                <span className="text-14 ml-2 not-heading-font text-cavolo leading-[150%] tracking-[-0.01em]">
                    {t('home.did-you-know')}
                </span>
            </div>

            <p className="relative font-medium heading-font font mt-4 text-cavolo text-18 leading-[132%] tracking-[-0.02rem]">
                {loading ? (
                    <>
                        <Skeleton className="w-full h-[13px] rounded-sm mt-3" />
                        <Skeleton className="w-8/12 h-[13px] rounded-sm mt-3" />
                    </>
                ) : (
                    item?.body
                )}
            </p>

            {homePage?.factOfTheDayShowReadMore && (
                <button onClick={onPress} className="font-semibold not-heading-font underline underline-offset-2 mt-4 text-14 text-cavolo tracking-[-0.02rem] leading-[150%]">
                    {t('home.read-more')}
                </button>
            )}

            {showFactOfTheDayModal && (
                <FactOfTheDayModal setShowModal={setShowFactOfTheDayModal} item={item} />
            )}
        </div>

    )
}
