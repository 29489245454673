import React, { useRef } from 'react'
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'
import { SliderLeftArrow, SliderRightArrow } from '../common/Svg'
import RecipeCard from './RecipeCard'
import { IRecipe } from '../../types/data'

interface IProps {
    items: IRecipe[]
}

export function RecipeMoreLikeThis({ items }: IProps) {
    const sliderRef = useRef<Slider>(null)
    const [t] = useTranslation('global')

    const next = () => {
        sliderRef.current?.slickNext()
    }

    const previous = () => {
        sliderRef.current?.slickPrev()
    }

    const settings = {
        speed: 500,
        infinite: false,
        arrows: false,
        variableWidth: true,
        slidesToScroll: 3,
        responsive: [
            { breakpoint: 415, settings: { slidesToScroll: 1 } },
            { breakpoint: 600, settings: { slidesToScroll: 2 } },
            { breakpoint: 815, settings: { slidesToScroll: 3 } },
            { breakpoint: 1199, settings: { slidesToScroll: 4 } },
            { breakpoint: 1400, settings: { slidesToScroll: 3 } },
            { breakpoint: 100000, settings: { slidesToScroll: 4 } },
        ],
    }

    return (
        <div className="medium:ml-10 ml-4">
            <div className="mb-5 flex justify-between mr-10">
                <h2 className="heading-uppercase text-black font text-18 font-medium">
                    {t('lower.more-recipes-like-this')}
                </h2>

                <div className="flex items-center">
                    <div onClick={previous} className="flex items-center justify-center cursor-pointer w-[40px] h-[40px]">
                        <SliderLeftArrow height={12.73} width={7.78} />
                    </div>

                    <div className="flex items-center justify-center cursor-pointer w-[40px] h-[40px]" onClick={next}>
                        <SliderRightArrow height={12.73} width={7.78} />
                    </div>
                </div>
            </div>

            <Slider ref={sliderRef} {...settings}>
                {items.map((item) => (
                    <div key={`${item.id}-slider`} className="tablet:pr-8 medium:pr-6 pr-4 slider-item">
                        <RecipeCard item={item} />
                    </div>
                ))}
            </Slider>
        </div>
    )
}
