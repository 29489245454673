import React from 'react'
import cn from 'classnames'
import { Field } from 'formik'
import { useSelector } from 'react-redux'
import ValidationErrorMessage from './ValidationErrorMessage'
import { getLoginConfig, getWhiteLabelingList } from '../../state/user/selectors'
import { Colors } from '../../themes'

interface CheckboxProps {
    label: string | React.ReactNode
    name: string
    className?: string
    validate?: any,
}

// eslint-disable-next-line react/function-component-definition
const Checkbox: React.FC<CheckboxProps> = ({ name, label, className, validate, ...attributes }) => {
    const { loginConfig } = useSelector(getLoginConfig)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    return (
        <div className="mb-3">
            <div className="flex mb-1">
                <Field
                    style={{ borderColor: loginConfig?.whiteLabeling?.avocado || whiteLabeling.avocado, marginBottom: 13 }}
                    type="checkbox"
                    name={name}
                    className={cn('mr-2 border rounded-[2px] mt-1', className)}
                    validate={validate}
                    {...attributes}
                    id={name} />

                <label
                    style={{ color: loginConfig?.whiteLabeling?.grey01 || Colors.grey01 }}
                    className="font-normal text-14 tracking-[-.01em] leading-[150%] mb-1.5"
                    htmlFor={name}>
                    {label}
                </label>
            </div>

            <ValidationErrorMessage name={name} />
        </div>
    )
}

export default Checkbox
