import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/layout/Layout'
import { Cross, FilterIcon } from '../../components/common/Svg'
import { useQueryParams } from '../../hooks/queryString'
import SearchInput from '../../components/common/SearchInput'
import { ITags } from '../../types/data'
import RecipeCategories from '../../components/common/RecipeCategories'
import { fetchRecipeByTags, fetchRecipeCategoryList, fetchRecipeTagsList } from '../../state/recipe/actions'
import RecipeTags from '../../components/common/RecipeTags'
import { getRecipe } from '../../state/recipe/selectors'
import RecipeCard from '../../components/recipe/RecipeCard'
import { getPerkLists } from '../../state/perks/selectors'
import RecentSearches from '../../components/RecentSearches'

export default function RecipeFilterByTag() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { tags } = useQueryParams()
    const tagsList = typeof tags === 'string' ? tags?.split(',') : []
    const { recipesByTags, loading, recipeTags, searchCollection } = useSelector(getRecipe)
    const { recentSearches } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const [t] = useTranslation('global')
    const handleFocus = () => setOnFocus(true)

    const filteredData = recipeTags?.filter((item) => tagsList.includes(item.id.toString()))

    const data = [
        { id: 1, title: t('lower.all-categories'), content: <RecipeCategories />, loadContent: () => dispatch(fetchRecipeCategoryList) },
        { id: 2, title: t('lower.tags'), content: <RecipeTags />, loadContent: () => dispatch(fetchRecipeTagsList) },
    ]

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        openedMenu: 2,
        buttonName: t('lower.recipes'),
    }

    const onChangeValueTag = (item: ITags) => {
        const newTags = tagsList.find((i) => i === item.id.toString())
            ? tagsList.filter((i) => i !== item.id.toString())
            : [...tagsList, item.id]

        if (newTags.length > 0) {
            navigate(`/lower/recipes/filter-by-tag?tags=${newTags.join(',')}`)
        } else {
            navigate('/lower/recipes')
        }
    }

    useEffect(() => {
        dispatch(fetchRecipeByTags(tags?.toString() || ''))
        // eslint-disable-next-line
    }, [tags])

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className={`pb-16 mobile:mx-10 mx-16 medium:mt-[52px] mt-[32px] ${recipesByTags.length === 0 && !loading && 'h-screen'}`}>
                <span onClick={() => navigate('/lower/recipes')} className="xs-mobile:block hidden text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2" style={{ fontSize: 14 }}>
                    {t('account.Recipes')}
                </span>

                <div className="flex items-center justify-between mt-[-20px] pull-right">
                    <SearchInput action="recipes" placeholder={t('lower.what-would-you-like-to-make')} onFocus={handleFocus} />
                </div>

                {onFocus && (
                    <RecentSearches data={recentSearches?.recipes} type="recipes" collection={searchCollection} />
                )}

                <div className="flex items-center justify-between">
                    <h1 className="xs-mobile:block hidden font tracking-tight font-medium text-36">
                        {t('lower.filter-results-for')}
                    </h1>

                </div>

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/lower/recipes')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2" style={{ fontSize: 14 }}>
                        {t('account.Recipes')}
                    </span>

                    <h1 className="font tracking-tight font-medium mt-2 text-36">
                        {t('lower.filter-results-for')}
                    </h1>
                </div>

                <div className="flex flex-wrap mt-3">
                    {filteredData.map((item) => {
                        const isSelected = tagsList.includes(String(item.id))

                        return (
                            <button onClick={() => onChangeValueTag(item)} key={item.id}
                                className={`h-[32px] hover:text-spinach hover:ease-in hover:duration-200 hover:bg-[#A7D49D] rounded-[30px] flex items-center justify-center cursor-pointer mb-4 mr-2 ${isSelected ? 'px-3 bg-cavolo' : 'px-5 bg-rocket'} `}>

                                <span className="font-semibold text-pistachio text-12">
                                    {item.title}
                                </span>

                                {isSelected ? (
                                    <div className="ml-1.5">
                                        <Cross width={10} height={10} />
                                    </div>
                                ) : null}
                            </button>
                        )
                    })}
                </div>

                {recipesByTags.length === 0 && !loading && (
                    <h1 className="font tracking-tight font-medium mt-4 text-18">
                        {t('lower.your-filters-returned-no-results')}
                    </h1>
                )}

                {recipesByTags.length !== 0 && !loading && (
                    <h1 className="font tracking-tight font-medium text-black mt-4 text-18">
                        {recipesByTags.length} {t('article.results')}
                    </h1>
                )}

                <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-3">
                    {recipesByTags?.map((item) => (
                        <div key={item.id}>
                            <RecipeCard item={item} type="recipe" />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}
