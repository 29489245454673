import React, { useState } from 'react'
import Skeleton from '../common/Skeleton'

interface IProps {
    item?: any
}

export default function RewardsCard({ item }: IProps) {
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    return (
        <div className="cursor-pointer relative" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div>
                {loading && <Skeleton className="rounded-t-lg w-full aspect-w-16 aspect-h-9 aspect-video object-cover" />}
                <img onLoad={handleImageLoad} src={item?.image} className={`${loading ? 'hidden' : 'block'} rounded-t-lg w-full aspect-w-16 aspect-h-9 aspect-video object-cover`} alt="" />

                <div className="flex flex-col justify-between p-3 rounded-b-lg bg-white h-[102px] hover:bg-[#F2F2F2] hover:drop-shadow-[0.3] hover:delay-200 ">
                    <h5 className="line-clamp-two mb-0.5 font-medium font tracking-tight text-darken text-15 leading-[132%]">
                        {item?.title}
                    </h5>
                </div>
            </div>
        </div>
    )
}
