const BASE = 'perks/'

export const FETCH_PERKS_START = `${BASE}FETCH_PERKS_START`

export const GET_PERKS_BRAND_LIST = `${BASE}GET_PERKS_BRAND_LIST`
export const GET_PERKS_USER_BOOKMARKED_LIST = `${BASE}GET_PERKS_USER_BOOKMARKED_LIST`
export const GET_PERKS_BY_TYPE_LIST = `${BASE}GET_PERKS_BY_TYPE_LIST`
export const GET_PERKS_FILTER_TAG_LIST = `${BASE}GET_PERKS_FILTER_TAG_LIST`
export const GET_PERK_LIST_BY_TYPE = `${BASE}GET_PERK_LIST_BY_TYPE`
export const GET_PERK_BY_TAG_LIST = `${BASE}GET_PERK_BY_TAG_LIST`
export const GET_PERKS_HOME_PAGE_LIST = `${BASE}GET_PERKS_HOME_PAGE_LIST`
export const GET_SEARCH_LIST = `${BASE}GET_SEARCH_LIST`
export const GET_PERK_LAST_COMMENT_LIST = `${BASE}GET_PERK_LAST_COMMENT_LIST`
export const GET_PERK_COMMENT_LIST = `${BASE}GET_PERK_COMMENT_LIST`
export const CLEAR_PERK_COMMENTS = `${BASE}CLEAR_PERK_COMMENTS`
export const POST_BOOKMARK_SUCCESS = `${BASE}POST_HOME_BOOKMARK_SUCCESS`
export const POST_PERK_RATE_SUCCESS = `${BASE}POST_PERK_RATE_SUCCESS`
export const POST_PERK_COMMENT_SUCCESS = `${BASE}POST_PERK_COMMENT_SUCCESS`
export const GET_RECENT_SEARCHES_LIST = `${BASE}GET_RECENT_SEARCHES_LIST`
export const GET_SEARCH_COLLECTION_LIST = `${BASE}GET_SEARCH_COLLECTION_LIST`
export const POST_PERK_PLEDGE_LOG_SUCCESS = `${BASE}POST_PERK_PLEDGE_LOG_SUCCESS`
export const GET_PERK_DETAIL_SUCCESS = `${BASE}GET_PERK_DETAIL_SUCCESS`

export const FETCH_PERKS_LIST_ERROR = `${BASE}FETCH_PERKS_LIST_ERROR`
