import { useEffect, useState } from 'react'

interface IProps {
    defaultWidth?: number
    changedWidth?: () => number
    changedHeight?: () => number
}

export function useResize({ defaultWidth, changedWidth, changedHeight }:IProps, dependencies: object[] = []) {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    useEffect(() => {
        if (defaultWidth) return

        const getwidth = () => {
            if (changedWidth && changedWidth()) setWidth(changedWidth())
            if (changedHeight && changedHeight()) setHeight(changedHeight())
        }

        getwidth()

        window.addEventListener('resize', getwidth)
        // eslint-disable-next-line consistent-return
        return () => window.removeEventListener('resize', getwidth)

        // eslint-disable-next-line
    }, [dependencies])

    return { width, height }
}

export function getNavbarHeight() {
    return document.getElementById('navbar')?.offsetHeight || 0
}
