import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'
import AccountSidebar from '../../components/account/AccountSidebar'
import SecondButton from '../../components/common/SecondButton'
import { daysOfWeek, daysOfWeekShort } from '../../utils/date'
import { getActivitiesList, getUser } from '../../state/user/selectors'
import { fetchActivities, postActivities } from '../../state/user/actions'
import { IActivities, IActivitiesData } from '../../types/data'
import { DismissIcon } from '../../components/common/Svg'
import { Context } from '../../components/common/BaseContext'

interface IProps {
  setShowModal?: (value: boolean) => void;
  showModal?: boolean;
}

export default function Schedule({ setShowModal, showModal }: IProps) {
    const dispatch = useDispatch()
    const { screenWidth } = useContext(Context)
    const { user } = useSelector(getUser)
    const { activities } = useSelector(getActivitiesList)
    const [t] = useTranslation('global')
    const [loading, setLoading] = useState(false)
    const [categoryStates, setCategoryStates] = useState<{ activities: IActivitiesData[] }>({ activities: [] })

    useEffect(() => {
        const initialCategoryStates = activities?.map((category: IActivities) => ({
            activity: category.id,
            monday: category.userActivity.monday,
            tuesday: category.userActivity.tuesday,
            wednesday: category.userActivity.wednesday,
            thursday: category.userActivity.thursday,
            friday: category.userActivity.friday,
            saturday: category.userActivity.saturday,
            sunday: category.userActivity.sunday,
        }))

        setCategoryStates({ activities: initialCategoryStates })
    }, [activities])

    const toggleBox = (categoryId: number, day: keyof IActivitiesData) => {
        setCategoryStates((prevState) => ({
            activities: prevState.activities?.map((activity) => (activity.activity === categoryId
                ? { ...activity, [day]: !activity[day] }
                : activity)),
        }))
    }

    const onRestartLoad = useCallback(() => {
        dispatch(fetchActivities())
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Schedule' })
    }, [])

    const onSubmit = async () => {
        setLoading(true)
        await dispatch(postActivities(categoryStates))
        setLoading(false)

        if (setShowModal) {
            window.location.reload()
            setShowModal(false)
        }
    }

    const renderContent = () => (
        <>
            <h1 className="heading-uppercase text-black font tracking-tight font-medium tablet:text-36 xs-max:text-26 text-24">
                {t('schedule.weekly-schedule')}
            </h1>

            <h1 className="text-black font tracking-tight font-medium text-16 mt-5">
                {t('schedule.when-do-you-usually-play').replace('[play]', user.company.activityVerb)}
            </h1>

            <h1 className="mt-1 text-14 tracking-tight font-normal text-grey01">
                {t('schedule.schedule-description')}
            </h1>

            <div className="mt-5">
                <div className="flex xs-max:mb-4 mb-2.5">
                    <h1 className="w-[80px]" />

                    <div className="col-span-5 grid grid-cols-7 xs-max:gap-5 gap-2">
                        {daysOfWeekShort?.map((day, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index} className="w-8 h-8 md:w-12 md:h-12 flex items-center justify-center">
                                <span className="xs-max:text-18 text-14 font-normal text-cavolo">{day}</span>
                            </div>
                        ))}
                    </div>
                </div>

                {activities?.map((category) => {
                    const categoryState = categoryStates?.activities?.find((activity) => activity.activity === category.id)

                    return (
                        <div key={category.id} className="flex mb-4">
                            <h1 style={{ letterSpacing: -0.14 }} className="flex items-center text-16 font-normal text-cavolo w-[80px]">
                                {category.name}
                            </h1>

                            <div className="col-span-5 grid grid-cols-7 xs-max:gap-5 gap-2">
                                {daysOfWeek?.map((day) => (
                                    <div
                                        key={day}
                                        className={`w-8 h-8 md:w-12 md:h-12 border-2 border-avocado rounded-md cursor-pointer ${categoryState?.[day] ? 'bg-avocado' : 'bg-transparent'}`}
                                        onClick={() => toggleBox(category.id, day)}
                                    />
                                ))}
                            </div>
                        </div>
                    )
                })}

                <SecondButton onClick={onSubmit} loading={loading} text={t('global.save')} className="mt-3 w-full h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio" />
            </div>
        </>
    )

    return !showModal ? (
        <AccountSidebar>
            <div className="tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 mobile:mr-10 xs-max:w-[534px]">{renderContent()}</div>
        </AccountSidebar>
    ) : (
        <div className="fixed inset-0 z-[1000000] overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

            <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                <div className="linear bg-pistachio relative xs-mobile:w-[550px] w-11/12 mobile:px-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                    <div className="w-full flex items-end justify-end">
                        <div onClick={() => setShowModal?.(false)} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center mt-[16px]">
                            <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                        </div>
                    </div>

                    <div className="flex flex-col justify-center py-[32px]">{renderContent()}</div>
                </div>
            </div>
        </div>
    )
}
