import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getLoginConfig, getWhiteLabelingList } from '../../state/user/selectors'

interface IProps {
    text: string
    className?: string
    to?: string
    hasTarget?: boolean
}

// eslint-disable-next-line react/function-component-definition
const LinkText: React.FC<IProps> = ({ text, className = '', to, hasTarget }) => {
    const { loginConfig } = useSelector(getLoginConfig)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    return (
        hasTarget ? (
            <a href={to} target="_blank" rel="noreferrer">
                <p style={{ color: loginConfig?.whiteLabeling?.black || whiteLabeling?.black }} className={classNames('underline font-semibold pointer mt-6 text-center text-14', className)}>
                    {text}
                </p>
            </a>
        )
            : (
                <NavLink to={to || '#'}>
                    <p style={{ color: loginConfig?.whiteLabeling?.black || whiteLabeling?.black }} className={classNames('underline font-semibold pointer mt-6 text-center text-14', className)}>
                        {text}
                    </p>
                </NavLink>
            )
    )
}

export default LinkText
