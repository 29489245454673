const BASE = 'recipe/'

export const FETCH_RECIPE_START = `${BASE}FETCH_RECIPE_START`
export const FETCH_RECIPE_SEARCH_START = `${BASE}FETCH_RECIPE_SEARCH_START`

export const GET_RECIPE_USER_BOOKMARKED_LIST = `${BASE}GET_RECIPE_USER_BOOKMARKED_LIST`
export const GET_RECIPE_HOME_PAGE_LIST = `${BASE}GET_RECIPE_HOME_PAGE_LIST`
export const GET_RECIPE_DETAIL_SUCCESS = `${BASE}GET_RECIPE_DETAIL_SUCCESS`
export const GET_RECIPE_CATEGORY_LIST = `${BASE}GET_RECIPE_CATEGORY_LIST`
export const GET_RECIPE_TAGS_LIST = `${BASE}GET_RECIPE_TAGS_LIST`
export const POST_BOOKMARK_SUCCESS = `${BASE}POST_HOME_BOOKMARK_SUCCESS`
export const GET_RECIPE_SEARCH_LIST = `${BASE}GET_RECIPE_SEARCH_LIST`
export const CLEAR_RECIPE_SEARCH_LIST = `${BASE}CLEAR_RECIPE_SEARCH_LIST`
export const GET_RECIPE_COMMENTS_SUCCESS = `${BASE}GET_RECIPE_COMMENTS_SUCCESS`
export const GET_RECIPES_BY_CATEGORY_SUCCESS = `${BASE}GET_RECIPE_BY_CATEGORY_SUCCESS`
export const GET_RECIPES_BY_TAGS_SUCCESS = `${BASE}GET_RECIPES_BY_TAGS_SUCCESS`
export const GET_RECIPE_SEARCH_COLLECTION_LIST = `${BASE}GET_RECIPE_SEARCH_COLLECTION_LIST`
export const CLEAR_RECIPE_DETAIL = `${BASE}CLEAR_RECIPE_DETAIL`
export const GET_RECIPE_BY_CATEGORY_LIST = `${BASE}GET_RECIPE_BY_CATEGORY_LIST`
export const POST_RECIPE_RATE_SUCCESS = `${BASE}POST_RECIPE_RATE_SUCCESS`
export const POST_RECIPE_COMMENT_SUCCESS = `${BASE}POST_RECIPE_COMMENT_SUCCESS`
export const POST_RECIPE_PLEDGE_LOG_SUCCESS = `${BASE}POST_RECIPE_PLEDGE_LOG_SUCCESS`

export const FETCH_RECIPE_LIST_ERROR = `${BASE}FETCH_RECIPE_LIST_ERROR`
