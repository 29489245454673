import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { FETCH_QUIZ_ERROR, FETCH_QUIZ_LIST_SUCCESS, FETCH_QUIZ_START, POST_QUIZ_ANSWER_SUCCESS } from './consts'
import { QuizState } from './types'

const initialState: QuizState = {
    // @ts-ignore
    quiz: {},
    quizAnswerResponse: null,
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AnyAction): QuizState => {
    const { payload } = action
    switch (action.type) {
    case FETCH_QUIZ_START:
        return { ...state, loading: true }

    case FETCH_QUIZ_LIST_SUCCESS:
        return {
            ...state,
            quiz: payload,
            loading: false,
        }

    case POST_QUIZ_ANSWER_SUCCESS:
        return {
            ...state,
            quizAnswerResponse: payload,
            loading: false,
        }

    case FETCH_QUIZ_ERROR:
        return { ...state, loading: false }

    default:
        return state
    }
}

const persistConfig: PersistConfig<QuizState> = {
    key: 'quiz',
    storage,
    whitelist: [],
}

export default persistReducer(persistConfig, reducer)
