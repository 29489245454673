import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import ChallengeLeftSidebar from '../../components/challenge/ChallengeLeftSidebar'
import { getChallengeList } from '../../state/challenges/selectors'
import { Badge, Bell, Calendar, Termometr } from '../../components/common/Svg'
import { getDateTimeChallenge } from '../../utils/date'
import ChallengeNotification from '../../components/challenge/ChallengeNotification'
import { fetchChallengeLoggedDaysList } from '../../state/challenges/actions'
import Skeleton from '../../components/common/Skeleton'
import { readableC02 } from '../../utils/helpers'

export default function ChallengeDetail() {
    const dispatch = useDispatch()
    const { challenge } = useSelector(getChallengeList)
    const { challengeId } = useParams()
    const [showModal, setShowModal] = useState(false)
    const challengeData = challenge?.find((item) => item.id === Number(challengeId))
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Challenge detail' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [challengeId])

    useEffect(() => {
        if (challengeData?.id) dispatch(fetchChallengeLoggedDaysList(challengeData?.id))

        // eslint-disable-next-line
    }, [challengeData])

    return (
        <Layout leftBackground>
            <div className="medium:pb-0 z-10 w-full flex flex-col medium:flex-row medium:items-stretch items-center medium:justify-between relative">
                <ChallengeLeftSidebar setShowModal={setShowModal} showModal={showModal} />

                <div className="flex flex-col medium:flex-1 max-medium:w-full">
                    {loading && <Skeleton className="medium:h-[540px] min-medium:aspect-video w-full object-cover medium:static absolute top-0 left-0" />}
                    <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} medium:h-[540px] min-medium:aspect-video w-full object-cover medium:static absolute top-0 left-0`} src={challengeData?.image} alt="" />

                    <div className="mobile:ml-10 ml-4 mt-8 mobile:mt-10 tablet:mr-[160px] mobile:mr-10 mr-4 mobile:mb-[120px] mb-[80px]">
                        {/* eslint-disable-next-line react/no-danger */}
                        <h1 className="rich-text" dangerouslySetInnerHTML={{ __html: challengeData?.body || '' }} />

                        <h1 className="mt-10 text-22 font font-medium tracking-tight text-black">
                            {t('lower.challenge-details')}
                        </h1>

                        <div className="flex items-center mt-6">
                            <Calendar width={24} height={24} />

                            <span className="ml-2 text-16 font-light text-grey01 flex-1">
                                {getDateTimeChallenge(challengeData?.startDate, challengeData?.endDate)}
                            </span>
                        </div>

                        <div className="flex items-start mt-6">
                            <div>
                                <Badge width={24} height={24} />
                            </div>

                            <span className="ml-2 text-16 text-grey01 font-light">
                                {t('lower.complete-the-challenge-to')}
                                <span className="font-semibold">
                                        &nbsp;{t('lower.receive-a-achievement')}&nbsp;
                                </span>
                                    &nbsp;{t('lower.and')}&nbsp;
                                <span className="font-bold">
                                    {t('lower.extra-points')}
                                </span> {t('lower.toward-this-month’s-prize-draw-entry')}
                            </span>
                        </div>

                        <div className="mt-6 flex items-center">
                            <Termometr width={24} height={24} />

                            <span className="ml-2 text-16 text-grey01 font-bold">{t('global.save')} {readableC02(Number(challengeData?.ceo2Kg))} <span className="text-16 text-grey01 font-normal">{t('lower.by-completing-this-challenge')}</span></span>
                        </div>

                        {challengeData?.isJoined && challengeData?.isActive && (
                            <div className="mt-6 flex items-center">
                                <Bell width={24} height={24} />

                                <span onClick={() => setShowModal(true)} className="ml-2 text-rocket tracking-tight font-medium cursor-pointer underline underline-offset-2 text-14">
                                    {t('lower.manage-notifications')}
                                </span>
                            </div>
                        )}

                        {showModal && (
                            <ChallengeNotification items={challengeData} setShowModal={setShowModal} showModal={showModal} />
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}
