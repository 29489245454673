import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Input from '../components/common/Input'
import Button from '../components/common/Button'
import AuthContainer from '../components/auth/LoginContainer'
import { fetchSettings, fetchUserRegisterEmail } from '../state/user/actions'
import { IRegisterForm } from '../types/data'
import { Context } from '../components/common/BaseContext'
import { getLoginConfig, getSettingsList, getUser } from '../state/user/selectors'
import InputWithTooltip from '../components/common/InputWithToolTip'
import Checkbox from '../components/common/Checkbox'
import { useQueryParams } from '../hooks/queryString'
import logo from '../static/lowr-black.png'
import { Colors } from '../themes'
import { required } from '../utils/validators'
import CustomPhoneInput from '../components/common/CustomPhoneInput'
import LinkText from '../components/common/LinkText'

export default function Login() {
    const dispatch = useDispatch()
    const params = useQueryParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const [error, setError] = useState('')
    const [statusCode, setStatusCode] = useState<number>()
    const { setIsIntroShown } = useContext(Context)
    const [loading, setLoading] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)
    const { settings } = useSelector(getSettingsList)
    const { loginConfig } = useSelector(getLoginConfig)
    const [t] = useTranslation('global')
    const referID = location?.state?.referId

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Register' })
    }, [])

    const onRestartLoad = useCallback(async () => {
        await dispatch(fetchSettings)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const onSubmit = useCallback(async (data: IRegisterForm) => {
        setLoading(true)
        dispatch(
            fetchUserRegisterEmail(data, (response) => {
                setIsIntroShown(response?.user?.company?.isOnboardingShown)
                navigate(String(params?.to || '/'))
            }, setError, setStatusCode),
        )
        setLoading(false)
        // eslint-disable-next-line
    }, [user?.company])

    const initialValues: IRegisterForm = {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        password2: '',
        agreedTerms: false,
        marketingOptin: false,
        ce: null,
        referral: Number(referID) || undefined,
        phoneNumber: '',
    }

    const toolTipText = <h1 style={{ color: loginConfig?.whiteLabeling?.grey01 }}>{t('register.tooltip-text-first')} <a style={{ color: loginConfig?.whiteLabeling?.rocket || Colors.rocket }} href={`mailto:${settings?.helpEmail}`}>{settings?.helpEmail}</a></h1>
    const termsOfServiceText = <h1 style={{ color: loginConfig?.whiteLabeling?.grey01 }}>{t('register.i-agree-to-the-lowr')} <a style={{ color: loginConfig?.whiteLabeling?.rocket }} href="https://www.lowr.co/terms-of-service" className="underline text-14 font-semibold" target="_blank" rel="noreferrer">{t('register.terms-and-conditions')}</a></h1>

    return (
        <AuthContainer title={t('register.sign-up')} bottomNavStyles={{ marginTop: 100 }}>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validateOnChange
                validateOnBlur
            >
                {({ errors }) => (
                    // @ts-ignore
                    <Form>
                        <Input
                            validate={required(t)}
                            hasError={typeof error !== 'string' && 'first_name' in error}
                            error={error}
                            name="firstName"
                            label={t('global.first-name')}
                            type="string" />

                        <Input
                            validate={required(t)}
                            hasError={typeof error !== 'string' && 'last_name' in error}
                            error={error}
                            name="lastName"
                            label={t('global.surname')}
                            type="string" />

                        {loginConfig?.signUpEmail === 'company_email_address' ? (
                            <InputWithTooltip
                                validate={required(t)}
                                hasError={typeof error !== 'string' && ('email' in error || 0 in error)}
                                tooltipText={toolTipText}
                                name="email"
                                label={t('register.company-email-address')}
                                placeholder={t('register.company-email-placeholder')}
                                type="email"
                                error={error}
                            />
                        ) : (
                            <Input
                                validate={required(t)}
                                hasError={typeof error !== 'string' && 'last_name' in error}
                                error={error}
                                name="email"
                                label={t('global.email-address')}
                                placeholder={t('register.email-placeholder')}
                                type="email"
                            />
                        )}

                        <div className="flex items-center mb-1.5">
                            <h1 className="text-14 font-medium text-black font">
                                {t('global.phone-number')}
                            </h1>

                            <span className="text-14 text-grey01 font">
                                &nbsp;{t('account.optional')}
                            </span>
                        </div>

                        <div className="mb-4">
                            <CustomPhoneInput
                                defaultValue={user?.phoneNumber}
                                name="phoneNumber"
                            />
                        </div>

                        <Input
                            validate={required(t)}
                            hasError={typeof error !== 'string' && 'password' in error}
                            error={error}
                            name="password"
                            label={t('global.password')}
                            placeholder={t('register.add-a-password')}
                            type="password" />

                        <Input
                            validate={required(t)}
                            hasError={typeof error !== 'string' && 'password2' in error}
                            error={error}
                            name="password2"
                            label={t('register.confirm-password')}
                            placeholder={t('register.repeat-your-password')}
                            type="password" />

                        <Checkbox
                            validate={required(t)}
                            label={termsOfServiceText}
                            name="agreedTerms" />

                        <Checkbox
                            label={t('register.i-agree-to-receive-marketing-emails-from-lowr')}
                            name="marketingOptin" />

                        <div className="grid">
                            <Button
                                error={(Object.keys(errors).length !== 0 || !!error) && buttonClicked}
                                loading={loading}
                                text={t('global.continue')}
                                onClick={() => setButtonClicked(true)}
                            />

                            {loginConfig?.showPoweredByOnAuth && (
                                <div className="flex item-center justify-end mt-2">
                                    <h1 style={{ color: loginConfig?.whiteLabeling?.black }} className="text-14 font">
                                        {t('register.powered-by')}
                                    </h1>

                                    <img src={logo} className="w-[60px] h-[15px] object-contain" alt="" />
                                </div>
                            )}

                            <div className="flex items-center justify-center mt-5">
                                <p className="text-14 text-grey01 font-normal mr-1">
                                    {t('login.don’t-have-an-account')}
                                </p>

                                <LinkText to="/login" text={t('register.log-in')} className="text-center mt-[0px]" />
                            </div>

                            {statusCode === 500 ? (
                                <h3 className="text-[#BD4823] tracking-[-0.02em] leading-[140%] text-[15px] font-semibold mt-3">
                                    {t('register.there-was-an-issue-processing-your-sign-up-if-you-think-the-form-looks-correct-and-you-would-like-support-please-contact')} {settings?.helpEmail}
                                </h3>
                            ) : (
                                (error || (Object.keys(errors).length !== 0 && buttonClicked)) && (
                                    <h3 className="text-[#BD4823] tracking-[-0.02em] leading-[140%] text-[15px] font-semibold mt-3">
                                        {t('register.you-have')} {Object.keys(errors).length || Object.keys(error).length} {(Object.keys(errors).length || Object.keys(error).length) > 1 ? t('register.errors') : t('register.error')} {t('register.in-the-form-above')}
                                    </h3>
                                )
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </AuthContainer>
    )
}
