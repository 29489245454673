import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DismissIcon, LeafPoint, PlusIcon } from './Svg'
import SecondButton from './SecondButton'
import { Context } from './BaseContext'
import { useQueryParams } from '../../hooks/queryString'
import { getUser } from '../../state/user/selectors'

interface IProps {
    isShown?: boolean
    onClose: () => void
    point: number | string | (string | null)[]
    description: string
    width?: boolean
    showModal?: boolean
}

export default function PointModal({ width, isShown = true, onClose, point, description, showModal = false }: IProps) {
    const { screenWidth } = useContext(Context)
    const { user } = useSelector(getUser)
    const params = useQueryParams()
    const [t] = useTranslation('global')

    useEffect(() => {
        if (params?.milestoneModal) {
            onClose()
        }
        // eslint-disable-next-line
    }, [params])

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={onClose} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                {!user?.company?.gamification && showModal ? null : (
                                    <div className="flex items-end justify-center mobile:mt-[76px] mt-[60px]" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.25))' }}>
                                        <div className="flex flex-col items-center justify-center absolute mobile:w-[148px] mobile:h-[148px] w-[120px] h-[120px] rotate-[-45deg]">
                                            <div className="absolute flex items-center rotate-45">
                                                <PlusIcon width={18} height={20} />
                                                <h1 className="not-heading-font mobile:text-58 text-48 font font-semibold text-spinach mx-1">
                                                    {point}
                                                </h1>
                                                <LeafPoint width={25} height={25} />
                                            </div>

                                            <div className="bg-sky w-full h-2/4 rounded-t-full" />
                                            <div className="bg-sea w-full h-2/4 rounded-b-full" />
                                        </div>
                                    </div>
                                )}

                                <div className="flex flex-col justify-center items-center pb-[32px]">
                                    <div className={`${width ? 'xs-mobile:w-[386px] w-[242px]' : 'xs-mobile:w-[296px] w-[242px]'} flex flex-col items-center justify-center mobile:mt-[22px] mt-[24px]`}>
                                        <h1 className="mobile:text-22 text-18 text-black font-semibold tracking-tight font">
                                            {t('global.congrats')}
                                        </h1>

                                        {!user?.company?.gamification && showModal ? (
                                            <h1 className="text-center mobile:text-22 text-18 text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                                {t('article.that’s-the-correct-answer')}
                                            </h1>
                                        ) : (
                                            <h1 className="text-center mobile:text-22 text-18 text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                                {description} {point} {t('global.points')}
                                            </h1>
                                        )}
                                    </div>

                                    <SecondButton onClick={onClose} text={t('global.continue')} className="mt-6 w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
