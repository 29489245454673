import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { IRecipe } from '../../types/data'
import { ArrowBottomIcon, SavedIconActive, SavedIconDefault } from '../common/Svg'
import { postRecipeBookmark } from '../../state/recipe/actions'
import Skeleton from '../common/Skeleton'
import { readableC02 } from '../../utils/helpers'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item: IRecipe
}

export default function RecipeWeLoveCard({ item }: IProps) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    const onPressBookmark = async (id: number) => {
        await dispatch(postRecipeBookmark(id))
    }

    return (
        <NavLink to={`/lower/recipes/${item?.id}`} className="cursor-pointer">
            <div className="hidden mobile:block">
                <div className="items-end flex justify-end relative">
                    <div className="max-w-[424px] absolute rounded-lg mr-8 mb-[26px]">
                        <div className="bg-pistachio p-5 rounded-b-lg">
                            <div className="flex mb-[2px] justify-between items-center">
                                <button className="font-medium font text-[22px] text-black">
                                    <ContentBlockTitle text={item?.title} />
                                </button>

                                <div onClick={(e) => {
                                    e.preventDefault()
                                    onPressBookmark(item?.id)
                                }} className="rounded-full flex justify-center items-center hover:text-spinach hover:ease-in hover:duration-200 bg-black w-[28px] h-[28px]">
                                    {item?.isBookmark
                                        ? <SavedIconActive width={12} height={15} />
                                        : <SavedIconDefault width={12} height={15} />}
                                </div>
                            </div>

                            <p className="text-grey01 w-11/12">
                                {item?.shortDescription}
                            </p>

                            <div className="flex items-center mb-1 mt-3.5">
                                <ArrowBottomIcon width={15} height={15} />

                                <p className="font-semibold not-heading-font text-grey ml-1.5" style={{ fontSize: 12 }}>
                                    {readableC02(item?.ceo2Kg || 0)}
                                </p>
                            </div>
                        </div>
                    </div>

                    {loading && <Skeleton className="aspect-w-16 aspect-h-9 rounded-lg w-screen h-[560px] object-cover cursor-pointer" />}
                    <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} aspect-w-16 aspect-h-9 rounded-lg w-screen h-[560px] object-cover cursor-pointer`} src={item?.image || item?.thumbnail} alt="" />
                </div>
            </div>

            <div className="relative mt-3 card cursor-pointer w-full" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                <div className="mobile:hidden flex justify-end mr-4">
                    <div onClick={(e) => {
                        e.preventDefault()
                        onPressBookmark(item?.id)
                    }} className="absolute rounded-full flex justify-center items-center mt-4 bg-darken w-[28px] h-[28px]">
                        {item?.isBookmark ? (
                            <SavedIconActive width={12} height={15} />
                        ) : (
                            <SavedIconDefault width={12} height={15} />
                        )}
                    </div>
                </div>

                <div className="mobile:hidden">
                    <div className="flex flex-col justify-end">
                        {loading && <Skeleton className="rounded-t-lg object-cover aspect-w-16 aspect-h-9 aspect-video" />}
                        <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-t-lg object-cover aspect-w-16 aspect-h-9 aspect-video`} src={item?.image || item?.thumbnail} alt="" />
                    </div>

                    <div className="rounded-b-lg bg-pistachio">
                        {item?.sponsorshipBlock && <SponsoredByBlock className="px-5 mb-1" fontColor="black" noBackground noBorder item={item.sponsorshipBlock} />}

                        <p className={cn('font-medium px-5 font text-18 text-black', { 'pt-5': !item?.sponsorshipBlock })}>
                            {item?.title}
                        </p>

                        <p className="text-grey01 px-5 w-11/12 text-14">{item?.shortDescription}</p>

                        <div className="flex px-5 pb-5 items-center mt-3.5">
                            <ArrowBottomIcon width={15} height={15} />

                            <p className="font-semibold not-heading-font text-grey ml-1.5" style={{ fontSize: 12 }}>
                                {readableC02(item?.ceo2Kg || 0)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </NavLink>
    )
}
