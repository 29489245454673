export const Colors = {
    black: 'rgba(49, 49, 49, 1)',
    text: '#EEFFED',
    avocado: 'rgba(167, 212, 157, 1)',
    pistachio: 'rgba(238, 255, 237, 1)',
    rocket: 'rgba(65, 115, 57, 1)',
    grey01: 'rgba(91, 91, 91, 1)',
    grey03: 'rgba(230, 230, 230, 1)',
    grey02: 'rgba(153, 153, 153, 1)',
    grey04: 'rgba(242, 242, 242, 1)',
    mint: 'rgba(212, 247, 210, 1)',
    dark: '#313131',

    raspberry: 'rgba(214, 104, 86, 1)',
    lime: '#FAF475',
    spinach: '#30542A',
    cavolo: '#052F11',
    error: '#BD4823',
}
