import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { articles } from '../state/articles/selectors'
import { RightArrow } from './common/Svg'
import Skeleton from './common/Skeleton'
import { getWhiteLabelingList } from '../state/user/selectors'

const data = [{ size: 10 }, { size: 8 }, { size: 9 }, { size: 6 }, { size: 5 }, { size: 9 }, { size: 4 }, { size: 6 }, { size: 3 }, { size: 8 }, { size: 4 }]

export default function BrowseByTopic() {
    const { category } = useSelector(articles)
    const { topicId } = useParams()
    const navigate = useNavigate()
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    const navigationArticleByTopic = (articleCategoryId: number) => {
        navigate(`/learn/articles/browse-by-topic-list/${articleCategoryId}`)
    }

    return (
        <div>
            {category.length !== 0 ? (
                category.map((item) => (
                    <div key={item.id} onClick={() => navigationArticleByTopic(item.id)}>
                        <h1 style={{ borderBottomColor: whiteLabeling?.avocado }} className={classNames(
                            'mt-2 w-full pb-1 text-black text-lg border-b cursor-pointer font tracking-tight flex items-center hover:font-bold hover:ease-in hover:duration-200',
                            item.id === Number(topicId) && 'font-bold',
                        )}>

                            {item.id === Number(topicId) && (
                                <div className="mr-2">
                                    <RightArrow width={13} height={8} />
                                </div>
                            )}

                            {item.name}
                        </h1>
                    </div>
                ))
            ) : (
                data.map((item) => (
                    <h1 className="mt-2 w-full pb-1 border-b-avocado text-black text-lg border-b cursor-pointer font tracking-tight flex items-center hover:font-bold hover:ease-in hover:duration-200">
                        <Skeleton className={`w-${item?.size}/12 h-[16px] rounded-sm mt-1`} />
                    </h1>
                ))
            )}
        </div>
    )
}
