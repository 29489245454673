import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import uniqueBy from 'lodash/uniqBy'
import { PerksState } from './types'
import { CLEAR_PERK_COMMENTS,
    FETCH_PERKS_LIST_ERROR,
    FETCH_PERKS_START,
    GET_PERK_BY_TAG_LIST,
    GET_PERK_COMMENT_LIST,
    GET_PERK_LAST_COMMENT_LIST,
    GET_PERK_LIST_BY_TYPE,
    GET_PERKS_BRAND_LIST,
    GET_PERKS_BY_TYPE_LIST,
    GET_PERKS_FILTER_TAG_LIST,
    GET_PERKS_HOME_PAGE_LIST,
    GET_PERKS_USER_BOOKMARKED_LIST, GET_RECENT_SEARCHES_LIST,
    GET_SEARCH_LIST,
    POST_BOOKMARK_SUCCESS,
    POST_PERK_COMMENT_SUCCESS,
    POST_PERK_RATE_SUCCESS,
    GET_SEARCH_COLLECTION_LIST, GET_PERK_DETAIL_SUCCESS } from './consts'

const initialState: PerksState = {
    perks: [],
    // @ts-ignore
    perksHomePage: [],
    perksBrand: [],
    bookmarkedPerks: { count: 0, results: [] },
    perksByType: [],
    perksFilterTag: [],
    perksByFilterTag: [],
    perksListByType: [],
    perksListBySearch: [],
    // @ts-ignore
    perkLastComment: [],
    // @ts-ignore
    recentSearches: [],
    searchCollection: [],
    perkDetail: null,
    perkCommentList: { count: 0, results: [] },
    count: 0,
    lastPage: 1,
    next: null,
    previous: null,
    loading: false,
    loadingPerkListByType: false,
    loadingPerkListBySearch: false,
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AnyAction): PerksState => {
    const { payload } = action

    switch (action.type) {
    case FETCH_PERKS_START:
        return {
            ...state,
            loading: true,
            loadingPerkListByType: true,
            loadingPerkListBySearch: true,
        }

    case GET_PERKS_HOME_PAGE_LIST:
        return {
            ...state,
            perksHomePage: payload,
            loading: false,
        }

    case GET_PERKS_BRAND_LIST:
        return {
            ...state,
            perksBrand: payload,
            loading: false,
        }

    case GET_PERKS_USER_BOOKMARKED_LIST:
        return {
            ...state,
            // eslint-disable-next-line no-unsafe-optional-chaining
            bookmarkedPerks: { ...payload, results: uniqueBy([...state?.bookmarkedPerks?.results, ...payload?.results], (e) => e.id) },
            loading: false,
        }

    case POST_BOOKMARK_SUCCESS:
        const newBookmarkedPerks = state?.bookmarkedPerks?.results?.map((item) => (item?.id === payload?.bookmarkedId
            ? {
                ...item,
                isBookmark: payload?.isBookmark,
            }
            : item))

        const newBookmarkedFilterByType = state?.perksListByType?.map((item) => (item?.id === payload?.bookmarkedId
            ? {
                ...item,
                isBookmark: payload?.isBookmark,
            }
            : item))

        const newBookmarkPerkBrand = state?.perksBrand?.map((item) => (item?.id === payload?.bookmarkedId
            ? {
                ...item,
                isBookmark: payload?.isBookmark,
            }
            : item))

        const newBookmarkedFilterByTag = state?.perksByFilterTag?.map((item) => (item?.id === payload?.bookmarkedId
            ? {
                ...item,
                isBookmark: payload?.isBookmark,
            }
            : item))

        const newPerksWeRecommend = state?.perksHomePage?.weRecommend?.id === payload?.bookmarkedId
            ? {
                ...state?.perksHomePage?.weRecommend,
                isBookmark: payload?.isBookmark,
            }
            : state?.perksHomePage?.weRecommend

        const newPerksOfTheDay = state?.perksHomePage?.perkOfTheDay?.id === payload?.bookmarkedId
            ? {
                ...state?.perksHomePage?.perkOfTheDay,
                isBookmark: payload?.isBookmark,
            }
            : state?.perksHomePage?.perkOfTheDay
        const newBookmarkSlotOne = state?.perksHomePage?.slotOne?.perks?.map((item) => (item?.id === payload?.bookmarkedId
            ? {
                ...item,
                isBookmark: payload?.isBookmark,
            }
            : item))
        const newBookmarkSlotTwo = state?.perksHomePage?.slotTwo?.perks?.map((item) => (item?.id === payload?.bookmarkedId
            ? {
                ...item,
                isBookmark: payload?.isBookmark,
            }
            : item))
        const newBookmarkSlotThree = state?.perksHomePage?.slotThree?.perks.map((item) => (item?.id === payload?.bookmarkedId
            ? {
                ...item,
                isBookmark: payload?.isBookmark,
            }
            : item))
        const newBookmarkSlotFour = state?.perksHomePage?.slotFour?.perks?.map((item) => (item?.id === payload?.bookmarkedId
            ? {
                ...item,
                isBookmark: payload?.isBookmark,
            }
            : item))
        const newBookmarkSearch = state?.perksListBySearch?.map((item) => (item?.id === payload?.bookmarkedId
            ? {
                ...item,
                isBookmark: payload?.isBookmark,
            }
            : item))
        const newPerkOfTheMonth = state?.perksHomePage?.freePerkOfTheMonth?.id === payload?.bookmarkedId
            ? {
                ...state.perksHomePage?.freePerkOfTheMonth,
                isBookmark: payload?.isBookmark,
            }
            : state.perksHomePage?.freePerkOfTheMonth
        return {
            ...state,
            bookmarkedPerks: { count: state.bookmarkedPerks?.count, results: newBookmarkedPerks },
            perksListByType: newBookmarkedFilterByType,
            perksByFilterTag: newBookmarkedFilterByTag,
            perksBrand: newBookmarkPerkBrand,
            perksListBySearch: newBookmarkSearch,
            perksHomePage: {
                ...state?.perksHomePage,
                perkOfTheDay: newPerksOfTheDay,
                freePerkOfTheMonth: newPerkOfTheMonth,
                weRecommend: newPerksWeRecommend,
                slotOne: { ...state?.perksHomePage?.slotOne, perks: newBookmarkSlotOne },
                slotTwo: { ...state?.perksHomePage?.slotTwo, perks: newBookmarkSlotTwo },
                slotThree: { ...state?.perksHomePage?.slotThree, perks: newBookmarkSlotThree },
                slotFour: { ...state?.perksHomePage?.slotFour, perks: newBookmarkSlotFour },
            },
        }

    case POST_PERK_RATE_SUCCESS:
        const newRatedPerksOfTheDay = state?.perksHomePage?.perkOfTheDay?.id === payload?.perk
            ? {
                ...state.perksHomePage?.perkOfTheDay,
                userRated: payload?.rate,
            }
            : state?.perksHomePage?.perkOfTheDay

        const newRatedPerkOfTheMonth = state?.perksHomePage?.freePerkOfTheMonth?.id === payload?.perk
            ? {
                ...state.perksHomePage?.freePerkOfTheMonth,
                userRated: payload?.rate,
            }
            : state.perksHomePage?.freePerkOfTheMonth

        return {
            ...state,
            perksHomePage: {
                ...state.perksHomePage,
                perkOfTheDay: newRatedPerksOfTheDay,
                freePerkOfTheMonth: newRatedPerkOfTheMonth,
            },
            loading: false,
        }

    case POST_PERK_COMMENT_SUCCESS:
        const newCommentedPerksOfTheDay = state?.perksHomePage?.perkOfTheDay?.id === payload?.perk
            ? {
                ...state?.perksHomePage?.perkOfTheDay,
                hasCommented: true,
            }
            : state?.perksHomePage?.perkOfTheDay

        const newCommentedPerkOfTheMonth = state?.perksHomePage?.freePerkOfTheMonth?.id === payload?.perk
            ? {
                ...state.perksHomePage?.freePerkOfTheMonth,
                hasCommented: true,
            }
            : state.perksHomePage?.freePerkOfTheMonth

        const newCommentedPerks = state?.bookmarkedPerks?.results?.map((item) => (item?.id === payload?.perk
            ? {
                ...item,
                hasCommented: true,
            }
            : item))

        const newCommentedFilterByType = state?.perksListByType?.map((item) => (item?.id === payload?.perk
            ? {
                ...item,
                hasCommented: true,
            }
            : item))

        const newCommentedPerkBrand = state?.perksBrand?.map((item) => (item?.id === payload?.perk
            ? {
                ...item,
                hasCommented: true,
            }
            : item))

        const newCommentedFilterByTag = state?.perksByFilterTag?.map((item) => (item?.id === payload?.perk
            ? {
                ...item,
                hasCommented: true,
            }
            : item))

        const newCommentedPerksWeRecommend = state?.perksHomePage?.weRecommend?.id === payload?.perk
            ? {
                ...state?.perksHomePage?.weRecommend,
                hasCommented: true,
            }
            : state?.perksHomePage?.weRecommend
        const newCommentedSlotOne = state?.perksHomePage?.slotOne?.perks?.map((item) => (item?.id === payload?.perk
            ? {
                ...item,
                hasCommented: true,
            }
            : item))
        const newCommentedSlotTwo = state?.perksHomePage?.slotTwo?.perks?.map((item) => (item?.id === payload?.perk
            ? {
                ...item,
                hasCommented: true,
            }
            : item))
        const newCommentedSlotThree = state?.perksHomePage?.slotThree?.perks.map((item) => (item?.id === payload?.perk
            ? {
                ...item,
                hasCommented: true,
            }
            : item))
        const newCommentedSlotFour = state?.perksHomePage?.slotFour?.perks?.map((item) => (item?.id === payload?.perk
            ? {
                ...item,
                hasCommented: true,
            }
            : item))
        const newCommentedSearch = state?.perksListBySearch?.map((item) => (item?.id === payload?.perk
            ? {
                ...item,
                hasCommented: true,
            }
            : item))
        return {
            ...state,
            loading: false,
            bookmarkedPerks: { count: state.bookmarkedPerks?.count, results: newCommentedPerks },
            perksListByType: newCommentedFilterByType,
            perksByFilterTag: newCommentedFilterByTag,
            perksBrand: newCommentedPerkBrand,
            perksListBySearch: newCommentedSearch,
            perksHomePage: {
                ...state?.perksHomePage,
                perkOfTheDay: newCommentedPerksOfTheDay,
                freePerkOfTheMonth: newCommentedPerkOfTheMonth,
                weRecommend: newCommentedPerksWeRecommend,
                slotOne: { ...state?.perksHomePage?.slotOne, perks: newCommentedSlotOne },
                slotTwo: { ...state?.perksHomePage?.slotTwo, perks: newCommentedSlotTwo },
                slotThree: { ...state?.perksHomePage?.slotThree, perks: newCommentedSlotThree },
                slotFour: { ...state?.perksHomePage?.slotFour, perks: newCommentedSlotFour },
            },
        }

    case GET_PERKS_BY_TYPE_LIST:
        return {
            ...state,
            perksByType: payload,
            loading: false,
        }

    case GET_PERKS_FILTER_TAG_LIST:
        return {
            ...state,
            perksFilterTag: payload,
            loading: false,
        }

    case GET_PERK_LIST_BY_TYPE:
        return {
            ...state,
            perksListByType: payload,
            loading: false,
        }

    case GET_PERK_BY_TAG_LIST:
        return {
            ...state,
            perksByFilterTag: payload,
            loading: false,
        }

    case GET_SEARCH_LIST:
        return {
            ...state,
            perksListBySearch: payload,
            loadingPerkListBySearch: false,
        }
    case GET_PERK_LAST_COMMENT_LIST:
        return {
            ...state,
            perkLastComment: payload,
            loading: false,
        }

    case GET_PERK_COMMENT_LIST:
        return {
            ...state,
            perkCommentList: { ...payload, results: uniqueBy([...state.perkCommentList.results, ...payload.results], (e) => e.id) },
            loading: false,
        }

    case CLEAR_PERK_COMMENTS:
        return {
            ...state,
            perkCommentList: { count: 0, results: [] },
            loading: false,
        }

    case GET_RECENT_SEARCHES_LIST:
        return {
            ...state,
            recentSearches: payload,
            loading: false,
        }

    case GET_SEARCH_COLLECTION_LIST:
        return {
            ...state,
            searchCollection: payload,
            loading: false,
        }

    case GET_PERK_DETAIL_SUCCESS:
        return {
            ...state,
            perkDetail: payload,
            loading: false,
        }

    case FETCH_PERKS_LIST_ERROR:
        return { ...state, loading: false }
    default:
        return state
    }
}

const persistConfig: PersistConfig<PerksState> = {
    key: 'perks',
    storage,
    whitelist: ['perks', 'perksBrand', 'perksByType', 'perkDetail'],
}

export default persistReducer(persistConfig, reducer)
