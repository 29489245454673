import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPerkLists } from '../../state/perks/selectors'
import { Cross } from './Svg'
import { IPerkFilterTag } from '../../types/data'
import { fetchByPerkByTag } from '../../state/perks/actions'
import { useQueryParams } from '../../hooks/queryString'

export default function PerkTags() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { perksFilterTag } = useSelector(getPerkLists)
    const { tags } = useQueryParams()
    const tagsList = typeof tags === 'string' ? tags?.split(',') : []

    const onChangeValueTag = (item: IPerkFilterTag) => {
        const newTags = tagsList.find((i) => i === item.id.toString())
            ? tagsList.filter((i) => i !== item.id.toString())
            : [...tagsList, item.id]

        if (newTags.length > 0) {
            navigate(`/lower/swaps/swap-filter-by-tag?tags=${newTags.join(',')}`)
        } else {
            navigate('/lower/swaps')
        }

        dispatch(fetchByPerkByTag(newTags.toString()))
    }

    return (
        <div className="flex flex-wrap">
            {perksFilterTag?.map((item) => {
                const isSelected = tagsList.includes(String(item.id))

                return (
                    <button onClick={() => onChangeValueTag(item)} key={item.id}
                        className={`h-[32px] rounded-[30px] flex items-center justify-center cursor-pointer mt-[8px] mb-[4px] mr-2 ${isSelected ? 'px-3 bg-cavolo' : 'px-5 bg-rocket'} `}>

                        <span className="font-semibold text-pistachio hover:font-bold hover:ease-in hover:duration-200"
                            style={{ fontSize: 12 }}>
                            {item.title}
                        </span>

                        {isSelected ? (
                            <div className="ml-1.5">
                                <Cross width={10} height={10} />
                            </div>
                        ) : null}
                    </button>
                )
            })}
        </div>
    )
}
