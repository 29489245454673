import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RateStarIcon, StarIcon } from '../common/Svg'
import { IPerkRate } from '../../types/data'
import { postPerkRate } from '../../state/perks/actions'
import SecondButton from '../common/SecondButton'
import { getPerkDetailLists } from '../../state/perks/selectors'
import PointModal from '../common/PointModal'
import { fetchUser } from '../../state/user/actions'
import { getWhiteLabelingList } from '../../state/user/selectors'

export default function PerkRateModal() {
    const dispatch = useDispatch()
    const maxRating = [1, 2, 3, 4, 5]
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { perkDetail: perksData } = useSelector(getPerkDetailLists)
    const [defaultRating, setDefaultRating] = useState(perksData?.userRated || 0)
    const [showModal, setShowModal] = useState(false)
    const [showModalPoint, setShowModalPoint] = useState(false)
    const [loading, setLoading] = useState(false)
    const [t] = useTranslation('global')

    const onPressPerkRate = async (data: IPerkRate) => {
        setLoading(true)
        await dispatch(
            postPerkRate(data, (response) => {
                dispatch(fetchUser)
                // eslint-disable-next-line no-unused-expressions
                !perksData?.userRated ? setShowModalPoint(true) : response ? setShowModal(false) : null
            }),
        )
        setLoading(false)
        setShowModal(false)
    }

    return (
        <>
            <button onClick={() => setShowModal(true)} className="flex items-center">
                <p className="font-semibold not-heading-font text-grey01 text-12">
                    {perksData?.totalRating.length === 3
                        ? perksData?.totalRating
                        : Math.floor(Number(perksData?.totalRating))}
                </p>

                <StarIcon width={18} height={19} />
            </button>

            {showModal ? (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div onClick={() => setShowModal(false)} className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="flex items-center justify-center min-h-screen">
                            <div className="relative xs-mobile:w-[423px] mobile:h-[311px] w-11/12 h-[279px] bg-white mobile:p-10 p-6 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <h1 className="text-12 font-normal text-grey01">
                                    {perksData?.perkBrand?.name}
                                </h1>

                                <h1 className="text-26 font-medium font tracking-tight text-black">
                                    {t('lower.rate-this-brand')}
                                </h1>

                                <h1 className="mt-4 font-medium text-14 font text-black">
                                    {t('lower.select-a-star-rating')}
                                </h1>

                                <div className="flex mt-2">
                                    {maxRating.map((item) => (
                                        <div key={item}>
                                            <button onClick={() => setDefaultRating(item)} className="mr-2">
                                                {item <= defaultRating ? (
                                                    <RateStarIcon color={whiteLabeling.rocket} width={40} height={40} />
                                                ) : (
                                                    <RateStarIcon color={whiteLabeling.white} width={40} height={40} />
                                                )}
                                            </button>
                                        </div>
                                    ))}
                                </div>

                                <div className="flex mt-8">
                                    <SecondButton
                                        text={t('global.cancel')}
                                        onClick={() => setShowModal(false)}
                                        className="mobile:w-[136px] h-[48px] w-[94px] mr-6 font-semibold bg-mint border-2 border-rocket rounded-md text-14 text-rocket hover:text-spinach hover:ease-in hover:duration-200" />

                                    <SecondButton
                                        text={t('lower.submit-your-rating')}
                                        onClick={() => onPressPerkRate({ rate: defaultRating, perk: perksData?.id })}
                                        loading={loading}
                                        className="w-[183px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <PointModal
                isShown={showModalPoint}
                onClose={() => setShowModalPoint(false)}
                point={5}
                description={t('lower.just-for-rating-you’ve-earned')} />
        </>
    )
}
