import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import PledgesCard from '../../components/pledges/PledgesCard'
import { getMyPledgesList } from '../../state/challenges/selectors'
import { fetchMyPledges } from '../../state/challenges/actions'

export default function PledgesList() {
    const dispatch = useDispatch()
    const { myPledges } = useSelector(getMyPledgesList)
    const [t] = useTranslation('global')

    const onRestartLoad = useCallback(() => {
        dispatch(fetchMyPledges)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Pledge' })
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    return (
        <Layout>
            <div className="medium:mt-[52px] mt-[32px] min-h-screen tablet:mb-[120px] mb-[80px] mobile:mx-10 mx-4">
                <div className="flex items-center justify-between">
                    <h1 className="heading-uppercase xs-mobile:block hidden font tracking-tight font-medium tablet:text-36 mobile:text-32 text-26">
                        {t('lower.pledges')}
                    </h1>
                </div>

                <div className="xs-mobile:hidden mt-8">
                    <h1 className="font tracking-tight font-medium mt-2 text-26">
                        {t('lower.pledges')}
                    </h1>
                </div>

                <div className="grid md:grid-cols-3 sm:grid-cols-1 mobile:gap-[24px] tablet:gap-[24px] laptop:gap-[32px] mt-8">
                    {myPledges?.map((item) => (
                        <div key={item?.id}>
                            <PledgesCard item={item?.pledge} />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}
