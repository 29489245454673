import { Dispatch } from 'redux'
import { FETCH_PERKS_LIST_ERROR,
    FETCH_PERKS_START,
    GET_PERK_LIST_BY_TYPE,
    GET_PERKS_BRAND_LIST,
    GET_PERKS_BY_TYPE_LIST,
    GET_PERKS_FILTER_TAG_LIST,
    GET_PERKS_USER_BOOKMARKED_LIST,
    GET_PERK_BY_TAG_LIST,
    GET_PERKS_HOME_PAGE_LIST,
    GET_SEARCH_LIST,
    GET_PERK_LAST_COMMENT_LIST,
    POST_BOOKMARK_SUCCESS,
    GET_PERK_COMMENT_LIST,
    POST_PERK_RATE_SUCCESS,
    POST_PERK_COMMENT_SUCCESS,
    CLEAR_PERK_COMMENTS,
    GET_RECENT_SEARCHES_LIST,
    GET_SEARCH_COLLECTION_LIST,
    POST_PERK_PLEDGE_LOG_SUCCESS, GET_PERK_DETAIL_SUCCESS } from './consts'
import { IPerkCommentList,
    IPerkCommentsListParams,
    IPerkFilterTag,
    IPerkHomePage,
    IPerkLastComment, IPerkPledgeLogResponse,
    IPerkRate,
    IPerkRateResponse,
    IPerks,
    IPerksType,
    IPerkWriteReviewData,
    IRecentSearchesResponse,
    ISearchCollection,
    IWriteReviewResponse } from '../../types/data'
import { getByPerkByTagList,
    getPerkByListType,
    getPerkCommentList, getPerkDetail,
    getPerkLastCommentList,
    getPerksBookmarkedList,
    getPerksBrandList,
    getPerksByTypeList,
    getPerksFilterTagList,
    getPerksHomePageList,
    getRecentSearchesItem,
    getSearchCollectionList,
    getSearchList,
    postBookmarkItem,
    postPerkCommentItem, postPerkPledgeLogItem,
    postPerkRateItem } from './api'
import { updateUserPoint } from '../user/actions'

const fetchPerksStart = () => ({
    type: FETCH_PERKS_START,
})

export const fetchPerksHomePageSuccess = (response: IPerkHomePage) => ({
    type: GET_PERKS_HOME_PAGE_LIST,
    payload: response,
})

export const fetchPerksBrandSuccess = (response: IPerks) => ({
    type: GET_PERKS_BRAND_LIST,
    payload: response,
})

export const fetchPerksBookmarkedSuccess = (response: IPerks) => ({
    type: GET_PERKS_USER_BOOKMARKED_LIST,
    payload: response,
})

export const fetchPerksByTypeSuccess = (response: IPerksType) => ({
    type: GET_PERKS_BY_TYPE_LIST,
    payload: response,
})

export const fetchPerksFilterTagSuccess = (response: IPerkFilterTag) => ({
    type: GET_PERKS_FILTER_TAG_LIST,
    payload: response,
})

export const fetchPerkListByTypeSuccess = (response: IPerks) => ({
    type: GET_PERK_LIST_BY_TYPE,
    payload: response,
})

export const fetchByPerkByTagSuccess = (response: IPerks) => ({
    type: GET_PERK_BY_TAG_LIST,
    payload: response,
})

const fetchEnd = () => ({
    type: FETCH_PERKS_LIST_ERROR,
})

export const fetchSearchSuccess = (response: IPerks) => ({
    type: GET_SEARCH_LIST,
    payload: response,
})

const postPerkBookmarkSuccess = (isBookmark: boolean, bookmarkedId: number | string) => ({
    type: POST_BOOKMARK_SUCCESS,
    payload: { bookmarkedId, isBookmark },
})

export const fetchPerkLastCommentSuccess = (response: IPerkLastComment) => ({
    type: GET_PERK_LAST_COMMENT_LIST,
    payload: response,
})

export const fetchPerkCommentListSuccess = (response: IPerkCommentList) => ({
    type: GET_PERK_COMMENT_LIST,
    payload: response,
})

export const postPerksRateSuccess = (response: IPerkRateResponse) => ({
    type: POST_PERK_RATE_SUCCESS,
    payload: response,
})

export const postPerkCommentSuccess = (response: IWriteReviewResponse) => ({
    type: POST_PERK_COMMENT_SUCCESS,
    payload: response,
})

export const fetchRecentSearchesSuccess = (response: IRecentSearchesResponse) => ({
    type: GET_RECENT_SEARCHES_LIST,
    payload: response,
})

export const fetchSearchCollectionSuccess = (response: ISearchCollection) => ({
    type: GET_SEARCH_COLLECTION_LIST,
    payload: response,
})

const postPerkPledgeLogSuccess = (response: IPerkPledgeLogResponse) => ({
    type: POST_PERK_PLEDGE_LOG_SUCCESS,
    payload: response,
})

const fetchPerkDetailSuccess = (response: IPerks) => ({
    type: GET_PERK_DETAIL_SUCCESS,
    payload: response,
})

export const clearPerkReview = () => ({
    type: CLEAR_PERK_COMMENTS,
})

/**
 * Fetch perks home page list
 * @returns
 */
export const fetchPerksHomePage = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getPerksHomePageList()

        if (response) {
            dispatch(fetchPerksHomePageSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPerksHomePage: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch perks all brand list
 * @returns
 */
export const fetchPerksBrand = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getPerksBrandList()

        if (response) {
            dispatch(fetchPerksBrandSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPerksBrand: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch user bookmarked perks list
 * @returns
 */
export const fetchBookmarkedPerks = (params: { page: number }) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getPerksBookmarkedList(params)

        if (response) {
            dispatch(fetchPerksBookmarkedSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPerksBrand: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch perks by type
 * @returns
 */
export const fetchPerksByType = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getPerksByTypeList()

        if (response) {
            dispatch(fetchPerksByTypeSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPerksByType: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch perks filter tag list
 * @returns
 */
export const fetchPerksFilterTag = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getPerksFilterTagList()

        if (response) {
            dispatch(fetchPerksFilterTagSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPerksFilterTag: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch perks list by type list
 * @returns
 */

export const fetchPerksListByType = (typeId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getPerkByListType(typeId)

        if (response) {
            dispatch(fetchPerkListByTypeSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPerksListByType: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchSearch = (text: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getSearchList(text)

        if (response) {
            dispatch(fetchSearchSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchSearch: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch perks by tage list
 * @returns
 */

export const fetchByPerkByTag = (tags: string | undefined) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getByPerkByTagList(tags)

        if (response) {
            dispatch(fetchByPerkByTagSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchByPerkByTag: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch perks last comment
 * @returns
 */

export const fetchPerksLastComment = (perk: number | string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getPerkLastCommentList(perk)

        if (response) {
            dispatch(fetchPerkLastCommentSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPerksLastComment: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post home perk book mark
 * @returns
 */

export const postPerkBookMark = (id: number) => async (dispatch: Dispatch) => {
    try {
        const response = await postBookmarkItem(id)

        if (response) {
            dispatch(postPerkBookmarkSuccess(response?.bookmarked, id))
        }
    } catch (e) {
        console.log('ERROR, postPerkBookMark: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch perk comment list
 * @returns
 */

export const fetchPerkCommentList = (params: IPerkCommentsListParams) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getPerkCommentList(params)

        if (response) {
            dispatch(fetchPerkCommentListSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPerkCommentList: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post perk rate
 * @returns
 */

export const postPerkRate = (data: IPerkRate, onSuccess: (res: IPerkRateResponse) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await postPerkRateItem(data)

        if (response) {
            dispatch(postPerksRateSuccess(response))
            dispatch(updateUserPoint(response.thisMonthPoints))
            onSuccess(response)
        }
    } catch (e) {
        console.log('ERROR, postPerkRate: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post perk comment
 * @returns
 */

export const postPerkComment = (
    data: IPerkWriteReviewData | undefined,
    onSuccess: (hasCommented: IWriteReviewResponse) => void,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await postPerkCommentItem(data)

        if (response) {
            dispatch(postPerkCommentSuccess(response))
            dispatch(updateUserPoint(response.thisMonthPoints))
            onSuccess(response)
        }
    } catch (e) {
        console.log('ERROR, postPerkComment: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch recent searches list
 * @returns
 */
export const fetchRecentSearches = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getRecentSearchesItem()

        if (response) {
            dispatch(fetchRecentSearchesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPerksBrand: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch perk search collection list
 * @returns
 */
export const fetchSearchCollectionList = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getSearchCollectionList()

        if (response) {
            dispatch(fetchSearchCollectionSuccess(response))
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
    }
}

/**
 * post perk pledge log
 * @returns
 */

export const postPerkPledgeLog = (id: number | undefined, onSuccess: () => void, setError?: (value: (((prevState: { message: string }) => { message: string }) | { message: string })) => void) => async (dispatch: Dispatch) => {
    try {
        const response = await postPerkPledgeLogItem(id)

        if (response) {
            dispatch(postPerkPledgeLogSuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('ERROR, postPerkPledgeLog: ', e)
        dispatch(fetchEnd())

        if (setError) {
            // @ts-ignore
            setError(e?.response?.data)
        }
    }
}

export const fetchPerkDetail = (id: number) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchPerksStart())
        const response = await getPerkDetail(id)

        if (response) {
            dispatch(fetchPerkDetailSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchRecipeDetail: ', e)
        dispatch(fetchEnd())
    }
}
