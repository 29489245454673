import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPerkLists } from '../state/perks/selectors'
import PerkByTypeItem from '../components/common/PerkByTypeItem'
import AllBrandsItem from '../components/common/AllBrandsItem'
import PerkTags from '../components/common/PerkTags'
import { fetchPerksBrand, fetchPerksByType, fetchPerksFilterTag } from '../state/perks/actions'
import { FilterIcon } from '../components/common/Svg'
import Layout from '../components/layout/Layout'
import SearchInput from '../components/common/SearchInput'
import RecentSearches from '../components/RecentSearches'
import { IPerks } from '../types/data'
import PerkCard from '../components/perk/PerkCard'
import { getHomePageLists } from '../state/home/selectors'

export default function HomeEmployeePerkViewAll() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { homePage } = useSelector(getHomePageLists)
    const { recentSearches, searchCollection } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)

    const data = [
        { id: 1, title: 'Swaps by type', content: <PerkByTypeItem />, loadContent: () => dispatch(fetchPerksByType) },
        { id: 2, title: 'All brands', content: <AllBrandsItem />, loadContent: () => dispatch(fetchPerksBrand) },
        { id: 3, title: 'Tags', content: <PerkTags />, loadContent: () => dispatch(fetchPerksFilterTag) },
    ]

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: 'Filter',
        openedMenu: 1,
        buttonName: 'perks',
    }

    useEffect(() => {
        dispatch(fetchPerksByType)
        dispatch(fetchPerksBrand)
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        // eslint-disable-next-line
    }, [])

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="mobile:mx-10 mx-16 medium:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px]">
                <span onClick={() => navigate('/')} className="xs-mobile:block hidden text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                    Home
                </span>

                <div className="flex items-center justify-between">
                    <h1 className="text-black xs-mobile:block hidden font tracking-tight font-medium tablet:text-36 mobile:text-32 text-26">
                        {homePage?.perkCollection?.name}
                    </h1>

                    <SearchInput action="perks" placeholder="Find a great swap" onFocus={handleFocus} />
                </div>

                {onFocus && (
                    <RecentSearches data={recentSearches?.perks} type="perks" collection={searchCollection} />
                )}

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/lower/swaps')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        Swaps
                    </span>

                    <h1 className="text-black font tracking-tight font-medium mt-2 text-26">
                        {homePage?.perkCollection?.name}
                    </h1>
                </div>

                <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-10">
                    {homePage?.perkCollection?.perks?.map((item: IPerks) => (
                        <div key={item?.id}>
                            <PerkCard item={item} type="home" />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}
