import React from 'react'
import { I18nextProvider } from 'react-i18next'
import ReactDOM from 'react-dom/client'
import i18next from 'i18next'
import App from './Router'
import './static/index.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import globalEn from './translations/en/global.json'
// import globalEs from './translations/es/global.json'

const languageDataString = localStorage.getItem('language')
const languageData = languageDataString ? JSON.parse(languageDataString) : ''

const measurementDataString = localStorage.getItem('persist:auth')
const measurementData = measurementDataString ? JSON.parse(measurementDataString) : null
const user = measurementData?.user ? JSON.parse(measurementData.user) : null
const googleAnalyticsMeasurementId = user?.company?.googleAnalyticsMeasurementId || null

i18next.init({
    interpolation: { escapeValue: false },
    lng: languageData || 'en',
    resources: {
        en: {
            global: globalEn,
        },
        // es: {
        //     global: globalEs,
        // },
    },
})

const newScript = document.createElement('script')

newScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleAnalyticsMeasurementId}');`
document.head.insertBefore(newScript, document.head.firstChild)

document.addEventListener('DOMContentLoaded', () => {
    const newNoscript = document.createElement('noscript')
    const iframe = document.createElement('iframe')

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${googleAnalyticsMeasurementId}`
    iframe.height = '0'
    iframe.width = '0'
    iframe.style.display = 'none'
    iframe.style.visibility = 'hidden'

    newNoscript.appendChild(iframe)

    document.body.insertBefore(newNoscript, document.body.firstChild)
})

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
root.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>,
)
