import React, { useState } from 'react'
import { IPerks } from '../../types/data'
import Skeleton from '../common/Skeleton'

interface IProps {
    item?: IPerks
}

export default function PerkPartners({ item }: IProps) {
    const firstImage = item?.perksImages && item?.perksImages.map((items) => items?.image)
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    return (
        <div className="flex flex-col items-center text-center mb-5 w-[80px] mr-[2px]">
            {loading && <Skeleton className="rounded-full object-cover w-[72px] h-[72px]" />}

            <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-full object-cover w-[72px] h-[72px]`} src={firstImage ? firstImage[0] : ''} alt="" />

            <span className="mt-2 tracking-[-0.01em] text-14 text-grey01 w-20 leading-[120%]">
                {item?.perkBrand?.name}
            </span>
        </div>
    )
}
