import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DismissIcon } from './Svg'
import SecondButton from './SecondButton'
import { IChallenges } from '../../types/data'
import { Context } from './BaseContext'

interface IProps {
    onClose: () => void
    challenge: IChallenges
}

export default function ChallengeBadgeModal({ onClose, challenge }: IProps) {
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    return (
        <div className="fixed inset-0 z-[1000000] overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

            <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                    <div onClick={onClose} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                        <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                    </div>

                    <div className="flex items-end justify-center mobile:mt-[54px] mt-[55px]" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.25))' }}>
                        <div className="flex flex-col items-center justify-center absolute">
                            <img className="object-contain mobile:w-[123px] mobile:h-[123px] w-[100px] h-[100px]" src={challenge?.activeBadgeIcon} alt="" />
                        </div>
                    </div>

                    <div className="flex flex-col justify-center items-center pb-[32px]">
                        <div className="xs-mobile:w-[296px] w-[242px] flex flex-col items-center justify-center mt-[24px]">
                            <h1 className="mobile:text-22 text-18 text-black font-semibold tracking-[-0.02rem] font">
                                {t('global.congrats')}
                            </h1>

                            <h1 className="text-center mobile:text-22 text-18 mt-[2.5px] text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                {challenge.badgeDescription}
                            </h1>

                            <h1 className="text-center mt-1 mobile:text-22 text-18 text-black tracking-tight font-semibold font mobile:leading-[28.6px] leading-[23.6px]">
                                {challenge.badgeTitle}
                            </h1>
                        </div>

                        <SecondButton onClick={onClose} text={t('global.continue')} className="mt-2 w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                    </div>
                </div>
            </div>
        </div>
    )
}
