import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ChallengeLogModalStar, DismissIcon } from './Svg'
import SecondButton from './SecondButton'
import { IChallenges } from '../../types/data'
import { Context } from './BaseContext'
import { useQueryParams } from '../../hooks/queryString'

interface IProps {
    isShown?: boolean
    onClose: () => void
    width?: boolean
    challenge: IChallenges
}

export default function ChallengeDayLogModal({ width, isShown = true, onClose, challenge }: IProps) {
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    const params = useQueryParams()

    useEffect(() => {
        if (params?.milestoneModal) {
            onClose()
        }
        // eslint-disable-next-line
    }, [params])

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 pb-[32px] mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={onClose} className="mobile:w-[36px] cursor-pointer mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex items-end justify-center mobile:mt-[38px] mt-[50px]">
                                    <div className="flex flex-col items-center justify-center absolute">
                                        <ChallengeLogModalStar />
                                    </div>
                                </div>

                                <div className="flex flex-col justify-center items-center ">
                                    <div className={`${width ? 'xs-mobile:w-[386px] w-[242px]' : 'xs-mobile:w-[296px] w-[242px]'} flex flex-col items-center justify-center mobile:mt-[32px] mt-[31px] pb-[32px]`}>
                                        <p className="mobile:text-22 text-18 text-black font-semibold tracking-[-0.02rem] font">
                                            {t('global.well-done')}
                                        </p>

                                        <p className="text-center mobile:text-22 text-18 mt-[2.5px] text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                            {t('lower.you-have')} {challenge.challengeDuration - challenge.loggedDaysCount} {t('lower.days-left-till-the-end-of-the-challenge')}
                                        </p>
                                    </div>

                                    <SecondButton onClick={onClose} text={t('global.continue')} className="w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
