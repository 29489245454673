import React, { useContext } from 'react'
import Select, { StylesConfig } from 'react-select'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getWhiteLabelingList } from '../state/user/selectors'
import { Context } from './common/BaseContext'

interface ColourOption {
  value: string;
  label: JSX.Element;
  color: string;
}

interface IProps{
    onChange: any
}

export default function UnitsDropdown({ onChange }: IProps) {
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')

    const { distanceUnit } = useContext(Context)

    const options = [
        {
            value: 'kilometres',
            label: <span className="not-heading-font font-bold text-14">{t('global.Kilometres')}</span>,
            color: whiteLabeling?.black || 'rgba(0, 0, 0, 1)',
        },
        {
            value: 'miles',
            label: <span className="not-heading-font font-bold text-14">{t('global.Miles')}</span>,
            color: whiteLabeling?.black || 'rgba(0, 0, 0, 1)',
        },
    ]

    const colourStyles: StylesConfig<ColourOption> = {
        dropdownIndicator: (base: any) => ({
            ...base,
            color: whiteLabeling?.rocket,
            cursor: 'pointer',
        }),
        // @ts-ignore
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            cursor: 'pointer',
            color: isSelected
                ? whiteLabeling?.grey01
                : (whiteLabeling?.grey01 && isFocused
                    ? whiteLabeling?.marrow
                    : whiteLabeling?.grey01),
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? whiteLabeling?.white
                    : isFocused
                        ? whiteLabeling?.mint
                        : whiteLabeling?.white,
        }),
        input: (styles) => ({ ...styles }),
        control: (styles) => ({ ...styles, padding: 7, borderRadius: 10, borderColor: whiteLabeling.avocado }),
        placeholder: (styles) => ({ ...styles }),
        indicatorSeparator: () => ({ display: 'none' }),
    }

    const defaultValue = options && options.length > 0
        ? options.find((option) => option.value === distanceUnit)
        : null

    return (
        <Select
            maxMenuHeight={200}
            options={options}
            placeholder={t('log.select-an-option')}
            styles={colourStyles}
            isSearchable={false}
            onChange={onChange}
            defaultValue={defaultValue}
        />
    )
}
