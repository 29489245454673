import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { getPerkLists } from '../../state/perks/selectors'
import { fetchPerksBrand, fetchPerksByType, fetchPerksFilterTag, fetchPerksListByType } from '../../state/perks/actions'
import { FilterIcon } from '../../components/common/Svg'
import PerkByTypeItem from '../../components/common/PerkByTypeItem'
import AllBrandsItem from '../../components/common/AllBrandsItem'
import PerkTags from '../../components/common/PerkTags'
import PerkCard from '../../components/perk/PerkCard'
import SearchInput from '../../components/common/SearchInput'
import RecentSearches from '../../components/RecentSearches'

export default function PerkByType() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { perkId } = useParams()
    const { perksByType } = useSelector(getPerkLists)
    const { perksListByType, recentSearches, searchCollection } = useSelector(getPerkLists)
    const perksData = perksByType?.find((item) => item?.id === Number(perkId))
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Swaps by type' })
    }, [])

    useEffect(() => {
        dispatch(fetchPerksListByType(Number(perkId)))
        // eslint-disable-next-line
    }, [perkId])

    const data = [
        { id: 1, title: t('lower.swaps-by-type'), content: <PerkByTypeItem />, loadContent: () => dispatch(fetchPerksByType) },
        { id: 2, title: t('lower.all-brands'), content: <AllBrandsItem />, loadContent: () => dispatch(fetchPerksBrand) },
        { id: 3, title: t('lower.tags'), content: <PerkTags />, loadContent: () => dispatch(fetchPerksFilterTag) },
    ]

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        openedMenu: 1,
        buttonName: t('lower.swaps'),
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        dispatch(fetchPerksByType)
        // eslint-disable-next-line
    }, [perkId])

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="pb-16 mobile:mt-[52px] mt-[32px] mobile:mx-10 mx-16">
                <span onClick={() => navigate('/lower/swaps')} className="xs-mobile:block hidden text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                    {t('navbar.Swaps')}
                </span>

                <div className="flex items-center justify-between">
                    <h1 className="text-black xs-mobile:block hidden font tracking-tight font-medium tablet:text-36 mobile:text-32 text-26">
                        {perksData?.name}
                    </h1>

                    <SearchInput
                        action="perks"
                        placeholder={t('lower.find-a-great-swap')}
                        onFocus={handleFocus} />
                </div>

                {onFocus && (
                    <RecentSearches
                        data={recentSearches?.perks}
                        type="perks"
                        collection={searchCollection} />
                )}

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/lower/swaps')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('navbar.Swaps')}
                    </span>

                    <h1 className="text-black font tracking-tight font-medium mt-2 text-26">
                        {perksData?.name}
                    </h1>
                </div>

                <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mobile:mt-10 mt-6">
                    {perksListByType?.map((item) => (
                        <div key={item.id}>
                            <PerkCard item={item} type="perk" />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}
