import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { getUser } from '../../state/user/selectors'
import Skeleton from '../common/Skeleton'

interface IProps {
    homeFan?: boolean
}

export default function MyAchievements({ homeFan }: IProps) {
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { badgeTasksList } = useSelector(getUser)
    const [t] = useTranslation('global')
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    const badgeTask = badgeTasksList ? badgeTasksList[0] : null
    const earnedBadge = user.earnedBadges && user.earnedBadges?.length > 0 ? user.earnedBadges[0] : null

    return (
        <div className={`${homeFan && 'hidden'} account-info-footer max-mobile:px-4 pb-3`}>
            <div className="mt-3">
                <div className="flex justify-between items-center cursor-pointer">
                    <h1 className="heading-uppercase font-medium leading-[132%] tracking-[-0.02rem] font text-18 text-black ">
                        {t('home.achievements')}
                    </h1>

                    <button
                        onClick={() => navigate('/me/achievements')}
                        className="font-semibold not-heading-font leading-[150%] tracking-[-0.02rem] underline underline-offset-2 text-14 text-rocket">
                        {t('global.view-all')}
                    </button>
                </div>

                {isEmpty(badgeTasksList) ? null : (
                    <div className="mt-3">
                        <div className="flex">
                            {loading && <Skeleton className="w-[46px] h-[46px] rounded-md" />}
                            <img onLoad={handleImageLoad} src={badgeTask?.disabledIcon} className={`${loading ? 'hidden' : 'block'} w-[46px] h-[46px]`} alt="" />

                            <div className="w-full ml-3 text-sm w-[200px]">
                                {loading ? (
                                    <Skeleton className="w-4/12 h-[10px] rounded-sm mt-1" />
                                ) : (
                                    <p className="font-bold not-heading-font tracking-[-0.02rem] text-black">{badgeTask?.title}</p>
                                )}

                                {loading ? (
                                    <>
                                        <Skeleton className="w-10/12 h-[8px] rounded-sm mt-2" />
                                        <Skeleton className="w-6/12 h-[8px] rounded-sm mt-2" />
                                    </>
                                ) : (
                                    <p className="tracking-[-0.01rem] text-grey01">{badgeTask?.description}</p>
                                )}
                            </div>
                        </div>

                        <div className="flex mt-1">
                            {loading ? (
                                <Skeleton className="w-[40px] h-[6px] rounded-sm mt-1" />
                            ) : (
                                <div className="not-heading-font font-bold text-xs text-center w-[46px] text-spinach">
                                    {badgeTask?.currentProgress}/{badgeTask?.requiredCompletionsCount}
                                </div>
                            )}

                            {loading ? (
                                <Skeleton className="w-10/12 h-[6px] rounded-sm ml-4 mt-1" />
                            ) : (
                                <div className={cn('rounded-full ml-3 flex-1 h-3 bg-mint', css(styles.progressBar))}>
                                    <div className="z-10 relative flex rounded-r-sm rounded-l-xl h-3 bg-raspberry"
                                        style={{ width: `${badgeTask?.completionPercentage}%` }} />
                                    <div
                                        className={cn('w-full relative z-0 rounded-full h-2.5 bg-mint', css(styles.unfilledBar))} />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {earnedBadge && (
                    <div className="flex mt-3">
                        <img src={earnedBadge.image} className={css(styles.image)} alt="" />

                        <div className="w-full ml-3 text-sm w-[200px]">
                            <p className="text-black font-bold not-heading-font tracking-[-0.02rem]">
                                {earnedBadge.title}
                            </p>

                            <p className="text-grey01 tracking-[-0.01rem]">
                                {earnedBadge.description}
                            </p>
                        </div>
                    </div>
                )}
            </div>

            {/* <LinkText className="text-start" to="/me/leaderboard" text="View leaderboard" /> */}
        </div>
    )
}

const styles = StyleSheet.create({
    progressBar: {
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.25)',
    },
    unfilledBar: {
        marginTop: -11,
    },
    image: {
        width: 46,
        height: 46,
    },
})
