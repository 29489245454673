import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NoteIcon, SavedIconActive, SavedIconDefault } from '../common/Svg'
import { postRecipeBookmark } from '../../state/recipe/actions'
import { getRecipe } from '../../state/recipe/selectors'
import RecipeWriteReviewModal from './RecipeWriteReviewModal'
import RecipeRateModal from './RecipeRateModal'
import Skeleton from '../common/Skeleton'
import { Context } from '../common/BaseContext'

interface IProps {
    openReviews: () => void
    recipesId?: number | undefined
}

export default function RecipeDetailSideBar({ openReviews, recipesId }: IProps) {
    const { recipeDetail: recipe } = useSelector(getRecipe)
    const [loading, setLoading] = useState(true)
    const { screenWidth } = useContext(Context)
    const { recipeCommentList } = useSelector(getRecipe)
    const [t] = useTranslation('global')

    useEffect(() => {
        if (recipe?.id) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [recipe])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onPressBookmark = async () => {
        await dispatch(postRecipeBookmark(recipe?.id || 0))
    }

    const navigation = () => {
        if (recipesId) {
            navigate('/lower/recipes')
        } else {
            navigate(-1)
        }
    }

    return (
        <div className="flex flex-col medium:mb-0 justify-between px-[16px] py-[24px] mobile:p-[32px] tablet:ml-0 bg-pistachio w-full">
            <div className="flex justify-center">
                <span onClick={navigation} className="text-center tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-rocket mt-0 medium:mt-10 text-14">
                    {t('navbar.Recipes')}
                </span>
            </div>

            <div>
                <div style={{ lineHeight: 1.2 }} className="text-center">
                    <h1 className="font tracking-tight font-medium text-32 w-full mobile:mt-[65px] mt-[36.5px] text-center">
                        {loading ? (
                            <div className="flex flex-col items-center">
                                <Skeleton className="medium:w-[100%] w-full h-[18px] rounded-sm mt-3" />
                            </div>
                        ) : (
                            recipe?.title
                        )}
                    </h1>

                    <div className="flex medium:block mt-[24px] justify-center">
                        <p className="mt-2 mr-6 text-grey01 text-14">
                            <span className="font-bold">{t('lower.prep-time')}</span> {recipe?.prepTime} {t('global.min')}
                        </p>

                        <p className="mt-2 mr-6 text-grey01 text-14">
                            <span className="font-bold">{t('lower.cook-time')}</span> {recipe?.cookTime} {t('global.min')}
                        </p>

                        <p className="mt-2 mr-6 text-grey01 text-14">
                            <span className="font-bold">{t('lower.serves')}</span> {recipe?.serves}
                        </p>
                    </div>
                </div>

                {screenWidth <= 911 && (
                    <div className="w-full mobile:mt-[65px] mt-[29.5px]">
                        <div className="flex justify-between w-full">
                            <div className="flex">
                                <RecipeRateModal />

                                <RecipeWriteReviewModal />
                            </div>

                            <div className="flex">
                                <button onClick={() => onPressBookmark()} className="rounded-full flex justify-center items-center bg-black w-[28px] h-[28px]">
                                    {recipe?.isBookmark ? (
                                        <SavedIconActive width={12} height={15} />
                                    ) : (
                                        <SavedIconDefault width={12} height={15} />
                                    )}
                                </button>

                                {recipeCommentList?.results?.length > 0 && (
                                    <button className="ml-[16px]" onClick={openReviews}>
                                        <NoteIcon width={29} height={29} />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {screenWidth > 911 && (
                <div className="w-full mobile:mt-[65px] mt-[29.5px]">
                    <div className="flex justify-between w-full">
                        <div className="flex">
                            <RecipeRateModal />

                            <RecipeWriteReviewModal />
                        </div>

                        <div className="flex">
                            <button onClick={() => onPressBookmark()} className="rounded-full flex justify-center items-center bg-black w-[28px] h-[28px]">
                                {recipe?.isBookmark ? (
                                    <SavedIconActive width={12} height={15} />
                                ) : (
                                    <SavedIconDefault width={12} height={15} />
                                )}
                            </button>

                            {recipeCommentList?.results?.length > 0 && (
                                <button className="ml-[16px]" onClick={openReviews}>
                                    <NoteIcon width={29} height={29} />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
