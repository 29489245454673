import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SecondButton from '../common/SecondButton'
import { IQuiz } from '../../types/data'
import Skeleton from '../common/Skeleton'
import { fetchQuiz } from '../../state/quiz/actions'
import { getQuiz } from '../../state/quiz/selectors'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item: IQuiz
}

export default function QuizCard({ item }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { quiz } = useSelector(getQuiz)
    const [t] = useTranslation('global')

    const navigateChallengeDetail = (quizId: number) => {
        navigate(`/quiz/${quizId}`)
    }

    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    const onRestartLoad = useCallback(async () => {
        if (!quiz?.id && item?.id !== quiz?.id) await dispatch(fetchQuiz(item?.id))

        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    return (
        <div onClick={() => navigateChallengeDetail(item?.id)}>
            <div className="cursor-pointer w-full" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                <div className="flex flex-col justify-end">
                    {loading && <Skeleton className="mobile:h-[351px] rounded-t-lg object-cover" />}

                    {item?.image ? (
                        <img onLoad={handleImageLoad}
                            className={`rounded-t-lg mobile:h-[351px]' ${loading ? 'hidden' : 'block'} object-cover`}
                            src={item?.image} alt="" />
                    ) : (
                        <div className="rounded-t-lg w-full h-[351px] border" />
                    )}
                </div>

                <div className="p-4 rounded-b-lg bg-pistachio">
                    {item.sponsorshipBlock && <SponsoredByBlock containerStyles={{ padding: 0, paddingBottom: 10 }} noBorder noBackground item={item.sponsorshipBlock} />}

                    <div>
                        {item?.quizName && (
                            <h1 className="font-normal text-grey01 text-12">
                                {item?.quizName}
                            </h1>
                        )}

                        <h1 className="text-darken font tracking-tight font-medium text-18">
                            <ContentBlockTitle text={item?.title} />
                        </h1>
                    </div>

                    <SecondButton
                        disabled={item?.image ? loading : false}
                        text={t('home.take-the-quiz')}
                        className="mt-2.5 mobile:w-[184px] w-full h-[48px] font-medium bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                    />
                </div>
            </div>
        </div>
    )
}
