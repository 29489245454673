import React, { SyntheticEvent, useContext, useEffect, useState } from 'react'
import './Layout.css'
import '../../static/index.css'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MenuChevronUpIcon,
    MenuHomeIcon,
    MenuLearnIcon,
    MenuLogIcon,
    MenuLowerIcon,
    MenuMeIcon,
    MenuPlanIcon } from '../common/Svg'
import { Context } from '../common/BaseContext'
import { getMenuItems, getUser } from '../../state/user/selectors'
import { travelSelector } from '../../state/travel/selectors'
import { fetchGame } from '../../state/travel/actions'
import { clearTravelData } from '../../utils/travel'

function MenuItem({ icon, className, text, onClick }: {icon: JSX.Element, className?: string, text: string, onClick: (event:SyntheticEvent) => void}) {
    return (
        <div onClick={onClick} className={cn('cursor-pointer flex py-3.5 bg-white justify-center items-center rounded-md mb-2', className)}>
            {icon}
            <p className="text-cavolo text-14 font-semibold ml-2.5 not-heading-font">{text}</p>
        </div>
    )
}

function SustainabilityMenu({ isFixed = false }: {isFixed?: boolean}): JSX.Element {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [t] = useTranslation('global')
    const { user } = useSelector(getUser)
    const [isOpen, setIsOpen] = useState(false)
    const { learnUrls, lowerUrls, meUrls } = useContext(Context)
    const { menuItems } = useSelector(getMenuItems)
    const { gameList } = useSelector(travelSelector)
    const { showFactOfTheDayModal, setShowFactOfTheDayModal, setShowArticleModal, showArticleModal } = useContext(Context)

    const onClickLink = (event: SyntheticEvent, link: string | undefined) => {
        event.stopPropagation()
        setIsOpen(false)

        if (showFactOfTheDayModal || showArticleModal) {
            setShowFactOfTheDayModal(false)
            setShowArticleModal(0)
        } else {
            navigate(link || '/')
        }
    }

    const onClickLinkTravel = (event: SyntheticEvent) => {
        clearTravelData(dispatch)

        event.stopPropagation()
        setIsOpen(false)

        if ((gameList?.length === 1 && user?.company?.autoForwardIfSingleEvent) && user?.company?.travelTrackingType !== 'standard') {
            const today = new Date()
            localStorage.setItem('travelData', JSON.stringify({
                date: today,
                gameDate: gameList[0]?.date,
                gameId: gameList[0]?.id,
                legs: [],
                gameData: gameList[0],
            }))

            navigate('/log', { state: { prevUrl: '/' } })
        } else {
            navigate('/log/track-travel-fan')
        }
    }

    useEffect(() => {
        dispatch(fetchGame())
    // eslint-disable-next-line
    }, [])

    const menuItemsList = menuItems.map((i) => i.name)
    const sustainabilityMenuContentHeight = document.getElementById('sustainabilityMenuContent')?.offsetHeight || 0

    return (
        <>
            {isOpen && (
                <div style={{ background: !isOpen ? 'transparent' : 'rgba(255, 255, 255, 0.44)', transition: 'background 0.5s ease' }} className="sticky z-[999999] bottom-0">
                    <div style={{ width: window.innerWidth, height: window.innerHeight }} />
                </div>
            )}

            <div onClick={() => setIsOpen(!isOpen)} className={`${user?.company?.menuPosition === 'None' && 'hidden'} bg-spinach z-[1000000] w-full px-5 pt-2 rounded-t-md border-t-[1px] border-white`} style={{ bottom: 0, position: isFixed ? 'fixed' : 'sticky' }}>
                <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer absolute left-[50%] ml-[-33px]" style={{ top: -38.7 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="39" viewBox="0 0 66 39" fill="none">
                        <path d="M0.500001 38.5634C-0.0299208 33.9782 0.156162 27.6197 1.59068 23.2197C3.0252 18.8198 5.39346 14.7681 8.53782 11.3344C11.6822 7.90075 15.5305 5.16378 19.8266 3.30573C24.1227 1.44768 28.7681 0.511178 33.4535 0.558555C38.1389 0.605931 42.7568 1.63611 46.9999 3.5805C51.2429 5.5249 55.0138 8.33891 58.0615 11.8352C61.1092 15.3315 63.3637 19.4299 64.6751 23.8576C65.9865 28.2853 66.1577 33.9902 65.5 38.5634L60.5001 38.5633C61.061 34.6627 60.6185 28.84 59.5 25.0635C58.3815 21.287 56.5995 18.0456 54 15.0635C51.4005 12.0814 48.119 9.72191 44.5 8.06348C40.881 6.40504 37.4498 5.60389 33.4535 5.56348C29.4572 5.52307 25.1643 6.47869 21.5 8.06348C17.8357 9.64827 15.1819 11.6348 12.5 14.5635C9.81808 17.4922 7.72355 20.8106 6.5 24.5635C5.27645 28.3163 5.04801 34.6525 5.5 38.5633L0.500001 38.5634Z" fill="white" />
                    </svg>
                </div>

                <div className="w-[56px] flex justify-center pt-1.5 z-[-1] rounded-full h-[56px] bg-spinach absolute top-[-33px] left-[50%] ml-[-28px]">
                    <MenuChevronUpIcon style={{ transform: isOpen ? 'rotate(180deg)' : undefined, transition: 'transform 0.2s ease' }} />
                </div>

                <p onClick={() => setIsOpen(!isOpen)} className="font-semibold text-14 text-savoy pb-3.5 text-center not-heading-font">
                    {t('global.sustainability-menu')}
                </p>

                <div style={{ transition: 'height 0.5s ease', height: isOpen ? sustainabilityMenuContentHeight + 20 : 0, overflow: 'hidden' }}>
                    <div id="sustainabilityMenuContent">
                        <MenuItem onClick={(event: SyntheticEvent) => onClickLink(event, '/')} text={t('navbar.Home')} icon={<MenuHomeIcon />} />

                        <div className="w-full mt-2 flex flex-wrap justify-between mb-5">
                            {menuItemsList.includes('Plan') && (
                                <MenuItem
                                    className="w-[calc(50%-0.25rem)]"
                                    onClick={(event: SyntheticEvent) => onClickLink(event, '/plan/plan-journey')}
                                    text={t('navbar.Plan')}
                                    icon={<MenuPlanIcon />} />
                            )}

                            {menuItemsList.includes('Log') && (
                                <MenuItem
                                    className="w-[calc(50%-0.25rem)]"
                                    onClick={(event: SyntheticEvent) => onClickLinkTravel(event)}
                                    text={t('navbar.Log')}
                                    icon={<MenuLogIcon />} />
                            )}

                            {menuItemsList.includes('Learn') && (
                                <MenuItem
                                    className="w-[calc(50%-0.25rem)]"
                                    onClick={(event: SyntheticEvent) => onClickLink(event, learnUrls)}
                                    text={t('navbar.Learn')}
                                    icon={<MenuLearnIcon />} />
                            )}

                            {menuItemsList.includes('Lower') && (
                                <MenuItem
                                    className="w-[calc(50%-0.25rem)]"
                                    onClick={(event: SyntheticEvent) => onClickLink(event, lowerUrls)}
                                    text={t('navbar.Lower')}
                                    icon={<MenuLowerIcon />} />
                            )}

                            {menuItemsList.includes('Me') && (
                                <MenuItem
                                    className="w-[calc(50%-0.25rem)]"
                                    onClick={(event: SyntheticEvent) => onClickLink(event, meUrls)}
                                    text={t('navbar.Me')}
                                    icon={<MenuMeIcon />} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SustainabilityMenu
