import React from 'react'
import cn from 'classnames'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { IQuestion } from '../../types/data'
import SecondButton from '../common/SecondButton'

interface IProps {
    disabled: boolean
    buttonLoading: boolean
    showAnswer: boolean
    onSubmit: () => void
    question: IQuestion | null
    answers: number[]
}

export default function QuizDetailFooter({ disabled, buttonLoading, onSubmit, showAnswer, answers, question }: IProps) {
    const [t] = useTranslation('global')

    const isCorrect = isEqual(answers, question?.correctAnswers)

    return (
        <div className={cn('medium:absolute bottom-0 w-screen duration-100', { 'bg-pistachio': showAnswer }, { 'h-[182px] mobile:h-[140px]': showAnswer, 'h-[116px] mobile:h-[112px]': !showAnswer })}
            style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)' }}>
            <div className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]">
                    {showAnswer && (
                        <>
                            <div className={cn('font font-medium tracking-[-0.02rem] leading-[132%] mobile:text-22 text-18 mb-1', { 'text-black': isCorrect, 'text-error': !isCorrect })}>
                                {isCorrect ? question?.correctAnswerTitle : question?.incorrectAnswerTitle}
                            </div>

                            <div className={cn('tracking-[-0.01rem] leading-[150%] mobile:text-16 text-14', { 'text-grey01': isCorrect, 'text-error': !isCorrect })}>
                                {isCorrect ? question?.correctAnswerContent : question?.incorrectAnswerContent}
                            </div>
                        </>
                    )}
                </div>

                <div className="mobile:ml-8 mobile:w-[180px] relative">
                    <SecondButton
                        disabled={disabled}
                        loading={buttonLoading}
                        onClick={onSubmit}
                        text={showAnswer ? t('global.continue') : t('global.check-my-answer')}
                        className={`border-2 border-rocket absolute bottom-10 duration-100 w-full h-[48px] font-semibold bg-rocket rounded-md text-14 ${disabled ? 'text-grey01' : 'text-white'} `}
                    />
                </div>
            </div>
        </div>
    )
}
