import React, { useState } from 'react'
import { IHowToEarnPoints } from '../types/data'
import Skeleton from './common/Skeleton'

interface IProps {
    item: IHowToEarnPoints
}

export default function HowToEarnPointsCard({ item }: IProps) {
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)

    return (
        <div onClick={() => window.open(item.link, '_self')} className="cursor-pointer relative"
            style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div>
                {loading && <Skeleton className="w-full aspect-w-16 aspect-h-9 aspect-video object-cover w-full" />}
                <img onLoad={handleImageLoad} src={item?.image} className={`${loading ? 'hidden' : 'block'} rounded-t-lg w-full aspect-w-16 aspect-h-9 aspect-video object-cover`} alt="" />

                <div className="flex flex-col justify-between p-3 rounded-b-lg bg-white h-[102px] hover:bg-[#F2F2F2] hover:drop-shadow-[0.3]">
                    <h5 className="mb-0.5 font-medium font tracking-tight line-clamp-2 text-black text-15 leading-[132%]">
                        {loading ? (
                            <>
                                <Skeleton className="w-full mr-[45%] h-[13px] rounded-sm mt-2" />
                                <Skeleton className="w-8/12 mr-[45%] h-[13px] rounded-sm mt-2" />
                            </>
                        ) : (
                            <div>
                                {item?.title}
                            </div>
                        )}
                    </h5>
                </div>
            </div>
        </div>
    )
}
