import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { getLeaderboard } from '../state/leaderboard/selectors'
import { getUser } from '../state/user/selectors'

interface IProps {
    home?: boolean
    progressDropDown?: boolean
}

export default function GoldZoneBlock({ home, progressDropDown }: IProps) {
    const { user } = useSelector(getUser)
    const { leaderboard } = useSelector(getLeaderboard)
    const [t] = useTranslation('global')
    const leaderboards = leaderboard.leaderboards.find((item) => item.id === leaderboard?.currentLeaderboard)
    const goldZoneOptionLength = leaderboard?.leaderboards?.filter((item) => item?.goldZoneOption !== 'off')

    const goldZonePoint = (user?.goldZone?.goldZonePoints || 0) - (user?.goldZone?.currentPoints || 0)

    return (
        (isEmpty(goldZoneOptionLength) || goldZoneOptionLength?.length > 1) || leaderboards?.goldZoneOption === 'off' ? null : (
            <div className={`${home ? `py-2 px-3 rounded-lg ${home && progressDropDown ? 'bg-pistachio' : 'bg-grey04'} min-h-[55px]` : 'flex flex-col mobile:h-[82px] xs-mobile:h-[55px] bg-grey04 rounded-lg mobile:p-[16px] py-[8px] px-[12px]'}`}>
                <p className={`${home ? `${home && progressDropDown ? 'text-18' : 'text-14'} font font-medium not-heading-font tracking-[-0.02rem] text-black` : 'font font-medium not-heading-font w-full tracking-[-0.02rem] text-cavolo mobile:text-[22px] text-16 mobile:leading-[29.04px] leading-[21.12px]'}`}>
                    {goldZonePoint > 0
                        ? `${goldZonePoint} ${t('global.pts')}`
                        : t('global.well-done')}
                </p>

                <p className={`${home ? `${home && progressDropDown ? 'text-14' : 'text-12'} tracking-[-0.01rem]` : 'tracking-[-0.01rem] w-full leading-[150%] text-cavolo text-12 mobile:text-14 mobile:leading:[21px] leading:[18px]'}`}>
                    {goldZonePoint > 0
                        ? leaderboard?.leaderboards.length === 1 ? t('me.to-gold-zone') : t('me.to-reach-org-unit-name-gold-zone').replace('[org-unit-name]', user?.company?.organisationalUnitName.toLowerCase())
                        : t('me.you\'re-in-the-org-unit-name-gold-zone').replace('[org-unit-name]', user?.company?.organisationalUnitName.toLowerCase())}
                </p>
            </div>
        )
    )
}
