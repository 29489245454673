import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { UserState } from './types'
import { FETCH_USER_ERROR,
    FETCH_USER_START,
    LOGIN_SUCCESS,
    FETCH_USER_UPDATE_SUCCESS,
    SET_USER,
    SIGN_OUT,
    FETCH_USER_SUCCESS,
    FETCH_USER_REGISTER_SUCCESS,
    FETCH_USER_RESET_PASSWORD_SUCCESS,
    FETCH_BADGE_TASKS_SUCCESS,
    FETCH_USER_ADD_VISIT_POINTS,
    FETCH_PRIVACY_POLICY_SUCCESS,
    FETCH_HOW_TO_EARN_POINTS_SUCCESS,
    FETCH_USER_AVATAR_UPDATE_SUCCESS,
    FETCH_FINAL_POINT_REWARDS_SUCCESS,
    FETCH_SETTINGS_SUCCESS,
    FETCH_WHITE_LABELING_SUCCESS,
    FETCH_LOGIN_CONFIG_SUCCESS,
    FETCH_MENU_ITEMS_SUCCESS,
    FETCH_LANDING_PAGE_SUCCESS,
    FETCH_LANGUAGE_ITEMS_SUCCESS,
    FETCH_LOGIN_CONFIG_START,
    GET_ORGANISATIONAL_UNITS_SEARCH_LIST,
    FETCH_REFERRAL_ITEMS_SUCCESS,
    FETCH_SAVED_PLACES_SUCCESS, FETCH_ACTIVITIES_SUCCESS } from './consts'

const initialState: UserState = {
    // @ts-ignore
    user: {},
    isSso: false,
    // @ts-ignore
    token: null,
    registerPoints: 0,
    users: [],
    privacyPolicy: {},
    howToEarnPoints: [],
    loading: false,
    loginConfigLoading: false,
    badgeTasksList: [],
    finalPointRewards: {},
    settings: {},
    whiteLabeling: {},
    loginConfig: {},
    menuItems: [],
    landingPage: [],
    languageItem: [],
    organisationUnitsSearchResults: [],
    referral: {},
    savedPlaces: [],
    activities: [],
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AnyAction): UserState => {
    const { payload } = action

    switch (action.type) {
    case SET_USER:
        return {
            ...state,
            user: payload.user,
        }
    case FETCH_USER_START:
        return { ...state, loading: true }

    case FETCH_LOGIN_CONFIG_START:
        return { ...state, loginConfigLoading: true }

    case LOGIN_SUCCESS:
        return {
            ...state,
            user: payload.user,
            token: { token: payload.token },
            isSso: payload.isSso,
            loading: false,
            loginConfig: {},
        }
    case FETCH_USER_SUCCESS:
        return {
            ...state,
            user: payload,
            loading: false,
        }
    case FETCH_USER_UPDATE_SUCCESS:
        return {
            ...state,
            user: payload,
            loading: false,
        }
    case FETCH_USER_REGISTER_SUCCESS:
        return {
            ...state,
            user: payload.user,
            token: { token: payload.token },
            registerPoints: payload.registerPoints,
            isSso: payload.isSso,
            loading: false,
            loginConfig: {},
        }
    case FETCH_BADGE_TASKS_SUCCESS:
        return {
            ...state,
            badgeTasksList: payload,
            loading: false,
        }
    case FETCH_USER_RESET_PASSWORD_SUCCESS:
        return {
            ...state,
            loading: false,
        }
    case FETCH_USER_ADD_VISIT_POINTS:
        return {
            ...state,
            loading: false,
        }
    case FETCH_PRIVACY_POLICY_SUCCESS:
        return {
            ...state,
            privacyPolicy: payload,
            loading: false,
        }

    case FETCH_HOW_TO_EARN_POINTS_SUCCESS:
        return {
            ...state,
            howToEarnPoints: payload,
            loading: false,
        }

    case FETCH_USER_AVATAR_UPDATE_SUCCESS:
        return {
            ...state,
            loading: false,
        }

    case FETCH_FINAL_POINT_REWARDS_SUCCESS:
        return {
            ...state,
            finalPointRewards: payload,
            loading: false,
        }

    case FETCH_SETTINGS_SUCCESS:
        return {
            ...state,
            settings: payload,
            loading: false,
        }

    case FETCH_WHITE_LABELING_SUCCESS:
        return {
            ...state,
            whiteLabeling: payload || {},
            loading: false,
        }

    case FETCH_LOGIN_CONFIG_SUCCESS:
        return {
            ...state,
            loginConfig: payload,
            whiteLabeling: payload.whiteLabeling,
            loading: false,
            loginConfigLoading: false,
        }

    case FETCH_MENU_ITEMS_SUCCESS:
        return {
            ...state,
            menuItems: payload,
            loading: false,
        }

    case FETCH_LANGUAGE_ITEMS_SUCCESS:
        return {
            ...state,
            languageItem: payload,
            loading: false,
        }

    case FETCH_LANDING_PAGE_SUCCESS:
        return {
            ...state,
            landingPage: payload.landingPage,
            whiteLabeling: payload.whiteLabeling,
            loading: false,
        }
    case GET_ORGANISATIONAL_UNITS_SEARCH_LIST:
        return {
            ...state,
            organisationUnitsSearchResults: payload,
            loading: false,
        }
    case FETCH_REFERRAL_ITEMS_SUCCESS:
        return {
            ...state,
            referral: payload,
            loading: false,
        }
    case FETCH_SAVED_PLACES_SUCCESS:
        return {
            ...state,
            savedPlaces: payload,
            loading: false,
        }
    case FETCH_ACTIVITIES_SUCCESS:
        return {
            ...state,
            activities: payload,
            loading: false,
        }
    case FETCH_USER_ERROR:
        return { ...state, loading: false }
    case SIGN_OUT:
        // @ts-ignore
        return { user: {}, token: null, loading: false }
    default:
        return state
    }
}

const persistConfig: PersistConfig<UserState> = {
    key: 'auth',
    storage,
    whitelist: [
        'user',
        'token',
        'badgeTasksList',
        'finalPointRewards',
        'isSso',
        'whiteLabeling',
        'loginConfig',
        'menuItems',
        'landingPage',
        'languageItem',
        'organisationUnitsSearchResults',
        'referral',
        'savedPlaces',
        'activities',
    ],
}

export default persistReducer(persistConfig, reducer)
