import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IRecentSearches, ISearchCollection } from '../types/data'
import { RecentArrow } from './common/Svg'
import { articleSearch, perkSearch, recipeSearch } from './common/SearchInput'
import Skeleton from './common/Skeleton'

interface IProps {
    data: IRecentSearches[]
    collection?: ISearchCollection[]
    type: string
}

export default function RecentSearches({ data, type, collection }:IProps) {
    const navigate = useNavigate()
    const [t] = useTranslation('global')

    const onClick = (value: string) => {
        if (type === 'perks') {
            perkSearch(value, navigate)
        }

        if (type === 'recipes') {
            recipeSearch(value, navigate)
        }

        if (type === 'articles') {
            articleSearch(value, navigate)
        }
    }

    return (
        <div>
            <h1 className="text-18 font-medium leading-[29.04px] font mt-[32px] text-black">
                {t('article.your-recent-searches')}
            </h1>

            <div className="mt-[16px] mobile:w-[343px]">
                {data ? data?.map((item) => (
                    <div key={item?.id} onClick={() => onClick(item?.text)} className="flex items-center justify-between mt-[8px] cursor-pointer">
                        <h1 className="leading-[22.5px] text-15 font-normal text-grey01 tracking-[-0.03rem]">
                            {item?.text}
                        </h1>

                        <div className="flex items-center justify-center w-[24px] h-[24px]">
                            <RecentArrow width={12} height={12} />
                        </div>
                    </div>
                )) : (
                    <div className="mt-6">
                        <Skeleton className="w-4/12 h-[13px] rounded-sm mt-4" />
                        <Skeleton className="w-3/12 h-[13px] rounded-sm mt-4" />
                        <Skeleton className="w-5/12 h-[13px] rounded-sm mt-4" />
                        <Skeleton className="w-6/12 h-[13px] rounded-sm mt-4" />
                        <Skeleton className="w-7/12 h-[13px] rounded-sm mt-4" />
                    </div>
                )}
            </div>

            <h1 className="text-18 font-medium leading-[29.04px] font mt-[32px] text-black">
                {t('article.suggestions')}
            </h1>

            <div className="flex flex-wrap mt-[16px] mobile:w-[343px]">
                {collection?.map((item) => (
                    <button key={item.id} className="h-[36px] rounded-[30px] flex items-center justify-center cursor-pointer mb-[8px] mr-[8px] px-5 bg-rocket">
                        <span className="font-semibold text-pistachio text-12">
                            {item?.title}
                        </span>
                    </button>
                ))}
            </div>
        </div>
    )
}
