import React, { useContext } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DismissIcon } from '../common/Svg'
import { Context } from '../common/BaseContext'
import { fetchUser, fetchUserAvatarUpdate } from '../../state/user/actions'

interface IProps {
    showModal: boolean
    setShowModal: (value: boolean) => void
    setLoading: (value: boolean) => void
}

export default function UploadImageModal({ showModal, setShowModal, setLoading }: IProps) {
    const dispatch = useDispatch()
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    const onSubmit = async () => {
        await dispatch(fetchUser)
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }

        const file = e.target.files[0]
        setLoading(true)
        await setShowModal(false)
        await dispatch(fetchUserAvatarUpdate({ uploadedImage: file }))
        await dispatch(fetchUser)
        setLoading(false)
    }

    return (
        <div>
            {showModal && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[407px] w-11/12 pb-[32px] mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={() => setShowModal(false)} className="mobile:w-[36px] cursor-pointer mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <Formik onSubmit={onSubmit} initialValues={{ uploadedImage: '' }}>
                                    <Form>
                                        <div className="flex flex-col items-center">
                                            <h1 className="text-center text-22 tracking-tight font-medium font text-black leading-[132%]">
                                                {t('account.update-your-profile-image')}
                                            </h1>

                                            <label htmlFor="file-upload" className="mt-6 flex items-center justify-center cursor-pointer border-2 border-solid border-green-700 bg-mint h-[48px] w-[120px] font-semibold border rounded-md text-14 text-marrow">
                                                {t('account.add-files')}
                                            </label>

                                            <input
                                                name="image"
                                                id="file-upload"
                                                type="file"
                                                className="hidden"
                                                onChange={handleFileChange} />
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
