import React, { useContext } from 'react'
import { Context } from './common/BaseContext'
import { DismissIcon, LeafPoint, PlusIcon } from './common/Svg'
import SecondButton from './common/SecondButton'

interface IProps {
    isShown?: boolean
    onClose: () => void
    point: number
    width?: boolean
    description: React.ReactNode
}

export default function ModalPopupWindow({ width, isShown = true, onClose, point, description }: IProps) {
    const { screenWidth } = useContext(Context)

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={onClose} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex items-end justify-center mobile:mt-[76px] mt-[60px]" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.25))' }}>
                                    <div className="flex flex-col items-center justify-center absolute mobile:w-[148px] mobile:h-[148px] w-[120px] h-[120px] rotate-[-45deg]">
                                        <div className="absolute flex items-center rotate-45">
                                            <PlusIcon width={18} height={20} />
                                            <h1 className="mobile:text-58 text-48 font font-semibold text-spinach mx-1">
                                                {point}
                                            </h1>
                                            <LeafPoint width={25} height={25} />
                                        </div>

                                        <div className="bg-sky w-full h-2/4 rounded-t-full" />
                                        <div className="bg-sea w-full h-2/4 rounded-b-full" />
                                    </div>
                                </div>

                                <div className="flex flex-col justify-center items-center pb-[32px]">
                                    <div className={`${width ? 'xs-mobile:w-[386px] w-[242px]' : 'xs-mobile:w-[296px] w-[242px]'} flex flex-col items-center justify-center mobile:mt-[22px] mt-[24px]`}>
                                        <h1 className="mobile:text-22 text-18 text-black font-bold tracking-tight font">
                                            Congrats!
                                        </h1>

                                        {description}
                                    </div>

                                    <SecondButton onClick={onClose} text="Continue" className="mt-6 w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
